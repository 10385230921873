<template>
    <v-card :width="isDialog ? 1111: ''" :loading="isCallingApi" :flat="!isDialog">
        <v-card-title>
            <v-row class="justify-space-between px-4 py-6">
                <v-row>
                    <h2 class="px-2">{{agent.user.name}} Profile Settings</h2>
                </v-row>

                <v-btn icon @click="$emit('hideDialog')" v-if="isDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>

            <v-tabs v-model="headerTab">
                <v-tab>Profile</v-tab>
                <v-tab :disabled="true">Password</v-tab>
            </v-tabs>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-tabs-items v-model="headerTab">
                <v-tab-item>
                    <h3>Profile Photo</h3>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="2">
                                    <v-avatar size="60">
                                        <v-img :src="agent.user.image_url ? agent.user.image_url : 'https://place-hold.it/140/E5E5E5/FFFFFF?text=Image'"></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="10">
                                    <v-row>
                                        <v-col cols="5">
                                            <v-avatar size="18" tile>
                                                <v-img src="../../assets/icons/upload.svg"></v-img>
                                            </v-avatar>
                                            <input type="file" ref="file" style="display: none"  @change="previewImage" />
                                            <a @click="uploadPhoto">Upload Photo</a>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-avatar size="18" tile>
                                                <v-img src="../../assets/icons/trash.svg"></v-img>
                                            </v-avatar>
                                            <a @click="removePhoto">Remove Photo</a>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <b><i><small>Photos help teammates recognize each other</small></i></b>
                                    </div>
                                    <div>
                                        <v-switch inset dense label="Show status as Away"
                                                  v-model="agent.is_away"
                                                  :readonly="!hasUpdatePermissions"
                                        @change="updateAgent('is_away', $event)"></v-switch>
                                    </div>
                                </v-col>
                            </v-row>


                        </v-col>
                        <v-col cols="12" md="6" v-if="user.agent.is_admin_agent">
                            <p>Is profile active</p>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-btn block outlined :color="agent.is_active ? 'primary': ''"
                                           @click="updateAgent('is_active', 1)"
                                           :disabled="!hasUpdatePermissions"
                                    >Active</v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-btn block outlined :color="!agent.is_active ? 'primary': ''"
                                           @click="updateAgent('is_active', 0)"
                                           :disabled="!hasUpdatePermissions"
                                    >Inactive</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <p>Full Name</p>
                                    <v-text-field outlined dense
                                                  v-model="agent.user.name"
                                                  @change="updateAgentUser('name', $event)"
                                                  :readonly="!hasUpdatePermissions"
                                                  @click="handleClick"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p>Username</p>
                                    <v-text-field outlined dense
                                                  v-model="agent.username"
                                                  @change="updateAgent('username', $event)"
                                                  :readonly="!hasUpdatePermissions"
                                                  @click="handleClick"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="user.agent.is_admin_agent">
                                <v-col cols="12">
                                    <p>Campuses</p>
                                    <div :class="agentCampuses.length > 4 ? 'school-area' : ''">
                                      <v-combobox
                                          v-model="agentCampuses"
                                          :items="campuses"
                                          multiple
                                          chips
                                          outlined
                                          dense
                                          item-text="name"
                                          item-value="id"
                                          @change="updateAgentCampuses($event)"
                                          :readonly="!hasUpdatePermissions"
                                      >
                                        <template v-slot:selection="data">
                                          <v-chip
                                              :key="JSON.stringify(data.item)"
                                              v-bind="data.attrs"
                                              :input-value="data.selected"
                                              :disabled="data.disabled"
                                              close
                                              @click:close="data.parent.selectItem(data.item)"
                                          >
                                            <v-avatar
                                                class="accent white--text"
                                                left
                                                v-text="data.item.name.slice(0, 1).toUpperCase()"
                                            ></v-avatar>
                                            {{ data.item.name }}
                                          </v-chip>
                                        </template>

                                        <template v-slot:prepend-item>
                                          <v-list-item
                                              ripple
                                              @click="toggleSchools"
                                          >
                                            <v-list-item-action>
                                              <v-icon :color="agentCampuses.length > 0 ? 'indigo darken-4' : ''">
                                                {{ icon }}
                                              </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                Select All
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-divider class="mt-2"></v-divider>
                                        </template>
                                      </v-combobox>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="user.agent.is_admin_agent">
                                <v-col cols="12">
                                    <p>Role</p>
                                    <v-select outlined dense
                                              :items="agentRoles"
                                              :loading="isLoadingAgentRoles"
                                              v-model="agent.role_id"
                                              item-text="role"
                                              item-value="id"
                                              @change="updateAgent('role_id', $event)"
                                              :readonly="!hasUpdatePermissions"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <p>Email</p>
                                    <v-text-field outlined dense
                                                  v-model="agent.user.email"
                                                  @change="updateAgentUser('email', $event)"
                                                  :readonly="!hasUpdatePermissions"
                                                  @click="handleClick"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p>Phone (Main)</p>
                                    <v-text-field outlined dense
                                                  v-model="agent.user.phone_number"
                                                  @change="updateAgentUser('phone_number', $event)"
                                                  :readonly="!hasUpdatePermissions"
                                                  @click="handleClick"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p>Phone (Other)</p>
                                    <v-text-field outlined dense
                                                  v-model="agent.user.phone_number_secondary"
                                                  @change="updateAgentUser('phone_number_secondary', $event)"
                                                  :readonly="!hasUpdatePermissions"
                                                  @click="handleClick"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p>Birthday</p>
                                    <v-menu
                                            v-model="birthdayDatePicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            :disabled="!hasUpdatePermissions"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="formattedDate"
                                                    outlined
                                                    append-icon="mdi-calendar"
                                                    dense
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" no-title @input="birthdayDatePicker = false" @change="updateAgentUser('date_of_birth', $event)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <h2 class="mb-4">Change Password</h2>
                            <p>New password</p>
                            <v-text-field outlined
                                          dense
                                          placeholder="Enter password" type="password"
                                          v-model="password"
                                          :readonly="!hasUpdatePermissions"
                                          @click="handleClick"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="password.length > 0">
                        <v-col cols="6" class="py-0">
                            <p>Confirm password</p>
                            <v-text-field outlined
                                          dense
                                          placeholder="Confirm password"
                                          type="password"
                                          v-model="confirmPassword"
                                          :error-messages="confirmPasswordErrors"
                                          @input="$v.confirmPassword.$touch()"
                                          @blur="$v.confirmPassword.$touch()"
                                          :disabled="isSavingPassword"
                                          @click="handleClick"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="text-right">
                            <v-btn color="primary" text @click="resetPassword">Cancel</v-btn>
                            <v-btn color="primary" @click="save" :loading="isSavingPassword">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
    import moment from 'moment'
    import AgentService from "../../services/agent.service";
    import UserService from "../../services/user.service";
    import {required, sameAs} from "vuelidate/lib/validators";
    import {displayMessage} from "@/utils/utils";
    import {mapGetters} from "vuex";

    export default {
        name: 'Agent',
        props: ['agent', 'isDialog', 'source'],
        validations: {
            confirmPassword: {required, sameAsPassword: sameAs('password')},
        },
        data() {
            return {
                headerTab: null,
                footerTab: null,
                isCallingApi: false,
                isStudentSaved: false,
                showMoreFooter: false,
                isSavingPassword: false,
                eventDate: new Date().toISOString().substr(0, 10),
                noteContent: '',
                eventContent: '',
                date: new Date().toISOString().substr(0, 10),

                loadNotes: false,
                loadEvents: false,
                birthdayDatePicker: false,
                defaultMessages: [],
                agentCampuses: [],
                password: '',
                confirmPassword: ''
            }
        },
        watch: {
            agent() {
              this.init();
            }
        },
        computed: {
            ...mapGetters('Campuses', {
              campuses: 'campuses'
            }),
            agentRoles() {
                return this.$store.getters.getAgentRoles;
            },
            formattedDate () {
                return this.formatDate(this.agent.user.date_of_birth)
            },
            isLoadingAgentRoles() {
                return this.$store.getters.getIsLoadingAgentRoles;
            },
            confirmPasswordErrors() {
                const errors = []
                if (!this.$v.confirmPassword.$dirty) return errors
                !this.$v.confirmPassword.required && errors.push('Password confirmation is required')
                !this.$v.confirmPassword.sameAsPassword && errors.push('Password confirmation must match password value')
                return errors
            },
            hasUpdatePermissions() {
                if (this.source && this.source === 'myProfile') {
                    return this.$store.getters.userHasPermission('myProfile', 'update');
                }

                return this.$store.getters.userHasPermission('allAgents', 'update');
            },
            selectedAllCampuses() {
              return this.agentCampuses.length === this.campuses.length
            },
            selectedSomeCampuses() {
              return this.agentCampuses.length > 0 && !this.selectedAllCampuses
            },
            icon () {
              if (this.selectedAllCampuses) return 'mdi-close-box'
              if (this.selectedSomeCampuses) return 'mdi-minus-box'
              return 'mdi-checkbox-blank-outline'
            },
	          user() {
            	return this.$store.getters.getUser
	          }
        },
        methods: {
            init() {
              this.agentCampuses = [];
              this.agent.campuses.forEach(campus => {
                this.agentCampuses.push({id: campus.id, name: campus.name})
              })
            },
            save: async function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'A mandatory field is missing. Please look through the form and fix the field highlighted in red',
                        color: 'red'
                    });
                    return
                }

                this.isSavingPassword = true;
                await UserService.updateUser(this.agent.user.id, {data: {password: this.password}});
                this.isSavingPassword = false;

                this.resetPassword();
            },
            resetPassword() {
                this.password = '';
                this.confirmPassword = '';
                this.$v.$reset();
            },
            getAgents() {
                this.$store.dispatch('getAgents', '?sort=desc&appendSchools&appendRole&appendUser');
            },
            updateAgent: async function(field, value) {
                if (field === 'is_active') {
                    this.agent.is_active = value;
                }

                this.isCallingApi = true;
                await AgentService.updateAgent(this.agent.id, {[field]: value})
                this.isCallingApi = false;

                this.getAgents();
            },
            updateAgentUser: async function(field, value) {
                this.isCallingApi = true;
                await  UserService.updateUser(this.agent.user.id, {data: {[field]: value}});
                this.isCallingApi = false;

                this.getAgents();
            },
            updateAgentCampuses(campuses) {
                this.isCallingApi = true;
                AgentService.updateCampuses(this.agent.id, {campuses: campuses});
                this.isCallingApi = false;
                this.getAgents();
            },
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            uploadPhoto() {
                if (!this.hasUpdatePermissions) {
                    displayMessage('error', 'You do not have permission to update agents');
                    return;
                }

                this.$refs.file.click();
            },
            removePhoto: async function() {
                if (!this.hasUpdatePermissions) {
                    displayMessage('error', 'You do not have permission to update agents');
                    return;
                }

                if (!this.agent.user.image_url) {
                    return;
                }

                this.isCallingApi = true;
                await UserService.deleteImage(this.agent.user.id);
                this.isCallingApi = false;

                this.$emit('getAgent');
                this.getAgents();
            },
            previewImage: function (event) {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    this.readImageFile(input.files[0])
                }
            },
            readImageFile: async function(file) {
                //add to files array
                this.file = file
                const formData = new FormData();
                formData.append('image', file);
                this.isCallingApi = true;
                await UserService.uploadImage(this.agent.user.id, formData);
                this.isCallingApi = false;

                // create a new FileReader to read this image and convert to base64 format
                let reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Read image as base64 and set to imageData
                    this.agent.user.image_url = e.target.result;
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(file);
            },
            handleClick() {
                if (!this.hasUpdatePermissions) {
                    displayMessage('error', 'You do not have permission to update agents');
                }
            },
            toggleSchools() {
              this.$nextTick(() => {
                if (this.selectedAllCampuses) {
                  this.agentCampuses = []
                } else {
                  this.agentCampuses = this.campuses.slice()
                }
              })
            }
        },
        filters: {
            loginDate(value) {
                return moment.utc(value).local().format('d/m/Y hh:mm A')
            }
        },
        beforeMount() {
            this.init();
        }
    }
</script>

<style scoped>
    .v-card-action-container {
        position: relative;
    }

    .footer-buttons {
        position: absolute;
        bottom: 45px;
        right: 15px;
    }

    .event-tab-component {
        width: 250px;
    }

    .school-area {
      height: 200px;
      overflow-y: auto;
    }
</style>
