import { DefaultMessageService } from '../../services/defaultmessages.service'

// State object
const state = {
    defaultMessages: [],
    categories: [],
    isLoadingDefaultMessages: false,
    isAddNewDefaultMessage: false
};

// Getter functions
const getters = {
    getIsLoadingDefaultMessages( state ) {
        return state.isLoadingDefaultMessages;
    },
    getDefaultMessages( state ) {
        return state.defaultMessages;
    },
    getDefaultMessageCategories( state ) {
        return state.categories;
    },
    getIsAddNewDefaultMessage( state ) {
        return state.isAddNewDefaultMessage;
    }
};

// Actions
const actions = {
    async getDefaultMessages({ commit }, data) {
        commit('setIsLoadingDefaultMessages', true);
        const defaultMessages = await DefaultMessageService.getDefaultMessages(data);
        commit('setDefaultMessages', defaultMessages);
        commit('setIsLoadingDefaultMessages', false);
    },
    async getDefaultMessageCategories({ commit }, data) {
        commit('setIsLoadingDefaultMessageCategories', true);
        const categories = await DefaultMessageService.getDefaultMessageCategories(data);
        commit('setCategories', categories);
        commit('setIsLoadingDefaultMessageCategories', false);
    },

};

// Mutations
const mutations = {
    setIsLoadingDefaultMessages(state, data) {
        state.isLoadingDefaultMessages = data;
    },
    setDefaultMessages(state, data) {
        state.defaultMessages = data;
    },
    setIsLoadingDefaultMessageCategories(state, data) {
        state.isLoadingDefaultMessages = data;
    },
    setCategories(state, data) {
        state.categories = data;
    },
    setIsAddNewDefaultMessageState(state, data) {
        state.isAddNewDefaultMessage = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
