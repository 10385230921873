<template>
    <v-sheet>
        <VuetifyCalendar
                ref="calendar"
                v-model="focus"
                :weekdays="weekday"
                :type="customType"
                :events="events"
                :event-overlap-mode="mode"
                :event-color="event => event.color"
                :start="calendarStartDate"
                :end="calendarEndDate"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
        >
            <template v-slot:event="{ event }">
                <div class="pl-1 event-card">
                    {{ event.name | truncate }}
                </div>
            </template>
        </VuetifyCalendar>

        <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-y
                max-width="400px"
        >
            <v-card
                    color="grey lighten-4"
                    min-width="400px"
                    flat
            >
                <v-toolbar
                        :color="selectedEvent.color"
                        dark
                >
                    {{selectedEvent.name | truncate}}
                </v-toolbar>
                <v-card-text class="d-flex flex-wrap">
                    {{selectedEvent.name}}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            text
                            color="secondary"
                            @click="selectedOpen = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-sheet>
</template>

<script>
    import moment from 'moment'
    import { truncateText } from "../../utils/utils";
    import VuetifyCalendar from '../Calendar/VuetifyCalendar'

    export default {
        name: 'DashboardCalendar',
        components: { VuetifyCalendar },
        props: {
            type: {
                type: String,
                default: () => 'month'
            },
            studentEvents: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                weekday: [0, 1, 2, 3, 4, 5, 6],
                mode: 'stack',
                colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                focus: moment().format('YYYY-MM-DD')
            }
        },
        computed: {
            events() {
                return this.studentEvents.map(event => ({
                    name: event.event,
                    start: moment(event.event_date).format('YYYY-MM-DD'),
                    end: moment(event.event_date).add(15, 'minutes').format('YYYY-MM-DD'),
                    color: this.randomColor()
                }))
            },
            customType() {
                return this.type === 'week' ? 'custom-weekly' : 'month'
            },
            calendarStartDate() {
                if (this.customType === 'custom-weekly') {
                    return moment(this.focus).startOf('week').format('YYYY-MM-DD')
                } else {
                    return moment(this.focus).startOf('month').format('YYYY-MM-DD')
                }
            },
            calendarEndDate() {
                if (this.customType === 'custom-weekly') {
                    return moment(this.focus).endOf('week').format('YYYY-MM-DD')
                } else {
                    return moment(this.focus).endOf('month').format('YYYY-MM-DD')
                }
            },
        },
        watch: {
            events() {
                if (this.$refs.calendar) {
                    this.$refs.calendar.checkChange()
                }
            }
        },
        methods: {
            next() {
                if (this.customType === 'custom-weekly') {
                    this.focus = moment(this.focus).add('1', 'week').format('YYYY-MM-DD')
                } else {
                    this.focus = moment(this.focus).add('1', 'month').format('YYYY-MM-DD')
                    this.$refs.calendar.next()
                }
                return this.focus
            },
            prev() {
                if (this.customType === 'custom-weekly') {
                    this.focus = moment(this.focus).subtract('1', 'week').format('YYYY-MM-DD')
                } else {
                    this.focus = moment(this.focus).subtract('1', 'month').format('YYYY-MM-DD')
                    this.$refs.calendar.prev()
                }
                return this.focus
            },
            today() {
                this.focus = moment().format('YYYY-MM-DD')
                return this.focus
            },
            randomColor() {
                const randomIndex = Math.floor(this.colors.length * Math.random())
                return this.colors[randomIndex]
            },
            async showEvent ({ nativeEvent, event }) {
                const open = async () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    await this.$nextTick()
                    this.selectedOpen = true
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    await this.$nextTick()
                    open()
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
            viewDay ({ date }) {
                this.$emit('viewDay', date)
            },
        },
        filters: {
            truncate(text) {
                if (!text) return ''

                return truncateText(text, 30)
            }
        },
        mounted() {
            this.$refs.calendar.checkChange()
        }
    }
</script>

<style scoped>
.v-application .event-card {
    overflow-x: hidden;
    text-overflow: ellipsis;
}
</style>
