import { VCalendar } from 'vuetify/lib'

export default {
  extends: VCalendar,

  methods: {

    // Override the default getScopedSlots
    getScopedSlots() {
      if (this.noEvents) {
        return {...this.$scopedSlots};
      }

      const mode = this.eventModeFunction(this.parsedEvents, this.parsedWeekdays[0], this.parsedEventOverlapThreshold);

      const getSlotChildren = (day, getter, mapper, timed) => {
        const events = getter(day);

        if (events.length === 0) {
          return;
        }

        const visuals = mode(day, events, timed);

        if (timed) {
          return visuals.map(visual => mapper(visual, day));
        }

        const children = [];
        visuals.forEach(visual => {
          while (children.length < visual.column) {
            children.push(this.genPlaceholder(day));
          }

          children.push(mapper(visual, day));
        });
        return children;
      };

      const slots = this.$scopedSlots;
      const slotDay = slots.day;
      const slotDayHeader = slots['day-header'];
      const slotDayBody = slots['day-body'];
      return { ...slots,
        day: day => {
          let children = getSlotChildren(day, this.getEventsForDay, this.genDayEvent, false);

          if (children && children.length > 0 && this.eventMore) {
            children.push(this.genMore(day));
          }

          if (slotDay) {
            const slot = slotDay(day);

            if (slot) {
              children = children ? children.concat(slot) : slot;
            }
          }

          return children;
        },
        'day-header': day => {
          let children = getSlotChildren(day, this.getEventsForDayAll, this.genDayEvent, false);

          if (slotDayHeader) {
            const slot = slotDayHeader(day);

            if (slot) {
              children = children ? children.concat(slot) : slot;
            }
          }

          return children;
        },
        'day-body': day => {
          const events = getSlotChildren(day, this.getEventsForDayTimed, this.genTimedEvent, true);
          let children = [this.$createElement('div', {
            staticClass: 'v-event-timed-container'
          }, events)];

          if (slotDayBody) {
            const slot = slotDayBody(day);

            if (slot) {
              children = children.concat(slot);
            }
          }

          return children;
        }
      };
    }
  }
}
