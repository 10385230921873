<template>
    <v-card  :loading="isInviting">
        <v-card-title class="justify-space-between">
            Invite New People
            <v-btn icon @click="hideDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle>Send email invitation to new team members.</v-card-subtitle>
        <v-card-text class="pb-0">
	        <v-select outlined
	                  dense
	                  label="Type"
	                  :items="types"
	                  v-model="type"
	        ></v-select>
            <v-textarea rows="4"
                        outlined
                        label="Emails"
                        placeholder="Enter one or more comma separated email addresses"
                        v-model="emails"
                        :disabled="isInviting"
            ></v-textarea>
        </v-card-text>
        <v-card-actions class="pr-6">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="hideDialog">Cancel</v-btn>
            <v-btn color="primary" @click="submit" :loading="isInviting">Invite</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import InviteService from "../../services/invitation.service";
    import {mapActions} from "vuex";

    export default {
        name: "InvitationDialog",
        data() {
            return {
                emails: '',
                isInviting: false,
		            types: [
			            {
				            text: 'Agent',
				            value: 'agent'
			            },
			            {
				            text: 'Property Agent',
				            value: 'propertyAgent'
			            }
		            ],
	              type: ''
            }
        },
        methods: {
        	...mapActions('Invitations', ['getPagination']),
            hideDialog() {
                this.emails = '';
                this.$emit('hideDialog')
            },
            submit: async function() {
                this.isInviting = true;
                await InviteService.sendBulkInvites({emails: this.emails, type: this.type});
                this.isInviting = false;

                this.getPagination()

                this.hideDialog();
            }
        }
    }
</script>

<style scoped>

</style>