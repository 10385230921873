<template>
    <v-card>
	    <v-card-title style="background: #2D76E3"><h4 class="white--text">Your session is about to expire</h4></v-card-title>
	    <v-divider></v-divider>
	    <v-card-text class="mt-6">
		    <p class="content">You have been idle for over 15 minutes.</p>
		    <p>You have {{seconds}} seconds left before you are automatically logged out!</p>
	    </v-card-text>
    </v-card>
</template>

<script>
    import {UserService} from "@/services/user.service";

    export default {
        name: 'TimeoutDialog',
        data () {
            return {
	            time: 10000
            }
        },
		    watch: {
	          isIdle(isIdle) {
	            if (!isIdle || !this.isUserLoggedIn) return

		          this.time = 10000
		          this.startTimer()
		        }
		    },
		    computed: {
	          seconds() {
	            return this.time / 1000
		        },
				    isIdle() {
					    return this.$store.state.idleVue.isIdle;
				    },
				    isUserLoggedIn() {
					    return this.$store.getters.isUserLoggedIn;
				    },
		    },
	      methods: {
		      startTimer() {
			      let timerId = setInterval(async () => {
				      this.time -= 1000;
				      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

				      if (this.time < 1) {
					      clearInterval(timerId);
					      await UserService.logout();
					      this.$store.commit('setIsUserLoggedIn', false)
					      this.$router.push('/login');
				      }
			      }, 1000);
		      }
	      },
	      beforeMount() {
        	this.startTimer()
	      }
    }
</script>

<style scoped>
.content {
    font-weight: bold;
		font-size: 16px;
		padding-bottom: 10px;
}
</style>
