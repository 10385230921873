<template>
    <div>
      <div class="filter-container">
        <v-text-field
            class="search-box"
            outlined
            dense
            append-icon="mdi-magnify"
            v-model="search"
            placeholder="Search All Students by Name, Email or Phone"
            background-color="white"
        ></v-text-field>

        <v-select outlined
                  dense
                  label="Profile Status"
                  :value="profileStatusFilter"
                  :items="['active', 'inactive']"
                  item-value="id"
                  item-text="status"
                  @change="setFilter('setProfileStatusFilter', $event)"
                  background-color="white"
                  class="filter"
        ></v-select>

        <v-select outlined dense label="Status"
                  :value="statusFilter"
                  :items="statuses"
                  item-value="id"
                  item-text="status"
                  @change="setFilter('setStatusFilter', $event)"
                  background-color="white"
                  class="filter"
        ></v-select>

        <v-sheet class="filter" height="40">
          <v-menu
              v-model="showDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  :value="startDateFilter"
                  outlined
                  append-icon="mdi-calendar"
                  dense
                  readonly
                  v-on="on"
                  label="Start Date Range"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates"
                           range
                           @change="setFilter('setStartDateRangeFilter', $event)"
            ></v-date-picker>
          </v-menu>
        </v-sheet>

        <v-badge
            bordered
            color="error"
            overlap
            :value="activeFiltersCount"
            :content="activeFiltersCount"
        >
          <v-btn color="primary" outlined class="text-capitalize" height="40" @click="showFilterDialog=true">
            All Filters
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </v-badge>

        <v-btn text height="40" color="primary" @click="resetFilters">
          Clear Filters
        </v-btn>
      </div>

        <v-data-table
            :search="search"
            :headers="$vuetify.breakpoint.smAndDown ? mobile_headers : headers"
            :items="students"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :loading="isLoadingStudents"
        >
            <template v-slot:item.name="props">
                <StudentDialog action="show-btn" :actionText="props.item.name" :existing-student="props.item"/>
            </template>
            <template v-slot:item.campus="props">
                {{ props.item.campus && props.item.campus.name ? props.item.campus.name : 'N/A'}}
            </template>
            <template v-slot:item.university_start_date="props">
                {{ props.item.university_start_date | formatDateYearMonthDay}}
            </template>
            <template v-slot:item.has_paid_application_fees="props">
              <v-chip v-if="props.item.has_paid_application_fees" color="success" small>PAID</v-chip>
              <v-chip v-else color="error" small>UNPAID</v-chip>
            </template>
            <template v-slot:item.created_at="props">
                {{ props.item.created_at | formatDate }}
            </template>
            <template v-slot:item.status="props">
                <StudentStatus :status-id="props.item.status_id" />
            </template>
            <template v-slot:item.phone_number=""></template>
            <template v-slot:item.phone_number_secondary=""></template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="deleteStudent(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nav="props">
	            <a @click.prevent="openViewStudent(props.item.id)" target="_blank">
		            <v-avatar tile size="10">
			            <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
		            </v-avatar>
	            </a>
            </template>
        </v-data-table>
        <v-row class="mt-3">
            <v-col cols="12" md="4">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start pt-md-5 ml-1'" v-if="!isLoadingStudents">
                    Showing {{(currentPage * itemsPerPage) - itemsPerPage + 1}} to {{itemsPerPage * currentPage}} of {{totalStudents}} {{isFiltered ? `Filtered from ${totalNoFilter}` : ''}}
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'" v-if="!isLoadingStudents">
                    
                        <v-row>
                            <v-col cols="12" sm="2" md="1" class="text-sm-left pt-md-5">Show</v-col>
                            <v-col cols="12" sm="4" md="2" class="m-0">
                                <v-select :items="pages" dense
                                        outlined
                                        @change="updatePageEntries(parseInt($event, 10))"
                                        :value="itemsPerPage"
                                        background-color="white"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="pt-md-5 text-left">Entries</v-col>
                            <v-col cols="12" md="7">
                                <Paginate state="Students" collection="students" list="getStudents" current-page-setter="setStudentCurrentPage" />
                            </v-col>
                        </v-row>
                </v-row>
            </v-col>
        </v-row>

      <all-filters-wrapper :show-dialog="showFilterDialog" title="Student Filters" @hideDialog="showFilterDialog=false">
            <v-select outlined dense label="Agent"
                      :value="agentFilter"
                      :items="agents"
                      item-value="id"
                      item-text="user.name"
                      @change="setFilter('setAgentFilter', $event, true)"
                      background-color="white"
                      class="filter"
                      v-if="canView"
            ></v-select>


            <v-select outlined dense label="School"
                      :value="schoolFilter"
                      :items="schools"
                      item-text="name"
                      item-value="id"
                      @change="setSchoolFilter('setSchoolFilter', $event, true)"
                      background-color="white"
                      class="filter"
                      v-if="canView"
            ></v-select>


            <v-select outlined dense
                      label="Campuses"
                      :value="campusFilter"
                      :items="selectedSchoolCampuses"
                      item-text="name"
                      item-value="id"
                      @change="setFilter('setStudentCampusFilter', $event, true)"
                      background-color="white"
                      class="filter"
            ></v-select>


            <v-select outlined dense
                      label="School Reps"
                      :value="schoolContactsFilter"
                      :items="schoolContacts"
                      item-text="name"
                      item-value="id"
                      @change="setFilter('setSchoolContactFilter', $event, true)"
                      background-color="white"
                      class="filter"
            ></v-select>

            <v-select outlined dense
                      label="Paid/Unpaid"
                      :value="paidStatusFilter"
                      :items="['Paid', 'Unpaid']"
                      @change="setFilter('setPaidStatusFilter', $event, true)"
                      background-color="white"
                      class="filter"
            ></v-select>
      </all-filters-wrapper>
    </div>
</template>

<script>
    import moment from 'moment'
    import StudentDialog from "./StudentDialog";
    import StudentStatus from "../StudentStatus";
    import StudentService from "../../services/student.service";
    import {alphabeticalSort, displayMessage, setFilter} from "@/utils/utils";
    import Paginate from "../Paginate";
    import {mapGetters} from "vuex";
    import AllFiltersWrapper from "@/components/Shared/AllFiltersWrapper.vue";

    export default {
        name: 'StudentDataTable',
        components: {
          AllFiltersWrapper,
            StudentStatus,
            StudentDialog,
            Paginate
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Campus', value: 'campus' },
                    { text: 'Start Date', value: 'university_start_date' },
                    { text: 'Unpaid/Paid', value: 'has_paid_application_fees' },
                    { text: 'Created On', value: 'created_at' },
                    { text: 'Status', value: 'status' },
                    { text: 'Email', value: 'email' },
                    { text: '', value: 'phone_number' },
                    { text: '', value: 'phone_number_secondary' },
                    { text: '', value: 'nav' },
                ],
                mobile_headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Campus', value: 'campus' },
                    { text: 'Start Date', value: 'university_start_date' },
                    { text: 'Unpaid/Paid', value: 'has_paid_application_fees' },
                    { text: 'Status', value: 'status' },
                    { text: 'Email', value: 'email' },
                    { text: '', value: 'phone_number' },
                    { text: '', value: 'phone_number_secondary' },
                ],
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingSchool: false,
                showDateMenu: false,
                dates: [new Date().toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)],
                showFilterDialog: false,
                activeFiltersCount: 0,
                activeFilters: []
            }
        },
        watch: {
          search(val) {
            if (val === '') return
            this.debouncedSearch(val)
          }
        },
        computed: {
            ...mapGetters('Campuses', {
              campuses: 'campuses'
            }),
            isLoadingStudents() {
                return this.$store.getters.isLoadingStudents
            },
            students() {
                return this.$store.getters.getStudents
            },
            totalStudents() {
                return this.$store.getters.getStudentsTotal
            },
            totalNoFilter() {
                return this.$store.getters.getTotalNoFilter
            },
            isFiltered() {
                return this.$store.getters.getIsFiltered
            },
            statuses() {
                return this.$store.getters.getStudentStatuses
            },
            schools() {
                return this.$store.getters.getSchools;
            },
            selectedSchoolCampuses() {
              if (this.schoolFilter) {
                return this.campuses.filter(c => c.school_id === this.schoolFilter)
              }

              if (this.user.type === 'schoolContact') {
                return this.campuses.filter(c => c.school_id === this.user.school_id)
              }

              return this.campuses
            },
            schoolContacts() {
              let school = null

              if (this.schoolFilter) {
                school = this.schools.find(school => {
                  return school.id === this.schoolFilter
                })
              } else {
                school = this.schools.find(school => {
                  return school.id === this.user.school_id
                })
              }

              if (!school) return []
              return school.contacts.sort(alphabeticalSort())
            },
            agents() {
                return this.$store.getters.getActiveAgents
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('student', 'delete');
            },
            statusFilter() {
                return this.$store.getters.getStudentStatusFilter;
            },
            profileStatusFilter() {
                return this.$store.getters.getStudentProfileStatusFilter;
            },
            agentFilter() {
                return this.$store.getters.getStudentAgentFilter;
            },
            schoolFilter() {
                return this.$store.getters.getStudentSchoolFilter;
            },
            campusFilter() {
                return this.$store.getters.getStudentCampusFilter;
            },
            schoolContactsFilter() {
                return this.$store.getters.getStudentSchoolContactsFilter;
            },
            startDateFilter() {
                return this.$store.getters.getStartDateFilter;
            },
            paidStatusFilter() {
                return this.$store.getters.getPaidStatusFilter;
            },
            itemsPerPage() {
                return this.$store.getters.getStudentsPerPage;
            },
            currentPage() {
                return this.$store.getters.getCurrentPage;
            },
            user() {
              return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteStudent: async function(studentId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete a student')
                    return;
                }

                this.isDeletingSchool = true;
                await StudentService.deleteStudent(studentId);
                this.isDeletingSchool = false;
                this.getStudents();
            },
            resetFilters() {
                this.search       = '';
                this.$store.commit('setStatusFilter', null);
                this.$store.commit('setProfileStatusFilter', null);
                this.$store.commit('setAgentFilter', null);
                this.$store.commit('setSchoolFilter', null);
                this.$store.commit('setStudentCampusFilter', null);
                this.$store.commit('setStudentSearchFilter', null);
                this.$store.commit('setSchoolContactFilter', null);
                this.$store.commit('setStartDateRangeFilter', null);
                this.$store.commit('setPaidStatusFilter', null);

                this.activeFiltersCount = 0
                this.activeFilters = []

                this.$store.dispatch('getStudents');
            },
            setSchoolFilter(type, value, shouldSetActiveFilterCount = false) {
                this.$store.commit('setStudentCampusFilter', null)
                this.setFilter(type, value, shouldSetActiveFilterCount);
            },
            setFilter(type, value, shouldSetActiveFilterCount = false) {
                this.$store.commit('setStudentCurrentPage', 1)
                setFilter(type, value, 'getStudents');

                if (shouldSetActiveFilterCount) {
                  this.showFilterDialog = false

                  if (this.activeFilters.includes(type) === false) {
                    this.activeFiltersCount += 1
                    this.activeFilters.push(type)
                  }

                }
            },
            updatePageEntries(count) {
                this.$store.commit('setStudentsPerPage', count);
                this.$store.commit('setStudentCurrentPage', 1);
                this.$store.dispatch('getStudents')
            },
            debouncedSearch(userInput) {
              // cancel pending call
              clearTimeout(this._timerId)

              // delay new call 500ms
              this._timerId = setTimeout(() => {
                this.searchApi(userInput)
              }, 500)
            },
            searchApi: async function (query) {
              this.setFilter('setStudentSearchFilter', query)
            },
		        openViewStudent(studentId) {
			        window.open(`/#/view-student/${studentId}`,'_blank')
		        }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
                }
            },
            formatDateYearMonthDay (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD')
                }
            },
            age(value) {
                let currentDate = new Date();
                let birthDate = new Date(value);
                let difference = currentDate - birthDate;
                return Math.floor(difference/31557600000);
            }
        },
        beforeMount() {
            this.$store.dispatch('getStudents');
        }
    }
</script>

<style scoped>
    .table-proto {
        width: 80vw;
        margin-left: -2em;
    }

    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
    .paginate-entries {
      display: inline-block;
    }

    .filter-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .filter {
      flex-grow: 1;
      flex-basis: 100px;
    }

    .search-box {
      flex-grow: 1;
      flex-basis: 200px;
    }
</style>
