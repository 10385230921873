<template>
  <div class="content page-style-proto">
    <v-row>
      <v-col>
        <v-row class="justify-start">
          <v-col class="justify-start d-flex">
            <h2 class="pr-6">Invoices</h2>
            <v-chip small color="red" text-color="white" class="mt-1" v-if="newInvoiceCount > 0">
              {{ newInvoiceCount }} New
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <InvoiceDataTable @openInvoiceDialog="dialog = true" />
      </v-col>
    </v-row>

    <InvoiceDialog @closeInvoiceDialog="dialog = false" v-if="dialog" />
  </div>
</template>

<script>
import InvoiceDataTable from "../components/Invoices/InvoiceDataTable";
import InvoiceDialog from "../components/Invoices/InvoiceDialog";

export default {
  name: 'Invoices',

  components: {
    InvoiceDataTable,
    InvoiceDialog,
  },

  computed: {
    newInvoiceCount() {
      return this.$store.getters.getNewInvoiceCount
    }
  },

  data() {
    return {
      dialog: false
    }
  },

  beforeMount() {
    this.$store.dispatch('getNewInvoiceCount');
  }
}
</script>

<style scoped>
  .content {
    padding-left: 30px;
    padding-right: 30px;
  }
</style>
