<template>
    <div>
        <h2 class="py-6">Personal Info</h2>
        <v-row>
            <v-col cols="12" :md="isAddNewStudentState ? 12 :6">
                <p>Full Name</p>
                <v-text-field outlined dense
                              v-model="student.name"
                              :error-messages="nameErrors"
                              @input="validation.student.name.$touch()"
                              @blur="validation.student.name.$touch()"
                              @change="updateExistingStudent('name', $event)"
                              :readonly="!hasPermissions"
                              @click="handleClick"
                ></v-text-field>
            </v-col>
        </v-row>
        <div v-if="isAddNewStudentState">
          <v-row>
            <v-col cols="12">
              <p>School Name</p>
              <v-select outlined dense
                        :items="schools"
                        v-model="student.school_id"
                        item-text="name"
                        item-value="id"
                        :error-messages="schoolErrors"
                        @input="validation.student.school_id.$touch()"
                        @blur="validation.student.school_id.$touch()"
                        :readonly="!hasPermissions"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p>Campus Name</p>
              <v-select outlined dense
                        :items="schoolCampuses"
                        v-model="student.campus_id"
                        item-text="name"
                        item-value="id"
                        :error-messages="campusErrors"
                        @input="validation.student.campus_id.$touch()"
                        @blur="validation.student.campus_id.$touch()"
                        :readonly="!hasPermissions"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <v-row>
            <v-col cols="12" md="6">
                <h3 class="py-6">Email Addresses</h3>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row class="justify-space-between px-3">
                            <p>Email (Main)</p>
                            <v-btn text small color="primary" class="px-0"
                                   v-if="(!isAddNewStudentState || isAddNewStudentState && isStudentSaved)
                                   && student.email !== null && student.email.length > 0
                                   && !validation.student.email.$invalid"
                                   @click="handleEmailClick($refs.showEmail)"
                            >
                                <v-icon left size="14">mdi-email</v-icon>
                                Send Email
                            </v-btn>
                            <a :href="`mailto:${student.email}`" style="display: none" ref="showEmail" />
                        </v-row>

                        <v-text-field outlined dense
                                      type="email"
                                      v-model="student.email"
                                      :error-messages="emailErrors"
                                      @input="validation.student.email.$touch()"
                                      @blur="validation.student.email.$touch()"
                                      @change="updateExistingStudent('email', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>

                  <v-col cols="12" md="6" class="pt-4">
                    <p>Description</p>
                    <v-text-field outlined dense
                                  v-model="student.email_description"
                                  @change="updateExistingStudent('email_description', $event)"
                                  :readonly="!hasPermissions"
                                  @click="handleClick"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-row class="justify-space-between px-3">
                            <p>Email (Other)</p>
                            <v-btn text small color="primary" class="px-0"
                                   v-if="(!isAddNewStudentState || isAddNewStudentState && isStudentSaved)
                                   && student.email_secondary !== null && student.email_secondary.length > 0
                                   && !validation.student.email_secondary.$invalid"
                                   @click="handleEmailClick($refs.showEmailSecondary)"
                            >
                                <v-icon left size="14">mdi-email</v-icon>
                                Send Email
                            </v-btn>
                            <a :href="`mailto:${student.email_secondary}`" style="display: none" ref="showEmailSecondary"/>
                        </v-row>
                        <v-text-field outlined dense
                                      type="email"
                                      v-model="student.email_secondary"
                                      :error-messages="emailSecondaryErrors"
                                      @input="validation.student.email_secondary.$touch()"
                                      @blur="validation.student.email_secondary.$touch()"
                                      @change="updateExistingStudent('email_secondary', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>

                  <v-col cols="6" class="pt-4">
                    <p>Description</p>
                    <v-text-field outlined dense
                                  v-model="student.email_secondary_description"
                                  @change="updateExistingStudent('email_secondary_description', $event)"
                                  :readonly="!hasPermissions"
                                  @click="handleClick"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-row class="justify-space-between px-3">
                            <p>Email (Other)</p>
                            <v-btn text small color="primary" class="px-0"
                                   v-if="(!isAddNewStudentState || isAddNewStudentState && isStudentSaved)
                                   && student.email_tertiary !==null && student.email_tertiary.length > 0
                                   && !validation.student.email_tertiary.$invalid"
                                   @click="handleEmailClick($refs.showEmailTertiary)"
                            >
                                <v-icon left size="14">mdi-email</v-icon>
                                Send Email
                            </v-btn>
                            <a :href="`mailto:${student.email_tertiary}`" style="display: none" ref="showEmailTertiary"/>
                        </v-row>
                        <v-text-field outlined dense
                                      type="email"
                                      v-model="student.email_tertiary"
                                      :error-messages="emailTertiaryErrors"
                                      @input="validation.student.email_tertiary.$touch()"
                                      @blur="validation.student.email_tertiary.$touch()"
                                      @change="updateExistingStudent('email_tertiary', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>

                  <v-col cols="6" class="pt-4">
                    <p>Description</p>
                    <v-text-field outlined dense
                                  v-model="student.email_tertiary_description"
                                  @change="updateExistingStudent('email_tertiary_description', $event)"
                                  :readonly="!hasPermissions"
                                  @click="handleClick"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                 <h3 class="py-6">Phone Numbers</h3>
                <v-row>
                    <v-col cols="6">

                        <v-row class="justify-space-between px-3">
                            <p>Phone (Main)</p>

                            <a text small color="primary" class="px-0 v-btn primary--text" @click="handlePhoneClick" v-bind:href="'tel:'+student.phone_number" v-if="student.phone_number !== null && student.phone_number.length > 0">
                                <span class="v-btn__content">
                                    <v-icon left size="14">mdi-phone</v-icon>
                                    Call
                                </span>
                            </a>
                        </v-row>
                        <v-text-field outlined dense
                                      v-model="student.phone_number"
                                      @input="formatPrimaryPhoneNumber($event)"
                                      @change="updateExistingStudent('phone_number', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-4">
                        <p>Description</p>
                        <v-text-field outlined dense
                                      v-model="student.phone_number_description"
                                      @change="updateExistingStudent('phone_number_description', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-row class="justify-space-between px-3">
                            <p>Phone (Other)</p>
                            <a text small color="primary" class="px-0 v-btn primary--text" @click="handlePhoneClick" v-bind:href="'tel:'+student.phone_number_secondary" v-if="student.phone_number_secondary !== null && student.phone_number_secondary.length > 0">
                                <span class="v-btn__content">
                                    <v-icon left size="14">mdi-phone</v-icon>
                                    Call
                                </span>
                            </a>
                        </v-row>
                        <v-text-field outlined dense
                                      v-model="student.phone_number_secondary"
                                      @input="formatSecondaryPhoneNumber($event)"
                                      @change="updateExistingStudent('phone_number_secondary', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-4">
                        <p>Description</p>
                        <v-text-field outlined dense
                                      v-model="student.phone_number_secondary_description"
                                      @change="updateExistingStudent('phone_number_secondary_description', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">

                        <v-row class="justify-space-between px-3">
                            <p>Phone (Other)</p>
                            <a text small color="primary" class="px-0 v-btn primary--text" @click="handlePhoneClick" v-bind:href="'tel:'+student.phone_number_tertiary" v-if="student.phone_number_tertiary !== null && student.phone_number_tertiary.length > 0">
                                <span class="v-btn__content">
                                    <v-icon left size="14">mdi-phone</v-icon>
                                    Call
                                </span>
                            </a>
                        </v-row>
                        <v-text-field outlined dense
                                      v-model="student.phone_number_tertiary"
                                      @input="formatTertiaryPhoneNumber($event)"
                                      @change="updateExistingStudent('phone_number_tertiary', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-4">
                        <p>Description</p>
                        <v-text-field outlined dense
                                      v-model="student.phone_number_tertiary_description"
                                      @change="updateExistingStudent('phone_number_tertiary_description', $event)"
                                      :readonly="!hasPermissions"
                                      @click="handleClick"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {displayMessage, formatPhoneNumber} from "@/utils/utils";
    import KlaviyoService from "../../services/klaviyo.service";
    import {mapGetters} from "vuex";

    export default {
        name: "StudentPersonalInfo",
        props: ['student', 'validation', 'isStudentSaved'],
        data() {
            return {
                maxPhoneLength: 14
            }
        },
        computed: {
            ...mapGetters('Campuses', {
              campuses: 'campuses'
            }),
            schoolCampuses() {
              return this.campuses.filter(c => c.school_id === this.student.school_id)
            },
            schools() {
              return this.$store.getters.getSchools
            },
            isAddNewStudentState() {
                return this.$store.getters.getIsAddNewStudentState
            },
            nameErrors () {
                const errors = []
                if (!this.validation.student.name.$dirty) return errors
                !this.validation.student.name.required && errors.push('Student name is required')
                return errors
            },
            emailErrors () {
                const errors = []
                if (!this.validation.student.email.$dirty) return errors
                !this.validation.student.email.email && errors.push('A valid email is required')
                !this.validation.student.email.required && errors.push('Student email is required')
                return errors
            },
            emailSecondaryErrors () {
                const errors = []
                if (!this.validation.student.email_secondary.$dirty) return errors
                !this.validation.student.email_secondary.email && errors.push('A valid email is required')
                return errors
            },
            emailTertiaryErrors () {
                const errors = []
                if (!this.validation.student.email_tertiary.$dirty) return errors
                !this.validation.student.email_tertiary.email && errors.push('A valid email is required')
                return errors
            },
            hasPermissions() {
                return this.$store.getters.userHasPermission('students', 'update');
            },
            schoolErrors() {
              const errors = [];
              if (!this.validation.student.school_id.$dirty) return errors
              return errors
            },
            campusErrors() {
              const errors = [];
              if (!this.validation.student.campus_id.$dirty) return errors
              return errors
            },
        },
        methods: {
            onClickCall(phone_number) {
                window.location.href = 'tel://' + phone_number;
            },
            updateExistingStudent(field, value) {
                this.$emit('updateExistingStudent', {[field]: value})
            },
            formatPrimaryPhoneNumber(val) {
                this.validation.student.phone_number.$touch();
                this.$emit('updateStudent', {phone_number: formatPhoneNumber(val)})
            },
            formatSecondaryPhoneNumber(val) {
                this.$emit('updateStudent', {phone_number_secondary: formatPhoneNumber(val)})
            },
            formatTertiaryPhoneNumber(val) {
                this.$emit('updateStudent', {phone_number_tertiary: formatPhoneNumber(val)})
            },
            handleClick() {
                if (this.isAddNewStudentState) {
                    return
                }

                if (!this.hasPermissions) {
                    displayMessage('error', 'You do not have permission to update student')
                }
            },
            handleEmailClick($emailAction) {
                const data = {
                    event_name: 'agent_email_student',
                    user_id: this.student.id,
                    email: this.student.email
                };

                KlaviyoService.actionClicked(data);

                $emailAction.click();
            },
            handlePhoneClick() {
                const data = {
                    event_name: 'agent_call_student',
                    user_id: this.student.id,
                    email: this.student.email
                };

                KlaviyoService.actionClicked(data);
            }
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }

    .other-phones-container {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        height: 270px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
    }
    .other-emails-container {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        height: 165px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
    }

    /*::-webkit-scrollbar {*/
    /*    -webkit-appearance: none;*/
    /*    width: 7px;*/
    /*}*/

    /*::-webkit-scrollbar-thumb {*/
    /*    border-radius: 4px;*/
    /*    background-color: rgba(0, 0, 0, .5);*/
    /*    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);*/
    /*}*/
</style>
