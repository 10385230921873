<template>
    <div>
        <v-row class="justify-space-between">
            <v-col>
                <h3>Documents</h3>
            </v-col>
            <v-col class="text-right">
                <v-btn color="primary" @click="addFile" :loading="isAddingNewDocument">
                    <v-icon left>mdi-plus</v-icon>
                    Add Document
                </v-btn>
            </v-col>
        </v-row>
        <input type="file" ref="file" style="display: none" @change="addPropertyDocument($event)">
        <v-data-table
            :headers="headers"
            :items="propertyDocuments"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="isLoadingPropertyDocuments"
        >
            <template v-slot:item.open="props">
                <v-icon left color="primary" size="16">mdi-open-in-new</v-icon>
                <a :href="props.item.presigned_url" target="_blank" class="open-link">Open</a>
            </template>
            <template v-slot:item.created_at="props">
                {{props.item.created_at | formatDate}}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="deletePropertyDocument(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'PropertyDocuments',
        props: ['property'],
        data () {
            return {
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Type', value: 'type'},
                    { text: 'Created On', value: 'created_at' },
                    { text: '', value: 'open' },
                    { text: '', value: 'delete' },
                ],
                propertyDocuments: [],
                isAddingNewDocument: false,
                isLoadingPropertyDocuments: false
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            addFile() {
                this.$refs.file.click();
            },
            addPropertyDocument(event) {
                this.isAddingNewDocument = true;

                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    const formData = new FormData();
                    formData.append('file', input.files[0]);

                    this.$store.dispatch('addPropertyDocument', {
                        propertyId: this.property.id,
                        file: formData
                    }).then(() => {
                        this.getPropertyDocuments();
                        this.isAddingNewDocument = false
                    });
                }
            },
            deletePropertyDocument(id) {
                this.isLoadingPropertyDocuments = true;
                this.$store.dispatch('deletePropertyDocument', id).then(() => {
                    this.getPropertyDocuments();
                });
            },
            getPropertyDocuments() {
                this.isLoadingPropertyDocuments = true;
                this.$store.dispatch('getPropertyDocuments', this.property.id).then(propertyDocuments => {
                    this.isLoadingPropertyDocuments = false;
                    this.propertyDocuments = propertyDocuments;
                })
            },
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            }
        },
        beforeMount() {
             this.isLoadingPropertyDocuments = true;
             this.$store.dispatch('getPropertyDocuments', this.property.id).then(propertyDocuments => {
                 this.isLoadingPropertyDocuments = false
                 this.propertyDocuments = propertyDocuments;
             });
        }
    }
</script>

<style scoped>
    .open-link {
        text-decoration: none;
    }
</style>
