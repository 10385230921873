import ApiService from './api.service'

const ContactService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    sendContactMessage: async function(data) {
        const requestData = {
            method: 'post',
            url: "info/contact-message",
            data: {
                name: data.name,
                sender_info: data.senderInfo,
                email: data.email,
                phone_number: data.phoneNumber,
                message: data.message
            },
        };

        const response = await ApiService.customRequest(requestData);
        return response.data;
    },

    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    sendGetInfoMessage: async function(data) {
        const requestData = {
            method: 'post',
            url: "info/pwu-get-more-info",
            data: {
                name: data.name,
                email: data.email,
                phone_number: data.phone,
                company: data.company,
                city: data.city,
                state: data.state,
            },
        };

        const response = await ApiService.customRequest(requestData);
        return response.data;
    },

    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    sendEdurentPartnerMessage: async function(data) {
        const requestData = {
            method: 'post',
            url: "info/pwu-edurent-partner",
            data: {
                name: data.name,
                email: data.email,
                phone_number: data.phone,
                school: data.school,
            },
        };

        const response = await ApiService.customRequest(requestData);
        return response.data;
    },

    /**
     * Send email from user to EDUrent requesting property information.
     *
     **/
    sendPropertyContactMessage: async function(data) {
        const requestData = {
            method: 'post',
            url: "info/property-contact-message",
            data: {
                name: data.name,
                email: data.email,
                phone_number: data.phone,
                message: data.message,
            },
        };

        const response = await ApiService.customRequest(requestData);
        return response.data;
    },
}

export default ContactService
