import ApiService from './api.service'
import {TokenService} from "./storage.service";

const UserService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    login: async function(data, params='') {
        const requestData = {
            method: 'post',
            url: `login${params}`,
            data: data,
        }

        const response = await ApiService.customRequest(requestData)

        TokenService.saveToken(response.data.data.access_token)
        ApiService.setAuthorizationHeader()

        return response.data.data;
    },


    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    register: async function(data) {
        const requestData = {
            method: 'post',
            url: "register-agent",
            data: data,
        };

        const response = await ApiService.customRequest(requestData)

        TokenService.saveToken(response.data.data.access_token)
        ApiService.setAuthorizationHeader()

        return response.data.data;
    },

    /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
     **/
    logout: async function() {
        await ApiService.post('logout', []);
        // Remove the token and remove Authorization header from Api Service as well
        TokenService.removeToken()
        TokenService.removeRefreshToken()
        ApiService.removeHeader()
    },
    getUserByToken: async function(params) {
        const response = await ApiService.get(`get-user-by-token${params}`);
        return response.data.data
    },
    updateUser: async function(userId, data) {
        await ApiService.post(`user/${userId}/update`, data)
    },
    uploadImage: async function(userId, data) {
        await ApiService.post(`user/${userId}/image`, data);
    },
    sendVerificationCode: async function(data) {
        await ApiService.post('/user/forgot-password', data)
    },
    confirmVerificationCode: async function(data) {
        await ApiService.post('/user/confirm-verification-code', data);
    },
    updatePassword: async function(email, password) {
        await ApiService.post('/user/update-password', {
            email: email,
            password: password,
        });
    },
    deleteImage: async function(userId) {
        await ApiService.delete(`user/${userId}/image`);
    }
}

export default UserService

export { UserService }
