<template>
    <div>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="status.status_order_number > 0 || status.property_status ? '' : 'status-incomplete'" :src="dashUser"></v-img>
        </v-avatar>
        <v-avatar v-if="(status.status_order_number >= 2  && status.roommate_status) || (status.property_status && status.roommate_status) " size="16" class="mr-1" tile>
            <v-img :src="dashFriendsFull"></v-img>
        </v-avatar>
        <v-avatar v-else size="16" class="mr-1" tile>
            <v-img :class="status.status_order_number > 1 || status.property_status ? '' : 'status-incomplete'" :src="dashFriends"></v-img>
        </v-avatar>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="status.status_order_number > 3 && status.property_status ? '' : 'status-incomplete'" :src="dashHouse"></v-img>
        </v-avatar>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="status.status_order_number >= 7 && status.property_status ? '' : 'status-incomplete'" :src="dashNote"></v-img>
        </v-avatar>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="status.invoice_status.ready ? '' : 'status-incomplete'" :src="dashCard"></v-img>
        </v-avatar>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="status.invoice_status.sent ? '' : 'status-incomplete'" :src="dashDone"></v-img>
        </v-avatar>
        <v-avatar size="16" class="mr-1" tile>
            <v-img :class="student.stripe ? '' : 'status-incomplete'" :src="dashMoney"></v-img>
        </v-avatar>
    </div>
</template>

<script>
import StatusService from '../../services/status.service';

    export default {
        name: "StudentStatusTracker",
        props: ['student'],
        data() {
            return {
                dashUser: require(`@/assets/icons/dash-user.svg`),
                dashFriends: require(`@/assets/icons/dash-friends.svg`),
                dashFriendsFull: require(`@/assets/icons/dash-friends-full.svg`),
                dashHouse: require(`@/assets/icons/dash-house.svg`),
                dashNote: require(`@/assets/icons/dash-note.svg`),
                dashMoney: require(`@/assets/icons/dash-money.svg`),
                dashCard: require(`@/assets/icons/dash-card.svg`),
                dashDone: require('@/assets/icons/dash-done.svg'),

                status: {   
                            "invoice_status": false,
                            "property_status": false,
                            "status_order_number": 0
                        },
            }
        },
        watch:{
            async student(){
                await this.getStudentStatus();
            }
        },            
        methods: {
            async getStudentStatus() {
                this.status = await StatusService.getUserStatus(this.student.id);
            }
        },
        async beforeMount() {
            if (this.student.id) {
                await this.getStudentStatus();
            }
        },
    }
</script>

<style scoped>
    .status-incomplete {
      opacity: 0.2;
    }
</style>