<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <h2 class="my-0">Roommate Profile</h2>
                <v-row>
                    <v-col class="py-0">
                        <v-card class="mx-auto my-12 head-card" elevation="2">
                            <v-list-item class="head-section">
                                <v-list-item-avatar color="grey" size="60" class="head-avatar">
                                    <v-img :src="student.image_url"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content class="head-item-section">
                                    <v-list-item-title class="headline"><small>{{student.name}}</small></v-list-item-title>
                                    <v-list-item-subtitle>{{calcAge(student.date_of_birth)}} | {{getSchoolName(student.school_id)}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-card-text class="switch-container py-0">
                                <v-switch label="Need a Roommate?"
                                          v-model="student.needs_roommate"
                                          @change="updateExistingStudent('needs_roommate', $event)"
                                          inset dense
                                          :readonly="!hasPermissions"
                                ></v-switch>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <p>Gender</p>
                        <v-select outlined dense
                                  :items="['male', 'female']"
                                  v-model="student.gender"
                                  :error-messages="genderErrors"
                                  @input="validation.student.gender.$touch()"
                                  @blur="validation.student.gender.$touch()"
                                  @change="updateExistingStudent('gender', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p>Date of Birth</p>
                        <v-menu
                                v-model="startDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                                :readonly="!hasPermissions"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="student.date_of_birth"
                                        outlined
                                        append-icon="mdi-calendar"
                                        dense
                                        readonly
                                        v-on="on"
                                        :error-messages="dobErrors"
                                        @input="validation.student.date_of_birth.$touch()"
                                        @blur="validation.student.date_of_birth.$touch()"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="student.date_of_birth"
                                           no-title
                                           @input="startDateMenu = false"
                                           :readonly="!hasPermissions"
                                           @change="updateExistingStudent('date_of_birth', $event)"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p>Major/Program</p>
                      <v-text-field
                          v-model="student.program"
                          outlined
                          dense
                          placeholder="Business, Finance, Welding, Art, etc."
                          @change="updateExistingStudent('program', $event)"
                          :readonly="!hasPermissions"
                      ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h3>Roommate Preferences</h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <p>Gender</p>
                                <v-select outlined dense
                                          :items="['any', 'male', 'female']"
                                          v-model="student.ideal_roommate_gender"
                                          :error-messages="roommateGenderErrors"
                                          @input="validation.student.ideal_roommate_gender.$touch()"
                                          @blur="validation.student.ideal_roommate_gender.$touch()"
                                          @change="updateExistingStudent('ideal_roommate_gender', $event)"
                                          :readonly="!hasPermissions"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <p>Min. Age</p>
                                <v-select outlined dense
                                          :items="minimumAges"
                                          v-model="student.ideal_roommate_age_young"
                                          :error-messages="roommateMinAgeErrors"
                                          @input="validation.student.ideal_roommate_age_young.$touch()"
                                          @blur="validation.student.ideal_roommate_age_young.$touch()"
                                          @change="updateExistingStudent('ideal_roommate_age_young', $event)"
                                          :readonly="!hasPermissions"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <p>Max. Age</p>
                                <v-select outlined dense
                                          :items="maximumAges"
                                          v-model="student.ideal_roommate_age_old"
                                          :error-messages="roommateMaxAgeErrors"
                                          @input="validation.student.ideal_roommate_age_old.$touch()"
                                          @blur="validation.student.ideal_roommate_age_old.$touch()"
                                          @change="updateExistingStudent('ideal_roommate_age_old', $event)"
                                          :readonly="!hasPermissions"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div class="d-flex justify-space-between py-2">
                            <h3>Chosen Roommate/s</h3>
                            <v-btn text small color="secondary"
                                   outlined class="text-capitalize"
                                   @click="showAddNewRoommateDialog = !showAddNewRoommateDialog"
                                   v-if="canView"
                            >Add Roommate</v-btn>

                        </div>
                        <v-data-table
                                :headers="headers"
                                :items="studentRoommates"
                                :items-per-page="itemsPerPage"
                                class="elevation-1 table-proto"
                                @page-count="pageCount = $event"
                                :loading="isLoadingStudentRoommates"
                        >
                            <template v-slot:item.name="props">
                                <a @click="showSelectedStudent(props.item.roommate)">{{props.item.roommate.name}}</a>
                            </template>
                            <template v-slot:item.status="props">
                                <StudentStatus :status-id="props.item.roommate.status_id" />
                            </template>
                            <template v-slot:item.delete="props">
                                <v-avatar tile size="14" @click="deleteRoommate(props.item.id)">
                                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                                    ></v-img>
                                </v-avatar>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="!isAddNewStudentState">
                <h2 class="my-0">Roommate Matches</h2>
                <v-row>
                    <v-col class="roommate-matches-container py-0">
                        <div class="pa-4" style="background: #F4F5F6;" v-if="isLoadingMatches">
                            <v-skeleton-loader
                                    type="card"
                                    loading
                                    style="background: white;  height: 200px;"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                    type="card"
                                    loading
                                    class="mt-4"
                                    style="background: white;  height: 200px;"
                            ></v-skeleton-loader>
                        </div>

                        <v-card class="mx-auto my-6" elevation="2" v-for="roommate in matchRoommates" :key="roommate.id">
                            <v-list-item class="head-section">
                                <v-list-item-avatar color="grey" size="50">
                                    <v-img :src="roommate.image_url"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                      <a @click="showSelectedStudent(roommate)"><small>{{roommate.name}}</small></a>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text">{{roommate.date_of_birth}} | {{getSchoolName(roommate.school_id)}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-content class="justify-end">
                                    <v-col class="text-right py-0" v-if="roommate.referred_roommates.length > 0">
                                        <p style="color:green">
                                            <v-icon left color="success" size="20">mdi-check</v-icon>
                                            Referred
                                        </p>
                                        <v-list-item-subtitle><i>{{roommate.created_at | formatDate}}</i></v-list-item-subtitle>
                                    </v-col>

                                    <v-checkbox label="Refer" v-model="referrals[roommate.id]" :readonly="!hasPermissions" v-else></v-checkbox>
                                </v-list-item-content>
                            </v-list-item>

                            <v-card-text class="switch-container py-0">
                                <v-row>
                                    <v-col>
                                        Start date: {{roommate.university_start_date}}
                                    </v-col>
                                    <v-col>
                                        Phone: {{roommate.phone_number}}
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="hasPermissions">
                    <v-col cols="12" class="text-md-right">
                        <v-btn color="primary" :loading="isSendingMatches" @click="sendRoommateMatches">Send Roommate Matches</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="showAddNewRoommateDialog" width="600">
            <v-card>
                <div class="d-flex justify-space-between">
                    <v-card-title>Add New Roommate</v-card-title>
                    <v-btn icon class="mt-4 mr-4" @click="showAddNewRoommateDialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <div class="d-flex justify-space-between">
                        <v-text-field outlined dense placeholder="Enter roommate name or email" v-model="searchedRoommate"></v-text-field>
                        <v-btn color="primary" height="40" @click="findRoommate">Search</v-btn>
                    </div>

                    <v-list two-line>
                        <v-subheader v-if="this.searchedRoommates.length > 0" v-text="`${this.searchedRoommates.length} results`"></v-subheader>

                        <v-card elevation="2">
                            <template v-for="roommate in searchedRoommates">

                                <v-list-item :key="roommate.id">
                                    <v-list-item-avatar>
                                        <v-img :src="roommate.image_url"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="roommate.name"></v-list-item-title>
                                        <v-list-item-subtitle>{{`${roommate.phone_number} \| ${roommate.email}`}}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-btn rounded color="primary" @click="addRoommate(roommate.id)">Add</v-btn>
                                </v-list-item>

                                <v-divider :key="roommate.id" inset></v-divider>
                            </template>
                        </v-card>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

      <v-dialog v-model="showStudentDialog" width="1000" @click:outside="closeDialog">
        <StudentPreview :existing-student="selectedStudent" v-on:hideDialog="showStudentDialog=false"/>
      </v-dialog>

    </div>
</template>

<script>
    import StudentService from "../../services/student.service";
    import moment from "moment";
    import range from 'lodash/range';
    import StudentStatus from "../StudentStatus";

    const MINIMUM_AGE = 17;
    const MAXIMUM_AGE = 99;

    export default {
        name: "StudentRoommateProfile",
        components: {
          StudentPreview: () => import('@/components/Students/StudentPreview'),
          StudentStatus
        },
        props: ['student', 'validation', 'isSaved'],
        data() {
            return {
                roommateMatches: [],
                studentRoommates: [],
                referrals: [],
                isSendingMatches: false,
                isLoadingMatches: false,
                isLoadingStudentRoommates: false,
                startDateMenu: false,
                showAddNewRoommateDialog: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Gender', value: 'roommate.gender' },
                    { text: 'Status', value: 'status' },
                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                itemsPerPage: 5,
                searchedRoommate: '',
                searchedRoommates: [],
                selectedStudent: null,
                showStudentDialog: false
            }
        },
        watch: {
            isSaved(saved) {
                if (saved) {
                    this.getStudentMatches();
                }
            },
            student() {
	            this.getStudentRoommates()
	            this.getStudentMatches()
            }
        },
        computed: {
            matchRoommates() {
                return this.$store.getters.getMatchRoommates;
            },
            isAddNewStudentState() {
                return this.$store.getters.getIsAddNewStudentState;
            },
            students() {
                return this.$store.getters.getStudents;
            },
            schools() {
                return this.$store.getters.getSchools;
            },
            genderErrors() {
                const errors = [];
                if (!this.validation.student.gender.$dirty) return errors
                !this.validation.student.gender.required && errors.push('Student gender is required')
                return errors
            },
            dobErrors() {
                const errors = [];
                if (!this.validation.student.date_of_birth.$dirty) return errors
                !this.validation.student.date_of_birth.required && errors.push('Student date of birth is required')
                return errors
            },
            roommateGenderErrors() {
                const errors = [];
                if (!this.validation.student.ideal_roommate_gender.$dirty) return errors
                !this.validation.student.ideal_roommate_gender.required && errors.push('Roommate gender is required')
                return errors
            },
            roommateMinAgeErrors() {
                const errors = [];
                if (!this.validation.student.ideal_roommate_age_young.$dirty) return errors
                !this.validation.student.ideal_roommate_age_young.required && errors.push('Roommate minimum age is required')
                return errors
            },
            roommateMaxAgeErrors() {
                const errors = [];
                if (!this.validation.student.ideal_roommate_age_old.$dirty) return errors
                !this.validation.student.ideal_roommate_age_old.required && errors.push('Roommate maximum age is required')
                return errors
            },
            hasPermissions() {
                return this.$store.getters.userHasPermission('students', 'update');
            },
            minimumAges() {
                return range(MINIMUM_AGE, MAXIMUM_AGE);
            },
            maximumAges() {
                const minAge = Number(this.student.ideal_roommate_age_young) + 1;
                return range(minAge, (MAXIMUM_AGE + 1));

            },
            user() {
              return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            }
        },
        methods: {
            closeDialog: function () {               
                this.getStudentRoommates()
                this.getStudentMatches()
            },
            sendRoommateMatches: async function() {
                let error = false
                this.isSendingMatches = true
                await StudentService.sendRoommateMatches(this.student.id, this.referrals).catch(() => {
                  error = true
                });
                this.isSendingMatches = false;

                if (error) {
                  return
                }


              this.getStudentMatches()
            },
            getStudentMatches() {
                this.isLoadingMatches = true;
                if(!this.student.id)
                    return
                StudentService.getRoommateMatches(this.student.id).then(roommateMatches => {
                    this.$store.commit('setMatchRoommates', roommateMatches);
                    //this.roommateMatches = roommateMatches;
                    this.isLoadingMatches = false;
                })
            },
            getStudentRoommates: async function() {
                this.isLoadingStudentRoommates = true;
                this.studentRoommates = await StudentService.getStudentRoommates(this.student.id, '?appendRoommate');
                this.isLoadingStudentRoommates = false
            },
            updateExistingStudent: async function (field, value) {
                //this.$emit('updateExistingStudent', {[field]: value})
                await this.$emit('updateExistingStudent', {[field]: value})
                this.$nextTick(() => {
                    if(field == 'needs_roommate'){
                        this.getStudentRoommates();
                        this.getStudentMatches();
                    } 
                })
            },
            getSchoolName(schoolId) {
                let schoolName = '';
                this.schools.forEach(school => {
                    if (Number(school.id) === Number(schoolId)) {
                        schoolName = school.name;
                    }
                })

                return schoolName;
            },
            calcAge(date) {
                const today = moment();
                return today.diff(moment(date), 'years');
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteRoommate: async function(roommateId) {
                await StudentService.removeRoommate(roommateId);
                this.getStudentRoommates();
            },
            findRoommate: async function() {
                if (this.searchedRoommate === '') {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Please enter the name or email of the roommate you are looking for',
                        color: 'red'
                    });

                    return
                }
                this.searchedRoommates = await StudentService.findRoommate(this.searchedRoommate);
            },
            addRoommate: async function(roommateId) {
                await StudentService.addRoommate(this.student.id, {roommateId});
                this.showAddNewRoommateDialog = false;

                this.getStudentRoommates()
            },
          showSelectedStudent(student) {
              this.selectedStudent = student;
              this.showStudentDialog = true;
          }
        },
        beforeMount: async function() {
            if (this.$store.getters.getIsAddNewStudentState) {
                return;
            }

            if (Object.keys(this.student).length === 0) {
            	return
            }

            this.getStudentRoommates()
            this.getStudentMatches()
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
                }
            },
            capitalize(value) {
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
    .v-list-item__content {
        padding: 0;
    }
    .head-card {
        position: relative;
    }
    .head-section {
        background-color: #F4F5F6;
    }
    .head-item-section {
        margin-left: 80px;
    }
    .head-avatar {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .switch-container {
        padding-left: 82px;
    }
    .roommate-matches-container {
        height: 670px;
        overflow-y: scroll;
        margin-top: 24px;
    }
</style>