import ContactService from '../../services/contact.service'


const state = () => ({
    isFetching: false,
})

const getters = {
    getContactFetchingState: (state) => {
        return state.isFetching
    },
}

const actions = {
    async sendContactMessage({ commit }, data) {
        commit('setFetchingState', true)
        await ContactService.sendContactMessage(data);
        commit('setFetchingState', false)
    },
    async sendGetInfoMessage({ commit }, data) {
        commit('setFetchingState', true)
        await ContactService.sendGetInfoMessage(data);
        commit('setFetchingState', false)
    },
    async sendEdurentPartnerMessage({ commit }, data) {
        commit('setFetchingState', true)
        await ContactService.sendEdurentPartnerMessage(data);
        commit('setFetchingState', false)
    },
    async sendPropertyContactMessage({ commit }, data) {
        commit('setFetchingState', true)
        await ContactService.sendPropertyContactMessage(data);
        commit('setFetchingState', false)
    },
}

const mutations = {
    setFetchingState (state, isFetching) {
        state.isFetching = isFetching
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
