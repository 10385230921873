<template>
    <div>
        <h2>SMS Messages</h2>
        <v-row>
            <v-col cols="12" md="4">
                <v-select outlined dense
                          :items="phoneNumbers"
                          v-model="phoneNumber"
                          @change="getTextMessages"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-menu
                        v-model="showDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                outlined
                                append-icon="mdi-calendar"
                                dense
                                readonly
                                v-on="on"
                                :value="dates"
                        ></v-text-field>
                    </template>
                  <v-date-picker
                      range
                      no-title
                      v-model="dates"
                      @change="getTextMessages"
                  ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-card :loading="isCallingApi" class="chat-body">
            <v-card-text class="chat-content">

              <div v-if="formattedTextMessages.length > 0">

                <div v-for="(item, i) in formattedTextMessages" :key="i" >
                  <v-row class="justify-center pb-4 pt-2 px-4" v-if="item.isNewDate">
                    <v-col cols="5" class="text-right px-0">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="2" class="pt-0 justify-center d-flex grey--text">
                      {{ item.textDate }}
                    </v-col>
                    <v-col cols="5" class="text-left px-0">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>

                  <v-row  :class="item.direction ==='Inbound' ? 'justify-start' : 'justify-end'">
                    <v-col cols="7" class="mt-6 text-left" v-if="item.direction ==='Inbound'">
                      <div class="chat-bubble-inbound">{{item.subject}}</div>
                      <span>{{student.name}} {{getTextCreationTime(item.creationTime)}}</span>
                    </v-col>
                    <v-col cols="7" class="mt-6 text-right" v-else>
                      <div class="chat-bubble-outbound">{{item.subject}}</div>
                      <span>Edurent {{getTextCreationTime(item.creationTime)}}</span>
                    </v-col>
                  </v-row>
                </div>

              </div>
              <div v-else>
                No text messages for this time period
              </div>
            </v-card-text>
            <v-card-actions class="elevation-2">
                <v-row class="px-2">
                    <v-col cols="12" md="11">
                        <v-text-field dense
                                      class="write-text"
                                      outlined
                                      placeholder="Type Message"
                                      v-model="textMessage"
                                      :disabled="isSendingTextMessage"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1"><v-btn color="primary" @click="sendTextMessage" :loading="isSendingTextMessage">Send</v-btn></v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import moment from 'moment'
    import StudentService from "../../services/student.service";

    export default {
        name: 'StudentTextMessages',
        props: ['student', 'shouldRefresh'],
        data () {
            return {
                isCallingApi: false,
                isSendingTextMessage: false,
                showDateMenu: false,
                textMessages: [],
                textMessage: '',
                phoneNumber: '',
                dates: [],
                lastTextDate: null
            }
        },
        watch: {
            shouldRefresh(refresh) {
	            if (!refresh) return

	            //update phone number and get texts
	            this.phoneNumber = this.phoneNumbers[0]
	            this.getTextMessages()
            }
        },
        computed: {
            school() {
                const school = this.$store.getters.getSchools.filter(school => {
                    return Number(school.id) === Number(this.student.school_id)
                });

                return school[0]
            },
            phoneNumbers() {
                let phoneNumbers = [];
                if (this.student.phone_number) {
                    phoneNumbers.push(this.student.phone_number)
                }
                if (this.student.phone_number_secondary) {
                    phoneNumbers.push(this.student.phone_number_secondary)
                }
                if (this.student.phone_number_tertiary) {
                    phoneNumbers.push(this.student.phone_number_tertiary)
                }
                return phoneNumbers;
            },
            formattedTextMessages() {
              if (Object.keys(this.textMessages).length === 0 || Object.keys(this.textMessages.records).length === 0) {
                return []
              }

              return this.textMessages.records.map(message => {
                if (!this.isNewTextDate(message.creationTime)) {
                  message['isNewDate'] = false;
                  message['textDate']  = null;
                  return message
                }

                message['isNewDate'] = true;
                message['textDate'] = moment(message.creationTime).format('l');

                return message;
              })
            }
        },
        methods: {
            async getTextMessages() {
                if (this.dates.length === 1) {
                  return
                }

                this.showDateMenu = false;

                let params = `?phoneNumber=${this.phoneNumber}`;
                if (this.dates.length > 1) {
                    params += `&dateFrom=${this.dates[0]}&dateTo=${this.dates[1]}`
                }

                this.isCallingApi = true
                this.textMessages = await StudentService.getTextMessages(this.student.id, params)
                this.isCallingApi = false
            },
            async sendTextMessage() {
                if (!this.textMessage) {
                    return
                }

                this.isSendingTextMessage = true;
                await StudentService.sendTextMessage(this.student.id, {phoneNumber: this.phoneNumber, message: this.textMessage});
                this.isSendingTextMessage = false;

                this.textMessage = ''
                this.getTextMessages();
            },
            getTextCreationTime(time) {
                return moment(time).format('h:mm a')
            },
            isNewTextDate(date) {
              const newTextDate = moment(date).format('l');
              if (!this.lastTextDate)  {
                this.lastTextDate = newTextDate;
                return true;
              }

              if (moment(newTextDate).isSame(this.lastTextDate)) {
                return false
              }

              this.lastTextDate = newTextDate;
              return true
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('l hh:mm A')
                }
            }
        },
        async beforeMount() {
            this.phoneNumber = this.phoneNumbers[0];

            //init date
            this.dates[0] = moment().subtract(6, 'month').format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

          //get texts
	        this.getTextMessages()
        }
    }
</script>

<style scoped>
    .chat-bubble-inbound {
        background: linear-gradient(0deg, #E9EAEB, #E9EAEB), #FFFFFF;
        padding: 16px;
        border-radius: 10px 10px 10px 0;
    }
    .chat-bubble-outbound {
        background: linear-gradient(0deg, rgba(45, 118, 227, 0.15), rgba(45, 118, 227, 0.15)), #FFFFFF;
        padding: 16px;
        border-radius: 10px 10px 0 10px;
    }
    .chat-content {
      height: 300px;
      overflow: auto;
    }
    .chat-body {
      background: #F4F5F6;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
    .write-text {
      background: white;
      height: 40px
    }
</style>
