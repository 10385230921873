<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <p>Contact Name</p>
                <v-text-field outlined dense v-model="contact.name"
                              :error-messages="nameErrors"
                              @input="$v.contact.name.$touch()"
                              @blur="$v.contact.name.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Type</p>
                <v-select dense outlined
                          v-model='contact.type'
                          :items="contactTypes"
                          item-text="type"
                          item-value="id"
                          @change="contact.contact_type_id = $event"
                          :error-messages="typeErrors"
                          @input="$v.contact.type.$touch()"
                          @blur="$v.contact.type.$touch()"
                />
            </v-col>
            <v-col cols="12" md="2">
                <p>Main Phone</p>
                <v-text-field outlined dense
                              v-model="mainPhoneNumber"
                              placeholder="(999) 999-9999"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Mobile Phone</p>
                <v-text-field outlined dense
                              v-model="mobilePhoneNumber"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Email</p>
                <v-text-field outlined dense v-model="contact.email"
                              :error-messages="emailErrors"
                              @input="$v.contact.email.$touch()"
                              @blur="$v.contact.email.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="mt-md-6">
                <v-btn dense color="primary" @click="addPropertyContact" :loading="isAddingNewContact">
                    <v-icon left>mdi-plus</v-icon>
                    Add
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="propertyContacts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="isCallingApi"
        >
            <template v-slot:item.name="props">
                <v-edit-dialog
                        :return-value.sync="props.item.name"
                > {{ props.item.name }}
                    <template v-slot:input>
                        <v-text-field
                                v-model="props.item.name"
                                label="Edit"
                                single-line
                                counter
                                @change="updateContact(props.item.id, 'name', props.item.name)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.phone_number="props">
                <v-edit-dialog
                        :return-value.sync="props.item.phone_number"
                > {{ props.item.phone_number }}
                    <template v-slot:input>
                        <v-text-field
                                v-model="props.item.phone_number"
                                label="Edit"
                                single-line
                                counter
                                @change="updateContact(props.item.id, 'phone_number', props.item.phone_number)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.mobile_number="props">
                <v-edit-dialog
                        :return-value.sync="props.item.mobile_number"
                > {{ props.item.mobile_number }}
                    <template v-slot:input>
                        <v-text-field
                                v-model="props.item.mobile_number"
                                label="Edit"
                                single-line
                                counter
                                @change="updateContact(props.item.id, 'mobile_number', props.item.mobile_number)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.email="props">
                <v-edit-dialog
                        :return-value.sync="props.item.email"
                > {{ props.item.email }}
                    <template v-slot:input>
                        <v-text-field
                                v-model="props.item.email"
                                label="Edit"
                                single-line
                                counter
                                @change="updateContact(props.item.id, 'email', props.item.email)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="deletePropertyContact(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import PropertyService from "../../services/property.service";
    import { required, email } from 'vuelidate/lib/validators'

    export default {
        name: 'PropertyContacts',
        validations: {
            contact: {
                name: { required },
                type: { required },
                email: { required, email }
            }
        },
        props: ['property'],
        watch: {
            mainPhoneNumber() {
                this.formatMainNumber();
            },
            mobilePhoneNumber() {
                this.formatMobileNumber();
            }
        },
        data () {
            return {
                headers: [
                    {
                        text: 'Contact Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Type', value: 'type'},
                    { text: 'Main Phone', value: 'phone_number' },
                    { text: 'Mobile Phone', value: 'mobile_number' },
                    { text: 'Email', value: 'email' },
                    { text: '', value: 'delete' },
                ],
                contact: this.initContact(),
                propertyContacts: [],
                contactTypes: [],
                isAddingNewContact: false,
                isCallingApi: false,

                mainPhoneNumber: null,
                mobilePhoneNumber: null
            }
        },
        computed: {
            nameErrors () {
                const errors = []
                if (!this.$v.contact.name.$dirty) return errors
                !this.$v.contact.name.required && errors.push('Contact name is required')
                return errors
            },
            typeErrors () {
                const errors = []
                if (!this.$v.contact.type.$dirty) return errors
                !this.$v.contact.type.required && errors.push('Contact type is required')
                return errors
            },
            emailErrors () {
                const errors = []
                if (!this.$v.contact.email.$dirty) return errors
                !this.$v.contact.email.email && errors.push('A valid email is required')
                !this.$v.contact.email.required && errors.push('Contact email is required')
                return errors
            },
        },
        methods: {
            initContact() {
                return {
                    name: '',
                    type: '',
                    contact_type_id: '',
                    phone_number: '',
                    mobile_number: '',
                    email: '',
                };
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            addPropertyContact() {
                this.$v.contact.$touch();
                if (this.$v.contact.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'One or more mandatory field(s) are missing. Please look through the contact form and fix the field(s) highlighted in red',
                        color: 'red'
                    });
                    return
                }

                this.isAddingNewContact = true;
                this.$store.dispatch('addPropertyContact', {
                    propertyId: this.property.id,
                    contact: this.contact
                }).then(() => {
                    this.contact = this.initContact();
                    this.mobilePhoneNumber = '';
                    this.mainPhoneNumber = ''
                    this.getPropertyContacts();

                    this.$v.$reset();
                    this.isAddingNewContact = false
                });
            },
            deletePropertyContact(id) {
                this.isCallingApi = true;
                this.$store.dispatch('deletePropertyContact', id).then(() => {
                    this.getPropertyContacts();
                });
            },
            getPropertyContacts() {
                this.isCallingApi = true;
                this.$store.dispatch('getPropertyContacts', this.property.id).then(propertyContacts => {
                    this.isCallingApi = false;
                    this.propertyContacts = propertyContacts;
                })
            },
            formatMainNumber() {
                let x = this.mainPhoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.mainPhoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

                this.contact.phone_number = this.mainPhoneNumber;
            },
            formatMobileNumber() {
                let x = this.mobilePhoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.mobilePhoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

                this.contact.mobile_number = this.mobilePhoneNumber;
            },
            updateContact(id, field, value) {
                this.isCallingApi = true;
                PropertyService.updatePropertyContact(id, {[field]: value}).then(() => {
                    this.isCallingApi = false;
                    this.getPropertyContacts();
                })
            }
        },

        beforeMount() {
             this.$store.dispatch('getContactTypes').then(contactTypes => {
                 this.contactTypes = contactTypes;
             });

             this.getPropertyContacts();
        }
    }
</script>
