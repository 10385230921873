<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h3>{{notification && notification.user && notification.user.name ? notification.user.name : ''}}</h3>
        <v-btn icon @click="$emit('hideDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="pt-2">
        SMS Notification Sent On {{formatDate(notification.created_at)}}
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea v-text="notification.content" readonly/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end pa-2 elevation-2">
        <v-btn color="error" @click="$emit('hideDialog')">Cancel</v-btn>
        <v-btn color="primary" @click="showStudent()" v-if="notification.user">Open Student Profile</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showStudentDialog" scrollable max-width="1111">
      <StudentPreview v-on:hideDialog="showStudentDialog=false" :existing-student="notification.user"/>
    </v-dialog>
  </div>
</template>

<script>
    import moment from "moment";
    import StudentPreview from "@/components/Students/StudentPreview";

    export default {
        name: 'NotificationMessage',
        components: {StudentPreview},
        props: {
          notification: {
            default: {
              user: {},
              content: ''
            },
            required: true
          }
        },
        data() {
            return {
              showStudentDialog: false
            }
        },
        methods: {
          showStudent() {
            this.showStudentDialog = true
            this.$emit('hideDialog')
          },
          formatDate (value) {
            if (value) {
              return moment(String(value)).format('l')
            }
          }
      }
    }
</script>