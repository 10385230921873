<template>

    <div class="content-login">

        <v-card tile color="#212B38" class="d-flex flex-row align-center justify-space-between">

            <v-card tile class="image-login">
                <LiveImage />
            </v-card>

            <v-card color="transparent" elevation="0" tile class="d-flex flex-column align-center justify-center form-login">

                <div class="form-login-container text-center">

                    <img src="../../src/assets/logo.svg" alt="logo" />

                    <p class="my-8 font-weight-bold white--text sign-in-text">
                        Sign in to your account!
                    </p>

                    <v-text-field
                            v-model="email"
                            placeholder="Username or Email Address"
                            background-color="white"
                            outlined
                            dense
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                    <v-text-field
                            v-model="password"
                            placeholder="Password"
                            background-color="white"
                            outlined
                            dense
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            :error-messages="passwordErrors"
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :disabled="isLoggingIn"
                            @keyup.enter="login"
                    ></v-text-field>

                    <div class="d-flex justify-space-between">
                        <p class="white--text">Remember me</p>
                        <p class="primary--text"><a @click="$router.push('/forgot-password')">Forgot password?</a></p>
                    </div>

                    <v-btn dense block x-large depressed
                           color="primary"
                           class="my-8 text-capitalize font-weight-bold"
                           @click="login"
                           :loading="isLoggingIn"
                    >Sign In</v-btn>

                    <p class="white--text">
                        Don't have an account? <a class="primary--text" @click="$router.push('/register')">Register</a>
                    </p>

                </div>

            </v-card>

        </v-card>

    </div>

</template>

<script>
    import {email, required} from 'vuelidate/lib/validators'
    import LiveImage from '../components/LiveImage.vue'

export default {
    name: 'Login',
    validations: {
        email: { email, required },
        password: { required }
    },
    beforeCreate: function() {
        document.body.className = 'login-page';
    },
    components: {
        LiveImage,
    },
    data () {
        return {
            show1: false,
            email: '',
            password: '',
            isLoggingIn: false
        }
    },
    computed: {
        emailErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push('A valid email is required');
            !this.$v.email.required && errors.push('Email is required');
            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.required && errors.push('Password is required');
            return errors
        }
    },
    methods: {
        login: async function() {
            //validate
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$store.commit('setSnackbar', {
                    show: true,
                    message: 'A valid username and password is required',
                    color: 'red'
                });

                return
            }

            this.isLoggingIn = true;

            await this.$store.dispatch('login', {email: this.email, password: this.password}).catch(() => {
                this.isLoggingIn = false;
            });

            this.isLoggingIn = false;
        }
    }
}

</script>

<style>

.content-login .v-card {
    height: 100vh;
    font-size: 14px;
    box-shadow:none!important;
    -webkit-box-shadow:none!important;
}

.content-login .v-card .v-card {
    width: 50%;
}

@media only screen and (max-width: 850px) {

    .content-login .v-card .v-card {
        width: 100%;
    }

    .content-login .image-login {
        display: none;
    }

}

.content-login .v-card.form-login {
    height: auto;
}

.content-login .sign-in-text {
    font-size: 20px;
}

.content-login .form-login-container {
    width: 350px;
}

@media only screen and (max-width: 380px) {

    .content-login .form-login-container {
        width: 90%;
    }

}

.content-login .form-login-container img {
    width: 70%;
}

</style>
