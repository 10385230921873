<template>
    <div>
      <div class="d-flex justify-space-between">
        <h2 class="pt-2">
          Property Matches
        </h2>
        <v-hover v-slot:default="{hover}">
          <v-btn icon
                 :elevation="hover ? 12 : 0"
                 :color="hover ? 'primary': ''"
                 @click="$emit('getProperties')"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-hover>
      </div>

        <div class="property-matches-container">
	        <v-lazy v-for="(item, i) in loadedProperties"
	                :key="i"
	                min-height="22"
	                class="my-4"
	                :options="{
                                    threshold: 0,
                                    rootMargin: '16px 0px 16px 0px'
                                }"
	                :value="false"
	        >
            <StudentPropertyMatchCard :property-unit="item"
                                      :key="i"
                                      :student="student"
                                      :margin="'mb-4'"
                                      type="matches"
                                      :card-index="i"
                                      :distance-to-school="getSchoolDistance(item)"
                                      :current-card-index="currentCardIndex"
                                      v-on:onCardClicked="onCardClicked($event)"
                                      @propertyReferred="$emit('propertyReferred', $event)"
            />
	        </v-lazy>
	        <v-btn block v-if="hasMoreProperties" @click="loadMoreProperties" class="text-capitalize mb-4">
		        Load More
	        </v-btn>
        </div>
    </div>
</template>

<script>
    import StudentPropertyMatchCard from "../../components/Students/StudentPropertyMatchCard";
    import {calculateGeoDistances} from "@/utils/utils";

    export default {
        name: "StudentPropertyMatches",
        props: ['student', 'propertyUnits'],
        components: {
            StudentPropertyMatchCard,
        },
        data() {
            return {
                currentCardIndex: 0,
	              loadedPropertyCounter: 10,
            }
        },
		    computed: {
			    hasMoreProperties() {
				    if (!this.propertyUnits) return false

				    return this.propertyUnits.length > this.loadedProperties.length
			    },
			    loadedProperties() {
				    if (!this.propertyUnits) return []

				    return this.propertyUnits.slice(0, this.loadedPropertyCounter)
			    }
		    },
        methods: {
            onCardClicked(content) {
                this.currentCardIndex = content.cardIndex;
                this.$emit('propertyUnitClicked', {
                  position: {
                    lat: Number(content.propertyUnit.property.latitude),
                    lng: Number(content.propertyUnit.property.longitude)
                  },
                  icon:"map-building.svg",
                  type: 'propertyUnit',
                  propertyUnit: content.propertyUnit,
                  distanceToSchool: Number(content.distanceToSchool)
                })
            },
		        loadMoreProperties() {
			        this.loadedPropertyCounter += this.loadedPropertyCounter
		        },
            getSchoolDistance(propertyUnit) {
              const distance = calculateGeoDistances(
                  Number(this.student.campus.latitude),
                  Number(this.student.campus.longitude),
                  Number(propertyUnit.property.latitude),
                  Number(propertyUnit.property.longitude)
              );

              return Number(distance).toFixed(0)
            },
        }
    }
</script>

<style scoped>
    .property-matches-container {
        height: 517px;
        overflow-y: auto;
        padding: 2px 6px 0 6px;
    }
</style>