<template>
    <v-card :loading="isSavingPropertyUnit||isCallingApi" :flat="!isNewPropertyUnit">
        <v-card-title v-if="isNewPropertyUnit"  class="justify-space-between">
            Add New Property Unit
            <v-btn icon small @click="hideDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" class="pt-4">
                    <h3 class="mb-6">Unit Info</h3>
                    <v-row class="justify-start">
                        <v-col>
                            <h5>Unit ID</h5>
                            <v-text-field outlined dense
                                          v-model="propertyUnit.name"
                                          :error-messages="nameErrors"
                                          @blur="$v.propertyUnit.name.$touch()"
                                          @input="$v.propertyUnit.name.$touch()"
                                          @change="updatePropertyUnit('name', $event)"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <h5>Bed</h5>
                            <v-select outlined dense :items="[1, 2, 3, 4, 5, 6]"
                                      v-model="propertyUnit.number_of_bedrooms"
                                      :error-messages="bedroomErrors"
                                      @blur="$v.propertyUnit.number_of_bedrooms.$touch()"
                                      @input="$v.propertyUnit.number_of_bedrooms.$touch()"
                                      @change="updatePropertyUnit('number_of_bedrooms', $event)"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <h5>Bath</h5>
                            <v-select outlined dense :items="[1, 2, 3, 4, 5, 6]"
                                      v-model="propertyUnit.number_of_baths"
                                      :error-messages="bathErrors"
                                      @blur="$v.propertyUnit.number_of_baths.$touch()"
                                      @input="$v.propertyUnit.number_of_baths.$touch()"
                                      @change="updatePropertyUnit('number_of_baths', $event)"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row class="justify-start">
                        <v-col class="py-0">
                            <h5>Sq. Ft.</h5>
                            <v-text-field outlined dense
                                          v-model="propertyUnit.square_feet"
                                          type="number"
                                          :error-messages="squareFeetErrors"
                                          @change="updatePropertyUnit('square_feet', $event)"
                                          @blur="$v.propertyUnit.square_feet.$touch()"
                                          @input="$v.propertyUnit.square_feet.$touch()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <h5>Price</h5>
                            <v-text-field outlined dense
                                          type="number"
                                          suffix="$"
                                          v-model="propertyUnit.price"
                                          :error-messages="priceErrors"
                                          @change="updatePropertyUnit('price', $event)"
                                          @blur="$v.propertyUnit.price.$touch()"
                                          @input="$v.propertyUnit.price.$touch()"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="justify-start">
                        <v-col class="py-0">
                            <h5>Unit Amenities</h5>
                            <v-combobox
                                    v-model="propertyUnit.amenities"
                                    :items="amenities"
                                    multiple
                                    chips
                                    outlined
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    @change="updateAmenities($event)"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                            :key="JSON.stringify(data.item)"
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                            :disabled="data.disabled"
                                            close
                                            @click:close="data.parent.selectItem(data.item)"
                                    >
                                        <v-avatar
                                                class="accent white--text"
                                                left
                                                v-text="data.item.name.slice(0, 1).toUpperCase()"
                                        ></v-avatar>
                                        {{ data.item.name }}
                                    </v-chip>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>

                    <v-row class="justify-start">
                        <v-col class="py-0">
                            <PropertyUnitImage
                                    v-on:onPropertyUnitImageModified=updatePropertyUnitImage($event)
                                    v-on:onPropertyUnitImageDeleted=deletePropertyUnitImage($event)
                                    :property-unit="propertyUnit"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6">
                    <v-row class="justify-space-between">
                        <h3 class="mb-6">Availability</h3>
                    </v-row>
                    <v-row class="justify-start">
                        <v-col class="py-0" cols="12">
                            <h5>Available After</h5>
                            <v-menu
                                    v-model="availabilityDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="computedDateFormatted"
                                            outlined
                                            append-icon="mdi-calendar"
                                            dense
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title
                                               @input="availabilityDate = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="4">
                        <h5># of Units</h5>
                        <v-text-field outlined dense type="number" v-model="unitTotal"></v-text-field>
                      </v-col>
                      <v-col cols="4" class="py-0">
                        <h5>Price</h5>
                        <v-text-field outlined dense type="number" v-model="availabilityPrice"></v-text-field>
                      </v-col>
                      <v-col cols="3" class="py-0 pt-6">
                        <v-btn color="primary" @click="addAvailability">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="justify-start" v-if="propertyUnit.availabilities.length">
                        <v-col cols="4">
                            <h3> Date </h3>
                        </v-col>
                        <v-col cols="4">
                            <h3> Total </h3>
                        </v-col>
                        <v-col cols="4">
                            <h3> Price </h3>
                        </v-col>
                        <hr/>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="py-0 mb-2" v-for="(row, index) in propertyUnit.availabilities"
                               :key="index">
                            <v-card height="45">
                                <v-card-text>
                                    <v-row class="justify-space-between">
                                        <p>{{row.available_date}}</p>
                                        <p>{{row.number_of_units}}</p>
                                        <p>{{row.price}}</p>
                                        <v-icon class="mb-4" @click="deleteUnitAvailability(row.id, index)">mdi-delete</v-icon>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-----Save buttons-->
            <v-card-actions class="justify-end mt-4 pt-4" style="border-top: #C4C4C4 solid 1px"
                            v-if="this.type === 'new'">
                <v-btn text color="primary" @click="hideDialog">Cancel</v-btn>
                <v-btn color="primary" @click="save" :loading="isSavingPropertyUnit">Save</v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
    import PropertyUnitImage from "./PropertyUnitImage";
    import {PropertyService} from "../../services/property.service";
    import {required} from 'vuelidate/lib/validators';
    import ActivityLogService from "@/services/acitivitylog.service";

    export default {
        name: 'PropertyUnit',
        props: ['propertyUnit', 'type'],
        components: {
            PropertyUnitImage
        },
        watch: {
            date() {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        validations: {
            propertyUnit: {
                name: {required},
                number_of_bedrooms: {required},
                number_of_baths: {required},
                square_feet: {required},
                price: {required}
            }
        },
        data() {
            return {
                isSavingPropertyUnit: false,
                isCallingApi: false,
                date: new Date().toISOString().substr(0, 10),
                unitTotal: null,
                availabilityPrice: null,
                showSaveButton: false,
                availabilityDate: false
            }
        },
        computed: {
            isNewPropertyUnit() {
                return this.type === 'new'
            },
	          user() {
            	return this.$store.getters.getUser
	          },
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            amenities() {
                return this.$store.getters.getAmenities;
            },
            nameErrors() {
                const errors = []
                if (!this.$v.propertyUnit.name.$dirty) return errors
                !this.$v.propertyUnit.name.required && errors.push('Property unit name is required')
                return errors
            },
            bedroomErrors() {
                const errors = []
                if (!this.$v.propertyUnit.number_of_bedrooms.$dirty) return errors
                !this.$v.propertyUnit.number_of_bedrooms.required && errors.push('Number of bedrooms is required')
                return errors
            },
            bathErrors() {
                const errors = []
                if (!this.$v.propertyUnit.number_of_baths.$dirty) return errors
                !this.$v.propertyUnit.number_of_baths.required && errors.push('Number of bathrooms is required')
                return errors
            },
            squareFeetErrors() {
                const errors = [];
                if (!this.$v.propertyUnit.square_feet.$dirty) return errors
                !this.$v.propertyUnit.square_feet.required && errors.push('Property unit square feet value is required')
                return errors
            },
            priceErrors() {
                const errors = [];
                if (!this.$v.propertyUnit.price.$dirty) return errors
                !this.$v.propertyUnit.price.required && errors.push('Property unit price is required')
                return errors
            }
        },
        methods: {
            updatePropertyUnitImage: async function(data) {
                this.propertyUnit.floorPlanImage = data.image;
                this.propertyUnit.floor_plan_image_url = data.image_url;

                if (this.type === 'new') {
                    return;
                }

                //upload the image
                const formData = new FormData();
                formData.append('image', data.image);

                this.isCallingApi = true;
                await PropertyService.updatePropertyUnitFloorPlanImage(this.propertyUnit.id, formData);
                this.isCallingApi = false;
            },
            deletePropertyUnitImage: async function() {
                if (this.isNewPropertyUnit) {
                    return;
                }

                this.isCallingApi = true;
                await PropertyService.deletePropertyUnitFloorPlanImage(this.propertyUnit.id);
                this.isCallingApi = false;

                this.$emit('getPropertyUnits');
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            save: async function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'One or more mandatory fields are missing. Please look through the form and fix the field(s) highlighted in red',
                        color: 'red'
                    });

                    return
                }


                this.isSavingPropertyUnit = true;
                const newPropertyUnit = await PropertyService.addPropertyUnit(this.propertyUnit.property_id, {propertyUnit: this.propertyUnit});
                this.isSavingPropertyUnit = false;

                //if there is image upload it
                if (this.propertyUnit.floor_plan_image_url !== '') {
                    //upload the image
                    const formData = new FormData();
                    formData.append('image', this.propertyUnit.floorPlanImage);

                    await PropertyService.addPropertyUnitImage(newPropertyUnit.id, formData)
                }

                this.resetComponent();
                this.$emit('propertyUnitSaved')

	            //log activity
	            await ActivityLogService.addLog({
		            userable_id: this.user.agent.id,
		            userable_type: 'App\\Models\\Agent',
		            content: `Added new property unit ${this.propertyUnit.name} for property ${this.propertyUnit.property.name}`,
		            type: 'property'
	            })
            },
            async addAvailability() {
                this.propertyUnit.availabilities.push({
                    available_date: this.date,
                    number_of_units: this.unitTotal,
                    price: this.availabilityPrice
                });

                if (this.type === 'new') {
                    return;
                }

                //add the new availability
                PropertyService.addPropertyUnitAvailability(
                    this.propertyUnit.id,
                    {
                        availability: {
                            available_date: this.date,
                            number_of_units: this.unitTotal,
                            price: this.availabilityPrice
                        }
                    }
                )

                // reset number of units count
                this.unitTotal = 1;

                //log activity
		            await ActivityLogService.addLog({
			            userable_id: this.user.agent.id,
			            userable_type: 'App\\Models\\Agent',
			            content: `Added new property unit availability for unit ${this.propertyUnit.name} of property ${this.propertyUnit.property.name}`,
			            type: 'property'
		            })
            },
            updateAmenities(amenities) {
                if (this.type === 'new') {
                    return
                }

                if (!this.isNewPropertyUnit) {
                    //existing so update on db
                    PropertyService.updatePropertyUnitAmenities(
                        this.propertyUnit.id, {
                            amenities: amenities
                        }
                    )
                }
            },
            handleDeleteIconMouseOver(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash.svg`);
            },
            async deleteUnitAvailability(propertyUnitAvailabilityId, index) {
                if (this.type === 'new') {
                  this.propertyUnit.availabilities.splice(index, 1)
                  return
                }

                this.isCallingApi = true;
                this.$store.dispatch('deletePropertyUnitAvailability', propertyUnitAvailabilityId).then(() => {
                    this.isCallingApi = false;
                    this.$emit('getPropertyUnits');
                });

		            //log activity
		            await ActivityLogService.addLog({
			            userable_id: this.user.agent.id,
			            userable_type: 'App\\Models\\Agent',
			            content: `Deleted property unit availability for unit ${this.propertyUnit.name} of property ${this.propertyUnit.property.name}`,
			            type: 'property'
		            })
            },
            updatePropertyUnit(field, value) {
                if (this.type === 'new') {
                    return;
                }

                PropertyService.updatePropertyUnit(this.propertyUnit.id, {[field]: value});
            },
            hideDialog() {
                this.resetComponent();
                this.$emit('hideDialog')
            },
            resetComponent() {
                this.$v.$reset();
                this.isSavingPropertyUnit = false;
                this.unitTotal = null;
                this.propertyUnit.amenities = [];
            }
        },
        beforeMount() {
            if (this.type === 'new') {
                this.showSaveButton = true;
            }
        }
    }
</script>

<style scoped>

    .side-menu {
        background: #F4F5F6;
    }

    .v-item--active {
        background: #FFFFFF;
    }
</style>
