<template>
    <v-snackbar v-model="show" :color="color" :timeout="timeout" top>
        {{message}}
        <v-btn text @click.native="show = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'Snackbar',
        data () {
            return {
                show: false,
                color: '',
                message: '',
                timeout: 4000
            }
        },
        watch: {
          show(isShowing) {
              if (!isShowing) {
                  this.$store.commit('setShowSnackbar', false)
              }
          }
        },
        computed: {
          isShowing() {
              return this.$store.getters.getShowSnackbar;
          }
        },
        created() {
            this.$store.watch(state => state.Snackbar.showSnackbar, () => {
                this.message = this.$store.getters.getSnackMessage
                this.color   = this.$store.getters.getSnackColor
                this.show    = this.$store.getters.getShowSnackbar
                this.timeout = this.$store.getters.getTimeout
            })
        }
    }
</script>
