import ApiService from './api.service'

const StatusService = {
    getUserStatus: async function(userId) {
        const response = await ApiService.get(`user-status/${userId}`);
        return response.data.data
    },
    updateUserStatus: async function (userId, data) {
        const response = await ApiService.post(`user-status/${userId}/update`, data);
        return response.data.data;
    }
}

export default StatusService;