<template>
    <div>
        <v-row>
            <v-col cols="12" md="5">
                <v-text-field outlined dense append-icon="mdi-magnify" v-model="search" placeholder="Search all Rent Specials" background-color="white"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="justify-end d-flex">
                <v-btn text height="40" color="primary" @click="resetFilters">
                    Clear Filters
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="headers"
            :items="rentSpecials"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1 table-proto"
            @page-count="pageCount = $event"
            :loading="isLoadingRentSpecials || isDeletingRentSpecials"
        >
            <template v-slot:item.label="props">
                <RentSpecialDialog action="show-btn" :actionText="props.item.label" :existing-special="props.item"/>
            </template>
            <template v-slot:item.content="props">
                {{truncateText(props.item.content, 50)}}
            </template>
            <template v-slot:item.created_at="props">
                {{props.item.created_at | formatDate}}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile width="16" height="18" min-width="16" min-height="18" @click="showNotification(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nav="props">
                <RentSpecialDialog action="show" :existing-special="props.item" />
            </template>
        </v-data-table>
        <v-row class="justify-end" v-if="!isLoadingRentSpecials">
            <v-col cols="12" md="6" class="text-right">
                <v-row>
                    <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="pages" dense
                                  outlined
                                  @change="itemsPerPage = parseInt($event, 10)"
                                  v-model="itemsPerPage"
                                  background-color="white"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pt-md-5 text-left">Entries</v-col>
                    <v-col cols="12" md="5">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete this rent special."
                    v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteRentSpecial"
            />
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment'
    import Notification from "../../Shared/Notification";
    import RentSpecialDialog from "./RentSpecialDialog";
    import RentSpecialService from "../../../services/rentspecials.service";
    import {displayMessage} from "../../../utils/utils";

    export default {
        name: 'RentSpecialDataTable',
        components: {
            RentSpecialDialog,
            Notification,
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                typeFilter: '',
                categoryFilter: '',
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingRentSpecials: false,
                headers: [
                    {
                        text: 'Label',
                        align: 'start',
                        value: 'label',
                    },
                    { text: 'Description', value: 'content' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Created On', value: 'created_at' },

                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                showNotificationDialog: false,
                currentRentSpecialId: null
            }
        },
        computed: {
            rentSpecials() {
                return this.$store.getters.getRentSpecials;
            },
            isLoadingRentSpecials() {
                return this.$store.getters.getIsLoadingRentSpecials;
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('rentSpecials', 'delete');
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },

            truncateText(text, length, clamp) {
                clamp = clamp || '...';
                return text.length > length ? text.slice(0, length) + clamp : text;
            },
            deleteRentSpecial: async function() {
                this.showNotificationDialog = false;
                this.isDeletingRentSpecials = true;
                await RentSpecialService.deleteRentSpecial(this.currentRentSpecialId);
                this.isDeletingRentSpecials = false;

                this.getRentSpecials();
            },
            getRentSpecials() {
                this.$store.dispatch('getRentSpecials', '?sort=desc');
            },
            showNotification(defaultMessageId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete a rent special')
                    return;
                }

                this.currentRentSpecialId = defaultMessageId;
                this.showNotificationDialog = true;
            },
            resetFilters() {
                this.typeFilter     = '';
                this.categoryFilter = '';
                this.search         = '';

                this.getRentSpecials();
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
                }
            }
        }
    }
</script>

<style scoped>
    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
</style>
