<template>
    <div v-if="campaign">
        <h3>EDUfund</h3>
        <v-row class="justify-space-between">
            <v-col><p>${{totalDonation}} Raised</p></v-col>
            <v-col class="text-right">Goal: ${{Number(campaign.amount)}}</v-col>
        </v-row>
        <v-row>
            <v-col class="my-0">
                <v-progress-linear
                        v-model="progress"
                        height="50"
                        class="progress-bar"
                        color="success"
                >
                    <strong v-if="progress > 0">{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="campaign.donations"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        class="elevation-1"
                        :loading="isCallingApi"
                >
                    <template v-slot:item.amount="props">
                        ${{Number(props.item.amount / 100).toFixed(2)}}
                    </template>
                    <template v-slot:item.created_at="props">
                        ${{props.item.created_at | formatDate}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
    <div v-else class="mt-3">
        <h3>EDUfund</h3>
        <v-row class="justify-space-between">
            <v-col><p>$0.00 Raised</p></v-col>
            <v-col class="text-right">Campaign not started</v-col>
        </v-row>
    </div>
</template>

<script>
    import StudentService from "../../services/student.service";
    import moment from 'moment'
    export default {
        name: "StudentEdufund",
        props: ['student'],
        data() {
            return {
                amount: 50,
                page: 0,
                itemsPerPage: 5,
                headers: [
                    {
                        text: 'Deposited by',
                        align: 'start',
                        value: 'donor_name',
                    },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Message', value: 'donor_note' },
                    { text: 'Date', value: 'created_at' },
                ],
                isCallingApi: false,
                donations: [],
                campaign: {
                    user_id: '',
                    amount: null,
                    deposit_type: ''
                }
            }
        },
        computed: {
            totalDonation() {
                if (!this.campaign || !this.campaign.donations) return 0;
                let total = 0;
                this.campaign.donations.forEach(donation => {
                    total += Number(donation.amount);
                });

                return Number(total / 100);
            },
            progress() {
                if (!this.campaign || !this.campaign.amount) return 0;
                return (this.totalDonation / Number(this.campaign.amount)) * 100;
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
                }
            }
        },
        beforeMount: async function() {
            this.isCallingApi = true;
            this.campaign     = await StudentService.getCampaign(this.student.id, '?appendUser&appendDonations');
            this.isCallingApi = false;
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
    .progress-bar {
        border-radius: 40px;
    }
</style>