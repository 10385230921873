import BusinessService from "@/services/business.service";

// State object
const state = {
    emails: [],
    pagination: null,
    isLoadingPagination: false,

    paginationCurrentPage: null,
    paginationItemsPerPage: 50,

    dateFilter: null,
};

// Getter functions
const getters = {
    emails( state ) {
        if (!state.pagination) return []
        return state.pagination.data;
    },
    isLoadingPagination(state) {
        return state.isLoadingPagination
    },
    pagination( state ) {
        return state.pagination;
    },
    paginationItemsPerPage( state ) {
        return state.paginationItemsPerPage
    }
};

// Actions
const actions = {
    async getPagination({ commit, state }) {
        let params = `?paginate=${state.paginationItemsPerPage}&appendUser`;

        if (state.dateFilter) {
            params += `&dateFrom=${state.dateFilter[0]}&dateTo=${state.dateFilter[1]}`
        }

        if (state.paginationCurrentPage) {
            params = `${params}&page=${state.paginationCurrentPage}`;
        }

        commit('setIsLoadingPagination', true);
        const pagination = await BusinessService.getEmails(params);
        commit('setPagination', pagination);
        commit('setIsLoadingPagination', false);
    }
};

// Mutations
const mutations = {
    setPagination(state, data) {
        state.pagination = data;
    },

    setIsLoadingPagination(state, data) {
        state.isLoadingPagination = data;
    },

    setState(state, data) {
        state[data.key] = data.value
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
