<template>
   <div>
       <v-card v-if="showUploadCard === false">
           <v-card-title class="pt-6 pb-4">Download Template</v-card-title>
           <v-card-text class="pb-4">
               Download and fill out the template below. Once ready, click "continue to import" to upload your file

               <a class="mt-4 justify-space-between v-btn v-btn--block v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" href="https://edurent-email-public.s3.amazonaws.com/edurent/files/bulk.csv" download>
                   bulk.csv
                   <v-avatar tile size="24" class="mr-2">
                       <v-img :src="require(`@/assets/icons/download-outline.svg`)"></v-img>
                   </v-avatar>
               </a>
               <input type="file" ref="file" style="display: none" @change="previewImage">
           </v-card-text>

           <v-card-actions class="justify-end px-6 pb-6">
               <v-btn text @click="onHideDialog">Cancel</v-btn>
               <v-btn color="primary" @click="showUploadCard = true" :loading="isUploading">Continue to import</v-btn>
           </v-card-actions>
       </v-card>

       <v-card v-else>
           <v-card-title class="pt-6 pb-4">Students Bulk Import</v-card-title>
           <v-card-text class="pb-4">
               {{content}}

               <v-btn block outlined class="mt-4 justify-space-between" color="primary" @click="addFileByClick">
                   Select File
                   <p>{{fileName}}</p>
               </v-btn>
               <input type="file" ref="file" style="display: none" @change="previewImage">
           </v-card-text>

           <v-card-actions class="justify-end px-6 pb-6">
               <v-btn text @click="showPreviousDialog">Back</v-btn>
               <div  v-if="errors.length > 0">
                   <v-btn color="error" @click="errorDialog=true">View Errors</v-btn>
               </div>

               <div v-else>
                   <v-btn color="primary" @click="importFile" :loading="isUploading">Import</v-btn>
               </div>
           </v-card-actions>
       </v-card>

       <v-dialog v-model="errorDialog" scrollable fullscreen>
           <v-card>
               <v-card-title>
                   Errors
                   <v-spacer></v-spacer>
                   <v-text-field
                           v-model="search"
                           append-icon="mdi-magnify"
                           label="Search"
                           single-line
                           hide-details
                           style="max-width: 400px;"
                   ></v-text-field>
                 <v-hover v-slot:default="{hover}" class="ml-12">
                   <v-btn icon
                          :elevation="hover ? 12 : 0"
                          :color="hover ? 'primary': ''"
                          @click="errorDialog = false"
                   >
                     <v-icon>mdi-close</v-icon>
                   </v-btn>
                 </v-hover>
               </v-card-title>
               <v-data-table
                       :headers="headers"
                       :items="errors"
                       :search="search"
                       :page.sync="page"
                       :items-per-page="10"
                       class="elevation-1"
               >
               </v-data-table>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>
    import StudentService from "../../services/student.service";

    export default {
        name: 'StudentBulkImport',
        data() {
            return {
                errorDialog: false,
                showUploadCard: false,
                file: null,
                fileName: '',
                errors: [],
                content: 'File must be a comma separated as seen in the provided template. Duplicates are displayed after import is completed',
                isUploading: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'user.name',
                    },
                    { text: 'Phone Numbers', value: 'user.phone_number' },
                    { text: 'Emails', value: 'user.email' },
                    { text: 'Created OnDOB', value: 'user.date_of_birth' },
                    { text: 'Gender', value: 'user.gender' },
                    { text: 'School', value: 'user.school' },
                    { text: 'Campus', value: 'user.campus' },
                    { text: 'Expected Start Date', value: 'user.university_start_date' },
                    { text: 'Message', value: 'message' },
                ],
                search: ''
            }
        },
        methods: {
            importFile: async function() {
                if (this.file === null) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Please select a file to import',
                        color: 'red'
                    });
                    return
                }

                const vm = this;
                let students = [];
                this.$papa.parse(this.file, {
                    complete: function(results) {
                        //Name, phone, email, dob, gender, school
                        results.data.forEach(record => {
                            if (record[0] !== '' && record[0] !== 'StudentName') {
                                students.push({
                                    name: record[0],
                                    phone_number: record[1],
                                    phone_number_secondary: record[2],
                                    school_contact_name: record[3],
                                    university_start_date: record[4],
                                    email: record[5],
                                    date_of_birth: record[6],
                                    school: record[7],
                                    campus: record[8],
                                });
                            }
                        });

                        //upload
                        vm.uploadStudents({users: students})
                    }
                })
            },
            addFileByClick() {
                this.errors = [];
                this.$refs.file.click();
            },
            previewImage: function(event) {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    this.file = input.files[0];
                    this.fileName = input.files[0].name
                }
            },
            uploadStudents: async function(data) {
                let error = false
                this.isUploading = true;
                this.errors = await StudentService.importStudents(data).catch(() => {
                  error = true
                })
                this.isUploading = false;

                if (error) {
                  return
                }

                if (this.errors.length > 0) {
                    this.content = 'Duplicates were found while uploading'
                }
            },
            showPreviousDialog() {
                this.showUploadCard = false;
                this.file = null;
                this.fileName = '';
                this.errors = [];
            },
            onHideDialog() {
                this.isUploading = false
                this.$emit('onHideDialog')
            }
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 250px
    }
</style>
