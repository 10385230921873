// State object
import {AppService} from "@/services/app.service";

const state = {
    countryId: 1,
    countries: [],
    isLoadingCountries: [],
    sideMenus: [
        { title: 'Dashboard', icon: 'dashboard', type: 'single', route: 'dashboard', entity: 'dashboard' },
        { title: 'Students', icon: 'users', type: 'single', route:'students', entity: 'students'  },
        { title: 'Properties', icon: 'building', type: 'single', route:'properties', entity: 'properties' },
        { title: 'Schools', icon: 'school', type: 'single', route:'schools', entity: 'schools' },
        { title: 'Invoices', icon: 'invoice', type: 'single', route:'invoices', entity: 'invoices' },
        { title: 'Campaigns', icon: 'contract', type: 'single', route:'campaigns', entity: 'campaigns'  },
        { title: 'General', icon: 'general', type: 'nested', route:'general', entity: 'general', subMenu: [
                {title: 'Rent Specials', icon: 'school', type: 'single', route:'rent-specials', entity: 'rentSpecials' },
                {title: 'Default Messages', icon: 'school', type: 'single', route:'default-messages', entity: 'defaultMessages' },
                {title: 'Amenities', icon: 'school', type: 'single', route:'amenities', entity: 'amenities' },
                {title: 'User Merge', icon: 'users', type: 'single', route:'user-merge', entity: 'userMerge' },
            ]
        },
        { title: 'Agents', icon: 'user', type: 'nested', route:'agents', entity: 'agents', subMenu: [
                {title: 'All Agents', icon: 'user', type: 'single', route:'all-agents', entity: 'allAgents' },
                {title: 'Agent Activity', icon: 'user', type: 'single', route:'agent-activity', entity: 'agentActivity' },
                {title: 'Invitations', icon: 'user', type: 'single', route:'invitations', entity: 'invitations' },
            ]
        },
        { title: 'Message Center', icon: 'messages', type: 'nested', route:'message-center', entity: 'messageCenter', subMenu: [
                {title: 'Emails', icon: 'messages', type: 'single', route:'message-center-emails', entity: 'messageCenterEmails' },
                {title: 'Notifications', icon: 'messages', type: 'single', route:'message-center-notifications', entity: 'messageCenterNotifications' },
            ]
        },
        {type: 'divider', entity: 'divider', },
        {title: 'My Profile', icon: 'agent', type: 'single', route:'my-profile', entity: 'myProfile'  },
        {title: 'App Services', icon: 'invoice', type: 'single', route:'app-services', entity: 'appServices'  }
    ],
    agentSideMenus: []
};

// Getter functions
const getters = {
    getCountries( state ) {
        return state.countries;
    },
    getStates( state ) {
        return state.countries.filter(country => {
            return Number(country.id) === Number(state.countryId);
        }).flatMap(country => {
            return country.states
        })
    },
    getSideMenus( state ) {
        return state.sideMenus
    },
    getAgentSideMenus( state ) {
        return state.agentSideMenus
    }
};

// Actions
const actions = {
    async getCountries({ commit }, data) {
        commit('setIsLoadingCountries', true);
        const countries = await AppService.getCountries(data);
        commit('setCountries', countries);
        commit('setIsLoadingCountries', false);
    },
};

// Mutations
const mutations = {
    setIsLoadingCountries (state, data) {
        state.isLoadingCountries = data;
    },
    setCountries (state, data) {
        state.countries = data;
    },
    setAgentSideMenus( state, data) {
        state.agentSideMenus = data
    }
}
export default {
    namespace: true,
    state,
    getters,
    actions,
    mutations
}
