<template>
    <v-card width="1111" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title>
            <v-row class="justify-space-between px-4 py-8">
                <h2>{{isAddNewPropertyState && property.name === '' ? 'Add New Property' : property.name}}</h2>
                <v-btn icon @click="hideDialog" v-if="isDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-tabs mobile-break-point="1" v-model="headerTab">
                <v-tab>Property</v-tab>
                <v-tab>Units</v-tab>
                <v-tab>Referrals</v-tab>
                <v-tab>Rent Specials</v-tab>
                <v-tab>Campuses</v-tab>
                <v-tab>Contacts</v-tab>
                <v-tab>Documents</v-tab>
                <v-tab :disabled="!property.agreement">Agreement</v-tab>
            </v-tabs>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
            <v-tabs-items v-model="headerTab">
                <v-tab-item>
                    <PropertyBasicInfo
                            :attempt-property-save="attemptPropertySave"
                            :is-property-saved="isPropertySaved"
                            :reset="resetBasicInfo"
                            :property="property"
                            v-on:isPropertySavedCompleted="onPropertySavedCompleted($event)"
                            v-on:updateProperty="$emit('updateProperty', $event)"
                            v-on:resetCompleted="onBasicInfoResetCompleted"
                            :v="$v"
                    />
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                    <h2 class="my-5">Units</h2>
                    <PropertyUnits :property="property" :is-property-saved="isPropertySaved"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                    <v-divider></v-divider>
                    <v-row class="justify-space-between mt-12 mb-3 px-md-3">
                        <h3>Referrals</h3>
                        <v-btn color="primary">Export List</v-btn>
                    </v-row>
                    <PropertyReferrals :property="property"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                    <v-divider></v-divider>
                    <h3 class="mt-8 mb-3">Rent Specials</h3>
                    <PropertyRentSpecials :property="property"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                    <v-divider></v-divider>
                    <h3 class="mt-8 mb-3">Campuses</h3>
                    <PropertyCampuses :property="property"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                    <v-divider></v-divider>
                    <h3 class="mt-8 mb-3">Contacts</h3>
                    <PropertyContacts :itemsPerPage="5" :property="property"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved" class="mt-10">
                    <PropertyDocuments :property="property"/>
                </v-tab-item>

                <v-tab-item v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved" class="mt-10">
                    <v-row class="justify-space-between" v-if="property.agreement">
                        <v-col>
                            <h3 class="my-4">Agreement</h3>
                            <p v-html="property.agreement"></p>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end pa-0">
            <v-card elevation="10" height="80" width="100%" class="save-button-card px-7" v-if="isAddNewPropertyState && !isPropertySaved">
                <v-card-text class="text-right">
                    <v-btn large text color="primary" @click="hideDialog">Cancel</v-btn>
                    <v-btn large color="primary"
                           @click="handleSave"
                           :loading="isSavingProperty"
                    >Save and Continue</v-btn>
                </v-card-text>
            </v-card>
        </v-card-actions>
    </v-card>
    <v-card v-else>
        <v-card-title>
            <v-btn icon @click="hideDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-row class="justify-space-between px-4 pt-8">
                <h2>{{isAddNewPropertyState && property.name === '' ? 'Add New Property' : property.name}}</h2>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <PropertyBasicInfo
                                :attempt-property-save="attemptPropertySave"
                                :is-property-saved="isPropertySaved"
                                :reset="resetBasicInfo"
                                :property="property"
                                v-on:isPropertySavedCompleted="onPropertySavedCompleted($event)"
                                v-on:updateProperty="$emit('updateProperty', $event)"
                                v-on:resetCompleted="onBasicInfoResetCompleted"
                                :v="$v"
                        />


                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                        <h2 class="my-5">Units</h2>
                        <PropertyUnits :property="property" :is-property-saved="isPropertySaved"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                        <v-divider></v-divider>
                        <v-row class="justify-space-between mt-12 mb-3 px-md-3">
                            <h3>Referrals</h3>
                            <v-btn color="primary">Export List</v-btn>
                        </v-row>
                        <PropertyReferrals :property="property"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                        <v-divider></v-divider>
                        <h3 class="mt-8 mb-3">Rent Specials</h3>
                        <PropertyRentSpecials :property="property"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                        <v-divider></v-divider>
                        <h3 class="mt-8 mb-3">Campuses</h3>
                        <PropertyCampuses :property="property"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved">
                        <v-divider></v-divider>
                        <h3 class="mt-8 mb-3">Contacts</h3>
                        <PropertyContacts :itemsPerPage="5" :property="property"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved" class="mt-10">
                        <PropertyDocuments :property="property"/>
                    </div>

                    <div v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved" class="mt-10">
                        <v-row class="justify-space-between" v-if="property.agreement">
                            <v-col>
                                <h3 class="my-4">Agreement</h3>
                                <p v-html="property.agreement"></p>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import PropertyBasicInfo from "../components/Properties/PropertyBasicInfo";
    import PropertyUnits from "../components/Properties/PropertyUnits";
    import PropertyReferrals from "../components/Properties/PropertyReferrals";
    import PropertyRentSpecials from "../components/Properties/PropertyRentSpecials";
    import PropertyContacts from "../components/Properties/PropertyContacts";
    import PropertyDocuments from "../components/Properties/PropertyDocuments";
    import {required, requiredIf} from "vuelidate/lib/validators";
    import PropertyCampuses from "@/components/Properties/PropertyCampuses.vue";

    export default {
        name: 'Property',
        validations : {
            property: {
                name: { required },
                description: { required },
                community: { required },
                address_line_1: { required },
                city: { required },
                state_id: { required },
                zip_code: { required },
                commission: { required: requiredIf(property => {
                    return property.flat_rate === '' && property.mo_marketing_fee === ''
                    }) },
                flat_rate: { required: requiredIf(property => {
                        return property.commission === '' && property.mo_marketing_fee === ''
                    })  },
                mo_marketing_fee: { required: requiredIf(property => {
                        return property.commission === '' && property.flat_rate === ''
                    })  },
            }
        },
        props: ['property', 'isDialog'],
        components: {
            PropertyCampuses,
            PropertyBasicInfo,
            PropertyUnits,
            PropertyReferrals,
            PropertyRentSpecials,
            PropertyContacts,
            PropertyDocuments
        },
        data() {
            return {
                search: '',
                statuses: ['active', 'disabled'],
                available: ['Yes', 'No'],
                isPropertySaved: false,
                attemptPropertySave: false,
                isSavingProperty: false,
                hasGoogleMapsError: false,
                resetBasicInfo: false,
                headerTab: null,
            }
        },
        computed: {
            isAddNewPropertyState() {
                return this.$store.getters.getIsAddNewPropertyState;
            },
            newPropertyCount() {
                return this.$store.getters.getNewPropertyCount;
            }
        },
        methods: {
            onPropertySavedCompleted() {
                this.isSavingProperty = false;
                this.isPropertySaved  = true;
            },
            hideDialog() {
                this.resetBasicInfo   = true;
            },
            onBasicInfoResetCompleted() {
                this.isPropertySaved  = false;
                this.isSavingProperty = false;
                this.resetBasicInfo   = false;
                this.$v.$reset();

                this.$emit('onHideDialog', false)
            },
            handleSave() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'One or more mandatory fields are missing. Please look through the form and fix the field(s) highlighted in red',
                        color: 'red'
                    });

                    this.$emit('onPropertySavedError');

                    return
                }

                // check for google maps coordinates
                if (this.property.google_place_id === ''
                    || this.property.longitude === ''
                    || this.property.latitude === ''
                ) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Please verify the property on google maps by adding a marker',
                        color: 'red'
                    });

                    return
                }

                this.isSavingProperty = true;
                this.attemptPropertySave = true;
            }
        },
    }
</script>
