import ApiService from './api.service'

const BusinessService = {
    getBusiness: async function(businessId) {
        const response = await ApiService.get(`business/${businessId}`);
        return response.data.data
    },
    getDonations: async function() {
        const response = await ApiService.get('business/donations/get');
        return response.data.data
    },
    getCallRecords: async function() {
        const response = await ApiService.get('business/call-records/get');
        return response.data.data
    },
    getEmails: async function(params='') {
        const response = await ApiService.get(`business-emails${params}`);
        return response.data.data
    },
    getNotifications: async function(params='') {
        const response = await ApiService.get(`notifications${params}`);
        return response.data.data
    },
    getRoles: async function() {
        const response = await ApiService.get(`roles`);
        return response.data.data
    }
}

export default BusinessService

export { BusinessService }
