<!--Paginator for namespaced stores-->

<template>
	<div class="pg-container" v-if="show">
		<div class="pg-entries-container">
			<span class="pt-2 mr-2">Show</span>
			<v-select :items="pages"
								:value="itemsPerPage"
								dense
								outlined
								@change="updateEntries($event)"
								background-color="white"
			></v-select>
			<span class="pt-2 ml-2 mr-8">Entries</span>
		</div>
		<v-pagination
				:value="currentPage"
				:length="lastPage"
				:total-visible="totalVisible"
				@input="paginate"
				class="pagination"
		></v-pagination>
	</div>

</template>

<script>
export default {
	name: 'Pagination',
	props: {
		state: {
			type: String
		}
	},
	data() {
		return {
			pages: [5, 10, 15, 20, 25, 30],
		}
	},
	computed: {
		show() {
			return this.$store.state[this.state] &&
					this.$store.state[this.state]['pagination'] &&
					this.$store.state[this.state]['pagination'].data
		},
		currentPage() {
			return this.$store.state[this.state]['pagination'].current_page;
		},
		itemsPerPage() {
			return this.$store.state[this.state].paginationItemsPerPage;
		},
		lastPage: {
			get() {
				return this.$store.state[this.state]['pagination'].last_page;
			}
		},
		totalVisible() {
			let total = 3;
			if (this.$vuetify.breakpoint.smAndDown) {
				total = 3
			}
			if (this.$vuetify.breakpoint.mdAndDown) {
				total = 1
			}
			if (this.$vuetify.breakpoint.lg) {
				total = 5
			}
			if (this.$vuetify.breakpoint.xl) {
				total = 8
			}

			return total
		}
	},
	methods: {
		updateEntries(entries) {
			this.$store.commit(`${this.state}/setState`, {key: 'paginationCurrentPage', value: 1});
			this.$store.commit(`${this.state}/setState`, {key: 'paginationItemsPerPage', value: entries});
			this.$store.dispatch(`${this.state}/getPagination`);
		},
		paginate(page) {
			this.$store.commit(`${this.state}/setState`, {key: 'paginationCurrentPage', value: page});
			this.$store.dispatch(`${this.state}/getPagination`);
		}
	}
}
</script>

<style scoped>

.v-pagination {
	border-radius: 4px;
	border: none;
	width: auto !important;
}

.v-pagination__item {
	padding: 0 !important;
	margin: 0 !important;
	border-radius: 0 !important;
	height: 41px !important;
	box-shadow: none !important;
	width: 51px !important;

}

.v-pagination__navigation {
	padding: 0 !important;
	margin: 0 !important;
	border-radius: 0 !important;
	height: 41px !important;
	box-shadow: none !important;
	background: transparent !important;
	width: 51px !important;
}

.v-pagination__more {
	padding: 0 !important;
	margin: 0 !important;
	border-radius: 0 !important;
	height: 41px !important;
	box-shadow: none !important;
	background: transparent !important;
	width: 51px !important;
}

.v-pagination li {
	border-left: 1px solid #d8d8d8;
}

.v-pagination li:first-child {
	border-left: 0;
}

.pg-container {
	display: flex;
	justify-content: flex-end;
}
.pg-entries-container {
	display: flex;
	padding-top: 20px;
}

</style>
