import ApiService from './api.service'

const CampaignService = {
    getCampaigns: async function(params = '') {
        const response = await ApiService.get(`campaigns${params}`);
        return response.data.data
    },
}

export default CampaignService

export { CampaignService }