import { SchoolService } from '@/services/school.service'

// State object
const state = {
    schools: [],
    schoolContactTypes: [],
    isLoadingSchools: false,
    isAddNewSchoolState: false,
    isLoadingSchoolContactType: false
};

// Getter functions
const getters = {
    isLoadingSchools( state ) {
        return state.isLoadingSchools;
    },
    getSchools( state ) {
        return state.schools;
    },
    getIsAddNewSchoolState( state ) {
        return state.isAddNewSchoolState;
    },
    getSchoolContactTypes( state ) {
        return state.schoolContactTypes;
    },
    getIsLoadingSchoolContactTypes( state ) {
        return state.isLoadingSchoolContactType;
    }
};

// Actions
const actions = {
    async getSchools({ commit }, data) {
        commit('setIsLoadingSchools', true);
        const schools = await SchoolService.getSchools(data);
        commit('setSchools', schools);
        commit('setIsLoadingSchools', false);
    },
    async getSchoolContactTypes({ commit }, data) {
        commit('setIsLoadingSchoolContactTypes', true);
        const schoolContactTypes = await SchoolService.getSchoolContactTypes(data);
        commit('setSchoolContactTypes', schoolContactTypes);
        commit('setIsLoadingSchoolContactTypes', false);
    },

};

// Mutations
const mutations = {
    addSchool(state, data) {
        state.schools.unshift(data)
    },
    addSchoolCampus(state, data) {
        const school = state.schools.find(s => s.id === data.school_id)
        school.campuses.push(data.campus)
    },
    addSchoolContact(state, data) {
        const school = state.schools.find(s => s.id === data.school_id)
        school.contacts.push(data.contact)
    },
    setSchools(state, data) {
        state.schools = data;
    },
    setIsLoadingSchools(state, data) {
        state.isLoadingSchools = data;
    },
    setIsAddNewSchoolState(state, data) {
        state.isAddNewSchoolState = data;
    },
    setIsLoadingSchoolContactTypes(state, data) {
        state.isLoadingSchoolContactType = data;
    },
    setSchoolContactTypes(state, data) {
        state.schoolContactTypes = data;
    },
    setSchoolCampus(state, campus) {
        const school = state.schools.find(s => s.id === campus.school_id)
        const campusIndex = school.campuses.findIndex(sc => sc.id === campus.id)
        Object.assign(school.campuses[campusIndex], campus)
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
