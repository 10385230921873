<template>
    <div class="content">
        <v-row>
            <v-col cols="12">
                <v-row class="justify-space-between">
                    <v-col cols="10" md="4">
                        <UniversalSearch v-if="canView"/>
                    </v-col>
                    <v-col cols="12" md="8" class="text-right">
                        <v-row class="justify-end">
                            <v-col class="d-flex align-center flex-grow-0 pr-4">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    offset-y
                                    v-if="canView"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-badge
                                            :content="unreadNotifications.length"
                                            :value="unreadNotifications.length"
                                            color="green"
                                            overlap
                                        >
                                            <v-btn icon
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon>mdi-bell-ring</v-icon>
                                            </v-btn>
                                        </v-badge>
                                    </template>

                                    <v-card height="400px">
                                        <v-list>
                                            <v-list-item>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{ unreadNotifications.length }} New
                                                        Notifications
                                                    </v-list-item-title>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-btn
                                                        color="success"
                                                        icon
                                                    >
                                                        <v-icon>mdi-email</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>

                                        <v-divider></v-divider>

                                        <v-list>
                                            <v-list-item-group>
                                                <v-list-item v-for="item in unreadNotifications" :key="item.id">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-message-alert</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="text-capitalize"
                                                                           v-if="item.user &&item.user.name">
                                                            {{ item.user.agent.user.name }} has {{ item.type }} from
                                                            {{ item.user.name }}
                                                        </v-list-item-title>
                                                        <v-list-item-title v-else>
                                                            New {{ item.type }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action @click="displayNotificationMessageDialog(item)">
                                                        <v-hover v-slot:default="{hover}">
                                                            <v-btn :color="hover ? 'success' : ''"
                                                                   :elevation="hover ? 12 : 0">
                                                                Read
                                                            </v-btn>
                                                        </v-hover>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card>

                                </v-menu>

                            </v-col>
                            <v-col class="d-flex align-center flex-grow-0 pa-0">
                                <v-avatar color="#7854F6" size="32"><p class="white--text">{{ initials }}</p></v-avatar>
                            </v-col>
                            <v-col class="d-flex align-center flex-grow-0 text-no-wrap px-2">{{ user.name }}</v-col>
                            <v-col class="flex-grow-0">
                                <v-btn text class="text-capitalize" @click="logout"><h4>Logout</h4></v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="700" scrollable v-model="showNotificationMessageDialog">
            <NotificationMessage :notification="currentNotification"
                                 @hideDialog="showNotificationMessageDialog = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import {UserService} from "@/services/user.service";
import {TokenService} from "@/services/storage.service";
import {mapActions, mapGetters} from "vuex";
import NotificationMessage from "@/components/MessageCenter/NotificationMessage";
import UniversalSearch from '@/components/UniversalSearch';

export default {
    name: 'NavHeader',
    components: {NotificationMessage, UniversalSearch},
    data() {
        return {
            messages: 4,
            fav: true,
            menu: false,
            message: false,
            hints: true,
            showNotificationMessageDialog: false,
            currentNotification: {}
        }
    },
    computed: {
        ...mapGetters('Notification', {
            notifications: 'notifications',
            isLoadingNotifications: 'isLoadingNotifications'
        }),
        unreadNotifications() {
            return this.notifications.filter(n => {
                return n.status_read === 0
            })
        },
        user() {
            return this.$store.getters.getUser;
        },
        initials() {
            let initials = '';
            this.user.name.split(' ').map(name => {
                initials += name.charAt(0)
            });

            return initials;
        },
        canView() {
            return this.user.type === 'agent';
        },

    },
    methods: {
        ...mapActions('Notification', ['getRecords', 'updateNotification']),
        logout: async function () {
            await UserService.logout();
            this.$store.commit('setIsUserLoggedIn', false)
            TokenService.removeToken();
            this.$router.push('/login');
        },
        displayNotificationMessageDialog(notification) {
            this.currentNotification = notification
            this.showNotificationMessageDialog = true

            this.updateNotificationRecord(notification)
        },
        updateNotificationRecord(notification) {
            if (notification.user && notification.user.agent.id !== this.user.agent_id) {
                return
            }

            this.updateNotification({id: notification.id, key: 'status_read', value: 1})
        }
    },

    mounted() {
        if (this.user && this.user.type === 'agent') {
            this.getRecords();
        }
    }
}
</script>

<style scoped>
.content {
    padding-left: 30px;
    padding-right: 30px;
}
</style>
