<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col>
                <v-row class="justify-start">
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Rent Specials</h2>
                        <RentSpecialDialog action="add" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <RentSpecialDataTable />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import RentSpecialDialog from "../components/General/RentSpecials/RentSpecialDialog";
    import RentSpecialDataTable from "../components/General/RentSpecials/RentSpecialDataTable";

    export default {
        name: 'RentSpecials',
        components : {
            RentSpecialDataTable,
            RentSpecialDialog,
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
