<template>
  <v-dialog scrollable max-width="1111" v-model="dialog" @click:outside="closeDialog">
    <Campaign :dialog="dialog" @hideDialog="closeDialog" />
  </v-dialog>
</template>

<script>
import Campaign from "../../views/Campaign"

export default {
  name: 'CampaignDialog',

  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Campaign
  },

  methods: {
    closeDialog() {
      this.$emit('closeCampaignDialog', true)
    }
  },

  beforeDestroy() {
    this.$store.dispatch('setCampaign', null)
  }
}
</script>

<style scoped>
.content {
  padding-left: 250px
}
</style>
