<template>
    <div class="content">
        <h2> Hello, {{ user.name }}!</h2>
        <v-row>
            <v-col cols="12">
                <v-card :class="$vuetify.breakpoint.mdAndUp ? side-menu : '' " style="border-top: 4px solid red">
                    <v-card-text>
                        <div class="d-flex justify-start pb-6">
                            <div class="pr-1">
                                <v-avatar size="44">
                                    <v-icon color="red" size="44">mdi-fire</v-icon>
                                </v-avatar>
                            </div>
                            <h2 class="red--text pt-4">New Leads</h2>
                        </div>
                        <div  v-if="isLoadingNewLeads">
                            <v-skeleton-loader class="pb-4" type="card-avatar" v-for="n in 4" :key="n"></v-skeleton-loader>
                        </div>
                        <div v-else>
                            <v-card v-for="(student, i) in students" :key="i" class="mb-2">
                                <v-card-text>
                                    <StudentDialog action="show-btn" :actionText="student.name" :existing-student="student"/>
                                    <p class="pb-4">{{student.school ? student.school.name : ''}}</p>
                                    <v-avatar size="26">
                                        <v-img v-if="student.agent && student.agent.user" :src="student.agent.user.image_url"> </v-img>
                                    </v-avatar>
                                    <span class="pl-2">{{student.agent && student.agent.user ? student.agent.user.name : ''}}</span>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import StudentDialog from "../Students/StudentDialog";
    import StudentService from "../../services/student.service";

    export default {
        name: 'NewLeads',
        components: {StudentDialog},
        data() {
          return {
              students: null,
              currentStudent: null,
              isLoadingNewLeads: false
          }
        },
        beforeMount: async function() {
            this.isLoadingNewLeads = true;
            this.students = await StudentService.getStudents('?filterByNewLeads&appendAgent&appendSchool');
            this.isLoadingNewLeads = false;
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            }
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .side-menu {
        height: 80vh;
        overflow: auto;
        box-shadow: 0px 1px 40px rgba(255, 65, 65, 0.18), inset 0px 3px 0px #FF4141;
    }
</style>
