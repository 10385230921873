<template>
    <div>
        <h2>Roommate Requests</h2>
        <v-row>
           <v-data-table
            :headers="headers"      
            :items="roommateRequests"
            :items-per-page="itemsPerPage"
            class="elevation-1 roommate-table"        
            >
            <template v-slot:item.controls="{ item }">
                <v-btn class="mx-1" small color="primary" :disabled="item.request && item.request.request_status !== 'pending'" @click="updateRoommateRequests('accept', item.request)">
                    <v-icon >mdi-check</v-icon>
                </v-btn>
                <v-btn class="mx-1" small color="error" :disabled="item.request && item.request.request_status !== 'pending'" @click="updateRoommateRequests('decline', item.request)">
                    <v-icon >mdi-close</v-icon>
                </v-btn>
            </template>
           </v-data-table>
        </v-row>       
    </div>
</template>

<script>
import StudentService from '../../services/student.service'

    export default {
        name: 'StudentRoommateCommunications',
        props: ['student'],
        data() {
            return {
                itemsPerPage: 5,
                roommateRequests: [
                    {
                        "sender": "Test Sender",
                        "receiver": "Test Receiver",
                        "status": "pending"
                    },
                ],
                headers: [
                    { text: 'Sender', value: 'sender.name' },
                    { text: 'Receiver', value: 'receiver.name' },
                    { text: 'Status', value: 'request.request_status' },
                    { text: '', value: 'controls', align: 'center', },
                ],
            }
        }, 
        methods: {
            async getRoommateRequests() {
                this.roommateRequests = await StudentService.getRoommateRequests(this.student.id);
            },
            async updateRoommateRequests(updateType, request) {
                let roommateId = this.student.id === request.roommate_id ? request.user_id : request.roommate_id;
                switch (updateType) {
                    case "decline":
                        await StudentService.declineRoommate(this.student.id, { requestId: request.id });
                        break;
                    case "accept":
                        await StudentService.acceptRoommate(this.student.id, { requestId: request.id, roommateId: roommateId });
                        break;
                }
                this.getRoommateRequests();
                await this.$store.dispatch('getStudents');
            }
        },
        beforeMount() {
            this.getRoommateRequests();
        },
    }
</script>

<style scoped>
    .roommate-table {
        width: 100vw;
    }
</style>
