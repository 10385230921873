<template>
    <div>
        <v-row>
            <v-col cols="12" md="5">
                <v-text-field outlined dense append-icon="mdi-magnify" v-model="search" placeholder="Search All Notes/Events" background-color="white"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Type"
                          :items="types"
                          background-color="white"
                          v-model="typeFilter"
                          @change="getDefaultMessages"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Category"
                          :items="categories"
                          v-model="categoryFilter"
                          item-text="category"
                          item-value="id"
                          background-color="white"
                          @change="getDefaultMessages"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="justify-end d-flex">
                <v-btn text height="40" color="primary" @click="resetFilters">
                    Clear Filters
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="headers"
            :items="defaultMessages"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1 table-proto"
            @page-count="pageCount = $event"
            :loading="isLoadingDefaultMessages || isDeletingDefaultMessage"
        >
            <template v-slot:item.label="props">
                <DefaultMessagesDialog action="show-btn" :actionText="props.item.label" :existing-message="props.item"/>
            </template>
            <template v-slot:item.default_message_category_id="props">
                {{getDefaultMessageCategoryName(props.item.default_message_category_id)}}
            </template>
            <template v-slot:item.content="props">
                {{truncateText(props.item.content, 50)}}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile width="16" height="18" min-width="16" min-height="18" @click="showNotification(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nav="props">
                <DefaultMessagesDialog action="show" :existing-message="props.item"/>
            </template>
        </v-data-table>
        <v-row class="justify-end" v-if="!isLoadingDefaultMessages">
            <v-col cols="12" md="6" class="text-right">
                <v-row>
                    <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="pages" dense
                                  outlined
                                  @change="itemsPerPage = parseInt($event, 10)"
                                  v-model="itemsPerPage"
                                  background-color="white"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pt-md-5 text-left">Entries</v-col>
                    <v-col cols="12" md="5">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete this default message."
                    v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteDefaultMessage"
            />
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment'
    import Notification from "../../Shared/Notification";
    import DefaultMessagesDialog from "./DefaultMessageDialog";
    import DefaultMessageService from "../../../services/defaultmessages.service";
    import {displayMessage} from "../../../utils/utils";

    export default {
        name: 'DefaultMessageDataTable',
        components: {
            DefaultMessagesDialog,
            Notification,
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                typeFilter: '',
                categoryFilter: '',
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingDefaultMessage: false,
                headers: [
                    {
                        text: 'Label',
                        align: 'start',
                        value: 'label',
                    },
                    { text: 'Type', value: 'type' },
                    { text: 'Category', value: 'default_message_category_id' },
                    { text: 'Value', value: 'content' },
                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                types: ['Event', 'Note'],
                showNotificationDialog: false,
                currentDefaultMessageId: null
            }
        },
        computed: {
            defaultMessages() {
                return this.$store.getters.getDefaultMessages
            },
            categories() {
                return this.$store.getters.getDefaultMessageCategories;
            },
            isLoadingDefaultMessages() {
                return this.$store.getters.getIsLoadingDefaultMessages;
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('defaultMessages', 'delete');
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            getDefaultMessageCategoryName(categoryId) {
                let category = {};
                this.categories.forEach(c => {
                    if (Number(c.id) === categoryId) {
                        category = c
                    }
                });

                return category.category;
            },

            truncateText(text, length, clamp){
                clamp = clamp || '...';
                return text.length > length ? text.slice(0, length) + clamp : text;
            },
            deleteDefaultMessage: async function() {
                this.showNotificationDialog = false;
                this.isDeletingDefaultMessage = true;
                await DefaultMessageService.deleteDefaultMessage(this.currentDefaultMessageId)
                this.isDeletingDefaultMessage = false;

                this.getDefaultMessages();
            },
            getDefaultMessages() {
                this.$store.dispatch('getDefaultMessages', `?sort=desc${this.typeFilter !=='' ? `&type=${this.typeFilter.toLowerCase()}` : ''}${this.categoryFilter !=='' ? `&category=${this.categoryFilter}` : ''}`);
            },
            showNotification(defaultMessageId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete a default note/event')
                    return;
                }

                this.currentDefaultMessageId = defaultMessageId;
                this.showNotificationDialog = true;
            },
            resetFilters() {
                this.typeFilter     = '';
                this.categoryFilter = '';
                this.search         = '';

                this.getDefaultMessages();
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
                }
            }
        }
    }
</script>

<style scoped>
    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
</style>
