<template>
    <div>
        <v-row>
            <v-col cols="12" md="5">
                <v-text-field outlined
                              dense
                              append-icon="mdi-magnify"
                              v-model="search"
                              placeholder="Search All Schools"
                              background-color="white"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="justify-end d-flex">
                <v-btn text height="40" color="primary" @click="resetFilters">
                    Clear Filters
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="headers"
            :items="schools"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1 table-proto"
            @page-count="pageCount = $event"
            :loading="isLoadingSchools || isDeletingSchool"
        >
            <template v-slot:item.name="props">
              <a v-if="$vuetify.breakpoint.mdAndUp" class="property-name-field" @click="showSelectedSchool(props.item)">
                {{props.item.name}}
              </a>
            </template>
            <template v-slot:item.website="props">
                <a :href="props.item.website" target="_blank" v-if="props.item.website">
                    <v-avatar tile size="14">
                        <v-img :src="require(`@/assets/icons/external-link.svg`)"></v-img>
                    </v-avatar>
                </a>
            </template>
            <template v-slot:item.created_at="props">
                {{ props.item.created_at | formatDate }}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile width="16" height="18" min-width="16" min-height="18" @click="showNotification(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nav="props">
              <v-avatar tile size="10" @click="showSelectedSchool(props.item)">
                <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
              </v-avatar>
            </template>
        </v-data-table>
        <v-row class="justify-end" v-if="!isLoadingSchools">
            <v-col cols="12" md="6" class="text-right">
                <v-row>
                    <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="pages" dense
                                  outlined
                                  @change="itemsPerPage = parseInt($event, 10)"
                                  v-model="itemsPerPage"
                                  background-color="white"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pt-md-5 mr-4 text-left">Entries</v-col>
                    <v-col class="mx-auto" cols="10" md="5">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete this school."
                    v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteSchool"
            />
        </v-dialog>

      <v-dialog v-if="showExistingSchoolDialog" v-model="showExistingSchoolDialog" scrollable max-width="1111">
          <ExistingSchool :school="selectedSchool" @hideDialog="showExistingSchoolDialog = false"></ExistingSchool>
      </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment'
    import SchoolService from "@/services/school.service";
    import Notification from "../Shared/Notification";
    import {displayMessage} from "@/utils/utils";
    import ExistingSchool from "@/components/Schools/ExistingSchool"

    export default {
        name: 'SchoolDataTable',
        components: {
          ExistingSchool,
          Notification
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                locationFilter: '',
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingSchool: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Website', value: 'website' },
                    { text: 'Created On', value: 'created_at' },
                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                showNotificationDialog: false,
                currentDeleteSchoolId: null,
                selectedSchool: null,
                showExistingSchoolDialog: false
            }
        },
        computed: {
            schools() {
                return this.$store.getters.getSchools
            },
            isLoadingSchools() {
                return this.$store.getters.isLoadingSchools
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('students', 'delete');
            }
        },
        methods: {
            showSelectedSchool(school) {
                this.selectedSchool = school
                this.showExistingSchoolDialog = true
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteSchool: async function() {
                this.showNotificationDialog = false;
                this.isDeletingSchool = true;
                await SchoolService.deleteSchool(this.currentDeleteSchoolId)
                this.isDeletingSchool = false;

                this.getSchools();
            },
            getSchools() {
                this.$store.dispatch('getSchools');
            },
            showNotification(schoolId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete a school')
                    return;
                }

                this.currentDeleteSchoolId = schoolId;
                this.showNotificationDialog = true;
            },
            resetFilters() {
                this.locationFilter = '';
                this.search       = '';

                this.getStudents();
            },
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
                }
            }
        }
    }
</script>
