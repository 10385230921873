<template>
    <School :school="school"
            :show-contact-tab="showContactTab"
            @hideDialog="hideDialog"
            @addCampus="handleAddCampus"
            v-if="showSchool"
    >
      <template #title>
        <h2>{{school.name || 'Add New School'}}</h2>
      </template>
      <template #campuses>
        <v-expansion-panels class="pa-1">
          <v-expansion-panel  v-for="(campus, i) in school.campuses" :key="i">
            <v-expansion-panel-header>
              {{campus.name}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ExistingCampus :school="school"
                         :campus="campus"
                         :markers="markers[i]"
              ></ExistingCampus>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template #card-actions="data">
        <v-card-actions class="justify-end pa-0">
          <v-card elevation="10" height="80" width="100%" class="save-button-card px-7">
            <v-card-text class="text-right">
              <v-btn large text color="primary" @click="hideDialog">Cancel</v-btn>
              <v-btn large color="primary"
                     @click="saveSchoolAndCampuses(data.$v)"
                     :loading="isSavingSchool"
              >Save and Continue</v-btn>
            </v-card-text>
          </v-card>
        </v-card-actions>
      </template>
    </School>
</template>

<script>
    import School from "@/components/Schools/School.vue";
    import SchoolService from "@/services/school.service";
    import ExistingCampus from "@/components/Campuses/ExistingCampus.vue";

    export default {
        name: 'CreateNewSchool',
        components: {ExistingCampus, School},
        data() {
          return {
            school: {
              name: '',
              website: '',
              campuses: []
            },
            isSavingSchool: false,
            showContactTab: false,
            markers: {},
            showGoogleMaps: false,
            showSchool: true
          }
        },
        methods: {
          init() {
            this.school = {
              name: '',
              website: '',
              campuses: []
            }
          },
          async generateSchoolMapMarkers() {
            this.showSchool = false
            for (let i=0; i < this.school.campuses.length; i++) {
              if (this.markers[i] === undefined) {
                this.markers[i] = []
              }

              const campus =  this.school.campuses[i]

              this.markers[i].push({
                position: {
                  lat: Number(campus.latitude),
                  lng: Number(campus.longitude)
                },
                icon: 'map-school.svg',
                type: 'school',
                school: campus
              });
            }

            this.showSchool = true
          },
          async generateCampusMapMarkers(campus) {
            const markers = []
            markers.push({
              position: {
                lat: Number(campus.latitude),
                lng: Number(campus.longitude)
              },
              icon: 'map-school.svg',
              type: 'school',
              school: campus
            });

            return markers
          },
          handleAddCampus(campus) {
            this.school.campuses.push(campus)
            this.generateSchoolMapMarkers()
          },
          async saveSchoolAndCampuses($v) {
            $v.$touch();
            if ($v.$invalid) {
              this.$store.commit('setSnackbar', {
                show: true,
                message: 'One or more mandatory fields are missing. Please look through the form and fix the field(s) highlighted in red',
                color: 'red'
              });

              return
            }

            // validate school has campus
            if (this.school.campuses.length === 0) {
              this.$store.commit('setSnackbar', {
                show: true,
                message: 'Please add at least one campus',
                color: 'red'
              });

              return
            }

            //all good now save
            this.isSavingSchool = true;

            const school = await SchoolService.addSchool(this.school);
            this.school.id = school.id

            await Promise.all(
                this.school.campuses.map(async campus => {
                  campus.school_id = school.id
                  const savedCampus = await SchoolService.addCampus(campus);
                  campus.id = savedCampus.id
                })
            )

            this.isSavingSchool = false;

            //now show remaining view
            this.showContactTab = true;


            this.$store.commit('addSchool', this.school);

            this.$store.commit('setSnackbar', {
              show: true,
              message: 'New school ' + this.school.name + ' has been created',
              color: 'success'
            });

            this.hideDialog()
          },
          hideDialog() {
            this.$emit('hideDialog')
          }
        },
        beforeMount() {
          this.init()
        }
    }
</script>
