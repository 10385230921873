import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import Students from "../views/Students";
import Campaigns from "../views/Campaigns";
import ViewStudent from "../views/ViewStudent";
import ViewProperty from "../views/ViewProperty";
import ViewInvoice from "../views/ViewInvoice";
import ViewSchool from "../views/ViewSchool";
import Properties from "../views/Properties";
import Schools from "../views/Schools";
import Invoices from "../views/Invoices";
import DefaultMessages from "../views/DefaultMessages";
import Amenities from "../views/Amenities";
import AllAgents from "../views/Agents/AllAgents";
import RentSpecials from "../views/RentSpecials";
import AgentProfile from "../views/Agents/AgentProfile";
import AgentActivity from "../views/Agents/AgentActivity";
import Register from "../views/Register";
import ForgotPassword from "../views/ForgotPassword";
import Notifications from "@/components/MessageCenter/Notifications";
import store from "../store";
import {TokenService} from "@/services/storage.service";
import {displayMessage} from "@/utils/utils";
import AllEmails from "@/components/MessageCenter/AllEmails";
import Invitations from "@/views/Agents/Invitations";
import AppServices from "@/views/AppServices"
import UserMerge from "@/views/UserMerge"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      public: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      public: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      public: true
    }
  },
  {
    path: '/students',
    name: 'Students',
    component: Students,
    meta: {
      public: true
    }
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: {
      public: true
    }
  },
  {
    path: '/view-student/:studentId',
    name: 'ViewStudent',
    component: ViewStudent,
    props: true,
    meta: {
      public: true
    }
  },
  {
    path: '/properties',
    name: 'Properties',
    component: Properties,
    meta: {
      public: true
    }
  },
  {
    path: '/view-property/:propertyId',
    name: 'ViewProperty',
    component: ViewProperty,
    props: true,
    meta: {
      public: true
    }
  },
  {
    path: '/schools',
    name: 'Schools',
    component: Schools,
    meta: {
      public: true
    }
  },
  {
    path: '/view-school/:schoolId',
    name: 'ViewSchool',
    component: ViewSchool,
    props: true,
    meta: {
      public: true
    }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: {
      public: true
    }
  },
  {
    path: '/view-invoice/:invoiceId',
    name: 'ViewInvoice',
    component: ViewInvoice,
    props: true,
    meta: {
      public: true
    }
  },
  {
    path: '/rent-specials',
    name: 'RentSpecials',
    component: RentSpecials,
    meta: {
      public: true
    }
  },
  {
    path: '/default-messages',
    name: 'DefaultMessages',
    component: DefaultMessages,
    meta: {
      public: true
    }
  },
  {
    path: '/amenities',
    name: 'Amenities',
    component: Amenities,
    meta: {
      public: true
    }
  },
  {
    path: '/all-agents',
    name: 'AllAgents',
    component: AllAgents,
    meta: {
      public: true
    }
  },
  {
    path: '/agent-activity',
    name: 'AgentActivity',
    component: AgentActivity,
    meta: {
      public: true
    }
  },
  {
    path: '/invitations',
    name: 'Invitations',
    component: Invitations,
    meta: {
      public: true
    }
  },
  {
    path: '/message-center-emails',
    name: 'MessageCenterEmails',
    component: AllEmails,
    meta: {
      public: true
    }
  },
  {
    path: '/message-center-notifications',
    name: 'MessageCenterNotifications',
    component: Notifications,
    meta: {
      public: true
    }
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: AgentProfile,
    meta: {
      public: true
    }
  },
  {
    path: '/app-services',
    name: 'AppServices',
    component: AppServices,
    meta: {
      public: true
    }
  },
  {
    path: '/user-merge',
    name: 'UserMerge',
    component: UserMerge,
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async(to, from, next) => {
  if ((to.name.toLowerCase() === 'login'
      || to.name.toLowerCase() === 'register'
      || to.name.toLowerCase() === 'forgotpassword'
  )) {
    return next();
  }

  if (!TokenService.getToken()) {
    return next('/login')
  }

  //routes that can be accessed but not part of the side menu
  if (to.name.toLowerCase() === 'viewstudent' 
      || to.name.toLowerCase() === 'viewproperty' 
      || to.name.toLowerCase() === 'viewinvoice'
      || to.name.toLowerCase() === 'viewschool') {
    return next();
  }

  if (Object.keys(store.getters.getUser).length === 0) {
    //handle page refresh (no user found but has user token in local storage)
    await store.dispatch('getUserByToken', '?appendAgent&appendAgentRole');
  }

  const sideMenus = store.getters.getAgentSideMenus;

  if (sideMenus.length === 0) {
    displayMessage('error', 'Sorry you do not have permission to view any pages. Please contact a supervisor')
    return next('/login')
  }

  const hasPermission = sideMenus.filter(sideMenu => {
    if (!sideMenu.route) {
      return false
    }

    if (sideMenu.type === 'nested') {
      return sideMenu.subMenu.filter(subMenu => {
        return subMenu.entity.toLowerCase() === to.name.toLowerCase()
      }).length > 0
    }

    return sideMenu.entity && sideMenu.entity.toLowerCase() === to.name.toLowerCase()
  }).length > 0;

  if (!hasPermission) {
    displayMessage('error', 'Sorry you do not have permission to access this page. Please contact your supervisor');
    return
  }

  return next();
});

export default router
