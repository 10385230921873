import axios from 'axios'
import { TokenService } from '../services/storage.service'
import router from '../router'
import  store  from '../store'

const ENABLE_DEBUGGER = true;
const XDEBUG_SESSION  = 'XDEBUG_SESSION_START=1';

const businessId = store.getters.getBusinessId;

const ApiService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL;

        // set interceptor
        this.initInterceptor();
    },

    setAuthorizationHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
    },

    setXAuthorizationHeader() {
        axios.defaults.headers.common["X-Authorization"] = process.env.VUE_APP_API_KEY
    },

    removeHeader() {
        // axios.defaults.headers.common = {}
        axios.defaults.headers.common["Authorization"] = ''
    },

    get(resource) {
        return axios.get(resource)
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    patch(resource, data) {
        return axios.patch(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)
    },

    initInterceptor() {
        axios.interceptors.request.use(config => {
            if (ENABLE_DEBUGGER) {
                config.url = config.url.indexOf('?') === -1 ? `${config.url}?${XDEBUG_SESSION}` : `${config.url}&${XDEBUG_SESSION}`;
            }

            return config;
        });
        axios.interceptors.response.use(
            response => {
                if (response.status === 200 || response.status === 201) {
                    if (response.data.message) {
                        this.displayMessage(response.data.message, 'success');
                    }
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            },

            error => {
                if (error.response.status) {
                    switch (error.response.status) {
                        case 400:
                        case 401:
                        case 403:
                        case 404:
                        case 500: //intentional fallthrough
                            this.displayMessage(error.response.data.message, 'error');
                            break;
                        case 502:
                            setTimeout(() => {
                                router.replace({
                                    path: "/",
                                    query: {
                                        redirect: router.currentRoute.fullPath
                                    }
                                });
                            }, 1000);
                    }
                    return Promise.reject(error.response);
                }
            }
        )
    },

    displayMessage(message, type) {
        store.commit('setSnackbar', {
            show: true,
            message: message,
            color: type
        })
    },

    getBusinessId() {
        return businessId;
    }
}

export default ApiService
