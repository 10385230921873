import { UserService } from '@/services/user.service'
import { TokenService } from '@/services/storage.service'
import router from '../../router'
import store from "../index";
import {initRequests} from "@/utils/utils";

// State object
const state = {
    user: {},
    accessToken: TokenService.getToken(),
    isLoggingIn: false,
    isLoadingUser: false,
    isUserLoggedIn: false
};

// Getter functions
const getters = {
    isUserLoggedIn(state ) {
        return state.isUserLoggedIn
    },
    getIsLoggingIn( state ) {
        return state.isLoggingIn;
    },
    getIsLoadingUser( state ) {
        return state.isLoadingUser;
    },
    getUser(state ) {
        return state.user;
    },
    userHasPermission(state) {
        return (entity, action) => {
            if (entity === 'divider') return true

            if (state.user.type === 'agent') {
                return state.user.agent.role.permissions.filter(permission => {
                    return permission.action === action && permission.entity === entity && permission.is_active === 1
                }).length > 0;
            } else if (state.user.type === 'schoolContact') {
                return state.user.school_contact.role.permissions.filter(permission => {
                    return permission.action === action && permission.entity === entity && permission.is_active === 1
                }).length > 0;
            }
        }
    }
};

// Actions
const actions = {
    async login({ getters, commit }, data) {
        commit('setIsLoggingIn', true);
        const response = await UserService.login(data, '?agent&appendAgentRole');
        commit('setUser', response.user);
        commit('setIsLoggingIn', false);
        commit('setIsUserLoggedIn', true);

        // Redirect user by their permission level
        const sideMenus = getters.getSideMenus.filter(menu => {
            return getters.userHasPermission(menu.entity, 'view')
        });

        if (sideMenus.length === 0) {
            router.push('/login');
            return
        }

        store.commit('setAgentSideMenus', sideMenus);

        router.push(router.history.current.query.redirect || sideMenus[0].route);
        initRequests();
    },
    async register({ getters, commit }, data) {
        let error = false
        commit('setIsLoggingIn', true);
        const response = await UserService.register(data).catch(() => {
            error = true
        });

        commit('setIsLoggingIn', false);
        if (error) {
            return
        }

        commit('setUser', response.user);
        commit('setIsUserLoggedIn', true)

        // Redirect user by their permission level
        const sideMenus = getters.getSideMenus.filter(menu => {
            return getters.userHasPermission(menu.entity, 'view')
        });

        if (sideMenus.length === 0) {
            router.push('/login');
            return
        }

        store.commit('setAgentSideMenus', sideMenus);

        // Redirect the user to the page he first tried to visit or to the home view
        router.push(router.history.current.query.redirect || sideMenus[0].route);
        initRequests();
    },
    async getUserByToken({ getters, commit }, data) {
        let user = null;
        commit('setIsLoadingUser', true);
        try {
            user = await UserService.getUserByToken(data);
        } catch (e) {
            router.push('/login');
            return
        }
        if (user === null) {
            router.push('/login');
            return
        }
        commit('setUser', user);
        commit('setIsLoadingUser', false);
        commit('setIsUserLoggedIn', true);

        // Redirect user by their permission level
        const sideMenus = getters.getSideMenus.filter(menu => {
            return getters.userHasPermission(menu.entity, 'view')
        });

        if (sideMenus.length === 0) {
            router.push('/login');
            return
        }

        store.commit('setAgentSideMenus', sideMenus);
        initRequests();
    },
    async getUserByTokenUnsigned({ commit }, data) {
        commit('setIsLoadingUser', true)
        const user = await UserService.getUserByToken(data)
        commit('setUser', user)
        commit('setIsLoadingUser', false)

        initRequests()
    },
};

// Mutations
const mutations = {
    setIsLoadingUser(state, data) {
        state.isLoadingUser = data;
    },
    setUser(state, data) {
        state.user = data;
    },
    setIsUserLoggedIn( state, data) {
        state.isUserLoggedIn = data;
    },
    setIsLoggingIn( state, data) {
        state.isLoggingIn = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
