<template>

    <div class="content-login">

        <v-card tile color="#212B38" class="d-flex flex-row align-center justify-space-between">

            <v-card tile class="image-login">
                <v-img
                        height="100%"
                        src="../../src/assets/admin-register-image.png"
                ></v-img>
            </v-card>

            <v-card color="transparent" elevation="0" tile class="d-flex flex-column align-center justify-center form-login">

                <div class="form-login-container text-center">

                    <img src="../../src/assets/logo.svg" alt="logo" />

                    <p class="my-8 font-weight-bold white--text sign-in-text">
                        Create an account!
                    </p>

                    <v-text-field
                            v-model="name"
                            type="text"
                            placeholder="Full Name"
                            background-color="white"
                            outlined
                            dense
                            :error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                    <v-text-field
                            v-model="email"
                            type="email"
                            placeholder="Email Address"
                            background-color="white"
                            outlined
                            dense
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                    <v-text-field
                            v-model="username"
                            type="text"
                            placeholder="Username"
                            background-color="white"
                            outlined
                            dense
                            :error-messages="usernameErrors"
                            @input="$v.username.$touch()"
                            @blur="$v.username.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                    <v-text-field
                            v-model="password"
                            placeholder="Password"
                            background-color="white"
                            outlined
                            dense
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            :error-messages="passwordErrors"
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                    <v-text-field
                            v-model="confirmPassword"
                            placeholder="Confirm Password"
                            background-color="white"
                            outlined
                            dense
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                            :error-messages="confirmPasswordErrors"
                            @input="$v.confirmPassword.$touch()"
                            @blur="$v.confirmPassword.$touch()"
                            :disabled="isLoggingIn"
                    ></v-text-field>

                  <vue-recaptcha :sitekey="siteKey" :loadRecaptchaScript="true" @verify="onVerify">
                    <v-btn dense block x-large depressed
                           color="primary"
                           class="my-8 text-capitalize font-weight-bold"
                           :loading="isLoggingIn"
                           @click="register"
                    >Register</v-btn>
                  </vue-recaptcha>
                </div>

            </v-card>

        </v-card>
    </div>

</template>

<script>
    import {email, required, sameAs} from 'vuelidate/lib/validators'
    import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'Register',
    validations: {
        name: { required },
        email: { email, required },
        username: { required },
        password: { required },
        confirmPassword: {required, sameAsPassword: sameAs('password')}
    },
    components: {
        VueRecaptcha
    },
    beforeCreate: function() {
        document.body.className = 'login-page';
    },
    data () {
        return {
            show1: false,
            show2: false,
            name: '',
            email: '',
            username: '',
            password: '',
            confirmPassword: '',
            siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
            captcha: null
        }
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push('Fullname is required');
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push('A valid email is required');
            !this.$v.email.required && errors.push('Email is required');
            return errors
        },
        usernameErrors () {
            const errors = []
            if (!this.$v.username.$dirty) return errors;
            !this.$v.username.required && errors.push('Username is required');
            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.required && errors.push('Password is required');
            return errors
        },
        confirmPasswordErrors() {
            const errors = []
            if (!this.$v.confirmPassword.$dirty) return errors
            !this.$v.confirmPassword.required && errors.push('Password confirmation is required')
            !this.$v.confirmPassword.sameAsPassword && errors.push('Password confirmation must match password value')
            return errors
        },
        isLoggingIn() {
            return this.$store.getters.getIsLoggingIn;
        }
    },
    watch: {
      captcha() {
        this.register()
      }
    },
    methods: {
        register: async function() {
          if (!this.captcha) return

            //validate
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$store.commit('setSnackbar', {
                    show: true,
                    message: 'One or more mandatory fields are missing. Please look through the form and fix the field(s) highlighted in red',
                    color: 'red'
                });

                return
            }

            await this.$store.dispatch('register', {
                name: this.name,
                email: this.email,
                username: this.username,
                password: this.password,
                type: 'agent',
            })
        },
        onVerify(verification) {
          if (!this.captcha) {
            this.captcha = verification
          }
        }
    },
    beforeMount() {
        if (this.$router.currentRoute.query) {
            this.email = this.$router.currentRoute.query.email;
        }
    }
}

</script>

<style>

.content-login .v-card {
    height: 100vh;
    font-size: 14px;
}

.content-login .v-card .v-card {
    width: 50%;
}

@media only screen and (max-width: 850px) {

    .content-login .v-card .v-card {
        width: 100%;
    }

    .content-login .image-login {
        display: none;
    }

}

.content-login .v-card.form-login {
    height: auto;
}

.content-login .sign-in-text {
    font-size: 20px;
}

.content-login .form-login-container {
    width: 350px;
}

@media only screen and (max-width: 380px) {

    .content-login .form-login-container {
        width: 90%;
    }

}

.content-login .form-login-container img {
    width: 70%;
}

</style>