<template>
    <div class="content page-style-proto">
        <v-row class="justify-space-between">
            <v-col>
                <v-row>
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Campaigns</h2>
                        <v-menu open-on-hover offset-y v-if="canView">
                            <v-list>
                                <v-list-item link @click="handleManualInputClick">
                                    <v-list-item-title>Manual Input</v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="handleBulkImportClick">
                                    <v-list-item-title>Bulk Import</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <CampaignsDataTable @openCampaignDialog="dialog = true" />
            </v-col>
        </v-row>

        <CampaignDialog @closeCampaignDialog="dialog = false" v-if="dialog" />
    </div>
</template>

<script>
    import CampaignsDataTable from "../components/Campaigns/CampaignsDataTable";
    import CampaignDialog from "../components/Campaigns/CampaignDialog";
    import {displayMessage} from "../utils/utils";

    export default {
        name: 'Campaigns',
        components : {
            CampaignsDataTable,
            CampaignDialog
        },
        data() {
            return {
                dialog: false
            }
        },
        computed: {
            user() {
                return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            }
        },
        methods: {
            handleManualInputClick() {
                if (!this.$store.getters.userHasPermission('students', 'create')) {
                    displayMessage('error', 'Sorry you do not have permission to create students')
                    return
                }

                this.$store.commit('setIsAddNewStudentState', true);
                this.showStudentDialog = true;
            },
            handleBulkImportClick() {
                if (!this.$store.getters.userHasPermission('students', 'bulk_import')) {
                    displayMessage('error', 'Sorry you do not have permission to import students')
                    return
                }

                this.showBulkImportDialog = true
            }
        },
        beforeMount() {
            //get the student statuses
            this.$store.dispatch('getStudentStatuses');
            this.$store.dispatch('getAmenities');
            this.$store.dispatch('getReferralStatuses');
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
