import { RentSpecialService} from "../../services/rentspecials.service";

// State object
const state = {
    rentSpecials: [],
    isLoadingRentSpecials: false,
    isAddNewRentSpecial: false
};

// Getter functions
const getters = {
    getIsLoadingRentSpecials( state ) {
        return state.isLoadingRentSpecials;
    },
    getRentSpecials( state ) {
        return state.rentSpecials;
    },
    getIsAddNewRentSpecial( state ) {
        return state.isAddNewRentSpecial;
    }
};

// Actions
const actions = {
    async getRentSpecials({ commit }, data) {
        commit('setIsLoadingRentSpecials', true);
        const rentSpecials = await RentSpecialService.getRentSpecials(data);
        commit('setRentSpecials', rentSpecials);
        commit('setIsLoadingRentSpecials', false);
    }
};

// Mutations
const mutations = {
    setIsLoadingRentSpecials(state, data) {
        state.isLoadingRentSpecials = data;
    },
    setRentSpecials(state, data) {
        state.rentSpecials = data;
    },
    setIsAddNewRentSpecialState(state, data) {
    state.isAddNewRentSpecial = data;
}
}
export default {
    state,
    getters,
    actions,
    mutations
}
