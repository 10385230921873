<template>
	<v-card class="mx-auto mb-2" :loading="isDeleting">
		<v-list-item>
			<v-list-item-avatar color="grey darken-3">
				<v-img
						class="elevation-6"
						:src="agentImage"
				></v-img>
			</v-list-item-avatar>

			<v-row align="center">
				<v-col cols="8">
					<v-list-item-content>
						<v-list-item-title>
							{{ note.agent && note.agent.user && note.agent.user.name ? note.agent.user.name : 'Johnny 5' }}
						</v-list-item-title>
						<v-list-item-subtitle>{{ note.created_at | formatDate }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-col>
				<v-col cols="4" class="text-right" v-if="hasUpdatePermission">
					<v-avatar tile size="18" @click="editable = !editable" class="mr-4">
						<v-img :ref="`delete-icon-edit-1`" :src="require(`@/assets/icons/pencil.svg`)"
						       @mouseover="handleDeleteIconMouseOver(props.item.id)"
						       @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
						></v-img>
					</v-avatar>

					<v-avatar tile size="18" @click="deleteNote()" class="mr-1">
						<v-img :ref="`delete-icon-1`" :src="require(`@/assets/icons/trash.svg`)"
						       @mouseover="handleDeleteIconMouseOver(1)"
						       @mouseleave="handleDeleteIconMouseLeave(1)"
						></v-img>
					</v-avatar>
				</v-col>
			</v-row>
		</v-list-item>

		<v-card-text class="font-weight-medium">
			<span v-if="!editable" @click="toggleEditable">{{ note.note }}</span>
			<v-textarea v-model="note.note"
			            @focusout="toggleEditable"
			            @change="updateNote"
			            outlined v-else></v-textarea>
		</v-card-text>
	</v-card>
</template>

<script>
import moment from 'moment'
import StudentService from "../../../services/student.service";

export default {
	name: "NoteCard",
	props: ['note'],
	data() {
		return {
			content: 'Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well',
			editable: false,
			isDeleting: false
		}
	},
	computed: {
		agentImage() {
			return this.note.agent
			&& this.note.agent.user
			&& this.note.agent.user.image_url
					? this.note.agent.user.image_url
					: 'https://edurent-email-public.s3.amazonaws.com/robot.jpeg'
		},
		user() {
			return this.$store.getters.getUser;
		},
		hasUpdatePermission() {
			return this.$store.getters.userHasPermission('students', 'update')
		}
	},
	methods: {
		handleDeleteIconMouseOver(iconNumber) {
			this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
		},
		handleDeleteIconMouseLeave(iconNumber) {
			this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
		},
		updateNote() {
			StudentService.updateActivityNote(this.note.id, this.note);
		},
		deleteNote: async function () {
			this.isDeleting = true;
			await StudentService.deleteActivityNote(this.note.id);
			this.isDeleting = false;

			this.$emit('onNoteDeleted');
		},
		toggleEditable() {
			if (!this.hasUpdatePermission) {
				return
			}

			this.editable = !this.editable
		}
	},
	filters: {
		formatDate(date) {
			return moment.utc(date).local().format('l hh:mm A')
		}
	}
}
</script>
