<template>
    <div>
      <School v-if="showSchool"
              :school="school"
              @hideDialog="hideDialog"
              @addCampus="handleAddCampus">
        <template #title>
          <h2>{{school.name}}</h2>
        </template>
        <template #campuses v-if="Object.keys(markers).length > 0">
          <v-expansion-panels class="pa-1">
            <v-expansion-panel  v-for="(campus, i) in school.campuses" :key="i">
              <v-expansion-panel-header>
                {{campus.name}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ExistingCampus :campus="campus"
                                :markers="markers[campus.id]"
                                @campusCoordinatesUpdated="handleCampusCoordinatesUpdated()"
                ></ExistingCampus>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </School>
      <v-skeleton-loader v-else
                         type="card, card-heading, article, article, list-item-three-line"
                         class="mx-auto"
      ></v-skeleton-loader>
    </div>
</template>

<script>
    import School from "@/components/Schools/School.vue"
    import SchoolService from "@/services/school.service";
    import ExistingCampus from "@/components/Campuses/ExistingCampus.vue";

    export default {
        name: 'ExistingSchool',
        props: {
          school: {
            type: Object,
            required: true
          }
        },
        data() {
          return {
            markers: {},
            showSchool: false
          }
        },
        components: {ExistingCampus, School},
        methods: {
          hideDialog() {
            this.$emit('hideDialog')
          },
          async generateSchoolMapMarkers() {
            for await (const campus of this.school.campuses) {
              this.addCampusMapMarker(campus)
              await this.addCampusPropertyMapMarkers(campus)
            }
          },

          addCampusMapMarker(campus) {
            if (this.markers[campus.id] === undefined) {
              this.markers[campus.id] = []
            }
            this.markers[campus.id].push({
              position: {
                lat: Number(campus.latitude),
                lng: Number(campus.longitude)
              },
              icon: 'map-school.svg',
              type: 'school',
              school: campus
            });
          },
          async addCampusPropertyMapMarkers(campus) {
            const properties = await SchoolService.getCampusProperties(campus.id)

            properties.forEach(property => {
              this.markers[campus.id].push({
                position: {
                  lat: Number(property.latitude),
                  lng: Number(property.longitude)
                },
                icon: 'map-building.svg',
                type: 'schoolProperty',
                propertyUnit: property
              })
            })
          },
          async handleAddCampus(campus) {
            const savedCampus = await SchoolService.addCampus(campus)
            this.addCampusMapMarker(savedCampus)

            this.$store.commit('addSchoolCampus', {school_id: this.school.id, campus: savedCampus})
          },
          async handleCampusCoordinatesUpdated() {
            this.showSchool = false
            this.markers = {}
            await this.generateSchoolMapMarkers()
            this.showSchool = true
          }
        },
        async beforeMount() {
          await this.generateSchoolMapMarkers()
          this.showSchool = true
        }
    }
</script>
