<template>
    <div>
        <h3 class="mb-md-4">Property Images</h3>
        <div class="property-image-container">
            <v-row>
                <v-col cols="12">
                    <div class="dropzone" v-cloak @drop.prevent="addFileByDrag" @dragover.prevent @click="addFileByClick">
                        <p>Click or drop property images here</p>
                    </div>
                    <input type="file" ref="file" style="display: none" @change="previewImage" accept="image/*"  multiple>
                </v-col>
            </v-row>

            <v-row>
                <v-skeleton-loader
                        class="col-md-4"
                        type="image"
                        loading
                        style="background: white"
                        v-if="!isAddNewPropertyState && isLoadingImages"
                ></v-skeleton-loader>
                <v-skeleton-loader
                        class="col-md-4 mr-4"
                        type="image"
                        loading
                        style="background: white"
                        v-if="!isAddNewPropertyState && isLoadingImages"
                ></v-skeleton-loader>
            </v-row>

            <draggable
                    v-model="propertyImages"
                    @start="dragging = true"
                    @end="dragging = false"
                    class="row wrap justify-start"
                    v-if="!isLoadingImages"
                    @change="updateImageOrder"
            >
                <v-col v-for="(item, i) in propertyImages" :key="i" class="d-flex child-flex" cols="4" md="4" xl="3">
                    <v-hover v-slot:default="{ hover }" open-delay="200" close-delay="200">
                        <v-card flat tile class="property-image-card d-flex" :elevation="hover ? 16 : 2">
                            <div class="featured-image">
                                <v-chip color="primary" v-if="i === 0" label>Featured</v-chip>
                            </div>
                            <div class="property-image-card-icons">
                                <v-avatar tile size="14" class="mr-2">
                                    <v-img :src="require(`@/assets/icons/property-img-move.svg`)"></v-img>
                                </v-avatar>
                                <v-avatar tile size="14" @click="deleteImage(item.id, i)">
                                    <v-img :src="require(`@/assets/icons/property-img-close.svg`)" ></v-img>
                                </v-avatar>
                            </div>
                            <v-img
                                    :src="item.image_url"
                                    :lazy-src="item.image_url"
                                    aspect-ratio="1"
                                    class="grey lighten-2 property-image"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </draggable>
        </div>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import {PropertyService} from "../../services/property.service";

    export default {
        name: 'PropertyImages',
        props: ['propertyId', 'isPropertySaved', 'reset'],
        components: {
            draggable
        },
        watch: {
            propertyImages() {
                this.$emit('onPropertyImagesModified', this.propertyImages)
            },
            reset(shouldReset) {
                if (shouldReset === true) {
                    this.propertyImages    = [];
                    this.$emit('resetCompleted');
                }
            }
        },
        data () {
            return {
                propertyImages: [],
                dragging: false,
                isLoadingImages: false
            }
        },
        methods: {
            addFileByDrag(e) {
                e.preventDefault();

                let droppedFiles = e.dataTransfer.files;
                if(!droppedFiles) return;

                // foreach dropped file read image
                ([...droppedFiles]).forEach(f => {
                    this.readImageFile(f)
                });
            },
            addFileByClick() {
                this.$refs.file.click();
            },
            previewImage: function(event) {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    input.files.forEach(f => {
                        this.readImageFile(f)
                    });
                }
            },
            readImageFile: async function(file) {
                // if it is an existing property, upload new image
                if (!this.isAddNewPropertyState || this.isAddNewPropertyState && this.isPropertySaved) {
                    const formData = new FormData();
                    formData.append('image', file);

                    await PropertyService.addNewPropertyImage(this.propertyId, formData);
                    this.getPropertyImages();

                    return
                }

                // create a new FileReader to read this image and convert to base64 format
                let reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Read image as base64 and set to imageData
                    this.propertyImages.push({
                        image_url: e.target.result,
                        file: file
                    });
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(file);
            },
            deleteImage: async function(propertyImageId, index) {
                if (this.isAddNewPropertyState && this.propertyId === null) {
                    this.propertyImages.splice(index, 1);
                    return
                }

                await PropertyService.deletePropertyImage(propertyImageId);
                this.getPropertyImages();
            },
            getPropertyImages: async function() {
                this.isLoadingImages = true;
                this.propertyImages = await PropertyService.getPropertyImages(this.propertyId);
                this.isLoadingImages = false;
            },
            updateImageOrder() {
                if (this.isAddNewPropertyState) {
                    return
                }

                PropertyService.updatePropertyImageOrder(this.propertyId, {images: this.propertyImages});
            }
        },
        computed: {
            uploadDisabled() {
                return this.files.length === 0;
            },
            isAddNewPropertyState() {
                return this.$store.getters.getIsAddNewPropertyState;
            }
        },
        filters: {
            kb(value) {
                return Math.floor(value/1024);
            }
        },
        beforeMount() {
            if (!this.isAddNewPropertyState) {
                this.getPropertyImages();
            }
        }

    }
</script>

<style scoped>
    .dropzone {
        display: flex;
        height: 81px;
        width: 100%;
        background: #FFFFFF;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
    }
    .property-image-container {
        background: #F4F5F6;
        padding: 20px;
        border-radius: 4px;
    }

    .property-image-card {
        position: relative;
    }

    .property-image-card-icons {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
    }
    .property-image {
        height: 174px;
        width: 174px;
        border-radius: 4px;
        background: #C4C4C4;
    }
    .featured-image {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
    }
</style>
