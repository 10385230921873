<template>
  <v-card width="1111">
    <v-card-title>
      <v-row class="justify-space-between px-4 py-2">
        <v-row class="justify-start d-flex">
          <h2 class="pr-6">Invoice for {{ invoice.user.name }}</h2>
          <v-chip small color="red" text-color="white" v-if="!invoice.status_sent" class="mt-1">New</v-chip>
          <v-chip small color="green" text-color="white" v-if="invoice.status_sent" class="mt-1">Sent</v-chip>
        </v-row>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="pt-4">
      <InvoiceCard :invoice="invoice"/>
    </v-card-text>

  </v-card>
</template>

<script>
import InvoiceCard from '../../components/Invoices/InvoiceCard';

export default {
    name: 'InvoicePreview',
    components: {InvoiceCard},
    props: ["invoice"]
}
</script>