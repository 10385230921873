<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field outlined dense append-icon="mdi-magnify" v-model="search" placeholder="Search All Invoices" background-color="white"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select outlined dense
                  :items="['New', 'Sent']"
                  v-model="statusFilter"
                  label="Status"
                  background-color="white"
                  @change="getInvoices"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="d-flex">
        <v-btn text height="40" color="primary" @click="resetFilters">
          Clear Filters
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        :search="search"
        :headers="headers"
        :items="invoices"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 table-proto"
        @page-count="pageCount = $event"
        :loading="isLoadingInvoices"
    >
      <template v-slot:item.user.name="props">
        <a class="invoice-name-field" @click.stop="openDialog(props.item)">
          {{ props.item.user.name }}
        </a>
      </template>
      <template v-slot:item.property_name="props">
        {{ props.item.property_name }}
      </template>
      <template v-slot:item.move_in_date="props">
        {{ props.item.move_in_date }}
      </template>
      <template v-slot:item.status_sent="props">
        <v-chip small color="red" text-color="white" v-if="!props.item.status_sent">New</v-chip>
        <v-chip small color="green" text-color="white" v-else>Sent</v-chip>
      </template>
      <template v-slot:item.created_at="props">
        {{ props.item.created_at | formatDate }}
      </template>
      <template v-slot:item.nav="props">
        <v-avatar tile size="10" @click.stop="openDialog(props.item)">
          <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
        </v-avatar>
      </template>
    </v-data-table>

    <v-row class="justify-end" v-if="!isLoadingInvoices">
      <v-col cols="12" md="6" class="text-right">
        <v-row>
          <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
          <v-col cols="12" md="2">
            <v-select :items="pages" dense
                      outlined
                      @change="itemsPerPage = parseInt($event, 10)"
                      v-model="itemsPerPage"
                      background-color="white"
            ></v-select>
          </v-col>
          <v-col cols="12" md="1" class="pt-md-5 mr-4 text-left">Entries</v-col>
          <v-col class="mx-auto" cols="10" md="6">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'InvoiceDataTable',

  data () {
    return {
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      statusFilter: '',
      pages: [5, 10, 15, 20, 25, 30],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'user.name',
        },
        { text: 'Property', value: 'property_name' },
        { text: 'Moved In', value: 'move_in_date' },
        { text: 'Status', value: 'status_sent' },
        { text: 'Created On', value: 'created_at' },
        { text: '', value: 'nav' },
      ]
    }
  },

  computed: {
    invoices() {
      return Object.values(this.$store.getters.getInvoices)
    },

    isLoadingInvoices() {
      return this.$store.getters.isLoadingInvoices
    }
  },

  methods: {
    getInvoices() {
      let payload = '?sort=desc'
      payload += '&appendUser=true'
      payload += this.statusFilter !== '' ? `&status=${this.statusFilter}` : ''

      this.$store.dispatch('getInvoices', payload);
    },

    resetFilters() {
      this.search       = ''
      this.statusFilter = ''

      this.getInvoices()
    },

    async openDialog(invoice) {
      await this.$store.dispatch('setInvoice', invoice.id)
      this.$emit('openInvoiceDialog', true)
    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
      }
    }
  },

  async beforeMount() {
    this.getInvoices()
  }
}
</script>

<style scoped>
.invoice-name-field {
  color: #2D76E3;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
</style>
