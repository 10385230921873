<template>
    <div>
      <div class="filter-container">
        <v-text-field
            class="search-box"
            outlined
            dense
            append-icon="mdi-magnify"
            v-model="search"
            placeholder="Search Student by Name, Email or Phone"
            background-color="white"
        ></v-text-field>

        <v-select outlined
                  dense
                  label="Active Status"
                  :value="activeStatusFilter"
                  :items="['active', 'processed']"
                  item-value="id"
                  item-text="status"
                  @change="setFilter('setActiveStatusFilter', $event)"
                  background-color="white"
                  class="filter"
        ></v-select>

        <v-select outlined dense label="Ended Status"
                  :value="endedStatusFilter"
                  :items="['all', 'not ended', 'ended']"
                  item-value="id"
                  item-text="status"
                  @change="setFilter('setEndedStatusFilter', $event)"
                  background-color="white"
                  class="filter"
        ></v-select>

        <v-select outlined dense label="Type"
                :value="campaignTypeFilter"
                :items="['delay', 'immediate']"
                item-value="id"
                item-text="status"
                @change="setFilter('setCampaignTypeFilter', $event)"
                background-color="white"
                class="filter"
        ></v-select>

        <v-sheet class="filter" height="40">
          <v-menu
              v-model="showDateFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  :value="dateFromFilter"
                  outlined
                  append-icon="mdi-calendar"
                  dense
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :value="dateFromFilter"
                           @input="showDateFromMenu=false"
                           @change="setFilter('setDateFromFilter', $event)"
            ></v-date-picker>
          </v-menu>
        </v-sheet>

        <v-sheet class="filter" height="40">
          <v-menu
              v-model="showDateToMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  :value="dateToFilter"
                  outlined
                  append-icon="mdi-calendar"
                  dense
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :value="dateToFilter"
                           @input="showDateToMenu=false"
                           @change="setFilter('setDateToFilter', $event)"
            ></v-date-picker>
          </v-menu>
        </v-sheet>

        <v-btn text height="40" color="primary" @click="resetFilters">
          Clear Filters
        </v-btn>
      </div>

        <v-data-table
            :search="search"
            :headers="$vuetify.breakpoint.smAndDown ? mobile_headers : headers"
            :items="campaigns"
            :page.sync="page"
            :custom-sort="customSort"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :loading="isLoadingCampaigns"
        >
            <template v-slot:item.hash="props">
                <a class="invoice-name-field" @click.stop="openDialog(props.item)">
                    {{props.item.hash}}
                </a>
            </template>
            <template v-slot:item.type="props">
                {{props.item.deposit_type}}
            </template>
            <template v-slot:item.from_date="props">
                {{ props.item.created_at | formatDate }}
            </template>
            <template v-slot:item.name="props">
                {{props.item.name}}
            </template>
            <template v-slot:item.email="props">
                {{props.item.email}}
            </template>
            <template v-slot:item.funds="props">
                ${{totalAmount(props.item.donations)}}
            </template>
            <template v-slot:item.num_donnors="props">
                {{props.item.donations.length}}
            </template>
            <template v-slot:item.ended="props">
                <div v-if="props.item.is_ended">
                    Ended
                </div>
                <div v-else>
                    Not ended
                </div>
            </template>
            <template v-slot:item.processed="props">
                <div v-if="props.item.is_active">
                    Active
                </div>
                <div v-else>
                    Not active
                </div>
            </template>
            <template v-slot:item.from_date="props">
                {{ props.item.created_at | formatDate }}
            </template>
            <template v-slot:item.processed_date="props">
                <div v-if="props.item.is_active">
                </div>
                <div v-else>
                    {{ props.item.updated_at | formatDate }}
                </div>
            </template>
        </v-data-table>
        <v-row>
            <v-col cols="12" md="4">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start pt-md-5 ml-1'" v-if="!isLoadingCampaigns">
                    Showing {{(currentPage * itemsPerPage) - itemsPerPage + 1}} to {{itemsPerPage * currentPage}} of {{totalCampaigns}} {{isFiltered ? `Filtered from ${totalNoFilter}` : ''}}
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'" v-if="!isLoadingCampaigns">
                    
                        <v-row>
                            <v-col cols="12" sm="2" md="1" class="text-sm-left pt-md-5">Show</v-col>
                            <v-col cols="12" sm="4" md="2" class="m-0">
                                <v-select :items="pages" dense
                                        outlined
                                        @change="updatePageEntries(parseInt($event, 10))"
                                        :value="itemsPerPage"
                                        background-color="white"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="2" class="pt-md-5 text-left">Entries</v-col>
                            <v-col cols="12" md="7">
                                <Paginate state="Campaigns" collection="campaigns" list="getCampaigns" current-page-setter="setCampaignsCurrentPage" />
                            </v-col>
                        </v-row>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from 'moment'
    import {setFilter} from "@/utils/utils";
    import Paginate from "../Paginate";

    export default {
        name: 'CampaignsDataTable',
        components: {
            Paginate
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                previousSort: null,
                previousSortDirection: null,
                headers: [
                    {
                        text: 'Campaign',
                        align: 'start',
                        value: 'hash',
                    },
                    { text: 'Type', value: 'type' },
                    { text: 'Creation Date', value: 'from_date' },
                    { text: 'Student', value: 'user.name' },
                    { text: 'Email', value: 'user.email' },
                    { text: 'Funds', value: 'funds' },
                    { text: 'Ended', value: 'ended' },
                    { text: 'Processed', value: 'processed' },
                    { text: 'Processed Date', value: 'processed_date' },
                ],
                mobile_headers: [
                    {
                        text: 'Campaign',
                        align: 'start',
                        value: 'hash',
                    },
                    { text: 'Type', value: 'type' },
                    { text: 'Creation Date', value: 'from_date' },
                    { text: 'Student', value: 'user.name' },
                    { text: 'Email', value: 'user.email' },
                    { text: 'Funds', value: 'funds' },
                    { text: 'Ended', value: 'ended' },
                    { text: 'Processed', value: 'processed' },
                    { text: 'Processed Date', value: 'processed_date' },
                ],
                pages: [5, 10, 15, 20, 25, 30],
                showDateFromMenu: false,
                showDateToMenu: false
            }
        },
        watch: {
          search(val) {
            this.debouncedSearch(val)
          },
        },
        computed: {
            isLoadingCampaigns() {
                return this.$store.getters.getIsloadingCampaigns
            },
            campaigns() {
                return this.$store.getters.getCampaigns
            },
            totalCampaigns() {
                return this.$store.getters.getCampaignsTotal
            },
            totalNoFilter() {
                return this.$store.getters.getTotalNoFilter
            },
            isFiltered() {
                return this.$store.getters.getIsCampaignFiltered
            },
            statuses() {
                return this.$store.getters.getStudentStatuses
            },
            schools() {
                return this.$store.getters.getSchools;
            },
            schoolContacts() {
              let school = null

              if (this.schoolFilter) {
                school = this.schools.find(school => {
                  return school.id === this.schoolFilter
                })
              } else {
                school = this.schools.find(school => {
                  return school.id === this.user.school_id
                })
              }

              if (!school) return []
              return school.contacts
            },
            agents() {
                return this.$store.getters.getAgents;
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('student', 'delete');
            },
            endedStatusFilter() {
                return this.$store.getters.getEndedStatusFilter;
            },
            activeStatusFilter() {
                return this.$store.getters.getActiveStatusFilter;
            },
            campaignTypeFilter() {
                return this.$store.getters.getCampaignTypeFilter;
            },
            agentFilter() {
                return this.$store.getters.getStudentAgentFilter;
            },
            schoolFilter() {
                return this.$store.getters.getStudentSchoolFilter;
            },
            schoolContactsFilter() {
                return this.$store.getters.getStudentSchoolContactsFilter;
            },
            dateFromFilter() {
                return this.$store.getters.getDateFromFilter;
            },
            dateToFilter() {
                return this.$store.getters.getDateToFilter;
            },
            itemsPerPage() {
                return this.$store.getters.getCampaignsPerPage;
            },
            currentPage() {
                return this.$store.getters.getCampaignsCurrentPage;
            },
            user() {
              return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            }
        },
        methods: {
            customSort(
                items /*: any[]*/,
                sortBy /*: string[]*/,
                sortDesc /*: boolean[]*/
                ) {
                    if (!sortBy.length && !this.previousSort) {
                        return items;
                    }
                    if (!sortBy.length && this.previousSort) {
                        this.$store.commit('setCampaignsCurrentPage', 1);
                        this.$store.commit('setSortByDirection', 'desc');
                        this.$store.commit('setSortByKey', 'createdAt');
                        this.$store.dispatch('getCampaigns');
                        this.previousSort = null;
                        return items;
                    }
                    if (this.previousSort === sortBy[0] && this.previousSortDirection === sortDesc[0] ) {
                        return items;
                    }
                    this.previousSort = sortBy[0];
                    this.previousSortDirection = sortDesc[0];
                    this.$store.commit('setCampaignsCurrentPage', 1);
                    this.$store.commit('setSortByDirection', sortDesc[0] ? 'desc': 'asc');
                    if (sortBy[0] === 'type') { 
                        this.$store.commit('setSortByKey', 'type');
                    }
                    if (sortBy[0] === 'funds') { 
                        this.$store.commit('setSortByKey', 'donations');
                    }
                    if (sortBy[0] === 'from_date') { 
                        this.$store.commit('setSortByKey', 'createdAt');
                    }
                    if (sortBy[0] === 'processed_date') { 
                        this.$store.commit('setSortByKey', 'processedDate');
                    }
                    if (sortBy[0] === 'name') { 
                        this.$store.commit('setSortByKey', 'studentName');
                    }
                    if (sortBy[0] === 'email') { 
                        this.$store.commit('setSortByKey', 'studentEmail');
                    }
                    this.$store.dispatch('getCampaigns');
                    return items;
            },
            totalAmount(donations) {
                let total = 0;
                donations.forEach(donation => {
                    total += Number(donation.amount);
                });
                return Math.ceil(total/100);
            },
            async openDialog(campaign) {
                await this.$store.dispatch('setCampaign', campaign);
                this.$emit('openCampaignDialog', true);
            },
            showExternalPropertyPage(website) {
                window.open(website,'_blank');
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            resetFilters() {
                this.search       = '';
                this.$store.commit('setActiveStatusFilter', null);
                this.$store.commit('setEndedStatusFilter', null);
                this.$store.commit('setCampaignTypeFilter', null);
                this.$store.commit('setDateFromFilter', null);
                this.$store.commit('setDateToFilter', null);

                this.$store.dispatch('getCampaigns');
            },
            setFilter(type, value) {
                this.$store.commit('setCampaignsCurrentPage', 1)
                setFilter(type, value, 'getCampaigns');
            },
            updatePageEntries(count) {
                this.$store.commit('setCampaignsPerPage', count);
                this.$store.commit('setCampaignsCurrentPage', 1);
                this.$store.dispatch('getCampaigns')
            },
            debouncedSearch(userInput) {
              // cancel pending call
              clearTimeout(this._timerId)

              // delay new call 500ms
              this._timerId = setTimeout(() => {
                this.searchApi(userInput)
              }, 500)
            },
            searchApi: async function (query) {
              this.setFilter('setCampaignsSearchFilter', query)
            },
		        openViewStudent(studentId) {
			        window.open(`/#/view-student/${studentId}`,'_blank')
		        }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY/MM/DD')
                }
            },
            age(value) {
                let currentDate = new Date();
                let birthDate = new Date(value);
                let difference = currentDate - birthDate;
                return Math.floor(difference/31557600000);
            }
        },
        beforeMount() {
            this.$store.dispatch('getCampaigns');
        }
    }
</script>

<style scoped>
    .table-proto {
        width: 80vw;
        margin-left: -2em;
    }

    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
    .paginate-entries {
      display: inline-block;
    }

    .filter-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .filter {
      flex-grow: 1;
      flex-basis: 100px;
    }

    .search-box {
      flex-grow: 1;
      flex-basis: 200px;
    }
</style>
