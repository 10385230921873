const state = () => ({
    showSnackbar: false,
    snackMessage: '',
    snackColor: '',
    timeout: 4000,
})

const getters = {
    getShowSnackbar: (state) => {
        return state.showSnackbar
    },
    getSnackMessage: (state) => {
        return state.snackMessage
    },
    getSnackColor: (state) => {
        return state.snackColor
    },
    getTimeout: (state) => {
        return state.timeout
    },
}

const actions = {}

const mutations = {
    setSnackbar (state, snackbar) {
        state.showSnackbar = snackbar.show
        state.snackMessage = snackbar.message
        state.snackColor   = snackbar.color

        if (snackbar.timeout) {
            state.timeout   = snackbar.timeout
        }
    },
    setShowSnackbar(state, data) {
        state.showSnackbar = data
    }
}


export default {
    // namespace: true,
    state,
    getters,
    actions,
    mutations
}
