<template>
  <div>
    <v-row>
      <v-col cols="4">
        <h2 class="mb-6">Campaign Info</h2>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <h4>Campaign Type</h4>
                <p class="text--primary">{{ campaign.deposit_type }}</p>
              </v-col>
              <v-col cols="6">
                <h4>Student Email</h4>
                <p class="text--primary">{{ campaign.email }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <h4>University Start Date</h4>
                <p class="text--primary">{{ campaign.university_start_date | formatDate }}</p>
              </v-col>
              <v-col cols="6">
                <h4>Campaign Creation Date</h4>
                <p class="text--primary">{{ campaign.created_at | formatDate }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <h4>Ended Status</h4>
                <p v-if="campaign.is_ended" class="text--primary">Ended</p>
                <p v-if="!campaign.is_ended" class="text--primary">Not Ended</p>
              </v-col>
              <v-col cols="6">
                <h4>Processed Status</h4>
                <p v-if="campaign.is_active" class="text--primary">Not Processed</p>
                <p v-if="!campaign.is_active" class="text--primary">Processed</p>
              </v-col>
            </v-row>

            <v-row v-if="campaign.is_ended">
              <v-col cols="6">
                <h4>Processed Date</h4>
                <p class="text--primary">{{ campaign.updated_at | formatDate }} </p>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <h2 class="mb-6">Donations</h2>
        <v-card>
          <v-card-text>

            <v-row>
              <v-col cols="3">
                <h4>Donor</h4>
              </v-col>
              <v-col cols="4">
                <h4>Donor Email</h4>
              </v-col>
              <v-col cols="3">
                <h4>Donor Note</h4>
              </v-col>
              <v-col cols="2">
                <h4>Amount</h4>
              </v-col>
            </v-row>

            <v-row v-for="(donation, i) in campaign.donations" :key="i">
              <v-col cols="3">
                <p v-if="!donation.is_anonymous" class="text--primary">{{donation.donor_name}}</p>
                <p v-if="donation.is_anonymous" class="text--primary">Anonymous</p>
              </v-col>
              <v-col cols="4">
                <p v-if="!donation.is_anonymous" class="text--primary">{{donation.donor_email}}</p>
              </v-col>
              <v-col cols="3">
                <p class="text--primary">{{donation.donor_note}}</p>
              </v-col>
              <v-col cols="2">
                <p class="text--primary">{{donation.amount | formatDonnation | formatPrice }}</p>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="card-actions pa-4">
            <div>
              <h4>Total</h4>
              <p>{{ amountTotal(campaign.donations) | formatPrice }}</p>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export default {
  name: 'CampaignCard',

  components: {
  },

  props: {
    campaign: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isMarkingAsSent: false
    }
  },

  computed: {

  },

  methods: {
    async markAsSent() {
    },
    amountTotal(donations) {
      let total = 0;
      donations.forEach(donation => {
        total += Number(donation.amount);
      });
      return Math.ceil(total/100);
    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return moment.utc(String(value)).local().format('MM/DD/YYYY')
      }
    },

    formatDonnation(price) {
      return Math.ceil(price/100);
    },

    formatPrice(value) {
      return priceFormatter.format(value ? value : 0)
    }
  }
}
</script>

<style scoped>
.card-actions {
  background-color: whitesmoke;
}
</style>
