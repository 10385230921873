<template>
    <div>
        <h3>Property Images</h3>
        <div class="property-image-container">
            <v-row>
                <v-col cols="6">

                    <div class="dropzone" v-cloak @drop.prevent="addFileByDrag" @dragover.prevent
                         @click="addFileByClick">
                        <p>Click or drop property image here</p>
                    </div>
                    <input type="file" ref="file" style="display: none" @change="previewImage">
                </v-col>
                <v-col cols="6" class="pl-4 pr-0">
                    <v-hover v-slot:default="{ hover }" open-delay="200" close-delay="200">
                        <v-card flat tile class="property-image-card d-flex" :elevation="hover ? 16 : 2">
                            <div class="property-image-card-icons">
                                <v-btn icon color="primary">
                                    <v-avatar tile size="14" @click="deleteImage">
                                        <v-img :src="require(`@/assets/icons/property-img-close.svg`)"></v-img>
                                    </v-avatar>
                                </v-btn>
                            </div>
                            <v-img
                                    :src="!isEmptyFloorPlanImage ? propertyUnit.floor_plan_image_url : 'https://place-hold.it/140/E5E5E5/FFFFFF?text=Image'"
                                    :lazy-src="!isEmptyFloorPlanImage ? propertyUnit.floor_plan_image_url : 'https://place-hold.it/140/E5E5E5/FFFFFF?text=Image'"
                                    aspect-ratio="1"
                                    class="grey lighten-2 property-image"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PropertyUnitImage',
        props: ['propertyUnit', 'currentPropertyUnitIndex'],
        data() {
            return {
                file: [],
            }
        },
        computed: {
            isEmptyFloorPlanImage() {
                return this.propertyUnit.floor_plan_image_url === '' || this.propertyUnit.floor_plan_image_url === null
            },
            uploadDisabled() {
                return this.files.length === 0;
            }
        },
        methods: {
            addFileByDrag(e) {
                e.preventDefault();

                let droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;

                // foreach dropped file read image
                ([...droppedFiles]).forEach(f => {
                    //this.files.push(f);
                    this.readImageFile(f)
                });
            },
            addFileByClick() {
                this.$refs.file.click();
            },
            removeFile(file) {
                this.files = this.files.filter(f => {
                    return f != file;
                });
            },
            previewImage: function (event) {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    this.readImageFile(input.files[0])
                }
            },
            readImageFile(file) {
                //add to files array
                this.file = file

                // create a new FileReader to read this image and convert to base64 format
                let reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Read image as base64 and set to imageData
                    this.$emit('onPropertyUnitImageModified', {
                        image: file,
                        image_url: e.target.result
                    })
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(file);
            },
            deleteImage() {
                if (this.isEmptyFloorPlanImage) {
                    return
                }

                this.$emit('onPropertyUnitImageDeleted', this.currentPropertyUnitIndex)
            }
        },
        filters: {
            kb(value) {
                return Math.floor(value / 1024);
            }
        },
    }
</script>

<style scoped>
    .dropzone {
        display: flex;
        height: 140px;
        width: 169px;
        background: #FFFFFF;
        border: 1px dashed rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dropzone p {
        font-size: smaller;
    }

    .property-image-container {
        background: #FFFFFF;
        border-radius: 4px;
    }

    .property-image-card {
        position: relative;
    }

    .property-image-card-icons {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
    }

    .property-image {
        height: 140px;
        width: 169px;
        border-radius: 4px;
        background: #C4C4C4;
    }
</style>
