<template>
  <v-card width="1111">
    <v-card-title>
      <v-row class="justify-space-between px-4 py-2">
        <v-row class="justify-start d-flex">
          <h2 class="pr-6">Campaign for {{ campaign.name }}</h2>
          <v-chip small color="green" text-color="white" v-if="!campaign.is_active" class="mt-1">Processed</v-chip>
          <v-chip small color="red" text-color="white" v-if="campaign.is_active" class="mt-1">Not processed</v-chip>
        </v-row>
        <v-btn icon @click.prevent="hideDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="pt-4">
      <CampaignCard :campaign="campaign"/>
    </v-card-text>

  </v-card>
</template>

<script>
import CampaignCard from '../components/Campaigns/CampaignCard'

export default {
  name: 'Invoice',

  components: {
    CampaignCard
  },

  computed: {
    campaign() {
      return this.$store.getters.getCampaign
    }
  },

  methods: {
    hideDialog() {
      this.$emit('hideDialog', true)
    }
  }
}
</script>
