<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <v-text-field outlined dense append-icon="mdi-magnify" v-model="search" placeholder="Search Amenities"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Type" :items="types"
                          v-model="typeFilter"
                          @change="getAmenities"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Featured" :items="featured"
                          v-model="featuredFilter"
                          @change="getAmenities"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn text color="primary" @click="clearFilters">Clear Filters</v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="headers"
            :items="amenities"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :loading="isLoadingAmenities || isDeletingAmenity"
        >
            <template v-slot:item.icon="props">
                <AmenityDialog action="show-icon" :amenity="props.item" :iconUrl="props.item.icon_url"/>
            </template>
            <template v-slot:item.name="props">
                <AmenityDialog action="show-name" :amenity="props.item" :name="props.item.name"/>
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile width="16" height="18" min-width="16" min-height="18" @click="deleteAmenity(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nav="props">
                <AmenityDialog action="show" :amenity="props.item" />
            </template>
        </v-data-table>
        <v-row class="justify-end" v-if="!isLoadingAmenities">
            <v-col cols="12" md="6" class="text-right">
                <v-row>
                    <v-col cols="12" md="1" class="pt-md-5">Show</v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="pages" dense
                                  outlined
                                  @change="itemsPerPage = parseInt($event, 10)"
                                  v-model="itemsPerPage"
                        >show </v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="pt-md-5 text-left">Entries</v-col>
                    <v-col cols="12" md="5">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AmenityDialog from "./AmenityDialog";
    import AmenityService from "../../services/amenity.service";
    import {displayMessage} from "../../utils/utils";

    export default {
        name: 'AmenityDataTable',
        components: {
            AmenityDialog
        },
        data () {
            return {
                types: ['Unit Amenity', 'Property Amenity'],
                featured: ['Yes', 'No'],
                typeFilter: '',
                featuredFilter: '',

                search: '',
                page: 1,
                pageCount: 1,
                itemsPerPage: 10,
                pages: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                headers: [
                    {
                        text: 'Icon',
                        align: 'start',
                        value: 'icon',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Featured', value: 'featured' },
                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],

                isDeletingAmenity: false
            }
        },
        computed: {
            amenities() {
                return this.$store.getters.getAmenities
            },
            isLoadingAmenities() {
                return this.$store.getters.getIsLoadingAmenities
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('amenities', 'delete');
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteAmenity(amenityId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete amenities')
                    return;
                }

                this.isDeletingAmenity = true;
                AmenityService.deleteAmenity(amenityId).then(() => {
                    this.isDeletingAmenity = false;
                    this.$store.dispatch('getAmenities');
                });
            },
            getAmenities() {
                this.$store.dispatch('getAmenities', {
                    type: this.typeFilter,
                    featured: this.featuredFilter
                });
            },
            clearFilters() {
                this.typeFilter = '';
                this.featuredFilter = '';

                this.getAmenities();
            }
        },
        beforeMount() {
            this.getAmenities();
        }
    }
</script>
