<template>
    <div class="content">
        <v-row>
           <v-col cols="12" md="1">
               <h2 class="pr-8">Properties</h2>
               <v-chip small color="red" text-color="white" class="mt-1" v-if="newPropertyCount > 0">
                   {{ newPropertyCount }} New
               </v-chip>
           </v-col>
            <v-col class="pl-md-8">
                <PropertyDialog action="add"/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <PropertyDataTable />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import PropertyDataTable from "../components/Properties/PropertyDataTable";
import PropertyDialog from "../components/Properties/PropertyDialog";

export default {
    name: 'Properties',
    components: {PropertyDataTable, PropertyDialog},
    computed: {
        newPropertyCount() {
            return this.$store.getters.getNewPropertyCount
        }
    },

    beforeMount() {
        this.$store.dispatch('getNewPropertyCount');
    }
}
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
