import ApiService from './api.service'

const InvitationService = {
    getInvitations: async function(params) {
        const response = await ApiService.get(`invitations${params}`);
        return response.data.data
    },
    sendInvite: async function(inviteId) {
        const response = await ApiService.post(`invitation/${inviteId}/send-invite`)
        return response.data.data
    },
    sendBulkInvites: async function(data) {
        return await ApiService.post('send-bulk-invites', data)
    },
    deleteInvite: async function(inviteId) {
        const response = await ApiService.delete(`invitation/${inviteId}`)
        return response.data.data
    }
}

export default InvitationService

export { InvitationService }
