import { RoommateService, AuthenticationError } from '../../services/roommate.service'

// State object
const state = {
    roommates: {},
    favoriteRoommates: {},
    contactedRoommates: {},
    fetching: false,
    matchRoommates: {}
};

// Getter functions
const getters = {
    getRoommates( state ) {
        return state.roommates.data;
    },
    getFavoriteRoommates( state ) {
        return state.favoriteRoommates.data;
    },
    getContactedRoommates( state ) {
        return state.contactedRoommates.data;
    },
    getMatchRoommates( state ) {
        return state.matchRoommates;
    },
};

// Actions
const actions = {
    async getRoommateList({ commit }, data) {
        try {
            const roommates = await RoommateService.fetchPaginatedRoommates(data);
            commit('setRoommates', roommates.data);
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }

            return false
        }
    },
    async getFavoriteRoommateList({ commit }, data) {
        try {
            const roommates = await RoommateService.fetchPaginatedFavoriteRoommates(data);
            commit('setFavoriteRoommates', roommates.data);
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }

            return false
        }
    },
    async getContactedRoommateList({ commit }, data) {
        try {
            const roommates = await RoommateService.fetchPaginatedContactedRoommates(data);
            commit('setContactedRoommates', roommates.data);
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }

            return false
        }
    },
    async fetchRoommates({ commit }) {
        try {
            const roommates = await RoommateService.fetchRoommates();
            commit('setRoommates', roommates.data);
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }

            return false
        }
    },
    async toggleFavoriteRoommateLike({ commit }, roommateUserId) {
        commit('setFetching', true);
        await RoommateService.toggleFavoriteRoommateLike(roommateUserId);
        commit('setFetching', false);
    }
};

// Mutations
const mutations = {
    setRoommates(state, data) {
        state.roommates = data;
    },
    setFavoriteRoommates(state, data) {
        state.favoriteRoommates = data;
    },
    setContactedRoommates(state, data) {
        state.contactedRoommates = data;
    },
    setCurrentPage(state, data) {
        state.roommates.current_page = data
    },
    setFavoriteCurrentPage(state, data) {
        state.favoriteRoommates.current_page = data
    },
    setContactedCurrentPage(state, data) {
        state.contactedRoommates.current_page = data
    },
    setFetching(state, data) {
        state.fetching = data
    },
    setMatchRoommates(state, data) {
        state.matchRoommates = data
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
