import ApiService from './api.service'

const RentSpecialService = {
    getRentSpecials: async function(params = '') {
        const response = await ApiService.get(`rent-specials${params}`);
        return response.data.data
    },
    updateRentSpecial: async function(rentSpecialId, data) {
        return await ApiService.post(`rent-special/${rentSpecialId}`, {data: data});
    },
    addRentSpecial: async function(data) {
        const response = await ApiService.post(`rent-special`, data);
        return response.data.data;
    },
    deleteRentSpecial: async function(rentSpecialId) {
        const response = await ApiService.delete(`rent-special/${rentSpecialId}`);
        return response.data.data;
    }

};

export default RentSpecialService

export { RentSpecialService }
