<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="referrals"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="elevation-1"
            :loading="isLoading"
        >
            <template v-slot:item.referral_date="props">
                {{props.item.referral_date | formatDate}}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="deleteReferral(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import moment from 'moment'
    import PropertyService from "../../services/property.service";

    export default {
        name: 'PropertyReferrals',
        props: ['property'],
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 1,
                itemsPerPage: 5,
                headers: [
                    {
                        text: 'Start Date',
                        align: 'start',
                        value: 'start_date',
                        class: 'dt-header'
                    },
                    { text: 'Student', value: 'student' },
                    { text: 'School', value: 'school' },
                    { text: 'Unit', value: 'unit' },
                    { text: 'Status', value: 'status' },
                    { text: 'Invoice', value: 'invoice' },
                    { text: 'Referral Date', value: 'referral_date' },
                    { text: '', value: 'delete' },
                ],
                referrals: [],
                isLoading: false
            }
        },
        methods: {
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteReferral: async function(referredPropertyId) {
                this.isLoading = true;
                await PropertyService.deleteReferredProperty(referredPropertyId);
                this.getReferrals();
                this.isLoading = false;
            },
            getReferrals: async function() {
                this.isLoading = true;
                this.referrals = await PropertyService.getReferrals(this.property.id, '?formatted');
                this.isLoading = false;
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
                }
            }
        },
        beforeMount: async function() {
            this.getReferrals();
        }
    }
</script>
