<template>
    <v-container>
        <h1>User Merger</h1>
        <h4>User A will be merged into User B, User A will be removed after the merge.</h4>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="user-card">
                    <v-card-title>
                        User A
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="10">
                                <v-combobox
                                        outlined
                                        dense
                                        append-icon="mdi-magnify"
                                        v-model="searchValueA"
                                        :items="itemsA"
                                        :search-input.sync="searchValueA"
                                        placeholder="Enter Search Value and Press Enter"               
                                        :no-filter="true"
                                        :menu-props="{ overflowY: true }"
                                        @keydown.enter="onTextChanged('A')"
                                        @focus="clearSearch()"
                                    >
                                    <template v-slot:item="{ item }">
                                            <v-list-item 
                                            @click="handleClick('A', item.id)">        
                                                {{item.id + " " + item.string}}
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                            </v-col>
                            <v-col cols="6" v-if="selectedUserA.id">
                                <v-card>
                                    <v-card-title>
                                        {{selectedUserA.id}}
                                        {{selectedUserA.name}}
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="user-card">
                    <v-card-title>
                        User B
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="10">
                                <v-combobox
                                        outlined
                                        dense
                                        append-icon="mdi-magnify"
                                        v-model="searchValueB"
                                        :items="itemsB"
                                        :search-input.sync="searchValueB"
                                        placeholder="Enter Search Value and Press Enter"               
                                        :no-filter="true"
                                        :menu-props="{ overflowY: true }"
                                        @keydown.enter="onTextChanged('B')"
                                        @focus="clearSearch()"
                                    >
                                    <template v-slot:item="{ item }">
                                            <v-list-item 
                                            @click="handleClick('B', item.id)">                        
                                                {{item.string}}
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                            </v-col>
                            <v-col cols="6" v-if="selectedUserB.id">
                                <v-card>
                                    <v-card-title>
                                        {{selectedUserB.id}}
                                        {{selectedUserB.name}}
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10">
                <v-checkbox
                    v-model="confirm"
                    :label="`I confirm the correct users have been selected.`"
                ></v-checkbox>
                <v-btn
                    color="primary"
                    :disabled="!confirm"
                    @click="submitMerge()"
                >
                    Merge
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {SearchService} from "@/services/search.service";
import StudentService from '../services/student.service';
    export default {
        name: "UserMerge",
        data() {
            return {
                confirm: false,
                searchValueA: "",
                searchValueB: "",
                itemsA: [],
                itemsB: [],
                selectedUserA: {},
                selectedUserB: {}
            }
        },
        methods: {
            async onTextChanged(user) {
                switch(user) {
                    case 'A':
                        if(this.searchValueA && this.searchValueA.length > 0) {
                            this.itemsA = await SearchService.search(`?value=${this.searchValueA}&type=student`);   
                        }
                        break
                    default:
                    case 'B':
                        if(this.searchValueB && this.searchValueB.length > 0) {
                            this.itemsB = await SearchService.search(`?value=${this.searchValueB}&type=student`);   
                        }
                        break
                }                
            },
            clearSearch() {
                this.itemsA = [];
                this.itemsB = [];
            },
            async handleClick(user, id) {
                switch(user) {
                    case 'A':
                        this.selectedUserA = await StudentService.getStudent(id)
                        this.searchValueA = ""
                        this.itemsA =[]
                        break
                    default:
                    case 'B':
                       this.selectedUserB = await StudentService.getStudent(id)
                       this.searchValueB = ""
                       this.itemsB = []
                        break
                }                
            },
            async submitMerge() {
                let merged = await StudentService.mergeStudents({studentA: this.selectedUserA.id, studentB: this.selectedUserB.id })
                if(!merged) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Merge was unsuccessful, please contact an admin.',
                        color: 'red'
                    });
                } else {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Users merged successfully',
                        color: 'green'
                    });
                    this.selectedUserA = {}
                    this.selectedUserB = {}
                    this.searchValueA = ""
                    this.searchValueB = ""
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.user-card {
    
}

</style>