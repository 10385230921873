<template>
    <v-dialog v-model="dialog" scrollable max-width="1111">
        <template v-slot:activator="{ on }">
            <v-btn depressed color="primary" v-on="on" v-if="action === 'add'" @click="updateState('add')" class="text-capitalize font-weight-bold">
                <v-icon left>mdi-plus</v-icon>
                Add New
            </v-btn>
            <v-avatar tile size="10" v-on="on" v-if="action === 'show'" @click="updateState('show')">
                <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
            </v-avatar>
            <a v-on="on" v-if="action === 'show-btn'" class="property-name-field" @click="updateState('show')">
                {{actionText}}
            </a>
        </template>

        <DefaultMessage :default-message="defaultMessage"
                        v-on:hideDialog="hideDialog"
        />
    </v-dialog>
</template>

<script>
    import DefaultMessage from "./DefaultMessage";

    export default {
        name: 'DefaultMessagesDialog',
        props: ['action', 'existingMessage', 'actionText'],
        components: {DefaultMessage},
        data() {
            return {
                dialog: false,
                defaultMessage: {}
            }
        },
        methods: {
            initDefaultMessage() {
               this.defaultMessage = {
                    business_id: this.$store.getters.getBusinessId,
                    label: '',
                    type: '',
                    default_message_category_id: null,
                    value: ''
                }
            },
            updateState(state) {
                if (state === 'add') {
                    this.$store.commit('setIsAddNewDefaultMessageState', true)
                } else {
                    this.$store.commit('setIsAddNewDefaultMessageState', false)
                }
            },
            hideDialog() {
                if (this.$store.getters.getIsAddNewDefaultMessage) {
                    this.initDefaultMessage();
                }

                this.dialog = false;
            }
        },
        beforeMount() {
            if (this.action !== 'add') {
                this.defaultMessage = this.existingMessage;
                return
            }

            this.initDefaultMessage();
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }

    .content {
        padding-left: 250px
    }
</style>
