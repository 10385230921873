<template>
	<div>
		<v-row>
			<v-col cols="12" md="2">
				<v-select outlined dense label="Agent"
									:items="agents"
									background-color="white"
									item-text="user.name"
									item-value="id"
									@change="setFilterState('agentFilter', $event)"
				></v-select>
			</v-col>
			<v-col cols="12" md="2">
					<v-select outlined dense label="Activity Type"
										:items="activityTypes"
										item-text="name"
										item-value="id"
										background-color="white"
										@change="setFilterState('typeFilter', $event)"
					></v-select>
			</v-col>
			<v-col cols="12" md="2">
					<v-select outlined dense label="All Time"
										:items="periods"
										v-model="period"
										background-color="white"
										item-text="name"
										item-value="id"
										@change="setTimeFilter($event)"
					></v-select>
			</v-col>
			<v-col cols="12" md="2" v-if="showDatePicker">
				<v-date-picker
						range
						v-model="dates" no-title @input="birthdayDatePicker = false"
						@change="filterByDateRange($event)"
						width="300"
				></v-date-picker>
			</v-col>
			<v-col cols="12" md="2" class="justify-end d-flex">
				<v-btn text height="40" color="primary" @click="resetFilters">
					Clear Filters
				</v-btn>
			</v-col>
		</v-row>

		<v-data-table
				:headers="headers"
				:items="activityLogs"
				:page.sync="page"
				:items-per-page="paginationItemsPerPage"
				hide-default-footer
				class="elevation-1 table-proto"
				@page-count="pageCount = $event"
				:loading="isLoadingPagination"
		>
			<template v-slot:item.description="props">
				<b>{{ props.item.userable.user.name }}</b> {{ props.item.content }}
				<span v-if="props.item.beneficiary !== undefined && props.item.beneficiary !== null">
                for <a @click="setCurrentStudent(props.item.beneficiary)">{{ props.item.beneficiary.name }}</a>
              </span>
			</template>
			<template v-slot:item.created_at="props">
				{{ props.item.created_at | formatDate }}
			</template>
		</v-data-table>

		<Pagination state="Activitylogs"/>

		<v-dialog v-model="showStudentDialog" scrollable max-width="1111">
			<StudentPreview v-on:hideDialog="showStudentDialog=false" :existing-student="currentStudent"/>
		</v-dialog>
	</div>
</template>

<script>
import moment from "moment";
import StudentPreview from "@/components/Students/StudentPreview";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Pagination from "@/components/Pagination";

export default {
	name: 'AgentActivityDataTable',
	components: {Pagination, StudentPreview},
	data() {
		return {
			page: 1,
			pageCount: 0,
			headers: [
				{
					text: 'Description',
					align: 'start',
					value: 'description',
				},
				{text: 'Date/Time', value: 'created_at'}
			],
			dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
			showDatePicker: false,
			period: '',
			periods: [
				'All time',
				'Last 7 days',
				'Last 30 days',
				'Last 90 days',
				'This month',
				'Last month',
				'This year',
				'Last year',
				'Choose date range ...'
			],
			activityTypes: [
				'Login',
				'Note',
				'Event',
				'Referral',
				'Status'
			],
			showStudentDialog: false,
			currentStudent: {}
		}
	},
	watch: {
		user() {
			this.setState({key: 'agentTypeFilter', value: 'agent'})
			this.getPagination()
		}
	},
	computed: {
		...mapGetters('Activitylogs', {
			activityLogs: 'activityLogs',
			isLoadingPagination: 'isLoadingPagination',
			paginationItemsPerPage: 'paginationItemsPerPage'
		}),
		user() {
			return this.$store.getters.getUser;
		},
		agents() {
			return this.$store.getters.getActiveAgents
		},
		isLoadingAgents() {
			return this.$store.getters.getIsLoadingAgents;
		}
	},
	methods: {
		...mapMutations('Activitylogs', {
			setState: 'setState'
		}),
		...mapActions('Activitylogs', ['getPagination']),
		setFilterState(key, value) {
			this.setState({key, value})
			this.getPagination()
		},
		handleDeleteIconMouseOver(iconNumber) {
			this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
		},
		handleDeleteIconMouseLeave(iconNumber) {
			this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
		},
		resetFilters() {
			this.setState({key: 'agentFilter', value: null})
			this.setState({key: 'typeFilter', value: null})

			this.showDatePicker = false;
			this.period = 'All Time';
			this.dates = [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)];

			this.setState({key: 'timeFilter', value: this.dates})

			this.getPagination()
		},
		setTimeFilter(filter) {
			this.showDatePicker = false;
			if (filter === 'Choose date range ...') {
				this.showDatePicker = true;
				return
			}

			let timeFilter = []

			switch (filter) {
				case 'Last 7 days':
					timeFilter['end'] = moment().format('YYYY-MM-DD');
					timeFilter['start'] = moment().subtract(7, 'days').format('YYYY-MM-DD')
					break;
				case 'Last 30 days':
					timeFilter['end'] = moment().format('YYYY-MM-DD');
					timeFilter['start'] = moment().subtract(30, 'days').format('YYYY-MM-DD')
					break;
				case 'Last 90 days':
					timeFilter['end'] = moment().format('YYYY-MM-DD');
					timeFilter['start'] = moment().subtract(90, 'days').format('YYYY-MM-DD')
					break;
				case 'This month':
					timeFilter['end'] = moment().format('YYYY-MM-DD');
					timeFilter['start'] = moment().startOf('month').format('YYYY-MM-DD');
					break;
				case 'Last month':
					timeFilter['end'] = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
					timeFilter['start'] = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
					break;
				case 'This year':
					timeFilter['end'] = moment().endOf('year').format('YYYY-MM-DD');
					timeFilter['start'] = moment().startOf('year').format('YYYY-MM-DD');
					break;
				case 'Last year':
					timeFilter['end'] = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
					timeFilter['start'] = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
					break;
				default:
					break;
			}

			this.setState({key: 'timeFilter', value: timeFilter})

			this.getPagination()
		},
		setCurrentStudent(student) {
			this.currentStudent = student
			this.showStudentDialog = true
		},
		filterByDateRange(dates) {
			this.timeFilter['start'] = dates[0];
			this.timeFilter['end'] = dates[1];
			this.getPagination()
		}
	},
	filters: {
		formatDate(value) {
			if (value) {
				return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
			}
		}
	},
	beforeMount() {
		if (Object.keys(this.user).length === 0) return

		this.setState({key: 'agentTypeFilter', value: 'agent'})
		this.getPagination()
	}
}
</script>

<style scoped>
.a-filter {
	color: #2D76E3;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	text-decoration: none;
}
</style>
