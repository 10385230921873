<template>
    <v-list>
        <v-list-group
                :value="false"
                prepend-icon="mdi-information"
                v-for="(item, i) in defaultMessageCategories"
                :key="i"
        >
            <template v-slot:activator>
                <v-list-item-title>{{item.category}}</v-list-item-title>
            </template>

            <div v-for="(message, i) in item.default_messages" :key="i">
                <v-list-item link
                             dense
                             v-if="message.type ==='Note'"
                             @click="$emit('noteSelected', message.content)"
                >
                    <p v-text="message.label"></p>
                </v-list-item>
            </div>
        </v-list-group>
    </v-list>
</template>

<script>

    export default {
        name: "NoteList",
        props: ['defaultMessageCategories']
    }
</script>