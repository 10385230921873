<template>
    <div>
        <h2 class="pt-6 pb-1">Call Log</h2>
        <v-data-table
            :headers="headers"
            :items="callRecords.records"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="elevation-1 table-proto"
            @page-count="pageCount = $event"
            :loading="isLoadingCallRecords"
        >
            <template v-slot:item.type="props">
                <v-avatar v-if="props.item.result === 'Missed'">
                    <v-icon color="red">mdi-phone-remove</v-icon>
                </v-avatar>
                <v-avatar v-if="props.item.result !== 'Missed' && props.item.direction ==='Inbound'">
                    <v-icon color="primary">mdi-phone-incoming</v-icon>
                </v-avatar>
                <v-avatar v-if="props.item.result !== 'Missed' && props.item.direction ==='Outbound'">
                    <v-icon color="success">mdi-phone-outgoing</v-icon>
                </v-avatar>
            </template>
            <template v-slot:item.phone="props">
                <p v-if="props.item.direction === 'Inbound'">From: {{props.item.from.phoneNumber}}</p>
                <p v-if="props.item.direction === 'Outbound'">To: {{props.item.to.phoneNumber}}</p>
            </template>
            <template v-slot:item.dateTime="props">
                {{props.item.startTime | formatDate}}
            </template>
            <template v-slot:item.length="props">
                {{getTimeInSeconds(props.item.duration)}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import moment from 'moment'
    import StudentService from "../../services/student.service";

    export default {
        name: 'StudentRecords',
        props: ['student', 'shouldRefresh'],
        data () {
            return {
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                headers: [
                    {
                        text: 'Type',
                        align: 'start',
                        value: 'type',
                    },
                    { text: 'Phone Number', value: 'phone' },
                    { text: 'Date/Time', value: 'dateTime' },
                    { text: 'Action', value: 'action' },
                    { text: 'Result', value: 'result' },
                    { text: 'Length', value: 'length' },
                ],
                isLoadingCallRecords: false,
                pages: [5, 10, 15, 20, 25, 30],
                callRecords: []
            }
        },
        watch: {
            shouldRefresh() {
                this.getCallRecords();
            }
        },
        methods: {
            async getCallRecords() {
                this.isLoadingCallRecords = true;
                this.callRecords = await StudentService.getCallRecords(this.student.id, `?phoneNumber=${this.student.phone_number}`);
                this.isLoadingCallRecords = false;
            },
            getTimeInSeconds(time) {
                return moment.utc(Number(time) * 1000).format('HH:mm:ss');
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('l hh:mm A')
                }
            }
        },
        async beforeMount() {
            this.getCallRecords();
        }
    }
</script>

<style scoped>
    .a-filter {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
    }
</style>
