<template>
    <div :class="$vuetify.breakpoint.smAndDown ? 'video_div_mobile' : 'video_div'">
        <video v-if="!loadingVideo" fluid autoplay muted loop class="video" playsinline>
            <source class="full-width" :src="baseURL + currentVideo.url" type="video/mp4">
        </video>
    </div>
</template>

<script>
import SiteService from "../services/site.service";
export default {
    name: 'LiveImage',
    props: ['page'],
    data() {
        return {
            baseURL: process.env.VUE_APP_CMS_BASE_URL,
            loadingVideo: true,
            currentVideo: null,
        }
    },
    methods: {
        async getCMSVideos() {
            this.loadingVideo = true;
            let videos = await SiteService.getHomeVideos();
            this.videoCollection = videos.data[0].attributes["Videos"].data;
        },
        getRandomVideo() {
            let videosLength = this.videoCollection.length;
            let randomIndex = Math.floor(Math.random() * videosLength);
            this.currentVideo = this.videoCollection[randomIndex].attributes;
            this.loadingVideo = false;
        }
    },
    async beforeMount() {
        await this.getCMSVideos();
        this.getRandomVideo();
    },
}
</script>

<style scoped>

.video_div {
    display: block;
    margin: auto;
    background-color: rgb(33, 43, 56);
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    top: 0;
    left: 0;
}

.video_div_mobile {
    display: block;
    margin: auto;
    background-color: black;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.video {
  opacity: 1;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content_mobile {
  position: absolute;
  top: 5.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 330px;
}

</style>
