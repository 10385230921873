<template>
    <div>
        <h2>Sent Emails</h2>
        <v-row>
            <v-col cols="12" md="4">
              <v-menu
                  v-model="showDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined
                      append-icon="mdi-calendar"
                      dense
                      readonly
                      v-on="on"
                      :value="dates"
                  ></v-text-field>
                </template>
                <v-date-picker
                    range
                    no-title
                    v-model="dates"
                    @change="getStudentEmails"
                ></v-date-picker>
              </v-menu>
            </v-col>
        </v-row>

        <v-row class="container">
            <v-col cols="12" md="4" class="side-menu">
                <v-tabs>
                    <v-tab @click="tabType = 'general'">General</v-tab>
                    <v-tab @click="tabType = 'roommate'">Roommate</v-tab>
                </v-tabs>  
                <div v-if="isLoadingEmails">
                    <v-skeleton-loader
                            type="list-item-three-line"
                            class="pb-4"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="list-item-three-line"
                    ></v-skeleton-loader>
                </div>              
                <div v-else>  
                    <div v-if="tabType === 'general'">
                        <v-card v-for="(email, index) in generalEmails" :key="index" class="mb-1" @click="currentEmail = email.body">
                            <v-card-text>
                                <v-row>
                                    <v-col><h2>{{student.name}}</h2></v-col>
                                    <v-col><p>{{email.date | formatEmailDate}}</p></v-col>
                                </v-row>

                                <h4>{{email.subject}}</h4>
                                <p>Click to view email</p>
                            </v-card-text>
                        </v-card> 
                    </div>         
                    <div v-if="tabType === 'roommate'">
                        <v-card v-for="(email, index) in roommateEmails" :key="index" class="mb-1" @click="currentEmail = email.body">
                            <v-card-text>
                                <v-row>
                                    <v-col><h2>{{student.name}}</h2></v-col>
                                    <v-col><p>{{email.date | formatEmailDate}}</p></v-col>
                                </v-row>

                                <h4>{{email.subject}}</h4>
                                <p>Click to view email</p>
                            </v-card-text>
                    </v-card> 
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="8" class="main-menu">
                <v-skeleton-loader
                        type="table-heading, list-item-two-line, image, table-tfoot"
                        v-if="isLoadingEmails"
                ></v-skeleton-loader>
                <p v-html="currentEmail" v-else></p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from 'moment'
    import StudentService from "../../services/student.service";

    export default {
        name: 'StudentEmails',
        props: ['student', 'shouldRefresh'],
        data () {
            return {
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                headers: [
                    {
                        text: 'Type',
                        align: 'start',
                        value: 'type',
                    },
                    { text: 'Phone Number', value: 'phone' },
                    { text: 'Date/Time', value: 'dateTime' },
                    { text: 'Action', value: 'action' },
                    { text: 'Result', value: 'result' },
                    { text: 'Length', value: 'length' },
                ],
                dates: [],
                showDatePicker: false,
                isLoadingEmails: false,
                pages: [5, 10, 15, 20, 25, 30],
                emails: [],
                generalEmails: [],
                roommateEmails: [],
                currentEmail: '',
                tabType: 'general'
            }
        },
        watch: {
            shouldRefresh() {
                this.getStudentEmails();
            }
        },
        methods: {
            async getStudentEmails() {
                if (this.dates.length === 1) {
                  return
                }

                this.currentEmail    = '';
                this.emails = [];

                let params = '';
                if (this.dates.length > 1) {
                  params += `?dateFrom=${this.dates[0]}&dateTo=${this.dates[1]}`
                }

                this.showDatePicker = false;

                this.isLoadingEmails = true;
                this.emails = await StudentService.getStudentEmails(this.student.id, params);
                this.isLoadingEmails = false;

                if (this.emails.length > 0) {
                    this.currentEmail = this.emails[0].body;
                } else {
                  this.currentEmail = `<h4>No emails found for ${this.student.name} for the specified dates</h4>`
                }
            },
            getTimeInSeconds(time) {
                return moment.utc(Number(time) * 1000).format('HH:mm:ss');
            },       
            filterEmails() {
                this.emails.forEach(element => {
                    if (element.subject    === "Roommate Contact" 
                        || element.subject === "Roommate Matches"
                        || element.subject === "Roommate Connection"
                        || element.subject === "Invite Roommate"
                        || element.subject === "Roommate Request"
                    ) {
                        this.roommateEmails.push(element);
                    } else {
                        this.generalEmails.push(element);
                    }
                });               
            }
        },
        filters: {
            formatEmailDate (value) {
                if (value) {
                    return moment(String(value)).format('l')
                }
            }
        },
        async beforeMount() {

            //init date
            this.dates[0] = moment().subtract(6, 'month').format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')

            await this.getStudentEmails();
            this.filterEmails();
        }
    }
</script>

<style scoped>
    .container {
        background: #F4F5F6;
    }
    .side-menu {
        padding: 0 10px 0 0;
        height: 50vh;
        overflow-y: auto;
    }
    .main-menu {
        padding: 0;
        height: 50vh;
        overflow-y: auto;
    }
</style>
