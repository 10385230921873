<template>
    <div class="content">
        <v-row>
            <v-col cols="12">
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex'" class="justify-space-between">
                    <div class="d-flex">
                        <span class="pt-1 mr-2"><v-icon>mdi-calendar</v-icon></span>
                        <h2>{{ calendarTitle }}</h2>
                    </div>
                    <div :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex'">
                        <v-select outlined dense :items="types" v-model="type" @change="updateView"></v-select>
                        <v-text-field dense outlined readonly value="Today"
                                      prepend-icon="mdi-chevron-left"
                                      append-outer-icon="mdi-chevron-right"
                                      @click:prepend="getPreviousDate"
                                      @click:append-outer="getNextDate"
                                      class="mx-2 clickable"
                                      @click="getCurrentDate"
                        >
                        </v-text-field>

                        <v-select outlined
                                  dense
                                  :items="agents"
                                  item-text="user.name"
                                  item-value="id"
                                  v-model="selectedAgent"
                                  @change="updateView"
                        >
                        </v-select>
                    </div>
                </div>

                <div class="d-flex justify-space-between flex-wrap" v-if="$vuetify.breakpoint.mdAndUp">
                    <div>
                        <v-card id="moveInsCard" color="orange" width="204" height="112" max-width="204" max-height="112">
                            <v-card-text class="">
                                <h2 class="cardValueText">{{studentsMovedInCount}}</h2>
                                <p class="cardText">Student Move-ins</p>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div>
                        <v-card id="invoicedCard" color="primary"  width="204" height="112" max-width="204" max-height="112">
                            <v-card-text>
                                <h2 class="cardValueText">{{totalInvoices | price}}</h2>
                                <p class="cardText">Total Invoiced</p>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div>
                        <v-card id="fundsCard" color="purple"  width="204" height="112" max-width="204" max-height="112">
                            <v-card-text>
                                <h2 class="cardValueText">{{totalDonations | price}}</h2>
                                <p class="cardText">Funds raised</p>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div>
                        <v-card id="callsCard" color="success"  width="204" height="112" max-width="204" max-height="112">
                            <v-card-text>
                                <h2  class="cardValueText">{{totalCalls}}</h2>
                                <p class="cardText">Calls Made</p>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-col>
        </v-row>

        <div v-if="type === 'day'">
            <DashboardDayView
		            :events="events"
		            :day="dayName"
		            @allEventsCompleted="getEventsByDate"
            ></DashboardDayView>
        </div>

        <div v-else>
            <DashboardCalendar class="container"
                ref="dashboardCalendar"
                :type="type"
                :student-events="events"
                @viewDay="changeViewToDay"
            ></DashboardCalendar>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import { StudentService } from '../../services/student.service'
    import DashboardService from "../../services/dashboard.service";
    import BusinessService from "../../services/business.service";
    import InvoiceService from "../../services/invoice.service";
    import DashboardCalendar from "./DashboardCalendar";
    import DashboardDayView from "./DashboardDayView";

    export default {
        name: 'DashboardContent',
        components: {DashboardDayView, DashboardCalendar},
        data() {
            return {
                type: 'day',
                types: ['day', 'week','month'],
                selectedAgent: 0,
                showDateMenu: false,
                selectedDate: new Date().toISOString().substring(0, 10),
                startDate: new Date().toISOString().substring(0, 10),
                endDate: new Date().toISOString().substring(0, 10),
                toggleCalendarType: 2,
                isLoadingEvents: false,
                events: [],
                donations: [],
                users: [],
                callRecords: [],
                totalInvoices: 0
            }
        },
        watch: {
            agents(agents) {
                if (agents.length === 1) {
                    this.$store.dispatch('addDefaultAgent');
                }
            }
        },
        computed: {
            agents() {
                return this.$store.getters.getActiveAgents;
            },
            students() {
                return this.$store.getters.getStudents;
            },
            pendingMoveInStatusId() {
                const status = this.$store.getters.getStudentStatusByName('Active: Approved: Pending Move-In');
                return status ? status.id : null;
            },
            studentsMovedInCount() {
                if (!this.users || !this.pendingMoveInStatusId) return 0;
                return this.users.filter(student => student.status_id === this.pendingMoveInStatusId).length;
            },
            totalDonations() {
                if (!this.donations) {
                  return 0
                }

                return this.donations.reduce((acc, donation) => {
                    return acc + (Number(donation.amount || 0) / 100)
                }, 0);
            },
            totalCalls() {
                return this.callRecords && this.callRecords.records ? this.callRecords.records.length : 0
            },
            dayName() {
                const date = moment(this.selectedDate)
                if (moment().startOf('day').diff(date, 'days') === 0) {
                    return 'Today'
                }
                return date.format('dddd Do')
            },
            calendarTitle() {
                if (this.type === 'day') return moment(this.selectedDate).format('LL')
                if (this.type === 'month') return moment(this.selectedDate).format('MMMM, YYYY')
                if (this.type === 'week') {
                    const month = moment(this.selectedDate).format('MMMM')
                    const start = moment(this.selectedDate).startOf('week').format('Do')
                    const end = moment(this.selectedDate).endOf('week').format('Do')
                    return `${month}, ${start} - ${end}`
                }
                return ''
            }
        },
        methods: {
            async getEventsByDate() {
                this.isLoadingEvents = true;
                let events = await DashboardService.getEventsByDate(`?isComplete=0&startDate=${this.startDate}&endDate=${this.endDate}&appendUser`);

                if (this.selectedAgent > 0) {
                    this.events = events.filter(event => event.user.agent_id === this.selectedAgent)
                } else {
                    this.events = events
                }

                this.isLoadingEvents = false;
            },
            getPreviousDate() {
                if (this.type !== 'day') {
                    const focusDate = this.$refs.dashboardCalendar.prev()
                    this.selectedDate = moment(focusDate).format('YYYY-MM-DD')
                    this.updateView()
                    return
                }
                this.selectedDate = moment(this.selectedDate).subtract('1', 'd').format('YYYY-MM-DD')
                this.updateView()
            },
            getNextDate() {
                if (this.type !== 'day') {
                    const focusDate = this.$refs.dashboardCalendar.next()
                    this.selectedDate = moment(focusDate).format('YYYY-MM-DD')
                    this.updateView()
                    return
                }
                this.selectedDate = moment(this.selectedDate).add('1', 'd').format('YYYY-MM-DD')
                this.updateView()
            },
            getCurrentDate() {
                if (this.type !== 'day') {
                    const focusDate = this.$refs.dashboardCalendar.today()
                    this.selectedDate = moment(focusDate).format('YYYY-MM-DD')
                    this.updateView()
                    return
                }
                this.selectedDate = moment().format('YYYY-MM-DD')
                this.updateView()
            },
            getEventsForDay(date) {
                this.startDate = moment(date).format('YYYY-MM-DD')
                this.endDate = moment(date).endOf('day').format('YYYY-MM-DD')
                this.getEventsByDate()
            },
            getEventsForWeek(date) {
                this.startDate = moment(date).startOf('week').format('YYYY-MM-DD')
                this.endDate = moment(date).endOf('week').format('YYYY-MM-DD')
                this.getEventsByDate()
            },
            getEventsForMonth(date) {
                // We get the bounds for month +/- week, as we show the weeks surrounding the month in view
                this.startDate = moment(date).startOf('month').startOf('week').format('YYYY-MM-DD')
                this.endDate = moment(date).endOf('month').endOf('week').format('YYYY-MM-DD')
                this.getEventsByDate()
            },
            changeViewToDay(date) {
                this.selectedDate = date
                this.getEventsForDay(date)
                this.type = 'day'
            },
            updateView() {
                switch (this.type) {
                    case "day":
                        this.getEventsForDay(this.selectedDate)
                        break;
                    case "week":
                        this.getEventsForWeek(this.selectedDate)
                        break;
                    case "month":
                        this.getEventsForMonth(this.selectedDate)
                        break;
                }
            }
        },
        filters: {
            price(value) {
                const absValue = Math.abs(Number(value));

                if (absValue >= 1.0e+9) return `$${Math.round(absValue / 1.0e+9)}B`;
                else if (absValue >= 1.0e+6) return `$${Math.round(absValue / 1.0e+6)}M`;
                else if (absValue >= 1.0e+3) return `$${Math.round(absValue / 1.0e+3)}K`;
                else return `$${absValue}`;
            }
        },
        beforeMount: async function() {
            this.updateView();
            this.donations = await BusinessService.getDonations();
            this.users = await StudentService.getStudents(`?type=student&statusId=7`);
            this.callRecords = await BusinessService.getCallRecords();
            this.totalInvoices = await InvoiceService.getInvoiceSum();
            await this.$store.dispatch('getStudentStatuses');

            if (this.agents.length === 0) {
                return
            }

            this.$store.dispatch('addDefaultAgent');
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .side-menu {
        box-shadow: 0px 1px 40px rgba(255, 65, 65, 0.18), inset 0px 3px 0px #FF4141;
    }

    .container {
        height: 60vh;
        overflow: auto;
    }

    .v-application .clickable::v-deep .v-input__slot,
    .v-application .clickable::v-deep input {
        cursor: pointer;
    }

    .cardText {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        /* White */

        color: #FFFFFF;
    }

    .cardValueText {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        /* identical to box height */

        text-align: center;

        /* White */

        color: #FFFFFF;
    }

    #moveInsCard {
        background: linear-gradient(251.74deg, #FFD600 0%, rgba(255, 255, 255, 0) 100%), #FEA045;
        border-radius: 4px;
    }

    #invoicedCard {
        background: linear-gradient(251.74deg, rgba(11, 240, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #2D76E3;
        border-radius: 4px;
    }

    #fundsCard {
        background: linear-gradient(251.74deg, rgba(241, 1, 240, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #7854F6;
        border-radius: 4px;
    }

    #callsCard {
        background: linear-gradient(251.74deg, rgba(240, 255, 64, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #40B771;
        border-radius: 4px;
    }

</style>
