<template>
    <div>
        <v-card :loading="isLoadingPropertyUnits">
            <v-card-text v-if="isLoadingPropertyUnits">Loading Property Units</v-card-text>
            <v-row v-else>
                <v-col cols="12" md="3" class="side-menu">
                    <v-list dense nav class="py-4 side-menu">
                        <v-list-item two-line class="py-md-4">
                            <v-list-item-content>
                                <v-btn large color="primary" @click="addNewUnit">
                                    <v-icon left>mdi-plus</v-icon>
                                    Add Unit
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item-group>
                            <v-divider v-if="propertyUnits.length"></v-divider>

                            <v-list-item
                                    v-for="(unit, index) in propertyUnits"
                                    :key="index"
                                    link
                                    @click="currentPropertyUnitIndex = index"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ unit.number_of_bedrooms }}/{{ unit.number_of_baths }} - {{ unit.name }}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <p class="mt-1">{{propertyUnits[index].availabilities.length}}</p>

                                <v-list-item-avatar tile size="18" @click="deleteUnit(propertyUnits[index])">
                                    <v-img :src="require(`@/assets/icons/trash.svg`)"
                                           :ref="`unit-delete-icon-${index}`"
                                           @mouseover="handleDeleteIconMouseOver(`unit-delete-icon-${index}`)"
                                           @mouseleave="handleDeleteIconMouseLeave(`unit-delete-icon-${index}`)"
                                    ></v-img>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="12" md="9">
                    <v-card-subtitle v-if="!propertyUnits.length">No Property Units</v-card-subtitle>
                    <PropertyUnit :property-unit="propertyUnits[currentPropertyUnitIndex]"
                                  v-on:getPropertyUnits="getPropertyUnits"
                                  type="existing"
                                  v-else
                    />
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="showAddNewPropertyUnitDialog" max-width="1000">
            <PropertyUnit type="new" :property-unit="propertyUnit"
                          v-on:propertyUnitSaved="onPropertyUnitSaved"
                          v-on:hideDialog="showAddNewPropertyUnitDialog=false"
            />
        </v-dialog>
    </div>
</template>

<script>
    import {PropertyService} from "@/services/property.service";
    import PropertyUnit from "./PropertyUnit";
    import ActivityLogService from "@/services/acitivitylog.service";

    export default {
        name: 'PropertyUnits',
        props: ['property', 'source', 'isPropertySaved'],
        components: {
            PropertyUnit
        },
        data () {
            return {
                propertyUnits: [],
                propertyUnit: {},
                currentPropertyUnitIndex: 0,
                isLoadingPropertyUnits: false,
                showAddNewPropertyUnitDialog: false
            }
        },
        computed: {
            isAddNewPropertyState() {
                return this.$store.getters.getIsAddNewPropertyState;
            },
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            amenities() {
                return this.$store.getters.getAmenities;
            },
		        user() {
	              return this.$store.getters.getUser
		        }
        },
        methods: {
            addNewUnit() {
               this.initPropertyUnit();
                this.showAddNewPropertyUnitDialog = true;
            },
            getPropertyUnits: async function () {
                this.isLoadingPropertyUnits = true;
                this.propertyUnits = await PropertyService.getPropertyUnits({property_id: this.property.id});
                this.isLoadingPropertyUnits = false;
            },
            handleDeleteIconMouseOver(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash.svg`);
            },
            async deleteUnit(propertyUnit) {
                this.$store.dispatch('deletePropertyUnit', propertyUnit.id).then(() => {
                    this.currentPropertyUnitIndex = 0;
                    this.getPropertyUnits();
                });

	            //log activity
	            await ActivityLogService.addLog({
		            userable_id: this.user.agent.id,
		            userable_type: 'App\\Models\\Agent',
		            content: `Deleted property unit ${propertyUnit.name} of property ${propertyUnit.property.name}`,
		            type: 'property'
	            })
            },
            initPropertyUnit() {
                this.propertyUnit = {
                    property_id: this.property.id,
                    name: `PRU${this.propertyUnits.length + 1}`,
                    number_of_bedrooms: '',
                    number_of_baths: '',
                    number_of_roommates: '',
                    square_feet: '',
                    is_pets_allowed: '',
                    is_co_signer_needed: '',
                    price: '',
                    floor_plan_image_url: '',
                    availabilities: [],
                    amenities: [],
	                  property: this.property
                }
            },
            onPropertyUnitSaved() {
                this.initPropertyUnit();
                this.showAddNewPropertyUnitDialog = false;
                this.getPropertyUnits();
            }
        },
        beforeMount() {
            this.getPropertyUnits();
        }
    }
</script>

<style scoped>

    .side-menu {
        background: #F4F5F6;
    }
    .v-item--active {
        background: #FFFFFF;
    }
</style>
