import axios from 'axios'
import { TokenService } from '@/services/storage.service'
import router from '../router'
import  store  from '../store'

let axiosCms = null;
const CmsService = {
    init(baseURL) {
        axiosCms = axios.create({
            baseURL: baseURL
        });

        // set interceptor
        //this.initInterceptor();
    },

    setAuthorizationHeader() {
        axiosCms.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
    },

    removeHeader() {
        axiosCms.defaults.headers.common = {}
        axiosCms.defaults.headers.common["Authorization"] = ''
    },

    get(resource) {
        this.removeHeader()
        return axiosCms.get(resource)
    },

    post(resource, data) {
        return axiosCms.post(resource, data)
    },

    put(resource, data) {
        return axiosCms.put(resource, data)
    },

    delete(resource) {
        return axiosCms.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axiosCms(data)
    },

    initInterceptor() {
        axiosCms.interceptors.request.use(config => {
            return config;
        });
        axiosCms.interceptors.response.use(
            response => {
                if (response.status === 200 || response.status === 201) {
                    if (response.data.message) {
                        this.displayMessage(response.data.message, 'success');
                    }
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            },

            error => {
                if (error.response.status) {
                    switch (error.response.status) {
                        case 400:
                        case 401:
                        case 403:
                        case 404:
                        case 500: //intentional fallthrough
                            this.displayMessage(error.response.data.message, 'error');
                            break;
                        case 502:
                            setTimeout(() => {
                                router.replace({
                                    path: "/",
                                    query: {
                                        redirect: router.currentRoute.fullPath
                                    }
                                });
                            }, 1000);
                    }
                    return Promise.reject(error.response);
                }
            }
        )
    },

    displayMessage(message, type) {
        store.commit('completeFetchRequest');
        store.commit('setSnackbar', {
            show: true,
            message: message,
            color: type
        })
    },
}

export default CmsService
