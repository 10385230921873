<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <p>Contact Name</p>
                <v-text-field outlined dense v-model="contact.name"
                              :error-messages="nameErrors"
                              @input="$v.contact.name.$touch()"
                              @blur="$v.contact.name.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Type</p>
                <v-select dense outlined
                          v-model='contact.type'
                          :items="schoolContactTypes"
                          item-text="type"
                          item-value="id"
                          @change="contact.school_contact_type_id = $event"
                          :error-messages="typeErrors"
                          @input="$v.contact.type.$touch()"
                          @blur="$v.contact.type.$touch()"
                />
            </v-col>
            <v-col cols="12" md="2">
                <p>Email</p>
                <v-text-field outlined dense v-model="contact.email"
                              :error-messages="emailErrors"
                              @input="$v.contact.email.$touch()"
                              @blur="$v.contact.email.$touch()"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <p>Campuses</p>
              <v-combobox
                  v-model="contact.campuses"
                  :items="school.campuses"
                  :error-messages="campusErrors"
                  multiple
                  chips
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  @blur="$v.contact.campuses.$touch()"
              >
                <template v-slot:selection="data">
                  <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      close
                      @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar
                        class="accent white--text"
                        left
                        v-text="data.item.name.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="toggleCampuses"
                  >
                    <v-list-item-action>
                      <v-icon :color="contact.campuses.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="1" class="mt-md-6">
                <v-btn dense color="primary" @click="handleAddingSchoolContact" :loading="isAddingNewContact">
                    <v-icon left>mdi-plus</v-icon>
                    Add
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="schoolContacts"
            :items-per-page="itemsPerPage"
            class="elevation-1"
            :loading="isCallingApi"
        >
            <template v-slot:item.name="props">
                <v-edit-dialog
                    :return-value.sync="props.item.name"
                > {{ props.item.name }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="props.item.name"
                            label="Edit"
                            single-line
                            counter
                            @change="updateContact(props.item.id, 'name', props.item.name)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.phone_number="props">
                <v-edit-dialog
                    :return-value.sync="props.item.phone_number"
                > {{ props.item.phone_number }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="props.item.phone_number"
                            label="Edit"
                            single-line
                            counter
                            @change="updateContact(props.item.id, 'phone_number', props.item.phone_number)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.mobile_number="props">
                <v-edit-dialog
                    :return-value.sync="props.item.mobile_number"
                > {{ props.item.mobile_number }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="props.item.mobile_number"
                            label="Edit"
                            single-line
                            counter
                            @change="updateContact(props.item.id, 'mobile_number', props.item.mobile_number)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.email="props">
                <v-edit-dialog
                    :return-value.sync="props.item.email"
                > {{ props.item.email }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="props.item.email"
                            label="Edit"
                            single-line
                            counter
                            @change="updateContact(props.item.id, 'email', props.item.email)"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:item.login="props">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="sendLogin(props.item.id)">
                            <v-icon size="19">mdi-login</v-icon>
                        </v-btn>
                    </template>
                    <span>Send Login</span>
                </v-tooltip>
            </template>
            <template v-slot:item.activity="props">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="showActivities(props.item)">
                            <v-icon size="19">mdi-view-list</v-icon>
                        </v-btn>
                    </template>
                    <span>Show Activity</span>
                </v-tooltip>
            </template>
            <template v-slot:item.campuses="props">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="showCampuses(props.item)">
                            <v-icon size="19">mdi-school</v-icon>
                        </v-btn>
                    </template>
                    <span>Show Campuses</span>
                </v-tooltip>
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="showNotification(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
        </v-data-table>

        <v-dialog width="500" v-model="showActivityDialog">
            <v-card>
                <v-card-title class="d-flex justify-space-between mb-4">
                    Activities
                    <v-hover v-slot:default="{hover}">
                        <v-btn icon
                               :color="hover? 'primary': ''"
                               :elevation="hover ? 12: 0"
                               @click="showActivityDialog=false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-hover>
                </v-card-title>
                <v-card-text>
                    <div v-if="currentContact.logs && currentContact.logs.length > 0">
                        <v-list-item two-line v-for="item in currentContact.logs" :key="item.id" style="border-top: #C4C4C4 1px solid">
                            <v-list-item-content>
                                <v-list-item-title v-if="item.type === 'view'">
                                    Viewed
                                    <span v-if="! item.beneficiary" class="red--text">[Deleted User's]</span>
                                    <a v-if="item.beneficiary" style="text-decoration: none" @click.prevent="showStudent(item.beneficiary)">
                                        {{item.beneficiary.name}}'s
                                    </a>
                                    profile
                                </v-list-item-title>
                                <v-list-item-title v-else>{{item.content}}</v-list-item-title>
                                <v-list-item-subtitle>{{formateDate(item.created_at)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <div v-else>
                        No activities
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="500" v-model="showCampusDialog">
            <v-card>
                <v-card-title class="d-flex justify-space-between mb-4">
                    Campuses
                    <v-hover v-slot:default="{hover}">
                        <v-btn icon
                               :color="hover? 'primary': ''"
                               :elevation="hover ? 12: 0"
                               @click="showCampusDialog=false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-hover>
                </v-card-title>
                <v-card-text>
                  <p>Campuses</p>
                  <v-combobox
                      v-model="currentContact.campuses"
                      :items="school.campuses"
                      :error-messages="campusErrors"
                      multiple
                      chips
                      outlined
                      dense
                      item-text="name"
                      item-value="id"
                      @change="updateCampuses($event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          close
                          @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.name.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>

                    <template v-slot:prepend-item>
                      <v-list-item
                          ripple
                          @click="toggleCampuses"
                      >
                        <v-list-item-action>
                          <v-icon :color="contact.campuses.length > 0 ? 'indigo darken-4' : ''">
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Select All
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-combobox>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete a school contact."
                          v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteSchoolContact"/>
        </v-dialog>

        <v-dialog v-model="showStudentDialog" scrollable max-width="1111">
            <StudentPreview v-on:hideDialog="showStudentDialog=false" :existing-student="currentStudent"/>
        </v-dialog>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import SchoolService from "../../services/school.service";
import Notification from "../Shared/Notification";
import moment from 'moment'
import StudentPreview from "@/components/Students/StudentPreview";

export default {
    name: 'SchoolContacts',
    components: {StudentPreview, Notification},
    validations: {
        contact: {
            name: { required },
            type: { required },
            email: { required, email },
            campuses: { required },
        }
    },
    props: {
      school: {
        type: Object,
        required: true
      }
    },
    data () {
        return {
            itemsPerPage: 5,
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name'
                },
                { text: 'Type', value: 'type'},
                { text: 'Email', value: 'email' },
                { text: '', value: 'login' },
                { text: '', value: 'activity' },
                { text: '', value: 'campuses' },
                { text: '', value: 'delete' },
            ],
            contact: {
              school_id: this.school.id,
              name: '',
              type: '',
              school_contact_type_id: '',
              email: '',
              campuses: []
            },
            schoolContacts: [],
            contactTypes: [],
            isAddingNewContact: false,
            isCallingApi: false,
            mainPhoneNumber: null,
            mobilePhoneNumber: null,
            showActivityDialog: false,
            showNotificationDialog: false,
            currentDeleteCandidateId: null,
            currentContact: {},
            currentStudent: {},
            showStudentDialog: false,
            showCampusDialog: false
        }
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.contact.name.$dirty) return errors
            !this.$v.contact.name.required && errors.push('Contact name is required')
            return errors
        },
        typeErrors () {
            const errors = []
            if (!this.$v.contact.type.$dirty) return errors
            !this.$v.contact.type.required && errors.push('Contact type is required')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.contact.email.$dirty) return errors
            !this.$v.contact.email.email && errors.push('A valid email is required')
            !this.$v.contact.email.required && errors.push('Contact email is required')
            return errors
        },
        campusErrors () {
            const errors = []
            if (!this.$v.contact.campuses.$dirty) return errors
            !this.$v.contact.campuses.required && errors.push('One or more campus selection is required')
            return errors
        },
        schoolContactTypes() {
            return this.$store.getters.getSchoolContactTypes;
        },
        icon () {
          if (this.selectedAllSchools) return 'mdi-close-box'
          if (this.selectedSomeSchools) return 'mdi-minus-box'
          return 'mdi-checkbox-blank-outline'
        },
        selectedAllCampuses() {
          return this.contact.campuses.length === this.school.campuses.length
        },
    },
    methods: {
        init() {
            this.contact = {
                school_id: this.school.id,
                name: '',
                type: '',
                school_contact_type_id: '',
                email: '',
                campuses: []
            };

            this.contact.campuses = []
        },
        handleDeleteIconMouseOver(iconNumber) {
            this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
        },
        handleDeleteIconMouseLeave(iconNumber) {
            this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
        },
        async handleAddingSchoolContact() {
          try {
            this.validateAddSchoolContactForm()
            const schoolContact = await this.addSchoolContact()
            const schoolContactWithCampuses = await this.addSchoolContactCampuses(schoolContact)
            this.updateSchoolContacts(schoolContactWithCampuses)
            this.reset()
          } catch (e) {
            this.$store.commit('setSnackbar', {
              show: true,
              message: e.message,
              color: 'red'
            });
          }
        },
        validateAddSchoolContactForm() {
          this.$v.contact.$touch();
          if (this.$v.contact.$invalid) {
            throw new Error("One or more mandatory field(s) are missing. Please look through the contact form " +
                "and fix the field(s) highlighted in red")
          }
        },
        addSchoolContact: async function() {
            this.isAddingNewContact = true;
            const schoolContact = await SchoolService.addSchoolContact({contact: this.contact}).catch(() => {
                // Handle error. Without the exception handling here, the fail to save hangs the form.
            });
            this.isAddingNewContact = false;

            return schoolContact

        },
        async addSchoolContactCampuses(schoolContact) {
            schoolContact.campuses = []
            for await (const campus of this.contact.campuses) {
              const schoolContactCampus = await SchoolService.addSchoolContactCampuses({
                school_contacts_id: schoolContact.id,
                campus_id: campus.id
              })

              schoolContact.campuses.push(schoolContactCampus)
            }

            return schoolContact
        },
        updateSchoolContacts(contact) {
          this.schoolContacts.unshift(contact)
        },
        reset() {
          this.init()
          this.$v.$reset()
        },
        deleteSchoolContact: async function() {
            this.showNotificationDialog = false;
            this.isCallingApi = true;
            await SchoolService.deleteSchoolContact(this.currentDeleteCandidateId);
            this.getSchoolContacts();
        },
        getSchoolContacts: async function() {
            this.isCallingApi = true;
            this.schoolContacts = await SchoolService.getSchoolContacts(this.school.id, '?sort=desc');
            this.isCallingApi = false;
        },
        async updateContact(id, field, value) {
            this.isCallingApi = true;
            await SchoolService.updateSchoolContact(id, {[field]: value}).catch(() => {
                // Handle error. Without the exception handling here, the fail to save hangs the form.
            });

            this.getSchoolContacts();
            this.isCallingApi = false;
        },
        showNotification(contactId) {
            this.currentDeleteCandidateId = contactId;
            this.showNotificationDialog = true;
        },
        sendLogin(contactId) {
            SchoolService.sendLoginCredentials(contactId).then(() => {
                this.isCallingApi = false;
                this.getSchoolContacts();
            })
        },
        showActivities(contact) {
            this.currentContact = contact
            this.showActivityDialog = true
        },
        showCampuses(contact) {
            this.currentContact = contact
            this.showCampusDialog = true
        },
        formateDate(date) {
            return moment(date).format('l')
        },
        showStudent(student) {
            if (!student) {
                return
            }
            this.currentStudent = student
            this.showStudentDialog = true
        },
        toggleCampuses() {
          this.$nextTick(() => {
            if (this.selectedAllCampuses) {
              this.contact.campuses = []
            } else {
              this.contact.campuses = this.school.campuses.slice()
            }
          })
        },
        async updateCampuses(campuses) {
          await SchoolService.updateSchoolContactCampuses(this.currentContact.id, {campuses})
          this.$store.commit('setSnackbar', {
            show: true,
            message: 'School contact campuses updated!',
            color: 'success',
            timeout: 4000
          });
        }
    },

    beforeMount: async function() {
        this.init();
        this.getSchoolContacts();
        this.$store.dispatch('getSchoolContactTypes');
    }
}
</script>
