<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col>
                <v-row class="justify-start">
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Schools</h2>
                      <v-btn depressed color="primary" @click="showNewSchoolDialog=true" class="text-capitalize font-weight-bold">
                        <v-icon left>mdi-plus</v-icon>
                        Add New
                      </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <SchoolDataTable />
            </v-col>
        </v-row>

      <v-dialog v-model="showNewSchoolDialog" scrollable max-width="1111">
          <NewSchool @hideDialog="showNewSchoolDialog=false"></NewSchool>
      </v-dialog>
    </div>
</template>

<script>
    import SchoolDataTable from "../components/Schools/SchoolDataTable";
    import NewSchool from "@/components/Schools/NewSchool.vue";

    export default {
        name: 'Schools',
        components : {
          NewSchool,
          SchoolDataTable
        },
        data() {
          return {
            showNewSchoolDialog: false
          }
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
