import ApiService from './api.service'

const PropertyService = {
    getProperties: async function(params = '') {
       const response = await ApiService.get(`properties${params}`);
       return response.data.data;
    },
    getProperty: async function(propertyId, params = '') {
        const response = await ApiService.get(`property/${propertyId}?${params}`);
        return response.data.data;
     },
    
    findProperty: async function(params = '') {
       const response = await ApiService.get(`property-find?${params}`);
       return response.data.data;
    },
    addNewProperty: async function(data) {
        const response = await ApiService.post('property', data);
        return response.data
    },
    addPropertyUnit: async function(propertyId, data) {
        const response = await ApiService.post('property-unit', data);
        return response.data.data
    },
    addPropertyUnitImage: async function(propertyUnitId, data) {
        const response = await ApiService.post(`property-unit/${propertyUnitId}/add-floor-plan-image`, data);
        return response.data
    },
    getPropertyUnits: async function(data) {
        const response = await ApiService.get(`property/${data.property_id}/property-units`);
        return response.data
    },
    updatePropertyUnits: async function(propertyId, data) {
        await ApiService.post(`property/${propertyId}/update-property-units`, data);
    },
    updatePropertyUnit: async function(propertyUnitId, data) {
        await ApiService.post(`property-unit/${propertyUnitId}`, data);
    },
    updatePropertyUnitFloorPlanImage: async function(propertyUnitId, data) {
        await ApiService.post(`property-unit/${propertyUnitId}/update-floor-plan-image`, data);
    },
    deletePropertyUnitFloorPlanImage: async function(propertyUnitId) {
        await ApiService.delete(`property-unit/${propertyUnitId}/delete-floor-plan-image`);
    },
    updatePropertyUnitAmenities: async function(propertyUnitId, data) {
        await ApiService.post(`property-unit/${propertyUnitId}/update-amenities`, data);
    },
    addPropertyUnitAvailability: async function(propertyUnitId, data) {
        await ApiService.post(`property-unit/${propertyUnitId}/add-availability`, data);
    },
    addNewPropertyImages: async function(data) {
        const response = await ApiService.post('property-images', data);
        return response.data
    },
    addNewPropertyImage: async function(propertyId, data) {
        await ApiService.post(`property/${propertyId}/store-image`, data);
    },
    getPropertyImages: async function(propertyId) {
        const response = await ApiService.get(`property/${propertyId}/images`);
        return response.data
    },
    deletePropertyImage: async function(propertyImageId) {
        const response = await ApiService.delete(`property-image/${propertyImageId}`);
        return response.data
    },
    updatePropertyImageOrder: async function(propertyId, data) {
        await ApiService.post(`property/${propertyId}/update-property-image-order`, data);
    },
    getPropertyUnitImages: async function(propertyUnitId) {
        const response = await ApiService.get(`property-unit/${propertyUnitId}/images`);
        return response.data
    },
    getPropertyAmenities: async function() {
        const response = await ApiService.get('property/amenities');
        return response.data;
    },
    getSelectedPropertyAmenities: async function(propertyId) {
        const response = await ApiService.get(`property/${propertyId}/amenities`);
        return response.data;
    },
    updatePropertyAmenities: async function(propertyId, data) {
        await ApiService.post(`property/${propertyId}/update-amenities`, data);
    },
    deleteProperty: async function(propertyId) {
        await ApiService.delete('property/' + propertyId);
    },
    updateProperty: async function(data) {
        const response = await ApiService.post(`property/${data.propertyId}/update`, data.data);
        return response.data;
    },
    deletePropertyUnit: async function(propertyUnitId) {
        const response = await ApiService.delete(`property-unit/${propertyUnitId}`);
        return response.data;
    },
    deletePropertyUnitAvailability: async function(availabilityId) {
        const response = await ApiService.delete(`property-unit/availability/${availabilityId}`);
        return response.data;
    },
    addNewPropertyRentSpecial: async function(data) {
        const response = await ApiService.post(`property/${data.propertyId}/add-rent-special`, data.special);
        return response.data;
    },
    getPropertyRentSpecials: async function(propertyId, params ='') {
        const response = await ApiService.get(`property/${propertyId}/rent-specials${params}`);
        return response.data.data;
    },
    deletePropertyRentSpecial: async function(propertyRentSpecialId) {
        const response = await ApiService.delete(`property-rent-special/${propertyRentSpecialId}`);
        return response.data;
    },
    getPropertyCampuses: async function(propertyId) {
        const response = await ApiService.get(`property/${propertyId}/campuses`);
        return response.data;
    },
    updatePropertyCampuses: async function(propertyId, data) {
        const response = await ApiService.post(`property/${propertyId}/update-property-campuses`, data);
        return response.data;
    },
    getContactTypes: async function() {
        return await ApiService.get(`contact-types`);
    },
    addPropertyContact: async function(data) {
        return await ApiService.post(`property/${data.propertyId}/add-contact`, {contact: data.contact});
    },
    getPropertyContacts: async function(propertyId) {
        const response = await ApiService.get(`property/${propertyId}/contacts`);
        return response.data;
    },
    deletePropertyContact: async function(propertyId) {
        const response = await ApiService.delete(`property-contact/${propertyId}`);
        return response.data;
    },
    updatePropertyContact: async function(propertyContactId, data) {
        const response = await ApiService.post(`property-contact/${propertyContactId}`, data);
        return response.data;
    },
    addPropertyDocument: async function(data) {
        const response = await ApiService.post(`property/${data.propertyId}/add-document`, data.file);
        return response.data;
    },
    getPropertyDocuments: async function(propertyId) {
        const response = await ApiService.get(`property/${propertyId}/documents`);
        return response.data;
    },
    deletePropertyDocument: async function(propertyDocumentId) {
        const response = await ApiService.delete(`property-document/${propertyDocumentId}`);
        return response.data;
    },
    getReferrals: async function(propertyId, params) {
        const response = await ApiService.get(`property/${propertyId}/referrals${params}`);
        return response.data.data;
    },
    deleteReferredProperty: async function(referredPropertyId) {
        return await ApiService.delete(`referred-property/${referredPropertyId}`);
    },
    getPropertyCount: async function(params = '') {
        const response = await ApiService.get(`property-count${params}`);
        return response.data.data
    },
}

export default PropertyService

export { PropertyService }
