<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                  outlined
                  dense
                  placeholder="Search by City or Property name"
                  v-model="search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Status"
                          :items="statuses"
                          v-model="statusFilter"
                          @change="setFilter('setPropertyStatusFilter', $event)"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="State"
                          :items="states"
                          item-value="id"
                          item-text="name"
                          v-model="stateFilter"
                          @change="setFilter('setStateFilter', $event)"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense
                          label="Available"
                          :items="available"
                          v-model="availableFilter"
                          @change="setFilter('setAvailableFilter', $event)"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select outlined dense label="Campus"
                          :items="sortedCampuses"
                          item-text="name"
                          item-value="id"
                          v-model="campusFilter"
                          @change="setFilter('setCampusFilter', $event)"
                ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="justify-end d-flex">
                <v-btn text height="40" color="primary" @click="resetFilters">
                    Clear Filters
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :search="search"
            :headers="$vuetify.breakpoint.smAndDown ? mobile_headers:headers"
            :items="properties"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :loading="isLoadingProperties || isDeletingProperty"
        >
            <template v-slot:item.name="props">
                <PropertyDialog action="show-btn" :actionText="props.item.pending ? `${props.item.name} (New)` : props.item.name" :existing-property="props.item"/>
            </template>
            <template v-slot:item.website="props">
                <div class="pl-5">
                    <v-avatar tile size="14" @click="showExternalPropertyPage(props.item.website)">
                        <v-img :src="require(`@/assets/icons/external-link.svg`)"></v-img>
                    </v-avatar>
                </div>
            </template>
            <template v-slot:item.state_id="props">
                {{getStateName(props.item.state_id)}}
            </template>
            <template v-slot:item.is_active="props">
                {{ props.item.is_active ? 'Active' : 'Inactive' }}
            </template>
            <template v-slot:item.available="props">
                <v-row>
                    <v-col cols="12" md="2">
                        <span v-if="isAvailable(props.item.property_units)" style="color: #40B771"> Yes </span>
                        <span v-else style="color: #FF4141"> No </span>
                    </v-col>
                    <v-col cols="12" md="1" >
                        <PropertyUnitAvailabilityDialog
                            :property="props.item"
                            v-on:getProperties="getProperties"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="props">
                {{ props.item.created_at | formatDate }}
            </template>
            <template v-slot:item.delete="props">
	            <div v-if="user.agent.is_admin_agent">
		            <v-avatar tile width="16"
		                      height="18"
		                      min-width="16"
		                      min-height="18"
		                      @click="showNotification(props.item.id)"

		            >
			            <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
			                   @mouseover="handleDeleteIconMouseOver(props.item.id)"
			                   @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
			            ></v-img>
		            </v-avatar>
	            </div>

            </template>
            <template v-slot:item.nav="props">
                <PropertyDialog action="show" :existing-property="props.item"/>
            </template>
        </v-data-table>
        <v-row class="mt-3">
            <v-col cols="12" md="6">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start pt-md-5 ml-1'" v-if="!isLoadingProperties">
                    Showing {{(currentPage * itemsPerPage) - itemsPerPage + 1}} to {{itemsPerPage * currentPage}} of {{totalProperties}} {{isFiltered ? `Filtered from ${totalPropertiesNoFilter}` : ''}}
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'" v-if="!isLoadingProperties">
                    <v-col cols="12" class="text-right">
                        <v-row>
                            <v-col cols="12" md="1" class="text-sm-left pt-md-5">Show</v-col>
                            <v-col cols="12" md="2">
                                <v-select :items="pages" dense
                                        outlined
                                        @change="updatePageEntries(parseInt($event, 10))"
                                        :value="itemsPerPage"
                                >show </v-select>
                            </v-col>
                            <v-col cols="12" md="1" class="pt-md-5  text-left">Entries</v-col>
                            <v-col cols="12" md="7">
                                <Paginate state="Properties" collection="properties" list="getProperties" current-page-setter="setPropertyCurrentPage"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog width="500" v-model="showNotificationDialog">
            <Notification message="You are about to delete this property."
                          v-on:hideDialog="showNotificationDialog=false"
                          v-on:continue="deleteProperty"
            />
        </v-dialog>

      <v-dialog v-model="showPropertyDialog" scrollable max-width="1111" v-if="showPropertyDialog">
        <Property :property="currentProperty"
                  :dialog="showPropertyDialog"
                  v-on:onHideDialog="showPropertyDialog=false"
        />
      </v-dialog>

    </div>
</template>

<script>
    import moment from 'moment'
    import PropertyDialog from "./PropertyDialog";
    import PropertyUnitAvailabilityDialog from "./PropertyUnitAvailabilityDialog";
    import PropertyService from "../../services/property.service";
    import Notification from "../Shared/Notification";
    import {alphabeticalSort, displayMessage, setFilter} from "../../utils/utils";
    import Paginate from "../Paginate";
    import Property from "@/views/Property";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'PropertyDataTable',
        components: {
          Property,
            Paginate,
            Notification,
            PropertyDialog,
            PropertyUnitAvailabilityDialog
        },
        data () {
            return {
                search: '',
                page: 1,
                pageCount: 0,
                headers: [
                    {
                        text: 'Property Name',
                        align: 'start',
                        value: 'name',
                        class: 'dt-header'
                    },
                    { text: 'Website', value: 'website' },
                    { text: 'State', value: 'state_id' },
                    { text: 'Status', value: 'is_active' },
                    { text: 'Available', value: 'available' },
                    { text: 'Created On', value: 'created_at' },
                    { text: '', value: 'delete' },
                    { text: '', value: 'nav' },
                ],
                mobile_headers: [
                    {
                        text: 'Property Name',
                        align: 'start',
                        value: 'name',
                        class: 'dt-header'
                    },
                    { text: 'Website', value: 'website' },
                    { text: 'State', value: 'state_id' },
                    { text: 'Status', value: 'is_active' },
                    { text: '', value: 'spacer' },
                ],

                statuses: ['Active', 'Inactive'],
                locations: [],
                available: ['Yes', 'No'],
                statusFilter: '',
                stateFilter: '',
                availableFilter: '',
                campusFilter: '',
                currentPropertyId: null,
                pages: [5, 10, 15, 20, 25, 30],
                isDeletingProperty: false,
                showNotificationDialog: false,
                sortedCampuses: [],

                currentProperty: {},
                showPropertyDialog: false
            }
        },
        watch: {
          search(val) {
            if (val === '') return
            this.debouncedSearch(val)
          },
        },
        computed: {
            ...mapGetters('Campuses', {
              campuses: 'campuses'
            }),
            properties() {
                return this.$store.getters.getProperties
            },
            isLoadingProperties() {
                return this.$store.getters.getIsLoadingProperties
            },
            states() {
                return this.$store.getters.getStates;
            },
            hasDeletePermissions() {
                return this.$store.getters.userHasPermission('properties', 'delete');
            },
            itemsPerPage() {
                return this.$store.getters.getPropertiesPerPage;
            },
            totalProperties() {
                return this.$store.getters.getPropertiesTotal;
            },
            totalPropertiesNoFilter() {
                return this.$store.getters.getPropertiesTotalNoFilter
            },
            user() {
                return this.$store.getters.getUser;
            },
            currentPage() {
                return this.$store.getters.getPropertiesCurrentPage;
            },
            isFiltered() {
                return this.$store.getters.getPropertiesIsFiltered
            },
        },
        methods: {
            ...mapActions('Campuses', {
              getCampuses: 'getCampuses'
            }),
            showExternalPropertyPage(website) {
                if (website === '') {
                    return
                }

                window.open(website,'_blank');
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deleteProperty: async function() {
                this.showNotificationDialog = false;
                this.isDeletingProperty = true;
                await PropertyService.deleteProperty(this.currentPropertyId);
                this.isDeletingProperty = false;

                this.getProperties();
            },
            getProperties() {
                this.$store.dispatch('getProperties');
            },
            resetFilters() {
                this.search = '';
                this.statusFilter = '';
                this.stateFilter = '';
                this.availableFilter = '';
                this.campusFilter = '';

                this.$store.commit('setPropertyStatusFilter', null);
                this.$store.commit('setStateFilter', null);
                this.$store.commit('setAvailableFilter', null);
                this.$store.commit('setCampusFilter', null);
                this.$store.commit('setPropertySearchFilter', null);

                this.getProperties();
            },
            showNotification(propertyId) {
                if (!this.hasDeletePermissions) {
                    displayMessage('error', 'You do not have permission to delete a property')
                    return;
                }
                this.currentPropertyId = propertyId;
                this.showNotificationDialog = true;
            },
            isAvailable(propertyUnits) {
                return propertyUnits.filter(propertyUnit => {
                    if (propertyUnit.property_unit_availabilities === undefined) {
                        return false
                    }
                    if (propertyUnit.property_unit_availabilities.length === 0) {
                        return false
                    }
                    return propertyUnit.property_unit_availabilities.filter(propertyUnitAvailability => {
                        if (propertyUnitAvailability.number_of_units === 0) {
                            return false;
                        }
                        if (moment(propertyUnitAvailability.available_date).isAfter(moment())) {
                            return false;
                        }
                        return true
                    }).length > 0
                }).length > 0;
            },
            getStateName(stateId) {
                return this.states.filter(state => {
                    return Number(state.id) === Number(stateId)
                }).map(state => {
                    return state.name
                }).toString();
            },
            setFilter(type, value) {
                this.$store.commit('setPropertyCurrentPage', 1)
                setFilter(type, value, 'getProperties');
            },
            updatePageEntries(count) {
                this.$store.commit('setPropertiesPerPage', count);
                this.$store.commit('setPropertyCurrentPage', 1);
                this.$store.dispatch('getProperties')
            },
            debouncedSearch(userInput) {
              // cancel pending call
              clearTimeout(this._timerId)

              // delay new call 500ms
              this._timerId = setTimeout(() => {
                this.searchApi(userInput)
              }, 500)
            },
            searchApi: async function (query) {
              this.setFilter('setPropertySearchFilter', query)
            },
            sortCampuses() {
              this.sortedCampuses = this.campuses.sort(alphabeticalSort())
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
                }
            }
        },
        beforeMount: async function() {
          if (this.campuses.length === 0) {
            await this.getCampuses()
          }

          this.sortCampuses()
          this.getProperties();
        }
    }
</script>
