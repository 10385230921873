<template>
    <v-dialog v-model="dialog" scrollable max-width="1111">
        <template v-slot:activator="{ on }">
            <!-- <v-btn depressed color="primary" v-on="on" v-if="action === 'add'" @click="updateState('add')" class="text-capitalize font-weight-bold">
                <v-icon left>mdi-plus</v-icon>
                Add New
            </v-btn> -->
            <v-avatar tile size="10" v-on="on" v-if="action === 'show'" @click="updateState('show')">
                <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
            </v-avatar>
            <a v-on="on" v-if="action === 'show-btn'" class="property-name-field" @click="updateState('show')">
                {{actionText}}
            </a>
        </template>

        <RentSpecial :rent-special="rentSpecial" v-on:hideDialog="hideDialog"/>
    </v-dialog>
</template>

<script>
    import RentSpecial from "./RentSpecial";

    export default {
        name: 'RentSpecialDialog',
        props: ['action', 'existingSpecial', 'actionText'],
        components: {RentSpecial},
        data() {
            return {
                dialog: false,
                rentSpecial: {}
            }
        },
        methods: {
            initRentSpecial() {
               this.rentSpecial = {
                    business_id: this.$store.getters.getBusinessId,
                    label: '',
                    amount: '',
                    content: ''
                }
            },
            updateState(state) {
                if (state === 'add') {
                    this.$store.commit('setIsAddNewRentSpecialState', true)
                } else {
                    this.$store.commit('setIsAddNewRentSpecialState', false)
                }
            },
            hideDialog() {
                if (this.$store.getters.getIsAddNewRentSpecial) {
                    this.initRentSpecial();
                }

                this.dialog = false;
            }
        },
        beforeMount() {
            if (this.action !== 'add') {
                this.rentSpecial = this.existingSpecial;
                return
            }

            this.initRentSpecial();
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }

    .content {
        padding-left: 250px
    }
</style>
