<template>
    <div>
        <div>
            <h3>{{title}}</h3>
            <v-card-subtitle class="py-2 pl-0">
                <v-icon color="info">mdi-information</v-icon>
                Click on the input to display a dropdown. If no dropdown, then the address is incorrect
            </v-card-subtitle>
            <label>
                <v-row>
                    <v-col cols="4">
                        <gmap-autocomplete @place_changed="setPlace" :options="{fields: ['geometry', 'formatted_address', 'place_id']}">
                            <template v-slot:input="slotProps">
                                <v-text-field outlined
                                              dense
                                              prepend-inner-icon="mdi-map-marker"
                                              :placeholder="pHolder"
                                              :value="address"
                                              ref="input"
                                              v-on:listeners="slotProps.listeners"
                                              v-on:attrs="slotProps.attrs">
                                </v-text-field>
                            </template>
                        </gmap-autocomplete>
                    </v-col>
                    <v-col class="px-0 pt-4">
                        <v-btn @click="addMarker"
                               color="primary"
                               style="width: 100px"
                               class="mr-2"
                               :loading="isLoading"
                        >Add</v-btn>
                        <v-btn
                            v-if="showRemoveButton"
                            color="primary"
                            :disabled="markers.length === 0"
                            @click="removeMarker"
                        >Remove</v-btn>
                    </v-col>
                </v-row>
            </label>
        </div>
        <gmap-map
                :center="center"
                :zoom="12"
                style="width:100%;  height: 250px;"
        >
            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                {{infoContent}}
            </GmapInfoWindow>

            <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @mouseover="toggleInfoWindow(m,i)"
                    :icon="{url: require(`../assets/icons/${icon}`)}"
            ></gmap-marker>
        </gmap-map>
    </div>
</template>

<script>

    export default {
        name: "GMAutoComplete",
        props: {
          address: {
            type: String
          },
          pHolder: {
            type: String
          },
          source: {
            type: String
          },
          title: {
            type: String,
            default: 'Verify Property Location'
          },
          isLoading: {
            type: Boolean,
            default: false
          },
          showRemoveButton: {
            type: Boolean,
            default: true
          },
          alwaysShowAddButton: {
            type: Boolean,
            default: false
          }
        },
        watch: {
          address(address) {
              if (address === '') {
                  this.removeMarker();
              }
          }
        },
        data() {
            return {
                // default to Montreal to keep it simple
                // change this to whatever makes sense
                center: { lat: 45.508, lng: -73.587 },
                markers: [],
                places: [],
                currentPlace: null,
                infoContent: '',
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
            };
        },

        computed: {
            icon() {
                if (this.source === 'school') {
                    return 'map-school.svg'
                }
                return 'map-building.svg';
            }
        },
        methods: {
            // receives a place object via the autocomplete component
            setPlace(place) {
                this.currentPlace = place;
            },
            addMarker() {
                if (this.currentPlace && Object.keys(this.currentPlace).length > 0) {
                    const marker = {
                        lat: this.currentPlace.geometry.location.lat(),
                        lng: this.currentPlace.geometry.location.lng()
                    };
                    this.markers.push({ position: marker });
                    this.places.push(this.currentPlace);
                    this.center = marker;
                    this.infoContent = this.currentPlace.formatted_address;

                    this.$emit('updateCoordinates', {
                        address: this.currentPlace.formatted_address,
                        google_place_id: this.currentPlace.place_id,
                        longitude: this.currentPlace.geometry.location.lng(),
                        latitude: this.currentPlace.geometry.location.lat()
                    });
                }
            },
            removeMarker() {
                this.markers = [];
                this.$emit('updateCoordinates', {
                    google_place_id: '',
                    longitude: '',
                    latitude: ''
                })
            },
            toggleInfoWindow: function (marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        }
    };
</script>

<style scoped>
    .search-input {
        border: solid 2px #F4F5F6;
        border-radius: 5px;
        width: 100%;
        padding-left: 10px;
    }
</style>