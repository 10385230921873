import ApiService from './api.service'

const InvoiceService = {
    addInvoice: async function(data) {
        const response = await ApiService.post('invoice', data);
        return response.data.data
    },
    updateInvoice: async function(invoiceId, data) {
        const response = await ApiService.post(`invoice/${invoiceId}/update`, data);
        return response.data.data
    },
    getInvoice: async function(invoiceId) {
        const response = await ApiService.get(`invoice/${invoiceId}`);
        return response.data.data
    },
    getInvoices: async function(params = '') {
        const response = await ApiService.get(`invoices${params}`);
        return response.data.data
    },
    getInvoiceCount: async function(params = '') {
        const response = await ApiService.get(`invoice-count${params}`);
        return response.data.data
    },
    getInvoiceSum: async function(params = '') {
        const response = await ApiService.get(`invoice-sum${params}`);
        return response.data.data;
    },
    markInvoiceSent: async function(invoiceId, data) {
        const response = await ApiService.post(`invoices/${invoiceId}/mark-sent`, data);
        return response.data.data;
    },
}

export default InvoiceService

export { InvoiceService }
