import { render, staticRenderFns } from "./DashboardCalendar.vue?vue&type=template&id=479b3d9f&scoped=true&"
import script from "./DashboardCalendar.vue?vue&type=script&lang=js&"
export * from "./DashboardCalendar.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCalendar.vue?vue&type=style&index=0&id=479b3d9f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479b3d9f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VMenu,VSheet,VToolbar})
