import ApiService from './api.service'

const SchoolService = {
    getSchools: async function(params = '') {
        const response = await ApiService.get(`schools${params}`);
        return response.data.data
    },
    getSchool: async function(schoolId = '', params = '') {
        const response = await ApiService.get(`school/${schoolId}${params}`);
        return response.data.data
    },
    addSchool: async function(data) {
        const response = await ApiService.post('schools', data);
        return response.data.data
    },
    addCampus: async function(data) {
        const response = await ApiService.post('campuses', data);
        return response.data.data
    },
    deleteSchool: async function(schoolId) {
        const response = await ApiService.delete(`school/${schoolId}`);
        return response.data.data
    },
    updateSchool:async function(schoolId, data) {
        const response = await ApiService.post(`school/${schoolId}/update`, data);
        return response.data.data
    },
    getSchoolContacts: async function(schoolId, params='') {
        const response = await ApiService.get(`school/${schoolId}/contacts${params}`);
        return response.data.data
    },
    addSchoolContact: async function(data) {
        const response = await ApiService.post('school-contact', data);
        return response.data.data
    },
    addSchoolContactCampuses: async function(data) {
        const response = await ApiService.post('school-contact-campuses', data);
        return response.data.data
    },
    updateSchoolContactCampuses: async function(schoolContactId, data) {
        const response = await ApiService.put(`school-contact/${schoolContactId}/updateCampuses`, data);
        return response.data.data
    },
    updateSchoolContact: async function(schoolContactId, data) {
        const response = await ApiService.post(`school-contact/${schoolContactId}`, data);
        return response.data.data;
    },
    deleteSchoolContact: async function(schoolContactId) {
        const response = await ApiService.delete(`school-contact/${schoolContactId}`);
        return response.data.data;
    },
    getSchoolContactTypes: async function() {
        const response = await ApiService.get(`school-contact-types`);
        return response.data.data;
    },
    sendLoginCredentials: async function(schoolContactId) {
        const response = await ApiService.get(`school-contact/${schoolContactId}/send-login`, {id: schoolContactId});
        return response.data.data;
    },
    getCampusProperties: async function (campusId) {
        const response = await ApiService.get(`campuses/${campusId}/properties`);
        return response.data.data;
    }
}

export default SchoolService

export { SchoolService }
