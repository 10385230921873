<template>
  <Campus
      :campus="campus"
      @updateCampus="handleUpdateCampus"
      @addCampus="$emit('addCampus', $event)"
      @hideDialog="$emit('hideDialog')"
  >
    <template #card-title="schoolCampus">
          <v-card-title class="d-flex justify-space-between">
            Add Campus
            <v-btn icon @click="schoolCampus.handleHideDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
    </template>
    <template #gmaps>
      <GMAutoComplete @updateCoordinates="updateGMapsCoordinates($event)"
                      :address="campus.address"
                      p-holder="Enter campus address"
                      source="school"
                      title="Verify Campus Location"
      />
    </template>
    <template #action-btn="schoolCampus">
      <v-card elevation="10" height="80" width="100%" class="px-7">
        <v-card-text class="text-right">
          <v-btn large text color="primary" @click="$emit('hideDialog')">Cancel</v-btn>
          <v-btn large color="primary" @click="addCampus(schoolCampus)">Add Campus</v-btn>
        </v-card-text>
      </v-card>
    </template>
  </Campus>
</template>

<script>
import Campus from "@/components/Campuses/Campus";
import GMAutoComplete from "@/components/GMAutoComplete.vue";

export default {
  name: "NewCampus",
  components: {GMAutoComplete, Campus},
  props: {
    school: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      campus: {
        school_id: '',
        name: '',
        address: '',
        website: '',
        google_place_id: '',
        longitude: '',
        latitude: ''
      },
    }
  },
  methods: {
    init() {
      this.campus = {
        school_id: this.school.id,
        name: this.school.name,
        address: '',
        website: '',
        google_place_id: '',
        longitude: '',
        latitude: ''
      }
    },
    addCampus(parentFunctions) {
      if (!parentFunctions.isValidForm()) {
        return
      }

      parentFunctions.resetCampusValidation()
      this.$emit('addCampus', this.campus)
      this.$emit('hideDialog')
    },
    handleUpdateCampus(ev) {
      this.campus[ev.field] = ev.value
    },
    updateGMapsCoordinates(gmaps) {
      this.campus.address = gmaps.address
      this.campus.google_place_id = gmaps.google_place_id
      this.campus.longitude = gmaps.longitude
      this.campus.latitude = gmaps.latitude
    }
  },
  beforeMount() {
    this.init()
  }
}
</script>

<style scoped>

</style>