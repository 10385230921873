import ApiService from './api.service'

const KlaviyoService = {
    actionClicked: async function(data) {
        const response = await ApiService.post(`student-klaviyo`,data);
        return response.data.data
    }
}

export default KlaviyoService

export { KlaviyoService }