import NotificationService from "@/services/notification.service";

// State object
const state = {
    notifications: [],
    pagination: null,
    isLoadingNotifications: false,

    paginationCurrentPage: null,
    paginationItemsPerPage: 10,

    statusFilter: null,
    typeFilter: null,

    sortByKey: 'id',
    sortByDirection: 'desc'
};

// Getter functions
const getters = {
    notifications( state ) {
        return state.notifications;
    },
    isLoadingNotifications(state) {
        return state.isLoadingNotifications
    },
    pagination( state ) {
        return state.pagination;
    },
    paginationItemsPerPage( state ) {
        return state.paginationItemsPerPage
    }
};

// Actions
const actions = {
    async getPagination({ commit, state }) {
        let params = `?paginate=${state.paginationItemsPerPage}&appendUser`;

        if (state.sortByKey) {
            params = `${params}&sortByKey=${state.sortByKey}&sortByDirection=${state.sortByDirection}`;
        }
        if (state.statusFilter || state.statusFilter === 0 ) {
            params = `${params}&statusRead=${state.statusFilter}`;
        }
        if (state.typeFilter) {
            params = `${params}&type=${state.typeFilter}`;
        }

        if (state.paginationCurrentPage) {
            params = `${params}&page=${state.paginationCurrentPage}`;
        }

        commit('setIsLoadingNotifications', true);
        const pagination = await NotificationService.getNotifications(params);
        commit('setPagination', pagination);
        commit('setIsLoadingNotifications', false);
    },
    async getRecords({ commit }) {
        let params = `?appendUser&statusRead=0`;

        commit('setIsLoadingNotifications', true);
        const notifications = await NotificationService.getNotifications(params);
        commit('setNotifications', notifications);
        commit('setIsLoadingNotifications', false);
    },
    async updateNotification({commit}, data) {
        this.isMarkingAsRead = true
        await NotificationService.updateNotification(data.id, {[data.key] : data.value})
        this.isMarkingAsRead = false

        // update notification on store
        commit('updatePaginationNotification', {id: data.id,  key: data.key, value: data.value})
        commit('updateNotification', {id:data.id,  key: data.key, value: data.value})
    },
};

// Mutations
const mutations = {
    setPagination(state, data) {
        state.pagination = data;
    },
    setNotifications(state, data) {
        state.notifications = data;
    },

    setIsLoadingNotifications(state, data) {
        state.isLoadingNotifications = data;
    },

    //updates
    updatePaginationNotification(state, data) {
        let notification = state.pagination.data.find(p => p.id === data.id)

        if (!notification) {
            return
        }

        notification[data.key] = data.value
    },
    updateNotification(state, data) {
        let notification = state.notifications.find(p => p.id === data.id)

        if (!notification) {
            return
        }

        notification[data.key] = data.value
    },

    setState(state, data) {
        state[data.key] = data.value
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
