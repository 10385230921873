<template>
    <div class="content page-style-proto">
        <Agent :agent="agent" v-if="showAgent" v-on:getAgent="getAgent" source="myProfile"/>
    </div>
</template>

<script>
    import Agent from "../../components/Agents/Agent";
    import AgentService from "../../services/agent.service";

    export default {
        name: 'AgentProfile',
        components : {Agent},
        data() {
            return {
                isLoadingAgent: false,
                showAgent: false,
                agent: {}
            }
        },
        watch: {
            user(value) {
                if (value !== {}) {
                    this.getAgent();
                }
            }
        },
        computed: {
            isUserLoggedIn() {
                return this.$store.getters.isUserLoggedIn;
            },
            user() {
                return this.$store.getters.getUser;
            }
        },
        methods: {
            getAgent: async function() {
                this.isLoadingAgent = true;
                this.agent = await AgentService.getAgent(this.user.agent.id, '?appendSchools&appendRole&appendUser');
                this.showAgent = true;
                this.isLoadingAgent = false;
            }
        },
        beforeMount() {
            this.getAgent();
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }
</style>