import ApiService from './api.service'


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const RoommateService = {
    fetchRoommates: async function() {
        try {
            const response = await ApiService.get('users/extended');
            return response.data
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    fetchPaginatedRoommates: async function(data) {
        try {
            const url = 'users/extended?page=' + data.pageNumber +'&sort=' + data.sort;
            const response = await ApiService.get(url);
            return response.data
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    fetchPaginatedFavoriteRoommates: async function(pageNumber) {
        try {
            const url =`user/1/favorite-roommates?page=`;
            const response = await ApiService.get(url + pageNumber);
            return response.data
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    fetchPaginatedContactedRoommates: async function(pageNumber) {
        try {
            const url =`user/1/contacted-roommates?page=`;
            const response = await ApiService.get(url + pageNumber);
            return response.data
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    toggleFavoriteRoommateLike: async function(roommateUserId) {
        const url = 'user/toggle-favorite-roommate-like/' + roommateUserId+'?XDEBUG_SESSION_START=1';
        return await ApiService.get(url);
    }
}

export default RoommateService

export { RoommateService, AuthenticationError }
