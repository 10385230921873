<template>
    <div class="refresh-container">
        <div :class="$vuetify.breakpoint.mdAndUp ? refresh-button : ''">
            <v-btn color="primary" outlined @click="refreshCount++">
                Refresh All
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </div>
        <div class="pt-6">
            <StudentTextMessages :student="student" :shouldRefresh="refreshCount"></StudentTextMessages>
        </div>
        <div class="py-6" v-if="$vuetify.breakpoint.mdAndUp">
            <StudentRecords :student="student" :shouldRefresh="refreshCount"></StudentRecords>
        </div>
        <div class="py-6">
            <StudentEmails :student="student" :shouldRefresh="refreshCount"></StudentEmails>
        </div>
         <div class="py-6">
            <StudentRoommateCommunications :student="student"></StudentRoommateCommunications>
        </div>
    </div>
</template>

<script>
    import StudentRecords from "../../components/Students/StudentRecords";
    import StudentEmails from "../../components/Students/StudentEmails";
    import StudentTextMessages from "../../components/Students/StudentTextMessages";
    import StudentRoommateCommunications from "../../components/Students/StudentRoommateCommunications";

    export default {
        name: "StudentCommunications",
        components: {StudentTextMessages, StudentEmails, StudentRecords, StudentRoommateCommunications},
        props: ['student'],
        data() {
            return {
                refreshCount: 0
            }
        },
        watch: {
          student() {
            this.refreshCount++
          }
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
    
    .refresh-container {
        position: relative;
    }

    .refresh-button {
        position: absolute;
        top: 25px;
        right: 15px;
    }
</style>
