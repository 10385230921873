import ApiService from './api.service'

const StudentService = {
    getStudents: async function(params = '') {
        const response = await ApiService.get(`users${params}`);
        return response.data.data
    },
    getStudent: async function(studentId, params = '') {
        const response = await ApiService.get(`student/${studentId}${params}`);
        return response.data.data
    },
    deleteStudent: async function(studentId) {
        return await ApiService.delete(`student/${studentId}`);
    },
    getStudentStatuses: async function() {
        const response = await ApiService.get('student-statuses');
        return response.data.data
    },
    getStudentAmenities: async function(studentId) {
        const response = await ApiService.get(`student/${studentId}/amenities`);
        return response.data.data
    },
    getStudentRoommates: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/roommates${params}`);
        return response.data.data
    },
    getRoommateRequests: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/roommate-requests${params}`);
        return response.data.data;
    },
    acceptRoommate: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/roommate`, data);
        return response.data.data;
    },
    declineRoommate: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/roommate-request/decline`, data);
        return response.data.data;
    },
    getRoommateMatches: async function(studentId) {
        const response = await ApiService.get(`student/${studentId}/roommate-matches`);
        return response.data.data
    },
    sendRoommateMatches: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/send-roommate-matches`, data);
        return response.data
    },
    addNewStudent: async function(data) {
        const response = await ApiService.post('student', data);
        return response.data.data
    },
    updateStudent: async function(studentId, data) {
        await ApiService.post(`student/${studentId}/update`, data);
    },
    updateStudentAmenities: async function(studentId, data) {
        await ApiService.post(`student/${studentId}/amenities`, data);
    },
    updateStudentRoommates: async function(studentId, data) {
        await ApiService.post(`student/${studentId}/roommates`, data);
    },
    getStudentPropertyUnitMatches: async function(params='') {
        const response = await ApiService.get(`property-units${params}`);
        return response.data.data;
    },
    referStudentsToProperty: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/refer-students-to-property`, data);
        return response.data.data;
    },
    getReferredPropertyUnits: async function(studentId, params= '') {
        const response = await ApiService.get(`student/${studentId}/referred-properties${params}`);
        return response.data.data;
    },
    removeReferredProperty: async function(referredPropertyId) {
        const response = await ApiService.delete(`referred-property/${referredPropertyId}`);
        return response.data.data;
    },
    getFavoritePropertyUnits: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/favorite-property-units${params}`);
        return response.data.data;
    },
    getContactedPropertyUnits: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/contacted-property-units${params}`);
        return response.data.data;
    },
    sendReferrals: async function(studentId) {
        const response = await ApiService.get(`student/${studentId}/send-referrals`);
        return response.data.data;
    },
    getReferralStatuses: async function() {
        const response = await ApiService.get(`referrals`);
        return response.data.data;
    },
    updateReferredPropertyStatus: async function(propertyUnitId, data) {
        const response = await ApiService.post(`referred-property/${propertyUnitId}/update`, data);
        return response.data.data;
    },
    updateFavoritedPropertyUnitStatus: async function(propertyUnitId, data) {
        const response = await ApiService.post(`favorite-property-unit/${propertyUnitId}/update`, data);
        return response.data.data;
    },
    updateContactedPropertyUnitStatus: async function(propertyUnitId, data) {
        const response = await ApiService.post(`contacted-property-unit/${propertyUnitId}/update`, data);
        return response.data.data;
    },
    getStudentActivityNotes: async function (studentId) {
        const response = await ApiService.get(`student/${studentId}/activity-notes`);
        return response.data.data;
    },
    addActivityNote: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/activity-note`, data);
        return response.data.data;
    },
    updateActivityNote: async function(noteId, note) {
        const response = await ApiService.post(`student-activity-note/${noteId}/update`, note);
        return response.data.data;
    },
    deleteActivityNote: async function(noteId) {
        return await ApiService.delete(`student-activity-note/${noteId}`);
    },
    getStudentActivityEvents: async function (studentId) {
        const response = await ApiService.get(`student/${studentId}/activity-events`);
        return response.data.data;
    },
    addActivityEvent: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/activity-event`, data);
        return response.data.data;
    },
    updateActivityEvent: async function(eventId, event) {
        const response = await ApiService.post(`student-activity-event/${eventId}/update`, event);
        return response.data.data;
    },
    importStudents: async function(data) {
        const response = await ApiService.post(`student/bulk-import`, data, {
            'content-type': 'application/json'
        });
        return response.data.data;
    },
    getStudentPhones: async function(studentId) {
        const response = await ApiService.get(`student/${studentId}/user-phones`);
        return response.data.data;
    },
    getStudentEmails: async function(studentId, params = '') {
        const response = await ApiService.get(`student/${studentId}/emails${params}`);
        return response.data.data;
    },
    deletePhoneNumber: async function(studentPhoneId) {
        await ApiService.delete(`student-phone/${studentPhoneId}`);
    },
    savePhoneNumber: async function(data) {
        const response = await ApiService.post('student-phone', data);
        return response.data.data;
    },
    saveEmail: async function(data) {
        const response = await ApiService.post('student-email', data);
        return response.data.data;
    },
    deleteEmail: async function(studentEmailId) {
        await ApiService.delete(`student-email/${studentEmailId}`);
    },
    updateOtherEmail: async function(studentEmailId, data) {
        await ApiService.post(`student-email/${studentEmailId}/update`, data);
    },
    updateOtherPhone: async function(studentPhoneId, data) {
        await ApiService.post(`student-phone/${studentPhoneId}/update`, data);
    },
    getCampaign: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/campaign${params}`);
        return response.data.data;
    },
    getStudentDonations: async function(studentId) {
        const response = await ApiService.get(`student/${studentId}/donations`);
        return response.data.data;
    },
    getTextMessages: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/text-messages${params}`);
        return response.data.data;
    },
    sendTextMessage: async function(studentId, data) {
        const response = await ApiService.post(`student/${studentId}/text-message`, data);
        return response.data.data;
    },
    getCallRecords: async function(studentId, params='') {
        const response = await ApiService.get(`student/${studentId}/call-records${params}`);
        return response.data.data;
    },
    findRoommate: async function(searchTerm) {
        const response = await ApiService.get(`find-roommate?search=${searchTerm}`);
        return response.data.data;
    },
    addRoommate: async function(studentId, data) {
        await ApiService.post(`student/${studentId}/roommate`, data);
    },
    removeRoommate: async function (roommateUserId) {
        return await ApiService.delete('roommate/' + roommateUserId);
    },
    addEvent: async function(data) {
        await ApiService.post(`student-activity-event`, data);
    },
    mergeStudents: async function(data) {
        return await ApiService.post(`merge-students`, data);
    }
}

export default StudentService

export { StudentService }
