<template>
    <v-dialog v-model="dialog" max-width="1111">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" large height="40" v-on="on" v-if="action === 'add'">
                <v-icon left>mdi-plus</v-icon>
                Add New
            </v-btn>
            <v-avatar tile size="10" v-on="on" v-if="action === 'show'">
                <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
            </v-avatar>
            <a v-on="on" v-if="action === 'show-icon'">
                <v-img :src="iconUrl" width="45" height="45"></v-img>
            </a>
            <a v-on="on" v-if="action === 'show-name'">
                {{name}}
            </a>
        </template>

        <v-card class="px-6">
            <v-row class="justify-space-between">
                <h2 class="py-4">{{amenity.name.length > 0 ? amenity.name : 'Add New Amenities'}}</h2>
                <v-icon light @click="dialog=false">mdi-close</v-icon>
            </v-row>
            <v-row>
                <v-col cols="12" md="2">
                    <p>Icon</p>
                    <AmenityIcon :amenity="amenity" v-on:onAmenityIconModified="onAmenityIconModified($event)"/>
                </v-col>
                <v-col cols="12" md="4">
                    <p>Name</p>
                    <v-text-field outlined dense v-model="amenity.name" @change="updateAmenity('name', $event)"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <p>Type</p>
                    <v-select outlined dense :items="['Unit Amenity', 'Property Amenity']"
                              v-model="amenity.type"
                              @change="updateAmenity('type', $event)"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <p>Featured</p>
                    <v-select outlined dense :items="['Yes', 'No']"
                              v-model="amenity.featured"
                              @change="updateAmenity('featured', $event)"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2" class="mt-md-7">
                    <v-btn dense block color="primary" @click="addAmenity" :loading="isAddingNewAmenity" :disabled="!isAddViewType">
                        <v-icon left>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    import AmenityIcon from "./AmenityIcon";
    import AmenityService from "../../services/amenity.service";
    export default {
        name: 'AmenityDialog',
        props: ['action', 'amenity', 'iconUrl', 'name'],
        components: {AmenityIcon},
        data() {
            return {
                isAddingNewAmenity: false,
                isAddViewType: false,
                dialog: false
            }
        },
        methods: {
            onAmenityIconModified(data) {
                this.amenity.icon = data.image;

                if (!this.isAddViewType) {
                    const formData = new FormData();
                    formData.append('icon', data.image);

                    this.updateAmenityIcon(formData)
                }
            },
            initAmenity() {
                this.amenity = {
                    icon_url: 'https://place-hold.it/140/E5E5E5/FFFFFF?text=Image',
                    name: '',
                    type: 'Unit Amenity',
                    featured: 'Yes'
                }
            },
            async addAmenity() {
                const formData = new FormData();
                formData.append('name', this.amenity.name);
                formData.append('type', this.amenity.type);
                formData.append('featured', this.amenity.featured);
                formData.append('icon', this.amenity.icon);

                this.isAddingNewAmenity = true;
                await AmenityService.addAmenity(formData);
                this.isAddingNewAmenity = false;
                this.initAmenity();
                this.dialog = false;

                this.$store.dispatch('getAmenities');
            },
            updateAmenity(field, value) {
                if (this.action === 'add') {
                    return
                }

                AmenityService.updateAmenity(this.amenity.id, {[field]: value}).then(() => {
                    this.$store.dispatch('getAmenities');
                });
            },
            updateAmenityIcon(data) {
                AmenityService.updateAmenity(this.amenity.id, data);
            }
        },
        beforeMount() {
            if (this.amenity === undefined) {
                // assume it's a new amenity being added
                this.isAddViewType = true;
                this.initAmenity();
            }
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }
</style>
