<template>
    <v-combobox
            outlined
            dense
            append-icon="mdi-magnify"
            v-model="searchValue"
            :items="items"
            :search-input.sync="searchValue"
            placeholder="Enter Search Value and Press Enter"
            :no-filter="true"
            :menu-props="{ overflowY: true }"
            @keydown.enter="onTextChanged()"
            @focus="clearSearch()"
        >
        <template v-slot:item="{ item }">
                <v-list-item
                @click="handleClick(item.type, item.id)">
                    {{item.string}}
                </v-list-item>
            </template>
        </v-combobox>
</template>
<script>
import {SearchService} from "@/services/search.service";
export default {
     name: 'UniversalSearch',
     data() {
         return {
             searchValue: "",
             items: []
         }
     },
     methods: {
        async onTextChanged() {
            if(this.searchValue && this.searchValue.length > 0) {
                this.items = await SearchService.search(`?value=${this.searchValue}`);
            }
         },
         clearSearch() {
             this.items = [];
         },
         handleClick(resource, id) {
             switch(resource) {
                 case "student":
                    window.open(`/#/view-student/${id}`,'_blank')
                    break;
                case "invoice":
                    window.open(`/#/view-invoice/${id}`,'_blank')
                    break;
                case "property":
                    window.open(`/#/view-property/${id}`,'_blank')
                    break;
                case "school":
                    window.open(`/#/view-school/${id}`,'_blank')
                    break;
             }

         }

     }
}
</script>
<style>

</style>
