<template>
    <v-container>
        <h1>App Services</h1>

        <v-card
            v-for="(s, i) in services"
            :key="i"
        >
            <v-card-title>
                {{ s.title }}
            </v-card-title>
            <v-card-text>
                <v-card class="mb-3"
                    v-for="(t, t_i) in s.type"
                    :key="t_i"
                >
                    <v-card-title>
                        {{ t.title }}
                    </v-card-title>
                    <v-card-subtitle>
                        {{ t.version }}
                    </v-card-subtitle>
                    <v-card-text v-if="t.links">
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-subheader>External Links</v-subheader>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(l, l_i) in t.links"
                                    :key="l_i"
                                    :href="l.url" target="_blank"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="l.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="l.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>

            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name:"AppServices",
        data() {
            return {
                    type: 'password',
                    btnText: 'Show Password',
                    services: [
                        {
                            title: 'Development',
                            type: [
                                {
                                    title: 'Front End',
                                    version: 'Vue JS V2.6.11',
                                    links: [
                                        { title: 'Admin', url: 'https://git.simplyphp.com/clients/edurent-admin', icon: 'mdi-git'},
                                        { title: 'Client', url: 'https://git.simplyphp.com/clients/edurent-client', icon: 'mdi-git'},
                                        { title: 'S3 Buckets', url: 'https://s3.console.aws.amazon.com/s3/buckets', icon: 'mdi-aws'},
                                    ]
                                },
                                {
                                    title: 'Back End',
                                    version: 'Laravel 9.5 PHP V8.0',
                                    links: [
                                        { title: 'API', url: 'https://git.simplyphp.com/clients/edurent-api', icon: 'mdi-git'},
                                    ]
                                },
                                {
                                    title: 'Database',
                                    version: 'MySQL 5.7.12',
                                    links: null
                                },
                            ]
                        },
                        {
                            title: 'Web Links',
                            type: [
                                {
                                    title: 'Strapi CMS',
                                    version: null,
                                    links: [
                                        { title: 'Login', url: 'https://cms.edurent.com/admin/auth/login', icon: 'mdi-exit-to-app'},
                                    ]
                                },
                                {
                                    title: 'Staging',
                                    version: null,
                                    links: [
                                        { title: 'Client', url: 'https://staging.edurent.com', icon: 'mdi-exit-to-app'},
                                        { title: 'Admin', url: 'https://staging.admin.edurent.com', icon: 'mdi-exit-to-app'},
                                    ]
                                },
                                {
                                    title: 'Production',
                                    version: null,
                                    links: [
                                        { title: 'Client', url: 'https://edurent.com', icon: 'mdi-exit-to-app'},
                                        { title: 'Admin', url: 'https://admin.edurent.com', icon: 'mdi-exit-to-app'},
                                    ]
                                },
                            ]
                        },
                    ],
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
