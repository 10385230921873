<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <p>Label</p>
                <v-text-field v-model="special.label"
                              :error-messages="labelErrors"
                              @input="$v.special.label.$touch()"
                              @blur="$v.special.label.$touch()"
                              dense
                              outlined
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <p>Value ({{special.content.length}}|500)</p>
                <v-text-field v-model="special.content"
                              :error-messages="contentErrors"
                              @input="$v.special.content.$touch()"
                              @blur="$v.special.content.$touch()"
                              dense
                              outlined
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Amount</p>
                <v-text-field v-model="special.amount"
                              :error-messages="amountErrors"
                              @input="$v.special.amount.$touch()"
                              @blur="$v.special.amount.$touch()"
                              dense
                              outlined
                              prefix="$"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <p>Start Date</p>
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="special.start_date"
                                :error-messages="startDateErrors"
                                @input="$v.special.start_date.$touch()"
                                @blur="$v.special.start_date.$touch()"
                                outlined
                                append-icon="mdi-calendar"
                                dense
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate"
                                   no-title
                                   @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <p>End Date</p>
                <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="special.end_date"
                                :error-messages="endDateErrors"
                                @input="$v.special.end_date.$touch()"
                                @blur="$v.special.end_date.$touch()"
                                outlined
                                append-icon="mdi-calendar"
                                dense
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate"
                                   no-title
                                   @input="menu3 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <p>Display</p>
                <v-select dense
                          outlined
                          :items="display"
                          v-model="special.display_in_website"
                          :error-messages="displayInWebsiteErrors"
                          @input="$v.special.display_in_website.$touch()"
                          @blur="$v.special.display_in_website.$touch()"
                />
            </v-col>
            <v-col cols="12" md="2" class="mt-md-6">
                <v-btn dense color="primary" @click="addRentSpecial">
                    <v-icon left>mdi-plus</v-icon>
                    Add
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="propertyRentSpecials"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="isCallingApi"
        >
            <template v-slot:item.label="props">
                {{props.item.rent_special && props.item.rent_special.label ? props.item.rent_special.label : ''}}
            </template>
            <template v-slot:item.display_in_website="props">
                {{props.item.display_in_website === 1 ? 'Yes' : 'No'}}
            </template>
            <template v-slot:item.delete="props">
                <v-avatar tile size="14" @click="deletePropertyRentSpecial(props.item.id)">
                    <v-img :ref="`delete-icon-${props.item.id}`" :src="require(`@/assets/icons/trash.svg`)"
                           @mouseover="handleDeleteIconMouseOver(props.item.id)"
                           @mouseleave="handleDeleteIconMouseLeave(props.item.id)"
                    ></v-img>
                </v-avatar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import moment from 'moment'
    import {PropertyService} from "../../services/property.service";
    import { required, maxLength } from "vuelidate/lib/validators"

    export default {
        name: 'PropertyRentSpecials',
        props: ['property'],
        validations: {
            special: {
                amount: { required },
                label: { required },
                content: { required, maxLength: maxLength(500) },
                start_date: {
                    required,
                    isBeforeEnd(value) {
                        return this.isBeforeEnd(value)
                    },
                },
                end_date: {
                    required,
                    isAfterStart(value)  {
                        return this.isAfterStart(value)
                    }
                },
                display_in_website: { required }
            }
        },
        watch: {
            searchQuery(value) {
                this.search = value
            },
            startDate() {
                this.special.start_date =  this.formatDate(this.startDate)
            },
            endDate() {
                this.special.end_date =  this.formatDate(this.endDate)
            }
        },
        computed: {
            rentSpecials() {
                return this.$store.getters.getRentSpecials;
            },
            rentSpecialIdErrors () {
                const errors = [];
                if (!this.$v.special.rent_special_id.$dirty) return errors;
                !this.$v.special.rent_special_id.required && errors.push('Rent special is required');
                return errors
            },
            amountErrors () {
                const errors = []
                if (!this.$v.special.amount.$dirty) return errors
                !this.$v.special.amount.required && errors.push('Amount is required');
                return errors
            },
            labelErrors () {
                const errors = []
                if (!this.$v.special.label.$dirty) return errors
                !this.$v.special.label.required && errors.push('Label is required');
                return errors
            },
            contentErrors () {
                const errors = []
                if (!this.$v.special.content.$dirty) return errors
                !this.$v.special.content.required && errors.push('Content is required');
                !this.$v.special.content.maxLength && errors.push('The content field maximun length is 500');
                return errors
            },
            startDateErrors () {
                const errors = []
                if (!this.$v.special.start_date.$dirty) return errors
                !this.$v.special.start_date.required && errors.push('Start date is required');
                !this.$v.special.start_date.isBeforeEnd && errors.push('Start date must be same or before end date');
                return errors
            },
            endDateErrors () {
                const errors = []
                if (!this.$v.special.end_date.$dirty) return errors
                !this.$v.special.end_date.required && errors.push('End date is required');
                !this.$v.special.end_date.isAfterStart && errors.push('End date must be same or after start date ');
                return errors
            },
            displayInWebsiteErrors () {
                const errors = [];
                if (!this.$v.special.display_in_website.$dirty) return errors
                !this.$v.special.display_in_website.required && errors.push('Display website choice is required');
                return errors
            },
        },
        data () {
            return {
                search: '',
                page: 1,
                menu2: false,
                menu3: false,
                pageCount: 1,
                itemsPerPage: 10,
                headers: [
                    {
                        text: 'Rent Specials',
                        align: 'start',
                        value: 'label'
                    },
                    { text: 'Amount', value: 'amount'},
                    { text: 'Start Date', value: 'start_date' },
                    { text: 'End Date', value: 'end_date' },
                    { text: 'Display In Website', value: 'display_in_website' },
                    { text: '', value: 'delete' },
                ],
                special: {
                    amount: 0,
                    content: '',
                    label: '',
                    start_date: this.formatDate(new Date().toISOString().substr(0, 10)),
                    end_date: this.formatDate(new Date().toISOString().substr(0, 10)),
                    display_in_website: 1
                },
                display: [
                    {text: 'Yes', value: 1},
                    {text: 'No', value: 0}
                ],
                propertyRentSpecials: [],
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                isCallingApi: false
            }
        },
        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            handleDeleteIconMouseOver(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(iconNumber) {
                this.$refs['delete-icon-' + iconNumber].src = require(`@/assets/icons/trash.svg`);
            },
            deletePropertyRentSpecial(id) {
                this.isCallingApi = true;
                this.$store.dispatch('deletePropertyRentSpecial', id).then(() => {
                    this.isCallingApi = false;
                    this.getPropertyRentSpecials();
                });
            },
            async addRentSpecial() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'A mandatory field is missing. Please look through the form and fix the field highlighted in red',
                        color: 'red'
                    });
                    return
                }

                await this.$store.dispatch('addNewPropertyRentSpecial', {propertyId: this.property.id, special: this.special});
                this.clearModel();
                this.getPropertyRentSpecials();
            },
            clearModel() {
                this.special.label = '';
                this.special.content = '';
                this.special.amount = 0;
                this.$v.$reset();
            },
            updateAmount(rentSpecialId) {
                Object.keys(this.rentSpecials).forEach(key => {
                    if (this.rentSpecials[key].id === rentSpecialId) {
                        this.special.amount = this.rentSpecials[key].amount
                    }
                })
            },
            getPropertyRentSpecials: async function() {
                this.isCallingApi = true;
                this.propertyRentSpecials = await PropertyService.getPropertyRentSpecials(this.property.id, '?appendRentSpecial');
                this.isCallingApi = false;
            },
            isBeforeEnd(date) {
                return moment(date).isSameOrBefore(this.special.end_date);
            },
            isAfterStart(date) {
                return moment(date).isSameOrAfter(this.special.start_date);
            }
        },
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
                }
            }
        },

        beforeMount() {
            this.getPropertyRentSpecials();
        }
    }
</script>
