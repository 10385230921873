import ApiService from './api.service'

const NotificationService = {
    async getNotifications(params='') {
        const response = await ApiService.get(`notifications${params}`);
        return response.data.data
    },
    async updateNotification(notificationId, data) {
        const response = await ApiService.patch(`notification/${notificationId}`, data);
        return response.data.data
    }
}

export default NotificationService

export { NotificationService }
