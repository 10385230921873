<template>
    <v-pagination
            v-if="show"
            :value="currentPage"
            :length="lastPage"
            :total-visible="totalVisible"
            @input="paginatePage"
    ></v-pagination>
</template>

<script>
    export default {
        name: 'Paginate',
        props: {
            state: {
                type: String
            },
            collection: {
                type: String
            },
            list: {
                type: String
            },
            currentPageSetter: {
                type: String,
                default: 'setCurrentPage'
            }
        },
        computed: {
            show() {
                return this.$store.state[this.state] &&
                    this.$store.state[this.state][this.collection] &&
                    this.$store.state[this.state][this.collection].data
            },
            currentPage() {
                return this.$store.state[this.state][this.collection].current_page;
            },
            lastPage: {
                get() {
                    return this.$store.state[this.state][this.collection].last_page;
                }
            },
            totalVisible() {
                let total = 3;
                if (this.$vuetify.breakpoint.smAndDown) {
                   total = 3
                }
                if (this.$vuetify.breakpoint.mdAndDown) {
                   total = 1
                }
                if (this.$vuetify.breakpoint.lg) {
                   total = 5
                }
                if (this.$vuetify.breakpoint.xl) {
                   total = 8
                }

                return total
            }
        },
        methods: {
            paginatePage(page) {
                this.$store.commit(this.currentPageSetter, page);
                this.$store.dispatch(this.list);
            }
        }
    }
</script>

<style>

    .v-pagination {
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        width: auto !important;
    }

    .v-pagination__item {
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        height: 41px !important;
        box-shadow: none !important;
        width: 51px !important;

    }

    .v-pagination__navigation {
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        height: 41px !important;
        box-shadow: none !important;
        background: transparent !important;
        width: 51px !important;
    }

    .v-pagination__more {
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
        height: 41px !important;
        box-shadow: none !important;
        background: transparent !important;
        width: 51px !important;
    }

    .v-pagination li {
        border-left: 1px solid #d8d8d8;
    }

    .v-pagination li:first-child {
        border-left: 0;
    }

</style>
