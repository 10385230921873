import ApiService from './api.service'

const AgentService = {
    getAgents: async function(params = '') {
        const response = await ApiService.get(`agents${params}`);
        return response.data.data
    },
    getAgent: async function(agentId, params ='') {
        const response = await ApiService.get(`agent/${agentId}${params}`);
        return response.data.data
    },
    updateAgent: async function(agentId, data) {
        return await ApiService.post(`agent/${agentId}`, {data: data});
    },
    deleteAgent: async function(agentId) {
        return await ApiService.delete(`agent/${agentId}`);
    },
    updateCampuses: async function(agentId, data) {
        return await ApiService.post(`agent/${agentId}/campuses`, data);
    }
}

export default AgentService

export { AgentService }
