import { AgentService } from '@/services/agent.service'
import { TokenService } from '@/services/storage.service'
import BusinessService from "@/services/business.service";
import {alphabeticalSortByUserObject} from "@/utils/utils";

// State object
const state = {
    agents: [],
    agent: {},
    roles: [],
    accessToken: TokenService.getToken(),
    isLoggingIn: false,
    isLoadingAgents: false,
    isLoadingAgent: false,
    isLoadingAgentRoles: false,
    isAgentLoggedIn: false
};

// Getter functions
const getters = {
    getIsLoadingAgents( state ) {
        return state.isLoadingAgents;
    },
    getAgents( state ) {
        return state.agents;
    },
    getActiveAgents( state ) {
        return state.agents.filter(agent => agent.is_active === 1).sort(alphabeticalSortByUserObject());
    },
    getIsLoadingAgent( state ) {
        return state.isLoadingAgent;
    },
    getAgent( state ) {
        return state.agent;
    },
    getIsLoadingAgentRoles( state ) {
        return state.isLoadingAgentRoles;
    },
    getAgentRoles( state ) {
        return state.roles;
    }
};

// Actions
const actions = {
    async getAgents({ commit }, data) {
        commit('setIsLoadingAgents', true);
        const agents = await AgentService.getAgents(data);
        commit('setAgents', agents);
        commit('setIsLoadingAgents', false);
    },
    async getAgent({ commit }, data) {
        commit('setIsLoadingAgent', true);
        const agent = await AgentService.getAgent(data.id, data.params);
        commit('setAgent', agent);
        commit('setIsLoadingAgent', false);
    },
    async getAgentRoles({ commit }, data) {
        commit('setIsLoadingAgentRoles', true);
        const roles = await BusinessService.getRoles(data);
        commit('setRoles', roles);
        commit('setIsLoadingAgentRoles', false);
    },
    addDefaultAgent({ commit }) {
        commit('addAgent', {
            index: 0,
            agent: {
                id: 0,
                user: {
                    name: 'All Agents'
                }
            }
        });
    }
};

// Mutations
const mutations = {
    setIsLoadingAgents(state, data) {
        state.isLoadingAgents = data;
    },
    setAgents(state, data) {
        state.agents = data;
    },
    addAgent(state, data) {
        state.agents.splice(data.index, 0, data.agent);
    },
    setIsLoadingAgent(state, data) {
        state.isLoadingAgent = data;
    },
    setAgent(state, data) {
        state.agent = data;
    },
    setIsLoadingAgentRoles(state, data) {
        state.isLoadingAgentRoles = data;
    },
    setRoles(state, data) {
        state.roles = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
