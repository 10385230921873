import ApiService from './api.service'

const CampusService = {
    async getCampuses(params = '') {
        const response = await ApiService.get(`campuses${params}`);
        return response.data.data
    },
    async updateCampus(campusId, data) {
        const response = await ApiService.patch(`campuses/${campusId}`, data);
        return response.data.data
    },
}


export default CampusService
