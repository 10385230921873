<template>
    <v-card>
        <v-card-title class="headline text--darken-2" :class="color ? color : 'amber'">
            <v-icon left size="40" color="white">mdi-alert</v-icon>
            {{title}}
        </v-card-title>

        <v-card-text class="pt-4">
            {{message}} Do you want to continue?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$emit('hideDialog')">
                Cancel
            </v-btn>
            <v-btn color="primary"  @click="$emit('continue')">
                Continue
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'Notification',
        props: {
          message: {
            required: true
          },
          title: {
            default: 'Delete Notification'
          },
          color: {
            default: 'amber'
          }
    }
    }
</script>