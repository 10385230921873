<template>
    <v-card width="1111" :loading="isSavingDefaultMessage">
        <v-card-title>
            <v-row class="justify-space-between px-4 pt-8">
                <h2>{{isAddNewDefaultMessageState && defaultMessage.label === '' ? 'Add New Note or Event' : defaultMessage.label}}</h2>
                <v-btn icon @click="hideDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <p>Label</p>
                    <v-text-field dense outlined
                                  v-model="defaultMessage.label"
                                  :error-messages="labelErrors"
                                  @input="$v.defaultMessage.label.$touch()"
                                  @blur="$v.defaultMessage.label.$touch()"
                                  @change="updateDefaultMessage('label', $event)"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <p>Type</p>
                    <v-select dense outlined :items="['Event', 'Note']"
                              v-model="defaultMessage.type"
                              :error-messages="typeErrors"
                              @input="$v.defaultMessage.type.$touch()"
                              @blur="$v.defaultMessage.type.$touch()"
                              @change="updateDefaultMessage('type', $event)"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <p>Category</p>
                    <v-select dense outlined :items="categories" item-text="category" item-value="id"
                              v-model="defaultMessage.default_message_category_id"
                              :error-messages="categoryErrors"
                              @input="$v.defaultMessage.default_message_category_id.$touch()"
                              @blur="$v.defaultMessage.default_message_category_id.$touch()"
                              @change="updateDefaultMessage('default_message_category_id', $event)"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p>Value</p>
                    <v-textarea outlined v-model="defaultMessage.content"
                                :error-messages="contentErrors"
                                @input="$v.defaultMessage.content.$touch()"
                                @blur="$v.defaultMessage.content.$touch()"
                                @change="updateDefaultMessage('content', $event)"
                    />
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="justify-end pa-0">
            <v-card elevation="1" height="80" width="100%" class="px-7"
                    v-if="isAddNewDefaultMessageState"
            >
                <v-card-text class="text-right">
                    <v-btn large text color="primary" @click="hideDialog">Cancel</v-btn>
                    <v-btn large color="primary"
                           @click="save"
                           :loading="isSavingDefaultMessage"
                    >Save </v-btn>
                </v-card-text>
            </v-card>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import DefaultMessageService from "../../../services/defaultmessages.service";

    export default {
        name: 'DefaultMessage',
        validations : {
            defaultMessage: {
                label: { required },
                type: { required },
                default_message_category_id: { required },
                content: { required },
            }
        },
        props: ['defaultMessage'],
        data() {
            return {
                isSavingDefaultMessage: false,
            }
        },
        computed: {
            isAddNewDefaultMessageState() {
                return this.$store.getters.getIsAddNewDefaultMessage;
            },
            categories() {
                return this.$store.getters.getDefaultMessageCategories;
            },
            labelErrors () {
                const errors = []
                if (!this.$v.defaultMessage.label.$dirty) return errors
                !this.$v.defaultMessage.label.required && errors.push('Label is required');
                return errors
            },
            typeErrors () {
                const errors = []
                if (!this.$v.defaultMessage.type.$dirty) return errors
                !this.$v.defaultMessage.label.required && errors.push('Type is required');
                return errors
            },
            categoryErrors () {
                const errors = []
                if (!this.$v.defaultMessage.default_message_category_id.$dirty) return errors
                !this.$v.defaultMessage.default_message_category_id.required && errors.push('Category is required');
                return errors
            },
            contentErrors () {
                const errors = []
                if (!this.$v.defaultMessage.content.$dirty) return errors
                !this.$v.defaultMessage.content.required && errors.push('Value is required');
                return errors
            },
        },
        methods: {
            hideDialog() {
                this.$v.$reset();
                this.$emit('hideDialog', false)
            },
            updateDefaultMessage: async function(field, value) {
                if (this.isAddNewDefaultMessageState) {
                    return
                }

                this.isSavingDefaultMessage = true;
                await DefaultMessageService.updateDefaultMessage(this.defaultMessage.id, {[field]: value})
                this.isSavingDefaultMessage = false
            },
            save: async function() {
                //validate
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'One or more mandatory fields are missing. Please look through the form and fix the field(s) highlighted in red',
                        color: 'red'
                    });

                    return
                }

                //all good now save
                this.isSavingDefaultMessage = true;
                await DefaultMessageService.addDefaultMessage({defaultMessage: this.defaultMessage});
                this.isSavingDefaultMessage = false;

                this.hideDialog();

                //now show remaining view
                this.$store.dispatch('getDefaultMessages', '?sort=desc');
            }
        }
    }
</script>
