<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col>
                <v-row class="justify-start">
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Agent Activity Overview</h2>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <AgentActivityDataTable />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AgentActivityDataTable from "../../components/Agents/AgentActivityDataTable";

    export default {
        name: 'AgentActivity',
        components : {
            AgentActivityDataTable
        },
        data() {
            return {
                showInvitationDialog: false
            }
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
