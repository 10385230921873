<template>
  <v-dialog scrollable max-width="1111" v-model="dialog" @click:outside="closeDialog">
    <Invoice :dialog="dialog" @hideDialog="closeDialog" />
  </v-dialog>
</template>

<script>
import Invoice from "../../views/Invoice"

export default {
  name: 'InvoiceDialog',

  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Invoice
  },

  methods: {
    closeDialog() {
      this.$emit('closeInvoiceDialog', true)
    }
  },

  beforeDestroy() {
    this.$store.dispatch('setInvoice', null)
  }
}
</script>

<style scoped>
.content {
  padding-left: 250px
}
</style>
