<template>
	<div class="d-flex justify-center py-9">
		<PropertyPreview :propertyId="propertyId" />
	</div>
</template>

<script>
	import PropertyPreview from "@/components/Properties/PropertyPreview.vue";
	

    export default {
        name: 'ViewProperty',
	      props: ['propertyId'],
        components: {
	        PropertyPreview,
        },
		    data() {
	          return {
	            property: {}
		        }
		    },
    }
</script>
