<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <h3 class="mb-6">Basic Info</h3>
                <h5>Status</h5>
                <v-row class="justify-start">
                    <v-col>
                        <v-btn outlined  block
                               :color="property.is_active === 1 ? 'primary' : ''"
                               @click="updateStatus(1)"
                        >Active</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn outlined block
                               :color="property.is_active === 0 ? 'primary' : ''"
                               @click="updateStatus(0)"
                        >Inactive</v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h5>Property Name</h5>
                        <v-text-field outlined dense
                                      v-model="property.name" @change="updateProperty('name', $event)"
                                      :error-messages="nameErrors"
                                      @input="v.property.name.$touch()"
                                      @blur="v.property.name.$touch()"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12">
                        <h5>Address</h5>
                        <v-text-field outlined dense
                                      v-model="property.address_line_1"
                                      :error-messages="addressLineErrors"
                                      @blur="updateAddress('address_line_1')"
                                      @input="v.property.address_line_1.$touch()"
                                      @change="updatePropertyAddress('address_line_1', $event)"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4">
                        <h5>City</h5>
                        <v-text-field outlined dense
                                      v-model="property.city"
                                      :error-messages="cityErrors"
                                      @blur="updateAddress('city')"
                                      @input="v.property.city.$touch()"
                                      @change="updatePropertyAddress('city', $event)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <h5>State</h5>
                        <v-select outlined dense :items="states"
                                  v-model="property.state_id"
                                  :error-messages="stateErrors"
                                  item-text="name"
                                  item-value="id"
                                  @blur="updateAddress('state_id')"
                                  @change="updatePropertyAddress('state_id', $event)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <h5>Zip Code</h5>
                        <v-text-field outlined dense
                                      v-model="property.zip_code"
                                      :error-messages="zipCodeErrors"
                                      @blur="updateAddress('zip_code')"
                                      @change="updatePropertyAddress('zip_code', $event)"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12">
                        <h5>Phone Number</h5>
                        <v-text-field outlined dense
                                      type="tel"
                                      placeholder="(999) 999-9999"
                                      v-model="phoneNumber"
                                      @change="updateProperty('phone_number', $event)"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" md="6">
                        <h5>Website</h5>
                        <v-text-field outlined dense
                                      v-model="property.website"
                                      @change="updateProperty('website', $event)"
                                      @blur="formatWebsite"
                        ></v-text-field>
                        <h5>Application URL</h5>
                        <v-text-field outlined dense
                                      v-model="property.application_website"
                                      @change="updateProperty('application_website', $event)"
                                      @blur="formatWebsite"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h5>Sponsored</h5>
                        <v-btn outlined  block
                               :color="property.is_sponsored === 0 ? 'primary' : ''"
                               @click="updateSponsored(0)"
                        >No</v-btn>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h5>Sponsored</h5>
                        <v-btn outlined  block
                               :color="property.is_sponsored === 1 ? 'primary' : ''"
                               @click="updateSponsored(1)"
                        >Yes</v-btn>
                    </v-col>

                    <v-col cols="12">
                        <h5>Referral E-mail</h5>
                        <v-text-field
                            outlined dense
                            v-model="property.referral_email"
                            @change="updateProperty('referral_email', $event)"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <h5>Invoice E-mail</h5>
                        <v-text-field
                            outlined dense
                            v-model="property.invoice_email"
                            @change="updateProperty('invoice_email', $event)"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row class="justify-start pt-md-12">
                    <v-col>
                        <h5>Commission</h5>
                        <v-text-field outlined dense prefix="%"
                                      type="number"
                                      v-model="property.commission"
                                      @change="updateProperty('commission', $event)"
                                      :error-messages="commissionErrors"
                                      @blur="v.property.commission.$touch()"
                                      @input="v.property.commission.$touch()"
                                      :disabled="isValid(property.flat_rate) || isValid(property.mo_marketing_fee)"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <h5>Flat Rate</h5>
                        <v-text-field outlined dense prefix="$"
                                      type="number"
                                      v-model="property.flat_rate"
                                      @change="updateProperty('flat_rate', $event)"
                                      :error-messages="flatRateErrors"
                                      @blur="v.property.flat_rate.$touch()"
                                      @input="v.property.flat_rate.$touch()"
                                      :disabled="isValid(property.commission) || isValid(property.mo_marketing_fee)"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <h5>Mo. Marketing Fee</h5>
                        <v-text-field outlined dense prefix="$"
                                      type="number"
                                      v-model="property.mo_marketing_fee"
                                      @change="updateProperty('mo_marketing_fee', $event)"
                                      :error-messages="moMarketingFeeErrors"
                                      @blur="v.property.mo_marketing_fee.$touch()"
                                      @input="v.property.mo_marketing_fee.$touch()"
                                      :disabled="isValid(property.commission) || isValid(property.flat_rate)"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h5>About this property</h5>
                        <v-textarea outlined height="100"
                                    v-model="property.description"
                                    @change="updateProperty('description', $event)"
                                    :error-messages="descriptionErrors"
                                    @blur="v.property.description.$touch()"
                                    @input="v.property.description.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h5>Community</h5>
                        <v-textarea outlined height="100"
                                    v-model="property.community"
                                    @change="updateProperty('community', $event)"
                                    :error-messages="communityErrors"
                                    @blur="v.property.community.$touch()"
                                    @input="v.property.community.$touch()"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h5>Property Amenities</h5>
                        <v-combobox
                                v-model="selectedAmenities"
                                :items="amenities"
                                multiple
                                chips
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                @change="updateAmenities($event)"
                                :loading="isLoadingPropertyAmenity"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                        :key="JSON.stringify(data.item)"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        :disabled="data.disabled"
                                        close
                                        @click:close="data.parent.selectItem(data.item)"
                                >
                                    <v-avatar
                                            class="accent white--text"
                                            left
                                            v-text="data.item.name.slice(0, 1).toUpperCase()"
                                    ></v-avatar>
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <PropertyImages v-on:onPropertyImagesModified = updatePropertyImages($event)
                                :reset="resetImages"
                                :propertyId="property.id"
                                :is-property-saved="isPropertySaved"
                                v-on:resetCompleted="onImageResetComplete"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="mt-8">
                   <GMAutoComplete v-on:onPlaceAdded="onGoogleMapsPlaceAdded($event)"
                                   v-on:onPlaceRemoved="onGoogleMapsPlaceRemoved()"
                                   p-holder="Enter property address"
                                   :address="address"
                                   v-if="isAddNewPropertyState && !isPropertySaved"
                                   v-on:updateCoordinates="$emit('updateProperty', $event)"
                   />

                <GoogleMaps :markers="markers" height=350 v-if="!isAddNewPropertyState || isAddNewPropertyState && isPropertySaved"/>
            </v-col>
        </v-row>

        <v-dialog v-model="showPropertyAddressDialog" max-width="900" persistent>
            <v-card>
                <v-card-text>
                    <GMAutoComplete p-holder="Enter property address"
                                    :address="address"
                                    v-on:updateCoordinates="onGoogleMapsPlaceUpdated($event)"
                                    class="pt-4"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import PropertyImages from "./PropertyImages";
    import {PropertyService} from "@/services/property.service";
    import GMAutoComplete from "../GMAutoComplete";
    import GoogleMaps from "../GoogleMaps";
    import ActivityLogService from "@/services/acitivitylog.service";

    export default {
        name: 'PropertyBasicInfo',
        props: ['attemptPropertySave', 'isPropertySaved', 'property', 'v', 'reset'],

        components: {
            GoogleMaps,
            PropertyImages,
            GMAutoComplete
        },
        watch: {
            reset(shouldReset) {
                if (shouldReset === true) {
                    this.resetImages = true;
                }
            },
            attemptPropertySave: async function (attempt) {
                if (attempt === true) {
                    this.property.business_id = 1;
                    const property = await PropertyService.addNewProperty(this.property);

	                //log activity
	                await ActivityLogService.addLog({
		                userable_id: this.user.agent.id,
		                userable_type: 'App\\Models\\Agent',
		                content: `Created a new property called  ${this.property.name}`,
		                type: 'property'
	                })

                    //update the property
                    this.$emit('updateProperty', {id: property.data.id})

                    // check if image was also added
                    if (this.propertyImages.length > 0) {
                        const formData = new FormData();
                        formData.append('property_id', property.data.id)

                        this.propertyImages.forEach((image, index) => {
                            formData.append(`images[${index}]`, image.file);
                        })

                        await PropertyService.addNewPropertyImages(formData);
                    }

                    //set map markers
                    this.setMapMarkers();

                    const sort      = '?sort=desc';
                    const appendPropertyUnits = '&appendPropertyUnits=true';

                    await this.$store.dispatch('getProperties', `${sort}${appendPropertyUnits}`);
                    this.$emit('isPropertySavedCompleted', property.data);
                }
            },
            phoneNumber() {
              this.formatPhoneNumber()
            }
        },
        computed: {
	          user() {
	            return this.$store.getters.getUser;
		        },
            isAddNewPropertyState() {
                return this.$store.getters.getIsAddNewPropertyState;
            },
            amenities() {
                return this.$store.getters.getAmenities;
            },
            nameErrors () {
                const errors = []
                if (!this.v.property.name.$dirty) return errors
                !this.v.property.name.required && errors.push('Property name is required')
                return errors
            },
            addressLineErrors() {
                const errors = []
                if (!this.v.property.address_line_1.$dirty) return errors
                !this.v.property.address_line_1.required && errors.push('Address line is required');
                return errors
            },
            cityErrors() {
                const errors = []
                if (!this.v.property.city.$dirty) return errors
                !this.v.property.city.required && errors.push('City is required');
                return errors
            },
            stateErrors() {
                const errors = []
                if (!this.v.property.state_id.$dirty) return errors
                !this.v.property.state_id.required && errors.push('State is required');
                return errors
            },
            zipCodeErrors() {
                const errors = []
                if (!this.v.property.zip_code.$dirty) return errors
                !this.v.property.zip_code.required && errors.push('Zip Code is required');
                return errors
            },
            commissionErrors() {
                const errors = []
                if (!this.v.property.commission.$dirty) return errors
                !this.v.property.commission.required && errors.push('Commission percentage is required if flat rate and marketing fee is empty');
                return errors
            },
            flatRateErrors() {
                const errors = []
                if (!this.v.property.flat_rate.$dirty) return errors
                !this.v.property.flat_rate.required && errors.push('Flat rate amount is required if commission and marketing fee is empty');
                return errors
            },
            moMarketingFeeErrors() {
                const errors = []
                if (!this.v.property.mo_marketing_fee.$dirty) return errors
                !this.v.property.mo_marketing_fee.required && errors.push('Marketing fee is required if commission and flat rate is empty');
                return errors
            },
            descriptionErrors() {
                const errors = []
                if (!this.v.property.description.$dirty) return errors
                !this.v.property.description.required && errors.push('Property description is required');
                return errors
            },
            communityErrors() {
                const errors = []
                if (!this.v.property.community.$dirty) return errors
                !this.v.property.community.required && errors.push('Community description is required');
                return errors
            },
            states() {
                return this.$store.getters.getStates
            }
        },
        data () {
            return {
                propertyImages : [],
                selectedAmenities: [],
                phoneNumber: null,
                address: '',
                isLoadingPropertyAmenity: false,
                markers: [],
                resetImages: false,
                showPropertyAddressDialog: false,
                isUpdatingPropertyAddress: false
            }
        },
        methods: {
            async updateProperty(fieldName, newValue) {
                if (this.isAddNewPropertyState && !this.isPropertySaved) {
                    return
                }

                this.$store.dispatch('updateProperty', {propertyId: this.property.id, data: { [fieldName] : newValue}})

		            //log activity
		            await ActivityLogService.addLog({
			            userable_id: this.user.agent.id,
			            userable_type: 'App\\Models\\Agent',
			            content: `Updated property. Set ${fieldName} to ${newValue}`,
			            type: 'property'
		            })
            },
            async updatePropertyAddress(fieldName, newValue) {
                if (this.isAddNewPropertyState && !this.isPropertySaved) {
                    return
                }

                await this.$store.dispatch('updateProperty', {propertyId: this.property.id, data: { [fieldName] : newValue}})

                this.showPropertyAddressDialog = true;

	            //log activity
	            await ActivityLogService.addLog({
		            userable_id: this.user.agent.id,
		            userable_type: 'App\\Models\\Agent',
		            content: `Updated property ${this.property.name}. Set ${fieldName} to ${newValue}`,
		            type: 'property'
	            })
            },
            updatePropertyImages(propertyImages) {
                this.propertyImages = propertyImages;
            },
            async updateAmenities(amenities) {
                let formattedAmenities = [];
                let amenityNames = ''
                amenities.forEach(amenity => {
                    if (typeof amenity === 'object') {
                        formattedAmenities.push({amenity_id: amenity.id})
	                      amenityNames += amenity.name + ', '
                    }
                });

                if (this.isAddNewPropertyState && !this.isPropertySaved) {
                    this.$emit('updateProperty', {amenities: formattedAmenities})
                    return
                }

                if (!formattedAmenities.length) {
                    return;
                }

                PropertyService.updatePropertyAmenities(this.property.id, {amenities: formattedAmenities})

	            //log activity
	            await ActivityLogService.addLog({
		            userable_id: this.user.agent.id,
		            userable_type: 'App\\Models\\Agent',
		            content: `Added ${amenityNames} amenities to property ${this.property.name}`,
		            type: 'property'
	            })
            },
            updateAddress(field) {
                this.v.property[field].$touch();

                if (this.v.property.address_line_1.$invalid) {
                    return
                }

                this.address = `${this.property.address_line_1} ${this.property.address_line_2} ${this.property.city} ${this.getState(this.property.state_id)} ${this.property.zip_code}`
            },
            formatPhoneNumber() {
                let x = this.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

                this.property.phone_number = this.phoneNumber;
            },
            formatWebsite() {
                if (
                    this.property.website.indexOf('http') !== -1
                    || this.property.website === ''
                ) {
                    return
                }

                this.property.website = `https://${this.property.website}`;
            },
            onGoogleMapsPlaceAdded(place) {
                if (this.isAddNewPropertyState) {
                    this.property.google_place_id = place.place_id;
                    this.property.longitude       = place.geometry.location.lng();
                    this.property.latitude        = place.geometry.location.lat();
                }
            },
            onGoogleMapsPlaceUpdated: async function(place) {
                if (place.google_place_id === ''
                    || place.longitude === ''
                    || place.latitude === ''
                ) {
                    return
                }

                if (this.property.google_place_id === place.google_place_id
                    && Number(this.property.longitude) === place.longitude
                    && Number(this.property.latitude) === place.latitude
                ) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Your google maps coordinates has not changed for your address update',
                        color: 'success'
                    });

                    this.showPropertyAddressDialog = false;
                    return
                }

                let hasErrors = false;
                await PropertyService.updateProperty({
                    propertyId: this.property.id,
                    data: {
                        google_place_id: place.google_place_id,
                        longitude: place.longitude,
                        latitude: place.latitude
                    }
                }).catch(() => {
                    hasErrors = true;
                });

                if (hasErrors) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Unable to verify your new address',
                        color: 'error'
                    });

                    return
                }

                this.property.google_place_id = place.google_place_id;
                this.property.longitude       = place.longitude;
                this.property.latitude        = place.latitude;

                this.setMapMarkers();

                this.$store.commit('setSnackbar', {
                    show: true,
                    message: 'Your address has been verified',
                    color: 'success'
                });

                this.showPropertyAddressDialog = false;
            },
            onGoogleMapsPlaceRemoved() {
                if (this.isAddNewPropertyState) {
                    this.property.google_place_id = '';
                    this.property.longitude       = '';
                    this.property.latitude        = '';
                }
            },
            setMapMarkers() {
                this.markers = [{
                    position: {
                        lat: Number(this.property.latitude),
                        lng: Number(this.property.longitude)
                    },
                    icon: 'map-building-lit.svg'
                }]
            },
            updateStatus(status) {
                this.$emit('updateProperty', {is_active: status});
                this.updateProperty('is_active', status)
            },
            updateSponsored(isSponsored) {
                this.$emit('updateProperty', {is_sponsored: isSponsored});
                this.updateProperty('is_sponsored', isSponsored)
            },
            getState(stateId) {
                return this.states.filter(state => {
                    return Number(state.id) === Number(stateId)
                }).map(state => {
                    return state.name
                }).toString();
            },
            onImageResetComplete() {
                this.selectedAmenities = [];
                this.propertyImages    = [];
                this.phoneNumber       = null;
                this.address           = '';
                this.markers           = [];
                this.resetImages       = false;

                this.$emit('resetCompleted');
            },
            isValid(value) {
              return value !== null && value.length > 0
            }
        },
        async beforeMount() {
            if (!this.isAddNewPropertyState) {
                this.phoneNumber = this.property.phone_number;

                //set address
                this.address = `${this.property.address_line_1} ${this.property.address_line_2} ${this.property.city} ${this.getState(this.property.state_id)} ${this.property.zip_code}`

                //set markers
                this.setMapMarkers();

                //get selected property amenities
                let error = false
                this.isLoadingPropertyAmenity = true;
                const propertyAmenities = await PropertyService.getSelectedPropertyAmenities(this.property.id).catch(() => {
                  error = true
                })
                this.isLoadingPropertyAmenity = false;

                if (error) {
                  return
                }

                if (propertyAmenities) {
                  Object.keys(propertyAmenities).forEach(key => {
                    this.selectedAmenities.push(propertyAmenities[key].amenity)
                  })
                }

            }
        }
    }
</script>
