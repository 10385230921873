import ActivityLogService from "@/services/acitivitylog.service";

// State object
const state = {
    pagination: null,
    isLoadingPagination: false,

    paginationCurrentPage: null,
    paginationItemsPerPage: 10,

    agentFilter: null,
    agentTypeFilter: null,
    typeFilter: null,
    timeFilter: null
};

// Getter functions
const getters = {
    activityLogs( state ) {
        if (state.pagination && state.pagination.data) return state.pagination.data
        return []
    },
    isLoadingPagination(state) {
        return state.isLoadingPagination
    },
    pagination( state ) {
        return state.pagination;
    },
    paginationItemsPerPage( state ) {
        return state.paginationItemsPerPage
    }
};

// Actions
const actions = {
    async getPagination({ commit, state }) {
        let params = `?paginate=${state.paginationItemsPerPage}&user&beneficiary`;
        if (state.agentFilter) {
            params += `&userableId=${state.agentFilter}`
        }
        if (state.agentTypeFilter) {
            params += `&userableType=${state.agentTypeFilter}`
        }
        if (state.typeFilter) {
            params += `&type=${state.typeFilter.toLowerCase()}`
        }
        if (state.timeFilter && state.timeFilter.start && state.timeFilter.end) {
            params += `&startDate=${state.timeFilter.start}&endDate=${state.timeFilter.end}`
        }

        if (state.paginationCurrentPage) {
            params = `${params}&page=${state.paginationCurrentPage}`;
        }

        commit('setIsLoadingPagination', true);
        const pagination = await ActivityLogService.getLogs(params);
        commit('setPagination', pagination);
        commit('setIsLoadingPagination', false);
    }
};

// Mutations
const mutations = {
    setPagination(state, data) {
        state.pagination = data;
    },

    setIsLoadingPagination(state, data) {
        state.isLoadingPagination = data;
    },

    setState(state, data) {
        state[data.key] = data.value
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
