<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col>
                <v-row class="justify-start">
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Default Notes/Events</h2>
                        <DefaultMessageDialog action="add" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <DefaultMessageDataTable />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import DefaultMessageDataTable from "../components/General/DefaultMessages/DefaultMessageDataTable";
    import DefaultMessageDialog from "../components/General/DefaultMessages/DefaultMessageDialog";

    export default {
        name: 'DefaultMessages',
        components : {
            DefaultMessageDataTable,
            DefaultMessageDialog,
        },
        beforeMount() {
            this.$store.dispatch('getDefaultMessages', '?sort=desc');
            this.$store.dispatch('getDefaultMessageCategories');
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
