import ApiService from './api.service'

const AmenityService = {
    getAmenities: async function(data) {
        let url = 'amenities';
        if (data !== undefined) {
            url = `amenities?type=${data.type}&featured=${data.featured}`;
        }

        const response = await ApiService.get(url);
        return response.data.data
    },
    addAmenity: async function(data) {
        const response = await ApiService.post('amenity', data);
        return response.data
    },
    deleteAmenity: async function(amenityId) {
        const response = await ApiService.delete(`amenity/${amenityId}`);
        return response.data
    },
    updateAmenity: async function(amenityId, data) {
        const response = await ApiService.post(`amenity/${amenityId}`, data);
        return response.data
    },
}

export default AmenityService
