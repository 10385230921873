import CmsService from "./cms.service";

const SiteService = {
    getHomeVideos: async function() {
        CmsService.init(process.env.VUE_APP_CMS_BASE_URL + '/api')
        const response = await CmsService.get('home-page-videos?populate=%2A');
        return response.data;
    }
}

export default SiteService

export { SiteService }
