<template>
    <div v-if="status">
        <span>
            <v-chip small v-if="status.status=='Active: Shared Housing'" color="blue" text-color="white">{{status.status}}</v-chip>
            <v-chip small v-else-if="status.is_active" color="success">{{status.status}}</v-chip>
            <v-chip small color="error-pink" v-else>{{status.status}}</v-chip>
        </span>
    </div>
</template>

<script>
    export default {
  watch: {
  },
        name: 'StudentStatus',
        props: ['statusId'],
        computed: {
            statuses() {
                return this.$store.getters.getStudentStatuses;
            },
            status() {
                if (!this.statuses) {
                    return
                }

                return this.statuses.find(status => {
                    return Number(status.id) === Number(this.statusId)
                });
            }
        }
    }
</script>
