import store from "../store";

// format phone number
export function formatPhoneNumber(phoneNumber) {
    let x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

    return phoneNumber;
}

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function calculateGeoDistances(lat1, lon1, lat2, lon2, unit = 'M') {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit==="K") { dist = dist * 1.609344 }
        if (unit==="N") { dist = dist * 0.8684 }
        return dist;
    }
}


export function getGoogleDistanceToSchool(originPlaceId, destinationPlaceId, mode='DRIVING') {
    const directionsService = new window.google.maps.DirectionsService;
    directionsService.route(
        {
            origin: {'placeId': originPlaceId},
            destination: {'placeId': destinationPlaceId},
            travelMode: mode
        },
        function(response, status) {
            if (status === 'OK') {
                if (response.routes[0].legs[0].distance.text !== undefined) {
                    return response.routes[0].legs[0].distance.text;

                }
            } else {
                setTimeout(function() {
                    getGoogleDistanceToSchool(true);
                }, 5000);
            }
        });
}

export function truncateText(text, length, clamp) {
    clamp = clamp || '...';
    return text.length > length ? text.slice(0, length) + clamp : text;
}

export function arrayToIndexedObject(array, indexName) {
    return array.reduce((obj, item) => {
        obj[item[indexName]] = item;
        return obj;
    }, {});
}

export function displayMessage(type, message) {
    store.commit('setSnackbar', {
        show: true,
        message: message,
        color: type === 'success' ? 'green' : 'red'
    });
}

export function initRequests() {
    store.dispatch('getSchools', '?sort=desc&appendContacts&appendCampuses');
    store.dispatch('Campuses/getCampuses', '?appendContacts');
    store.dispatch('getSchoolContactTypes');
    store.dispatch('getAmenities');
    store.dispatch('getCountries', '?withStates');
    store.dispatch('getAgentRoles');
    store.dispatch('getAgents', '?sort=desc&appendCampuses&appendRole&appendUser');
    store.dispatch('getRentSpecials', '?sort=desc');
    store.dispatch('getNewInvoiceCount');
    store.dispatch('getNewPropertyCount');
}

export function setFilter(type, value, action) {
    store.commit(type, value);
    store.dispatch(action);
}

export function alphabeticalSort() {
    return (a,b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }
}

export function alphabeticalSortByUserObject() {
    return (agentA,agentB) => {
        const nameA = agentA.user.name.toUpperCase();
        const nameB = agentB.user.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }
}