<template>
  <div class="container">
    <div class="left">
      <div v-if="!isLoadingPagination">
        <h2>Sent Emails</h2>
        <v-menu
            v-model="showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                outlined
                append-icon="mdi-calendar"
                dense
                readonly
                v-on="on"
                :value="dates"
            ></v-text-field>
          </template>
          <v-date-picker
              range
              no-title
              v-model="dates"
              @change="getAllEmails"
          ></v-date-picker>
        </v-menu>
      </div>

      <div class="side-menu-cards">
        <div v-if="isLoadingPagination">
          <v-skeleton-loader
              type="list-item-three-line"
              class="pb-4 pr-6 pt-6"
              :min-width="$vuetify.breakpoint.lgAndUp ? '500px' : '350px'"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="list-item-three-line"
              class="pr-6"
          ></v-skeleton-loader>
        </div>
        <v-card v-for="(email, index) in emails" :key="index" class="mb-1" @click="currentEmail = email.body" v-else :max-width="$vuetify.breakpoint.lgAndUp ? '500px' : '350px'">
          <v-card-text>
            <v-row>
              <v-col><h2>{{formatName(email.to)}}</h2></v-col>
              <v-col><p>{{email.date | formatEmailDate}}</p></v-col>
            </v-row>

            <h4>{{email.subject}}</h4>
            <p>Click to view email</p>
          </v-card-text>
        </v-card>
      </div>

      <pagination class="mt-6" state="Allemails" />

    </div>

    <div class="right">
      <v-sheet>
        <v-skeleton-loader
            type="table-heading, list-item-two-line, image, table-tfoot"
            v-if="isLoadingPagination"
        ></v-skeleton-loader>
        <p v-html="currentEmail" v-else-if="currentEmail"></p>
      </v-sheet>
      <v-card v-if="!isLoadingPagination && !currentEmail" class="ml-6">
        <v-card-text class="d-flex justify-center">No emails found!</v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from "@/components/Pagination";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'AllEmails',
      components: {Pagination},
      data () {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            headers: [
                {
                    text: 'Type',
                    align: 'start',
                    value: 'type',
                },
                { text: 'Phone Number', value: 'phone' },
                { text: 'Date/Time', value: 'dateTime' },
                { text: 'Action', value: 'action' },
                { text: 'Result', value: 'result' },
                { text: 'Length', value: 'length' },
            ],
            dates: [],
            showDatePicker: false,
            currentEmail: ''
        }
      },
      computed: {
        ...mapGetters('Allemails', {
          emails: 'emails',
          isLoadingPagination: 'isLoadingPagination',
          paginationItemsPerPage: 'paginationItemsPerPage'
        }),
      },
      methods: {
        ...mapMutations('Allemails', {
          setState: 'setState'
        }),
        ...mapActions('Allemails', ['getPagination', 'updateNotification']),
          async getAllEmails() {
              if (this.dates.length === 1) {
                return
              }

              this.currentEmail    = '';
              this.showDatePicker = false;

              this.setState({key: 'dateFilter', value: this.dates})
              await this.getPagination()

              if (this.emails.length > 0) {
                  this.currentEmail = this.emails[0].body;
              }
          },
          getTimeInSeconds(time) {
              return moment.utc(Number(time) * 1000).format('HH:mm:ss');
          },
        formatName(name) {
            return (name.split('<'))[0].trim()
        }
      },
      filters: {
          formatEmailDate (value) {
              if (value) {
                  return moment(String(value)).format('l')
              }
          }
      },
      async beforeMount() {

        this.dates[0] = moment().subtract(1, 'month').format('YYYY-MM-DD')
        this.dates[1] = moment().format('YYYY-MM-DD')

        this.setState({key: 'dateFilter', value: this.dates})
        this.getAllEmails();
      }
    }
</script>

<style scoped>
.container {
  display: flex;
}
.left {
  flex-grow: 1;
}
.side-menu-cards {
  flex-grow: 1;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.right {
  flex-grow: 100;
  height: calc(100vh - 145px);
  overflow-y: auto;
  margin-top: 20px;
}

</style>
