<template>
   <div>
       <v-dialog class="preview" v-model="dialog" scrollable :max-width="$vuetify.breakpoint.mdAndUp ? '1111' : '70%'">
           <template v-slot:activator="{ on }">
               <v-avatar tile size="10" v-on="on" v-if="action === 'show'" @click="updateState('show')">
                   <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
               </v-avatar>
               <a v-on="on" v-if="action === 'show-btn' && $vuetify.breakpoint.mdAndUp" class="property-name-field" @click="updateState('show')">
                   {{actionText}}
               </a>
                <a v-else class="property-name-field" @click="openViewStudent(existingStudent)">
                   {{actionText}}
               </a>
           </template>

           <StudentPreview :existing-student="existingStudent"
                           v-on:hideDialog="dialog=false"
                           :is-dialog="true"
           />

       </v-dialog>
   </div>
</template>

<script>
    import StudentPreview from "./StudentPreview";

    export default {
        name: 'StudentDialog',
        props: ['action', 'existingStudent', 'actionText'],
        components: {StudentPreview},
        data() {
            return {
                dialog: false
            }
        },
        methods: {
            openViewStudent(student) {
			    window.open(`/#/view-student/${student.id}`,'_blank')
		    },
            updateState(state) {               
                if (state === 'add') {
                    this.$store.commit('setIsAddNewStudentState', true)
                } else {
                    this.$store.commit('setIsAddNewStudentState', false)
                }             
            }
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }

    .content {
        padding-left: 250px
    }

@media only screen and (max-width: 600px) {
    .v-dialog__content {
        align-items: flex-start;
        justify-content: flex-start;
    }
}
</style>
