<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="container">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2>{{day}}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-lazy v-for="(item, i) in loadedEvents"
                        :key="i"
                        min-height="22"
                        class="my-4"
                        :options="{
                                    threshold: 0,
                                    rootMargin: '16px 0px 16px 0px'
                                }"
                        :value="false"
                >
                  <v-card elevation="0" color="#F4F5F6">
                    <v-card-text v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-space-between py-0">
                      <div class="d-flex">
                        <a @click="openViewStudent(item.user)"><b>{{item.user.name}}:</b></a>
                        <p class="text-truncate" style="padding-left:10px; max-width: 450px;">{{truncateEventText(item.event)}}</p>
                      </div>
                      <div class="d-flex">
                        <div class="mr-4">
                          <p>Start Date: {{item.user.university_start_date | eventDate}}</p>
                        </div>
                        <div>
                            <StudentStatusTracker :student="item.user"></StudentStatusTracker>    
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-row>
                          <v-col col="10">
                            <a @click="openViewStudent(item.user)"><b>{{item.user.name}}:</b></a>
                          </v-col>
                          <v-col col="10">
                            <p class="text-truncate" style="padding-left:10px; max-width: 450px;">{{truncateEventText(item.event)}}</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <StudentStatusTracker :student="item.user"></StudentStatusTracker>    
                          </v-col>
                        </v-row>
                    </v-card-text>


                  </v-card>
                </v-lazy>
              </v-col>
            </v-row>

            <v-row >
              <v-col cols="12" align="center">
                <v-btn
                    v-if="hasMoreEvents"
                    @click="loadMoreEvents"
                >
                  Load More
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showStudentDialog" scrollable max-width="1111">
      <StudentPreview
		      v-on:hideDialog="showStudentDialog=false"
		      :existing-student="currentStudent"
		      @allEventsCompleted="$emit('allEventsCompleted')"
      />
    </v-dialog>
  </div>
</template>

<script>
    import { truncateText } from "@/utils/utils";
    import moment from "moment";
    import StudentPreview from "@/components/Students/StudentPreview";
    import StudentStatusTracker from "@/components/Students/StudentStatusTracker";

    export default {
      name: 'DashboardDayView',
      components: {StudentPreview, StudentStatusTracker},
      props: ['events', 'day'],
        data() {
            return {
                loadedEventCounter: 50,
                dashUser: require(`@/assets/icons/dash-user.svg`),
                dashFriends: require(`@/assets/icons/dash-friends.svg`),
                dashFriendsFull: require(`@/assets/icons/dash-friends-full.svg`),
                dashHouse: require(`@/assets/icons/dash-house.svg`),
                dashNote: require(`@/assets/icons/dash-note.svg`),
                dashMoney: require(`@/assets/icons/dash-money.svg`),
                dashCard: require(`@/assets/icons/dash-card.svg`),
                dashDone: require('@/assets/icons/dash-done.svg'),
                showStudentDialog: false,
                currentStudent: {}
            }
        },
        computed: {
            hasMoreEvents() {
                if (!this.events) return false

                return this.events.length > this.loadedEvents.length
            },
            loadedEvents() {
                if (!this.events) return []

                return this.events.slice(0, this.loadedEventCounter)
            }
        },
        methods: {
            truncateEventText(text) {
                return truncateText(text, 60)
            },
            loadMoreEvents() {
                this.loadedEventCounter += 50
            },
            openViewStudent(student) {
			        window.open(`/#/view-student/${student.id}`,'_blank')
		        },
            showCurrentStudent(student) {
              this.currentStudent = student;
              this.showStudentDialog = true
            }
        },
        filters: {
            eventDate(date) {
                return moment(date).format('l')
            }
        }
    }
</script>

<style scoped>
    .side-menu {
        box-shadow: 0px 1px 40px rgba(255, 65, 65, 0.18), inset 0px 3px 0px #FF4141;
    }

    .status-incomplete {
      opacity: 0.2;
    }

    .container {
        height: 60vh;
        overflow: auto;
    }
</style>
