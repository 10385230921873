<template>
	<div class="d-flex justify-center py-9">
		<InvoicePreview :invoice="invoice" v-if="this.invoice.user" />
	</div>
</template>

<script>
	import InvoicePreview from "@/components/Invoices/InvoicePreview.vue";
	import InvoiceService from '@/services/invoice.service';

    export default {
        name: 'ViewInvoice',
	      props: ['invoiceId'],
        components: {
	        InvoicePreview,
        },    
		data() {
			return {
				invoice: {}
			}
		},
		methods: {
        	async getInvoice() {
            	this.invoice = await InvoiceService.getInvoice(this.invoiceId);
        	}
    	},
		async mounted() {
			this.getInvoice();
		},
    }
</script>
