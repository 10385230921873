<template>
  <v-dialog v-if="showDialog" v-model="showDialog" max-width="600" persistent>
    <v-card>
      <v-card-title class="elevation-1">
        {{title}}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('hideDialog')"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-6">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AllFiltersWrapper',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}

</script>

<style scoped>

</style>