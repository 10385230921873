<template>
  <div v-if="school" class="d-flex justify-center py-9">
    <ExistingSchool :school="school"></ExistingSchool>
  </div>
  <v-skeleton-loader v-else type="card, table-heading, article, article, list-item-three-line" max-width="1111" class="mx-auto">
  </v-skeleton-loader>
</template>

<script>
import ExistingSchool from "@/components/Schools/ExistingSchool"
import SchoolService from '../services/school.service'

export default {
  name: 'ViewSchool',
  props: ['schoolId'],
  components: {
    ExistingSchool
  },
  data() {
    return {
      school: undefined,
      dialog: false

    }
  },
  async beforeMount() {
    this.school = await SchoolService.getSchool(this.schoolId, '?appendCampuses');
  },
}
</script>
