<template>
    <v-card class="mb-2">
       <div class="px-4">
           <v-row>
               <v-col cols="10" class="pb-0">
                   <span><b>School:</b></span><span> {{event.user && event.user.school && event.user.school.name ? event.user.school.name : 'None'}}</span>
                 <p class="font-weight-light grey--text">{{formatDate(event.event_date)}}</p>
               </v-col>

               <v-col cols="2" class="pb-0" v-if="showCheckbox && canView">
                   <v-btn small outlined color="secondary" :loading="isUpdating" @click="eventCompleted">
                       <v-icon>mdi-check</v-icon>
                   </v-btn>
               </v-col>
           </v-row>
       </div>

        <v-card-text>
            <p class="font-weight-medium">{{event.event}}</p>
        </v-card-text>
    </v-card>
</template>

<script>
    import StudentService from "../../../services/student.service";
    import moment from "moment";

    export default {
        name: "EventCard",
        props: ['event', 'showCheckbox'],
        data() {
            return {
                isUpdating: false
            }
        },
        computed: {
          user() {
            return this.$store.getters.getUser;
          },
          canView() {
            return this.user.type !== 'schoolContact'
          }
        },
        methods: {
            eventCompleted: async  function() {
                this.isUpdating = true;
                await StudentService.updateActivityEvent(this.event.id, {is_complete: 1});
                this.isUpdating = false;

                this.$emit('onEventCompleted')
            },
          formatDate(date) {
            return moment(date).format('l')
          }
        }
    }
</script>