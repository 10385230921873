import { BusinessService } from '../../services/business.service'

// State object
const state = {
    //todo remove temporary business id
    businessId: 1,
    business: {},
    isLoadingBusiness: false,
    isAddNewBusinessState: false
};

// Getter functions
const getters = {
    isLoadingBusiness( state ) {
        return state.isLoadingBusiness;
    },
    getBusiness( state ) {
        return state.business;
    },
    getBusinessId (state ) {
        // return state.business.id;

        //todo remove temporary business getter
        return state.businessId;
    },
    getIsAddNewBusinessState( state ) {
        return state.isAddNewBusinessState;
    }
};

// Actions
const actions = {
    async getBusiness({ commit }, data) {
        commit('setIsLoadingBusiness', true);
        const business = await BusinessService.getBusiness(data);
        commit('setBusiness', business);
        commit('setIsLoadingBusiness', false);
    },

};

// Mutations
const mutations = {
    setBusiness(state, data) {
        state.business = data;
    },
    setIsLoadingBusiness(state, data) {
        state.isLoadingBusiness = data;
    },
    setIsAddNewBusinessState(state, data) {
        state.isAddNewBusinessState = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
