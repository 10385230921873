import { InvoiceService } from '@/services/invoice.service'
import { PropertyService } from "@/services/property.service"
import { arrayToIndexedObject } from '@/utils/utils'

// State object
const state = {
    invoices: {},
    newInvoiceCount: 0,
    newPropertyCount: 0,
    invoiceId: null,
    isLoadingInvoices: false,
    isLoadingNewInvoiceCount: false,
    isLoadingNewPropertyCount: false
};

// Getter functions
const getters = {
    isLoadingInvoices( state ) {
        return state.isLoadingInvoices;
    },
    getInvoices( state ) {
        return state.invoices;
    },
    getInvoice( state ) {
        return state.invoiceId !== null ? state.invoices[state.invoiceId] : {};
    },
    getNewInvoiceCount( state ) {
        return state.newInvoiceCount;
    },
    getNewPropertyCount( state ) {
        return state.newPropertyCount;
    },
    getInvoicesByStatus( state ) {
        return function(status) {
            return Object.keys(state.invoices)
                .filter(key => state.invoices[key].status_sent === (status === 'sent'))
                .reduce((result, key) => (result[key] = state.invoices[key], result), {})
        }
    }
};

// Actions
const actions = {
    async getInvoices({ commit, state }, data) {
        if (!state.isLoadingInvoices) {
            commit('setIsLoadingInvoices', true);
            const invoices = await InvoiceService.getInvoices(data);
            commit('setInvoices', arrayToIndexedObject(invoices, 'id'));
            commit('setIsLoadingInvoices', false);
        }
    },
    async getNewInvoiceCount({ commit, state }) {
        if (!state.isLoadingNewInvoiceCount) {
            commit('setIsLoadingNewInvoiceCount', true);
            const invoiceCount = await InvoiceService.getInvoiceCount('?status=new');
            commit('setNewInvoiceCount', invoiceCount);
            commit('setIsLoadingNewInvoiceCount', false);
        }
    },
    async getNewPropertyCount({ commit, state }) {
        if (!state.isLoadingNewPropertyCount) {
            commit('setIsLoadingNewPropertyCount', true);
            const propertyCount = await PropertyService.getPropertyCount('?status=pending');
            commit('setNewPropertyCount', propertyCount);
            commit('setIsLoadingNewPropertyCount', false);
        }
    },
    async markInvoiceSent({ commit }, data) {
        await InvoiceService.markInvoiceSent(data.id, { data });
        commit('markInvoiceSent', data.id);
    },
    setInvoice({ commit }, data) {
        commit('setInvoice', data);
    }
};

// Mutations
const mutations = {
    setInvoices(state, data) {
        state.invoices = data;
    },
    setInvoice(state, data) {
        state.invoiceId = data;
    },
    setNewInvoiceCount(state, data) {
        state.newInvoiceCount = data;
    },
    setNewPropertyCount(state, data) {
        state.newPropertyCount = data;
    },
    setIsLoadingInvoices(state, data) {
        state.isLoadingInvoices = data;
    },
    setIsLoadingNewInvoiceCount(state, data) {
        state.isLoadingNewInvoiceCount = data;
    },
    setIsLoadingNewPropertyCount(state, data) {
        state.isLoadingNewPropertyCount = data;
    },
    markInvoiceSent(state, invoiceId) {
        if (state.invoices[invoiceId]) {
            state.invoices[invoiceId].status_sent = true;
        }
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
