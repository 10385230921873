<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col>
                <v-row class="justify-start">
                    <v-col class="justify-start d-flex">
                        <h2 class="pr-6">Agents</h2>
                        <v-btn depressed color="primary" class="text-capitalize font-weight-bold" @click="invitePeople">
                            <v-icon left size="18">mdi-plus</v-icon>
                            Invite People
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <AgentDataTable />
            </v-col>
        </v-row>

        <v-dialog v-model="showInvitationDialog" max-width="500">
            <InvitationDialog v-on:hideDialog="showInvitationDialog= false" />
        </v-dialog>
    </div>
</template>

<script>
    import InvitationDialog from "../../components/Agents/InvitationDialog";
    import AgentDataTable from "../../components/Agents/AgentDataTable";
    import {displayMessage} from "@/utils/utils";

    export default {
        name: 'AllAgents',
        components : {
            AgentDataTable,
	          InvitationDialog
        },
        data() {
            return {
                showInvitationDialog: false
            }
        },
        computed: {
            hasAddPermissions() {
                return this.$store.getters.userHasPermission('agents', 'create');
            }
        },
        methods: {
            invitePeople() {
                if (!this.hasAddPermissions) {
                    displayMessage('error', 'You do not have permission to invite an agent');
                    return
                }

                this.showInvitationDialog = true;
            }
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
