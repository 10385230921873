<template>
	<Student :student="student"
	         :is-dialog="isDialog"
	         :v="$v"
	         v-on:hideDialog="onHideDialog"
	         v-on:updateStudent="onUpdateStudent($event)"
	         @allEventsCompleted="$emit('allEventsCompleted')"
	/>
</template>

<script>
import Student from "../../views/Student";
import {email, required, maxLength, requiredIf} from "vuelidate/lib/validators";
import ActivityLogService from "@/services/acitivitylog.service";

export default {
	name: 'StudentPreview',
	validations: {
		student: {
			agent_id: {},
			name: {required},
			email: {email, required},
			email_secondary: {email},
			email_tertiary: {email},
			phone_number: {maxLength: maxLength(14)},
			phone_number_secondary: {maxLength: maxLength(14)},
			phone_number_tertiary: {maxLength: maxLength(14)},
			status_id: {},
			school_id: {required},
			campus_id: {required},
			school_contact_id: {},
			university_start_date: {},
			preferred_bedrooms: {},
			preferred_bathrooms: {},
			preferred_max_price: {},
			preferred_pet_friendly: {},
			gender: {},
			date_of_birth: {},
			ideal_roommate_gender: {
				required: requiredIf(student => {
					return student.needs_roommate
				}),
			},
			ideal_roommate_age_young: {
				required: requiredIf(student => {
					return student.needs_roommate
				}),
			},
			ideal_roommate_age_old: {
				required: requiredIf(student => {
					return student.needs_roommate
				}),
			},
		}
	},
	props: {
		existingStudent: {
			type: Object
		},
		isDialog: {
			default: false
		}
	},
	components: {Student},
	data() {
		return {
			dialog: false,
			student: {}
		}
	},
	watch: {
		existingStudent() {
			this.student = this.existingStudent
			this.logActivity()
		}
	},
	computed: {
		isAddNewStudentState() {
			return this.$store.getters.getIsAddNewStudentState;
		},
		user() {
			return this.$store.getters.getUser
		}
	},
	methods: {
		initStudent() {
			this.student = {
				school_id: '',
				campus_id: '',
				agent_id: '',
				school_contact_id: '',
				name: '',
				email: '',
				email_description: '',
				email_secondary: '',
				email_secondary_description: '',
				email_tertiary: '',
				email_tertiary_description: '',
				phone_number: '',
				phone_number_description: '',
				phone_number_secondary: '',
				phone_number_secondary_description: '',
				phone_number_tertiary: '',
				phone_number_tertiary_description: '',
				gender: null,
				date_of_birth: null,
				profile_status: 'active',
				type: 'student',
				user_inactive_reason_id: 1,
				status: '',
				university_start_date: '',
				ideal_roommate_gender: '',
				ideal_roommate_age_young: '',
				ideal_roommate_age_old: '',
				preferred_bedrooms: '',
				preferred_bathrooms: '',
				preferred_max_price: '',
				preferred_pet_friendly: '',
			};
		},
		onHideDialog() {
			if (this.isAddNewStudentState) {
				this.initStudent();
				this.$v.$reset();
			}

			this.$emit('hideDialog')
		},
		onUpdateStudent(data) {
			Object.keys(data).forEach((key) => {
				this.student[key] = data[key];
			})
		},
		needsRoommate() {
			return this.student.needs_roommate
		},
		logActivity() {
			let userable = null
			let type = null
			switch (this.user.type) {
				case 'agent':
					userable = this.user.agent
						type = 'App\\Models\\Agent'
					break
				case 'schoolContact':
					userable = this.user.school_contact
					type = 'App\\Models\\SchoolContacts'
					break
				default:
					userable = null
					break
			}

			if (userable) {
				ActivityLogService.addLog({
					userable_id: userable.id,
					userable_type: type,
					beneficiary_user_id: this.existingStudent.id,
					content: 'Viewed Student Profile',
					type: 'view'
				})
			}
		}
	},
	beforeMount() {
		if (this.isAddNewStudentState) {
			this.initStudent();
			return
		}

		this.student = this.existingStudent
		this.logActivity()
	}
}
</script>

<style scoped>
.property-name-field {
	color: #2D76E3;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
}

.content {
	padding-left: 250px
}
</style>
