<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-row class="justify-space-between">
                    <v-col>
                        <v-row>
                            <v-col cols="12" md="2"><v-btn outlined height="45"
                                                           :color="currentPanel === 'all' ? 'primary': ''"
                                                           @click="openAllPanels()"
                            >All</v-btn></v-col>
                            <v-col cols="12" md="3"><v-btn outlined height="45"
                                                           :color="currentPanel === 0 ? 'primary': ''"
                                                           @click="openPanel(0)"
                            >Referrals</v-btn></v-col>
                            <v-col cols="12" md="3"><v-btn outlined height="45"
                                                           :color="currentPanel === 1 ? 'primary': ''"
                                                           @click="openPanel(1)"
                            >Favorited</v-btn></v-col>
                            <v-col cols="12" md="3"><v-btn outlined height="45"
                                                           :color="currentPanel === 2 ? 'primary': ''"
                                                           @click="openPanel(2)"
                            >Contacted</v-btn></v-col>
                        </v-row>
                    </v-col>

                    <v-col>
                        <v-row class="justify-end">
                            <v-col cols="12" md="4">
                                <v-btn height="45" color="primary" :loading="isSendingReferrals" @click="sendReferrals">Send Referrals</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <div class="property-matches-container">
                    <div>
                        <v-expansion-panels
                                v-model="panels"
                                multiple
                                flat
                        >
                            <v-expansion-panel key="0">
                                <v-expansion-panel-header>
                                    <h3>Referred</h3>
                                </v-expansion-panel-header>
                                <v-divider class="mx-6"></v-divider>
                                <v-expansion-panel-content>
                                  <v-skeleton-loader type="card" v-if="isLoadingReferredProperties"></v-skeleton-loader>
                                  <div v-else>
                                    <StudentPropertyMatchCard
                                        v-for="(referredPropertyUnit, i) in referredPropertyUnits"
                                        :property-unit="referredPropertyUnit.property_unit"
                                        :referredPropertyUnitId="referredPropertyUnit.id"
                                        :student="student"
                                        :key="i"
                                        :index="i"
                                        :margin="'mb-4'"
                                        type="referred"
                                        :status-id="referredPropertyUnit.referral_status_id"
                                        :card-index="'referred' + i"
                                        :current-card-index="currentCardIndex"
                                        :distance-to-school="getSchoolDistance(referredPropertyUnit.property_unit)"
                                        :parentId="referredPropertyUnit.id"
                                        @removeReferredProperty="$emit('removeReferredProperty', $event)"
                                        v-on:onCardClicked="onCardClicked($event, 'referred')"
                                    />
                                  </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel key="1">
                                <v-expansion-panel-header><h3>Favorited</h3></v-expansion-panel-header>
                                <v-divider class="mx-6"></v-divider>
                                <v-expansion-panel-content>
                                    <v-skeleton-loader type="card" v-if="isLoadingFavoritedProperties"></v-skeleton-loader>
                                    <div v-else>
                                      <StudentPropertyMatchCard v-for="(favoriteProperty, i) in favoritePropertyUnits"
                                                                :property-unit="favoriteProperty.property_unit"
                                                                :student="student"
                                                                :key="i"
                                                                :margin="'mb-4'"
                                                                type="favorite"
                                                                :status-id="favoriteProperty.referral_status_id"
                                                                :card-index="'fave' + i"
                                                                :current-card-index="currentCardIndex"
                                                                :parentId="favoriteProperty.id"
                                                                :index="i"
                                                                v-on:onCardClicked="onCardClicked($event, 'favorite')"
                                      />
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel key="2">
                                <v-expansion-panel-header><h3>Contacted</h3></v-expansion-panel-header>
                                <v-divider class="mx-6"></v-divider>
                                <v-expansion-panel-content>
                                    <v-skeleton-loader type="card" v-if="isLoadingContactedProperties"></v-skeleton-loader>
                                    <div v-else>
                                        <div v-if="contactedPropertyUnits">
                                          <StudentPropertyMatchCard v-for="(contactedProperty, i) in contactedPropertyUnits"
                                                                    :property-unit="contactedProperty.property_unit"
                                                                    :student="student"
                                                                    :key="i"
                                                                    :margin="'mb-4'"
                                                                    type="contacted"
                                                                    :status-id="contactedProperty.referral_status_id"
                                                                    :card-index="'contacted' + i"
                                                                    :current-card-index="currentCardIndex"
                                                                    :parentId="contactedProperty.id"
                                                                    :index="i"
                                                                    v-on:onCardClicked="onCardClicked($event, 'contacted')"
                                          />
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="7">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col>
                                <p>Property's Referral Status</p>
                                <v-select outlined dense
                                          :disabled="!isReferedPropertySelected"
                                          :items="referralStatuses"
                                          v-model="currentPropertyUnitStatusId"
                                          item-text="status_long"
                                          item-value="id"
                                          @change="onPropertyReferralStatusUpdated($event)"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>{{propertyTitle}} ID</p>
                                <v-text-field outlined dense disabled
                                              v-model="currentPropertyUnit.id"
                                              background-color="rgba(0, 0, 0, 0.12)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Email Sent</p>
                                <v-text-field outlined dense
                                              disabled
                                              background-color="rgba(0, 0, 0, 0.12)"
                                              :value="formatDate(currentPropertyUnit.emailSentDate)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col>
                                <p>Move In Date</p>
                                <v-menu offset-y max-width="290px" min-width="290px" transition="scale-transition"
                                    v-model="moveInDateMenu"
                                    :close-on-content-click="false"
                                    :disabled="isStatusApproved && currentPropertyUnit.isReady"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined dense append-icon="mdi-calendar"
                                            :background-color="!isStatusApproved ? 'rgba(0, 0, 0, 0.12)' : ''"
                                            v-model="currentPropertyUnit.moveInDate"
                                            :disabled="!isStatusApproved"
                                            v-on="on"
                                            :error-messages="invoiceMoveInDateErrors"
                                            @input="$v.currentPropertyUnit.moveInDate.$touch()"
                                            @blur="$v.currentPropertyUnit.moveInDate.$touch()"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker no-title
                                        @input="moveInDateMenu = false"
                                        v-model="currentPropertyUnit.moveInDate"
                                        @change="updateInvoice(currentPropertyUnit.invoiceId, 'move_in_date',$event)"
                                    />
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Lease Amount</p>
                                <v-text-field outlined dense value="$"
                                    :background-color="!isStatusApproved ? 'rgba(0, 0, 0, 0.12)' : ''"
                                    v-model="currentPropertyUnit.leaseAmount"
                                    :disabled="!isStatusApproved"
                                    :error-messages="invoiceLeaseAmountErrors"
                                    @input="$v.currentPropertyUnit.leaseAmount.$touch()"
                                    @blur="$v.currentPropertyUnit.leaseAmount.$touch()"
                                    :readonly="isStatusApproved && currentPropertyUnit.isReady"
                                    @change="updateInvoice(currentPropertyUnit.invoiceId, 'monthly_rent',$event)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Unit #</p>
                                <v-text-field outlined dense
                                    :background-color="!isStatusApproved ? 'rgba(0, 0, 0, 0.12)' : ''"
                                    v-model="currentPropertyUnit.unitName"
                                    :disabled="!isStatusApproved"
                                    :error-messages="invoiceUnitNameErrors"
                                    @input="$v.currentPropertyUnit.unitName.$touch()"
                                    @blur="$v.currentPropertyUnit.unitName.$touch()"
                                    :readonly="isStatusApproved && currentPropertyUnit.isReady"
                                    @change="updateInvoice(currentPropertyUnit.invoiceId, 'unit_name',$event)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <div v-if="currentPropertyUnit.isReady">
                                  <v-icon small color="green">mdi-check-bold</v-icon>
                                  <em class="green--text pl-1 pr-2">Invoiced</em>
                                  <a @click.prevent="$router.push('/invoices')">
                                    View
                                    <v-avatar tile size="12">
                                      <v-img :src="require(`@/assets/icons/external-link.svg`)"></v-img>
                                    </v-avatar>
                                  </a>
                                </div>
                                <v-btn color="primary"
                                       :disabled="!isStatusApproved"
                                       :loading="isSendingInvoice"
                                       @click="updateInvoice(currentPropertyUnit.invoiceId, 'is_ready', 1)"
                                       v-else
                                >Invoice Ready</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import StudentPropertyMatchCard from "../../components/Students/StudentPropertyMatchCard";
    import StudentService from "../../services/student.service";
    import InvoiceService from "../../services/invoice.service";
    import {calculateGeoDistances} from "@/utils/utils";
    import moment from "moment";

    export default {
        name: "StudentCombinedPropertyUnits",
        validations: {
            currentPropertyUnit: {
                moveInDate: { required },
                leaseAmount: { required },
                unitName: { required },
            }
        },
        props: ['student', 'referredPropertyUnits','isLoadingReferredProperties', 'campus'],
        components: {
            StudentPropertyMatchCard,
        },
        data() {
            return {
                favoritePropertyUnits: [],
                contactedPropertyUnits: [],
                currentPropertyUnit: {},
                currentPropertyUnitType: '',
                currentPropertyUnitStatusId: '',
                currentReferralCardIndex: 0,
                currentReferredPropertyUnit: {},
                panels: [0, 1, 2],
                currentPanel: 'all',
                currentCardIndex: 0,
                cardIndexCurr: 0,
                currentPropertyUnitParentId: null,
                isLoadingFavoritedProperties: false,
                isLoadingContactedProperties: false,
                isSendingReferrals: false,
                isSendingInvoice: false,
                moveInDateMenu: false,
                isReferedPropertySelected: false
            }
        },
        computed: {
            user() {
              return this.$store.getters.getUser;
            },
            isStatusApproved() {
                return this.currentPropertyUnitStatusId === 3;
            },
            propertyTitle() {
                let title = ''
                if (this.currentPropertyUnitType === 'referred') {
                    title = 'Referral'
                }
                if (this.currentPropertyUnitType === 'favorite') {
                    title = 'Favorites'
                }
                if (this.currentPropertyUnitType === 'contacted') {
                    title = 'Contacted'
                }

                return title;
            },
            referralStatuses() {
                return this.$store.getters.getReferralStatuses;
            },
            invoiceMoveInDateErrors() {
                const errors = []
                if (!this.$v.currentPropertyUnit.$dirty) return errors;
                !this.$v.currentPropertyUnit.moveInDate.required && errors.push('Move in date is required');
                return errors
            },
            invoiceLeaseAmountErrors() {
                const errors = []
                if (!this.$v.currentPropertyUnit.$dirty) return errors;
                !this.$v.currentPropertyUnit.leaseAmount.required && errors.push('Lease amount is required');
                return errors
            },
            invoiceUnitNameErrors() {
                const errors = []
                if (!this.$v.currentPropertyUnit.$dirty) return errors;
                !this.$v.currentPropertyUnit.unitName.required && errors.push('Unit name is required');
                return errors
            }
        },
        methods: {
            onCardClicked(data, type) {
              this.currentCardIndex = data.cardIndex
              this.setCurrentPropertyUnit(data.index, type)
              this.currentPropertyUnitType = data.type
              this.currentPropertyUnitStatusId = data.statusId
              this.currentPropertyUnitParentId = data.parentId
              this.currentReferredPropertyUnit = this.referredPropertyUnits[data.index]
            },
            onPropertyReferralStatusUpdated: async function() {
              if (this.isStatusApproved) {
                StudentService.updateStudent(this.student.id,{status_id: 7})
                this.$store.commit('updateStudent', {
                  studentId: this.student.id,
                  key: 'status_id',
                  value: 7
                })
              }

              if (this.isStatusApproved && !this.currentPropertyUnit.isReady) {
               await this.createInitialInvoice()
              }

              let Approved_Pending_Move_In = 3;
              if(this.currentPropertyUnitStatusId == Approved_Pending_Move_In){
                  this.createMoveInDateEvent()
              }

              this.updatePropertyReferralStatus()
            },
            async updateInvoice(invoiceId, field, value) {
              await InvoiceService.updateInvoice(invoiceId, {[field]: value})

              if (field === 'move_in_date') {
                this.createMoveInDateEvent()
              }
              if (field === 'is_ready') {
                this.currentPropertyUnit.isReady = 1
                StudentService.updateStudent(this.student.id, {profile_status: 'inactive'})
                StudentService.updateStudent(this.student.id, {needs_roommate: 0})
                StudentService.updateStudent(this.student.id, {status_id: 11})
                this.$store.commit('updateStudent', {
                  studentId: this.student.id,
                  key: 'status_id',
                  value: 11
                })
              }
            },
            getReferredPropertyUnits() {
                this.$emit('fetchReferredPropertyUnits', true);
            },
            getFavoritePropertyUnits: async function () {
                this.isLoadingFavoritedProperties = true;
                this.favoritePropertyUnits        = await StudentService.getFavoritePropertyUnits(this.student.id, '?appendInvoice&appendPropertyUnit');
                this.isLoadingFavoritedProperties = false;
            },
            getContactedPropertyUnits: async function () {
                this.isLoadingContactedProperties = true;
                this.contactedPropertyUnits      = await StudentService.getContactedPropertyUnits(this.student.id, '?appendInvoice&appendPropertyUnit');
                this.isLoadingContactedProperties = false;
            },
            openPanel(panelId) {
                this.currentPanel = panelId;

                this.panels = [];
                this.panels.push(panelId)
            },
            openAllPanels() {
                this.currentPanel = 'all';

                this.panels = [];
                this.panels = [0, 1, 2];
            },
            sendReferrals: async function() {
                this.isSendingReferrals = true;
                await StudentService.sendReferrals(this.student.id);
                this.isSendingReferrals = false;

                this.getReferredPropertyUnits();
            },
            updatePropertyReferralStatus: async function() {
                if (this.currentPropertyUnitType === 'referred') {
                    await StudentService.updateReferredPropertyStatus(this.currentPropertyUnitParentId, {
                          referral_status_id: this.currentPropertyUnitStatusId
                      });

                    this.getReferredPropertyUnits();
                }
                if (this.currentPropertyUnitType === 'favorite') {
                    await StudentService.updateFavoritedPropertyUnitStatus(this.currentPropertyUnitParentId, {
                        referral_status_id: this.currentPropertyUnitStatusId
                    });

                    this.getFavoritePropertyUnits();
                }
                if (this.currentPropertyUnitType === 'contacted') {
                    await StudentService.updateContactedPropertyUnitStatus(this.currentPropertyUnitParentId, {
                        referral_status_id: this.currentPropertyUnitStatusId
                    });

                    this.getContactedPropertyUnits();
                }
            },
            getSchoolDistance(propertyUnit) {
                const distance = calculateGeoDistances(
                    Number(this.campus.latitude),
                    Number(this.campus.longitude),
                    Number(propertyUnit.property.latitude),
                    Number(propertyUnit.property.longitude)
                );

                return Number(distance).toFixed(0)
            },
            setCurrentPropertyUnit(index, type) {
                if (type === 'referred') {
                  this.setReferredCurrentPropertyUnit(index)
                }
                if (type === 'favorite') {
                  this.setFavoriteCurrentPropertyUnit(index)
                }
                if (type === 'contacted') {
                  this.setContactedCurrentPropertyUnit(index)
                }
                this.isReferedPropertySelected = true;
            },
            async createInitialInvoice() {
              const invoice = await InvoiceService.addInvoice({
                userId: this.student.id,
                unitId: this.currentPropertyUnit.id,
                unitName: this.currentPropertyUnit.unitName,
                leaseAmount: this.currentPropertyUnit.leaseAmount,
                moveInDate: this.currentPropertyUnit.moveInDate,
                referralDate: this.currentPropertyUnit.emailSentDate,
                isReady: 0,
              });
             
              this.currentPropertyUnit.invoiceId = invoice.id
            },
            formatDate(date) {
                return moment(date).format('l hh:mm:ss' )
            },
            setReferredCurrentPropertyUnit: async function(index) {
              if (!this.referredPropertyUnits[index]) {
                return
              }

              const referredPropertyUnit = this.referredPropertyUnits[index];
              const propertyUnit = referredPropertyUnit.property_unit;

              if (referredPropertyUnit.invoices && referredPropertyUnit.invoices.length > 0) {
                const invoice = await InvoiceService.getInvoice(referredPropertyUnit.invoices[0].id);

                this.currentPropertyUnit = {
                  id:             invoice.property_unit_id,
                  invoiceId:      invoice.id,
                  emailSentDate:  referredPropertyUnit.email_sent_date,
                  leaseAmount:    invoice.monthly_rent,
                  unitName:       invoice.unit_name,
                  moveInDate:     moment(invoice.move_in_date).format('YYYY-MM-DD'),
                  isReady:        invoice.is_ready,
                  propertyName:   propertyUnit ? propertyUnit.property.name : null,
                }

                return
              }

              this.setNewCurrentPropertyUnit(propertyUnit, referredPropertyUnit.email_sent_date);
            },
            setFavoriteCurrentPropertyUnit: async function(index) {
              if (!this.favoritePropertyUnits[index]) {
                return
              }

              const favoritePropertyUnit = this.favoritePropertyUnits[index];
              const propertyUnit = favoritePropertyUnit.property_unit;

              if (favoritePropertyUnit.invoices && favoritePropertyUnit.invoices.length > 0) {
                const invoice = await InvoiceService.getInvoice(favoritePropertyUnit.invoices[0].id);

                this.currentPropertyUnit = {
                  id:             invoice.property_unit_id,
                  invoiceId:      invoice.id,
                  emailSentDate:  favoritePropertyUnit.created_at,
                  leaseAmount:    invoice.monthly_rent,
                  unitName:       invoice.unit_name,
                  moveInDate:      moment(invoice.move_in_date).format('YYYY-MM-DD'),
                  isReady:        invoice.is_ready,
                  propertyName:   propertyUnit ? propertyUnit.property.name : null,
                }

                return
              }

              this.setNewCurrentPropertyUnit(propertyUnit, favoritePropertyUnit.created_at);
            },
            setContactedCurrentPropertyUnit: async function(index) {
              if (!this.contactedPropertyUnits[index]) {
                return
              }

              const contactedPropertyUnit = this.contactedPropertyUnits[index];
              const propertyUnit = contactedPropertyUnit.property_unit;

              if (contactedPropertyUnit.invoices && contactedPropertyUnit.invoices.length > 0) {
                const invoice = await InvoiceService.getInvoice(contactedPropertyUnit.invoices[0].id);

                this.currentPropertyUnit = {
                  id:             invoice.property_unit_id,
                  invoiceId:      invoice.id,
                  emailSentDate:  contactedPropertyUnit.created_at,
                  leaseAmount:    invoice.monthly_rent,
                  unitName:       invoice.unit_name,
                  moveInDate:      moment(invoice.move_in_date).format('YYYY-MM-DD'),
                  isReady:        invoice.is_ready,
                  propertyName:   propertyUnit ? propertyUnit.property.name : null,
                }

                return
              }

              this.setNewCurrentPropertyUnit(propertyUnit, contactedPropertyUnit.created_at);
            },
            setNewCurrentPropertyUnit(propertyUnit, emailSentDate) {
              this.currentPropertyUnit = {
                id:             propertyUnit ? propertyUnit.id : null,
                emailSentDate:  emailSentDate,
                leaseAmount:    propertyUnit ? propertyUnit.price : null,
                unitName:       propertyUnit ? propertyUnit.name : null,
                moveInDate:     this.student.university_start_date,
                isReady:        false,
                propertyName:   propertyUnit ? propertyUnit.property.name : null,
                created_date:   propertyUnit ? propertyUnit.created_at : null
              }
            },
          createMoveInDateEvent() {
              let eventDate = moment(this.currentPropertyUnit.moveInDate).add(1, 'day');
              //If the follow up date is a Saturday(6) or Sunday(0) move it to monday.
              if (eventDate.day() === 6 || eventDate.day() === 0) {
                eventDate = eventDate.add(3, 'days').day(1).format('YYYY-MM-DD');
              } else {
                  eventDate = eventDate.format('YYYY-MM-DD');
              }
              StudentService.addEvent({
                agent_id: this.user.agent.id,
                user_id: this.student.id,
                event_date: eventDate,
                event: `Tentative MOVE IN DATE has been set at ${this.currentPropertyUnit.propertyName}”`
              })
          }
        },
        beforeMount() {
	          if (this.referralStatuses.length === 0) {
	            this.$store.dispatch('getReferralStatuses')
		        }

            this.getReferredPropertyUnits();
            this.getFavoritePropertyUnits();
            this.getContactedPropertyUnits();
        }
    }
</script>

<style scoped>
    .property-matches-container {
        height: 500px;
        overflow-y: auto;
        padding: 2px 6px 2px 6px;
    }
</style>
