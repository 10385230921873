<template>
   <div>
       <v-dialog v-model="dialog" max-width="1111">
           <template v-slot:activator="{ on }">
               <v-avatar tile size="16" v-on="on">
                   <v-img
                          v-on:mouseover="pencilOver =true"
                          v-on:mouseleave="pencilOver =false"
                          v-bind:src="pencilOver === true ? require(`@/assets/icons/pencil-black.svg`) : require(`@/assets/icons/pencil.svg`)"
                   ></v-img>
               </v-avatar>
           </template>
           <PropertyUnitAvailabilities :property="property"
                                       v-on:onHideDialog="dialog=false"
                                       v-on:getProperties="$emit('getProperties')"
                                       source="dialog"
           />
       </v-dialog>
   </div>
</template>

<script>
    import PropertyUnitAvailabilities from "./PropertyUnitAvailabilities";

    export default {
        name: 'PropertyUnitAvailabilityDialog',
        props: ['action', 'property', 'actionText'],
        components: {PropertyUnitAvailabilities},
        data() {
            return {
                dialog: false,
                pencilOver: false
            }
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }

    .content {
        padding-left: 250px
    }
</style>
