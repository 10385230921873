import ApiService from './api.service'

const ActivityLogService = {
    getLogs: async function(params ='') {
        const response = await ApiService.get(`activity-logs${params}`);
        return response.data.data
    },
    addLog: async function(data) {
        const response = await ApiService.post('activity-log', data);
        return response.data.data
    }
}

export default ActivityLogService

export { ActivityLogService }
