<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h2 class="mb-6">Invoice ID {{ invoice.id }}</h2>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <h4>Invoice Date</h4>
                <p class="text--primary">{{ (invoice.referral_date ? invoice.referral_date :  invoice.created_at) | formatDate }}</p>
              </v-col>
              <v-col cols="6">
                <h4>Student</h4>
                <StudentDialog
                  action="show-btn"
                  :actionText="invoice.user.name"
                  :existing-student="invoice.user"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <h4>Property</h4>
                <p class="text--primary">{{ invoice.property_name }}</p>
              </v-col>
              <v-col cols="6">
                <h4>Move-In Date</h4>
                <p class="text--primary">{{ invoice.move_in_date }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <h4>Unit #</h4>
                <p class="text--primary">{{ invoice.unit_name }}</p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-space-between card-actions pa-4">
            <div>
              <h4>Invoice Total</h4>
              {{ invoiceTotal | formatPrice}}
            </div>
            <v-btn dense color="primary"
                   @click="markAsSent"
                   :loading="isMarkingAsSent"
                   v-if="!invoice.status_sent"
            >Mark As Sent</v-btn>
            <div v-if="invoice.status_sent">
              <v-icon small color="green">mdi-check-bold</v-icon>
              <em class="green--text pl-1">Invoice Sent</em>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <h2 class="mb-6">Referral Fee</h2>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <h4>Monthly Rent</h4>
                <p class="text--primary">{{ invoice.monthly_rent | formatPrice }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <h4>Commission</h4>
                <p class="text--primary">%{{ invoice.commission }}</p>
              </v-col>
              <v-col cols="4">
                <h4>Flat Rate</h4>
                <p class="text--primary">{{ invoice.flat_rate | formatPrice }}</p>
              </v-col>
              <v-col cols="4">
                <h4>Mo. Marketing Fee</h4>
                <p class="text--primary">{{ invoice.mo_marketing_fee | formatPrice }}</p>
              </v-col>
            </v-row>

            <!-- Non-breaking spaces for spacing -->
            <v-row>
              <v-col cols="4">
                <h4>{{ '\xa0' }}</h4>
                <p>{{ '\xa0' }}</p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="card-actions pa-4">
            <div>
              <h4>Total</h4>
              <p>{{ invoiceTotal | formatPrice}}</p>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import StudentDialog from '../Students/StudentDialog'
import {displayMessage} from "../../utils/utils";

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export default {
  name: 'InvoiceCard',

  components: {
    StudentDialog
  },

  props: {
    invoice: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isMarkingAsSent: false
    }
  },

  computed: {
    invoiceTotal() {
      let total = 0

      if (this.invoice.commission) {
        total += Number((this.invoice.commission / 100) * this.invoice.monthly_rent)
      }

      if (this.invoice.flat_rate) {
        total += Number(this.invoice.flat_rate)
      }

      if (this.invoice.mo_marketing_fee) {
        total += Number(this.invoice.mo_marketing_fee)
      }

      return total
    },
    hasMarkAsSentPermissions() {
      return this.$store.getters.userHasPermission('invoices', 'mark_invoice_sent');
    }
  },

  methods: {
    async markAsSent() {
      if (!this.hasMarkAsSentPermissions) {
        displayMessage('error', 'You do not have permission to mark an invoice as sent')
        return;
      }

      this.isMarkingAsSent = true
      await this.$store.dispatch('markInvoiceSent', { id: this.invoice.id })
      this.isMarkingAsSent = false
      this.$store.dispatch('getNewInvoiceCount')
    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return moment.utc(String(value)).local().format('MM/DD/YYYY hh:mm:ss')
      }
    },

    formatPrice(value) {
      return priceFormatter.format(value ? value : 0)
    }
  }
}
</script>

<style scoped>
.card-actions {
  background-color: whitesmoke;
}
</style>
