<template>
    <div>
        <h3>Rental Profile</h3>
        <v-row>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12" md="6">
                        <p>Is student profile active</p>
                        <v-btn outlined dense block
                               :color="student.profile_status === 'active' ? 'primary' : ''"
                               @click="showActiveStatusDialog = true"
                               height="40"
                               style="margin-bottom: 26px"
                               :disabled="!hasPermissions"
                        >Active</v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p style="visibility: hidden">.</p>
                        <v-btn outlined dense block
                               :color="student.profile_status === 'inactive' ? 'primary' : ''"
                               @click="showInactiveStatusDialog = true"
                               height="40"
                               style="margin-bottom: 26px"
                               :disabled="!hasPermissions"
                        >Inactive</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!isAddNewStudentState && student.profile_status === 'inactive'">
                    <v-col cols="12">
                        <p>Reason for inactivating profile</p>
                        <v-select
                                v-model="student.status_id"
                                :items="inactiveStatuses"
                                item-text="status"
                                item-value="id"
                                outlined
                                dense
                                @change="updateExistingStudent('status_id', $event)"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <p>Status Update</p>
                        <v-select
                                v-model="student.status_id"
                                :items="statuses"
                                chips
                                outlined
                                dense
                                item-text="status"
                                item-value="id"
                                :error-messages="statusErrors"
                                @input="validation.student.status_id.$touch()"
                                @blur="validation.student.status_id.$touch()"
                                @change="updateExistingStudent('status_id', $event)"
                                :readonly="!hasPermissions"
                        >
                            <template v-slot:selection="data">
                                <StudentStatus :status-id="data.item.id" />
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <p>School Name</p>
                        <v-select outlined dense
                                  :items="schools"
                                  v-model="student.school_id"
                                  item-text="name"
                                  item-value="id"
                                  :error-messages="schoolErrors"
                                  @input="validation.student.school_id.$touch()"
                                  @blur="validation.student.school_id.$touch()"
                                  @change="updateExistingStudent('school_id', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <p>Campus Name</p>
                        <v-select outlined dense
                                  :items="schoolCampuses"
                                  v-model="student.campus_id"
                                  item-text="name"
                                  item-value="id"
                                  :error-messages="campusErrors"
                                  @input="validation.student.campus_id.$touch()"
                                  @blur="validation.student.campus_id.$touch()"
                                  @change="updateExistingStudent('campus_id', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <p>Agent</p>
                        <v-select outlined dense
                                  :items="agents"
                                  item-text="user.name"
                                  item-value="id"
                                  v-model="student.agent_id"
                                  :error-messages="agentErrors"
                                  @input="validation.student.agent_id.$touch()"
                                  @blur="validation.student.agent_id.$touch()"
                                  @change="updateExistingStudent('agent_id', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="6">
                        <p>Bedrooms</p>
                        <v-select outlined dense
                                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
                                  v-model="student.preferred_bedrooms"
                                  :error-messages="bedroomErrors"
                                  @input="validation.student.preferred_bedrooms.$touch()"
                                  @blur="validation.student.preferred_bedrooms.$touch()"
                                  @change="updateExistingStudent('preferred_bedrooms', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                    <v-col cols="6">
                        <p>Bathrooms</p>
                        <v-select outlined dense
                                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
                                  v-model="student.preferred_bathrooms"
                                  :error-messages="bathroomErrors"
                                  @input="validation.student.preferred_bathrooms.$touch()"
                                  @blur="validation.student.preferred_bathrooms.$touch()"
                                  @change="updateExistingStudent('preferred_bathrooms', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <p>Max Price</p>
                        <v-select outlined dense
                                  :items="[250, 500, 750, 1000, 1250, 1500, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4250, 4500, 4750, 5000]"
                                  v-model="student.preferred_max_price"
                                  :error-messages="priceErrors"
                                  @input="validation.student.preferred_max_price.$touch()"
                                  @blur="validation.student.preferred_max_price.$touch()"
                                  @change="updateExistingStudent('preferred_max_price', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                    <v-col cols="6">
                        <p>Pets</p>
                        <v-select outlined dense
                                  :items="[{id: 1, text: 'Yes'}, {id: 0, text: 'No'}]"
                                  item-value="id"
                                  v-model="student.preferred_pet_friendly"
                                  :error-messages="petErrors"
                                  @input="validation.student.preferred_pet_friendly.$touch()"
                                  @blur="validation.student.preferred_pet_friendly.$touch()"
                                  @change="updateExistingStudent('preferred_pet_friendly', $event)"
                                  :readonly="!hasPermissions"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h5>Amenities</h5>
                        <v-combobox
                                v-model="studentAmenities"
                                :items="amenities"
                                item-text="name"
                                multiple
                                chips
                                outlined
                                dense
                                @change="updateAmenities($event)"
                                :loading="isLoadingStudentAmenities"
                                :readonly="!hasPermissions"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                        :key="JSON.stringify(data.item)"
                                        v-bind="data.attrs"
                                        close
                                        :input-value="data.selected"
                                        :disabled="data.disabled"
                                        @click:close="data.parent.selectItem(data.item)"
                                >
                                    <v-avatar
                                            class="accent white--text"
                                            left
                                            v-text="data.item.name.slice(0, 1).toUpperCase()"
                                    ></v-avatar>
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-row>

              <v-row>
                <v-col cols="12">
                  <p>Estimated Start Date</p>
                  <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="student.university_start_date"
                          outlined
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-on="on"
                          :error-messages="universityStartDateErrors"
                          @input="validation.student.university_start_date.$touch()"
                          @blur="validation.student.university_start_date.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="student.university_start_date" no-title @input="startDateMenu = false" :readonly="!hasPermissions" @change="updateExistingStudent('university_start_date', $event)"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <p>Admission Rep</p>
                  <v-select outlined dense
                            v-model="student.school_contact_id"
                            :items="schoolReps"
                            :readonly="!hasPermissions"
                            item-text="name"
                            item-value="id"
                            :error-messages="schoolContactErrors"
                            @input="validation.student.school_contact_id.$touch()"
                            @blur="validation.student.school_contact_id.$touch()"
                            @change="updateExistingStudent('school_contact_id', $event)"
                  ></v-select>
                </v-col>
              </v-row>

            </v-col>
        </v-row>

        <v-dialog v-model="showInactiveStatusDialog" max-width="300">
            <v-card>
               <v-card-text>
                   <p class="pt-4">Reason for inactivating profile</p>
                   <v-select
                           v-model="student.status_id"
                           :items="inactiveStatuses"
                           item-text="status"
                           item-value="id"
                           outlined
                           dense
                           @change="processInactiveDialog($event)"
                           :readonly="!hasPermissions"
                   ></v-select>

                   <v-btn block color="primary" @click="showInactiveStatusDialog = false">Cancel</v-btn>
               </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showActiveStatusDialog" max-width="300">
            <v-card>
               <v-card-text>
                   <p class="pt-4">Status Update</p>
                   <v-select
                           v-model="student.status_id"
                           :items="activeStatuses"
                           item-text="status"
                           item-value="id"
                           chips
                           outlined
                           dense
                           @change="processActiveDialog($event)"
                           :readonly="!hasPermissions"
                   >
                       <template v-slot:selection="data">
                           <StudentStatus :status-id="data.item.id" />
                       </template>
                   </v-select>

                   <v-btn block color="primary" @click="showActiveStatusDialog = false">Cancel</v-btn>
               </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import StudentStatus from "../StudentStatus";
    import StudentService from "../../services/student.service";
    import {mapActions, mapGetters} from "vuex";
    import {alphabeticalSort} from "@/utils/utils";

    export default {
        name: "StudentRentalProfile",
        props: ['student', 'validation'],
        components: {
            StudentStatus
        },
        data() {
            return {
                isLoadingStudentAmenities: false,
                startDateMenu: false,
                studentAmenities: [],
                showInactiveStatusDialog: false,
                showActiveStatusDialog: false
            }
        },
        computed: {
            ...mapGetters('Campuses', {
              campuses: 'campuses'
            }),
            schoolCampuses() {
              return this.campuses.filter(c => c.school_id === this.student.school_id)
            },
            agents() {
              return this.$store.getters.getAgents;
            },
            isAddNewStudentState() {
              return this.$store.getters.getIsAddNewStudentState;
            },
            statuses() {
                return this.$store.getters.getStudentStatuses;
            },
            activeStatuses() {
                return this.statuses.filter(status => {
                    return status.is_active === 1
                });
            },
            inactiveStatuses() {
                return this.statuses.filter(status => {
                    return status.is_active === 0
                });
            },
            amenities() {
                return this.$store.getters.getAmenities;
            },
            schools() {
                return this.$store.getters.getSchools
            },
            agentErrors() {
                const errors = []
                if (!this.validation.student.agent_id.$dirty) return errors
                return errors
            },
            statusErrors() {
                const errors = []
                if (!this.validation.student.status_id.$dirty) return errors
                return errors
            },
            schoolErrors() {
                const errors = [];
                if (!this.validation.student.school_id.$dirty) return errors
                return errors
            },
            campusErrors() {
                const errors = [];
                if (!this.validation.student.campus_id.$dirty) return errors
                return errors
            },
            schoolContactErrors() {
                const errors = [];
                if (!this.validation.student.school_contact_id.$dirty) return errors
                return errors
            },
            universityStartDateErrors() {
                const errors = [];
                if (!this.validation.student.university_start_date.$dirty) return errors
                return errors
            },
            bedroomErrors() {
                const errors = [];
                if (!this.validation.student.preferred_bedrooms.$dirty) return errors
                return errors
            },
            bathroomErrors() {
                const errors = [];
                if (!this.validation.student.preferred_bathrooms.$dirty) return errors
                return errors
            },
            priceErrors() {
                const errors = [];
                if (!this.validation.student.preferred_max_price.$dirty) return errors
                return errors
            },
            petErrors() {
                const errors = [];
                if (!this.validation.student.preferred_pet_friendly.$dirty) return errors
                return errors
            },
            hasPermissions() {
                return this.$store.getters.userHasPermission('students', 'update');
            },
            schoolReps() {
                const school = this.schools.find(s => s.id === this.student.school_id);
                if (!school) return [];

                return school.contacts.filter(sc => {
                  return sc.campuses.filter(scc => scc.id === this.student.campus_id).length > 0
                }).sort(alphabeticalSort())

            }
        },
        methods: {
            ...mapActions('Campuses', {
              getCampuses: 'getCampuses'
            }),
            handleProfileStatus(text) {
                this.student.profile_status = text

                if (!this.isAddNewStudentState) {
                    this.updateExistingStudent('profile_status', text)
                }
            },
            updateExistingStudent(field, value) {
                this.$emit('updateExistingStudent', {[field]: value});
            },
            updateAmenities: async function(amenities) {
                this.student['amenities'] = [];
                amenities.forEach(amenity => {
                    this.student.amenities.push({id: amenity.id})
                });

                if (!this.isAddNewStudentState) {
                    this.isLoadingStudentAmenities = true;
                    await StudentService.updateStudentAmenities(this.student.id, {amenities: amenities})
                    this.isLoadingStudentAmenities = false;
                }
            },
            processInactiveDialog(inactiveStatusId) {
                this.handleProfileStatus('inactive');
                this.updateExistingStudent('status_id', inactiveStatusId);

                this.showInactiveStatusDialog = false;
            },
            processActiveDialog(status) {
                this.handleProfileStatus('active');
                this.updateExistingStudent('status_id', status);

                this.showActiveStatusDialog = false;
            }
        },
        beforeMount() {
	          if (this.statuses.length === 0) {
	            this.$store.dispatch('getStudentStatuses')
		        }
            if (this.campuses.length === 0) {
              this.getCampuses()
            }
            if (this.$store.getters.getIsAddNewStudentState) {
                return;
            }

            this.isLoadingStudentAmenities = true;
            StudentService.getStudentAmenities(this.student.id).then(studentAmenities => {
                studentAmenities.forEach(studentAmenity => {
                    this.amenities.forEach(amenity => {
                        if (Number(amenity.id) === Number(studentAmenity.amenity_id)) {
                            this.studentAmenities.push(amenity)
                        }
                    })
                })

                this.isLoadingStudentAmenities = false;
            })

        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
</style>