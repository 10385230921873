<template>
    <div class="px-8">
        <v-row>
            <v-col cols="12" md="2">
                <p>Type</p>
                <v-select dense
                          outlined
                          v-model="type"
                          :items="contactTypes"
                          @change="filterResults('typeFilter', $event)"
                />
            </v-col>
            <v-col cols="12" md="2">
                <p>Status</p>
              <v-select dense
                        outlined
                        v-model="status"
                        :items="statuses"
                        @change="filterResults('statusFilter', $event)"
              />
            </v-col>
            <v-col cols="12" md="2" class="pt-10">
              <v-btn text color="primary" @click="resetFilters">
                Clear Filters
              </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="notifications"
            :page.sync="page"
            :items-per-page="paginationItemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="isLoadingNotifications || isMarkingAsRead"
        >
          <template v-slot:item.agent="props">
            {{props.item.user && props.item.user.agent && props.item.user.agent.user && props.item.user.agent.user.name ? props.item.user.agent.user.name : 'Agent not found'}}
          </template>
          <template v-slot:item.user="props">
            <a @click="showCurrentStudent(props.item)" v-if="props.item.user && props.item.user.name">{{props.item.user.name}}</a>
            <span v-else>Student not found</span>
          </template>
          <template v-slot:item.status_read="props">
            <v-chip color="success" v-if="props.item.status_read" class="chip">Read</v-chip>
            <v-chip color="info" v-else class="chip">Unread</v-chip>
          </template>
          <template v-slot:item.preview="props">
            <a @click.prevent="displayNotificationMessageDialog(props.item)">
              {{truncateText(props.item.content)}}
            </a>
          </template>
          <template v-slot:item.created_at="props">
            {{formatDate(props.item.created_at)}}
          </template>
          <template v-slot:item.is_read="props">
            <v-hover v-slot="{hover}">
              <v-btn
                  class="ma-2"
                  outlined
                  fab
                  small
                  :color="hover ? 'success' : 'primary'"
                  v-if="props.item.status_read===0"
                  @click="markNotificationAsRead(props.item)"
                  :elevation="hover ? 12 : 0"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-hover>
          </template>
        </v-data-table>

				<Pagination state="Notification" />

      <v-dialog v-model="showStudentDialog" scrollable max-width="1111">
        <StudentPreview v-on:hideDialog="showStudentDialog=false" :existing-student="currentStudent"/>
      </v-dialog>

      <v-dialog width="700" scrollable v-model="showNotificationMessageDialog">
        <NotificationMessage :notification="currentNotification"
                             @hideDialog="showNotificationMessageDialog=false"
        />
      </v-dialog>

      <v-dialog width="500" v-model="showNotificationDialog">
        <Notification message="You are about to mark this notification as read."
                      title="Update Notification"
                      color="amber"
                      v-on:hideDialog="showNotificationDialog=false"
                      v-on:continue="updateNotificationRecord"
        />
      </v-dialog>
    </div>
</template>

<script>
    import StudentPreview from "@/components/Students/StudentPreview"
    import Pagination from "@/components/Pagination";
    import moment from "moment"
    import {mapGetters, mapActions, mapMutations} from 'vuex'
    import {truncateText} from "@/utils/utils";
    import NotificationMessage from "@/components/MessageCenter/NotificationMessage";
    import Notification from "@/components/Shared/Notification";


    export default {
        name: 'Notifications',
        components: {StudentPreview, Pagination, NotificationMessage, Notification},
        data () {
            return {
                pages: [5, 10, 15, 20, 25, 30],
                page: 1,
                headers: [
                    {
                        text: 'Agent',
                        align: 'start',
                        value: 'agent'
                    },
                  { text: 'Student', value: 'user' },
                  { text: 'Notification Type', value: 'type' },

                  { text: 'Phone Number', value: 'phone_number' },
                  { text: 'Preview', value: 'preview' },
                    { text: 'Status', value: 'status_read' },
                    { text: 'Date', value: 'created_at' },
                    { text: 'Mark As Read', value: 'is_read' },
                ],
                contactTypes: ['SMS', 'Call Logs'],
                currentStudent: null,
                showStudentDialog: false,
                statuses: [
                  {
                    text: 'Read',
                    value: 1
                  },
                  {
                    text: 'Unread',
                    value: 0
                  }
                ],
                type: null,
                status: null,
                isMarkingAsRead: false,
                showNotificationMessageDialog: false,
                showNotificationDialog: false,
                currentNotification: null
            }
        },

        computed: {
          ...mapGetters('Notification', {
            pagination: 'pagination',
            isLoadingNotifications: 'isLoadingNotifications',
            paginationItemsPerPage: 'paginationItemsPerPage'
          }),
          notifications() {
            if (!this.pagination) return []
            return this.pagination.data
          },
          user() {
            return this.$store.getters.getUser;
          }
        },
        methods: {
          ...mapMutations('Notification', {
            setState: 'setState'
          }),
          ...mapActions('Notification', ['getPagination', 'updateNotification']),
          showCurrentStudent(notification) {
            this.currentStudent = notification.user;
            this.showStudentDialog = true
          },
          formatDate(date) {
            return moment(date).format('l')
          },
          filterResults(key, value) {
            this.setState({key, value})
            this.getPagination()
          },
          resetFilters() {
            this.type = null
            this.status = null

            this.setState({key: 'statusFilter', value: null})
            this.setState({key: 'typeFilter', value: null})

            this.getPagination()
          },
          markNotificationAsRead(notification) {
            this.currentNotification = notification
            this.showNotificationDialog = true
          },
          updateNotificationRecord() {
            this.updateNotification({id: this.currentNotification.id, key: 'status_read', value: 1})
            this.showNotificationDialog = false
          },
          updatePageEntries(count) {
            this.setState({key: 'paginationItemsPerPage', value: count})
            this.setState({key: 'paginationCurrentPage', value: 1})
            this.getPagination()
          },
          truncateText(text) {
            return truncateText(text, 15)
          },
          displayNotificationMessageDialog(notification) {
            this.currentNotification = notification
            this.showNotificationMessageDialog = true
          }
        },
        beforeMount() {
             if (!this.pagination) {
               this.getPagination()
             }
        }
    }
</script>

<style scoped>
  .chip {
    width: 80px;
    display: flex;
    justify-content: center;
  }

  a {
    font-size: 14px
  }
</style>
