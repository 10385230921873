import ApiService from './api.service'

const AppService = {
    getCountries: async function(params='') {
        const response = await ApiService.get(`countries${params}`);
        return response.data.data
    },
}

export default AppService

export { AppService }
