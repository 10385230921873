<template>
  <v-app>
    <v-navigation-drawer
            v-if="isUserLoggedIn"
            v-model="drawer"
            :color="color"
            :expand-on-hover="expandOnHover"
            :mini-variant="mini"
            :right="right"
            :permanent="permanent"
            :src="bg"
            width="240"
            app
            dark
            :fixed="true"
            :height="$vuetify.breakpoint.smAndDown ? '100%' : '100vh'"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="mini && 'px-0'">
          <v-list-item-avatar @click="miniVariant = !miniVariant" v-if="miniVariant">
            <img src="../src/assets/logo.svg">
          </v-list-item-avatar>

          <v-list-item-content class="pb-10">
            <v-img src="../src/assets/logo.svg"
                   @click="miniVariant = !miniVariant"
                   v-if="!miniVariant"></v-img>
          </v-list-item-content>
        </v-list-item>

	      <!----Side Menus-------->
        <div v-for="item in sideMenus" :key="item.title">
	        <v-divider v-if="item.type === 'divider'" class="my-2"></v-divider>
          <v-list-item
                  v-if="item.type === 'single'"
                  link
                  :to="item.route"
          >
            <v-list-item-avatar tile size="18">
              <v-img :src="require(`@/assets/icons/${item.icon}.svg`)"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}

                <!-- Display Chip for Invoices -->
                <v-chip x-small color="red" text-color="white" class="ml-1 px-1"
                        v-if="item.title === 'Invoices' && newInvoiceCount > 0"
                >
                  {{ newInvoiceCount }}
                </v-chip>

                <v-chip x-small color="red" text-color="white" class="ml-1 px-1"
                        v-if="item.title === 'Properties' && newPropertyCount > 0"
                >
                  {{ newPropertyCount }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!----For Nested Groups---->
          <v-list-group
                  :value="false"
                  v-if="item.type === 'nested'"
                  no-action
          >
            <template v-slot:activator>
              <v-list-item-avatar tile size="18">
                <v-img :src="require(`@/assets/icons/${item.icon}.svg`)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <div v-for="subItem in item.subMenu" :key="subItem.title">
              <v-list-item
                      v-if="subItem.type === 'single' && hasViewPermissions(subItem.entity)"
                      :key="subItem.title"
                      link
                      :to="subItem.route"
                      class="pl-md-12"
                      @click="showDialog(subItem.title)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

          </v-list-group>
        </div>

      </v-list>
    </v-navigation-drawer>

    <v-content>
      <NavHeader v-if="isUserLoggedIn" />
      <router-view/>
    </v-content>
    <Snackbar />

	  <v-dialog v-model="showTimeoutDialog" width="400">
		  <timeout-dialog></timeout-dialog>
	  </v-dialog>
  </v-app>
</template>
<script>

import Snackbar from "./components/Snackbar";
import NavHeader from "./components/NavHeader";
import {mapActions} from "vuex";
import TimeoutDialog from "@/components/TimeoutDialog";

export default {
  name: 'App',
  components: {TimeoutDialog, Snackbar, NavHeader},
  data () {
    return {
      drawer: true,
      color: '#1E2837',
      right: false,
      permanent: true,
      expandOnHover: this.$vuetify.breakpoint.smAndDown,
      background: false,
      showMyProfileDialog: false,
      agent: {},
      miniVariant: false
    }
  },
  computed: {
    bg () {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    newInvoiceCount() {
      return this.$store.getters.getNewInvoiceCount;
    },
    newPropertyCount() {
      return this.$store.getters.getNewPropertyCount;
    },
    sideMenus() {
      return this.$store.getters.getAgentSideMenus;
    },
	  showTimeoutDialog() {
    	return false
    	//return this.isUserLoggedIn && this.$store.state.idleVue.isIdle
	  },
    mini: {
      get() {
        return this.$vuetify.breakpoint.smAndDown ? true : this.miniVariant
      },
      set(value) {
        this.mini = value
      }
    }
  },
  methods: {
    ...mapActions('Notification', ['getRecords', 'getPaginatedRecords']),
    showDialog(title) {
      if (title === 'My Profile') {
        this.showMyProfileDialog = true;
      }
    },
    hasViewPermissions(entity) {
      return this.$store.getters.userHasPermission(entity, 'view');
    }
  },

  mounted() {
    window.Echo.channel('edurent').listen('NewStudentRegistration', (e) => {
      const name = e && e.user && e.user.name ? e.user.name : 'A new student'
      this.$store.commit('setSnackbar', {
        show: true,
        message: `${name} just registered with EDUrent`,
        color: 'success',
        timeout: 8000
      });
    })

    window.Echo.channel('edurent').listen('NewPropertySignUp', (e) => {     
      const propertyName = e && e.property ? e.property : 'A new student'
      this.$store.commit('setSnackbar', {
        show: true,
        message: `${propertyName} just signed up with EDUrent`,
        color: 'success',
        timeout: 8000
      });
    })

    window.Echo.channel('edurent').listen('NewAgentRegistration', (e) => {
      const name = e && e.user && e.user.name ? e.user.name : 'A new agent'
      this.$store.commit('setSnackbar', {
        show: true,
        message: `${name} just registered with EDUrent`,
        color: 'success',
        timeout: 8000
      });
    })

    window.Echo.channel('edurent').listen('NewSmsReceived', async (e) => {
      await this.getRecords()
      await this.getPaginatedRecords()

      const name = e && e.user && e.user.name ? e.user.name : 'Someone'
      this.$store.commit('setSnackbar', {
        show: true,
        message: `${name} just sent an SMS`,
        color: 'success',
        timeout: 8000
      });
    })
  }
};
</script>
<style>

  .mobile-drawer {
    position: relative;
    top: 0;
    left: 0;
  } 

  body, .v-application {
    font-family: 'Poppins', sans-serif !important;
  }
  a {
    text-decoration: none !important;
  }
  .v-application p {
    margin-bottom: 0 !important;
  }

  h1, h2, h3, h4, h5 {
    color: black;
  }

  /* RICO ADJUSTMENTS */

  .theme--light.v-application {
    background: #f4f5f6 !important;
  }

  .v-btn {
    letter-spacing: 0;
  }

  .v-text-field--outlined fieldset {
    border-color: #d8d8d8 !important;
  }

  .v-text-field input,
  .v-text-field label {
    font-size: 0.85em;
  }

  .v-application .page-style-proto .table-proto.elevation-1 {
    outline: none !important;
    box-shadow: unset !important;
    background: transparent !important;
  }

  .page-style-proto .table-proto table {
    border-collapse: separate;
    border-spacing:0 15px;
  }

  .page-style-proto .table-proto table td {
    border-bottom: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background-color: white;
    border-bottom: 1px solid #eee !important;
    border-top: 1px solid #eee !important;
  }

  .page-style-proto .table-proto table tr td:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #eee !important;
  }

  .page-style-proto .table-proto table tr td:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px solid #eee !important;
  }

  .theme--light.v-data-table thead tr th {
    color: rgba(0, 0, 0, 1);
  }

  .search-box .v-input__icon--append .v-icon {
    color: #1976d2;
  }

  .page-style-proto .theme--light.v-chip.error-pink:not(.v-chip--active) {
    background: #e84083;
  }

  .page-style-proto .theme--light.v-chip.error-pink {
    color: #fff;
  }

  .page-style-proto .v-pagination li {
  }

  .page-style-proto .v-pagination li button {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    opacity: 1 !important;
    /*width: 50px;*/
    height: 40px;
    border-radius: 0px;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
  }

  .page-style-proto .v-pagination li:first-child button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid #d8d8d8;
  }

  .page-style-proto .v-pagination li:last-child button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

</style>
