<template>
	<div class="d-flex justify-center py-9">
		<StudentPreview :existing-student="student" v-on:hideDialog="dialog=false"/>
	</div>
</template>

<script>
    import StudentService from "@/services/student.service";
    import StudentPreview from "@/components/Students/StudentPreview";

    export default {
        name: 'ViewStudent',
	      props: ['studentId'],
        components: {
	        StudentPreview,
        },
		    data() {
	          return {
	            student: {}
		        }
		    },
        beforeMount: async function() {
					this.student = await StudentService.getStudent(this.studentId, '')
        }
    }
</script>
