const state = () => ({
    propertyUnits: [],
})

const getters = {
    getGoogleMapsPropertyUnits: (state) => {
        return state.propertyUnits
    },
}

const actions = {}

const mutations = {
    setGoogleMapsPropertyUnits (state, propertyUnits) {
        state.propertyUnits = propertyUnits
    }
}


export default {
    // namespace: true,
    state,
    getters,
    actions,
    mutations
}
