import AmenityService from '../../services/amenity.service'

// State object
const state = {
    amenities: [],
    isLoadingAmenities: false
};

// Getter functions
const getters = {
    getAmenities( state ) {
        return state.amenities;
    },
    getIsLoadingAmenities( state ) {
        return state.isLoadingAmenities;
    }
};

// Actions
const actions = {
    async getAmenities({ commit }, data) {
        commit('setLoadingAmenities', true)
        const amenities = await AmenityService.getAmenities(data);
        commit('setAmenities', amenities)
        commit('setLoadingAmenities', false)

        return amenities.data;
    },
};

// Mutations
const mutations = {
    setAmenities(state, data) {
        state.amenities = data;
    },
    setLoadingAmenities(state, data) {
        state.isLoadingAmenities = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
