import { StudentService } from '@/services/student.service'

// State object
const state = {
    students: [],
    statuses: [],
    referralStatuses: [],

    isLoadingStudents: false,
    isLoadingStudentStatus: false,
    isAddNewStudentState: false,
    isLoadingReferralStatuses: false,

    profileStatusFilter: null,
    statusFilter: null,
    agentFilter: null,
    schoolFilter: null,
    campusFilter: null,
    searchFilter: null,
    schoolContactFilter: null,
    startDateRangeFilter: null,
    paidStatusFilter: null,

    totalNoFilter: 0,
    currentPage: null,
    studentsPerPage: 10
};

// Getter functions
const getters = {
    isLoadingStudents( state ) {
        return state.isLoadingStudents;
    },
    getStudents( state ) {
        return state.students.data;
    },
    getStudentsTotal( state ) {
        return state.students.total;
    },
    getCurrentPage( state ) {
        return state.students.current_page;
    },
    getTotalNoFilter( state ) {
        return state.totalNoFilter;
    },
    getStudentsPerPage( state ) {
        return state.studentsPerPage;
    },
    getStudentStatuses( state ) {
        return state.statuses;
    },
    getStudentStatusByName( state ) {
        return statusName => {
            const statusIndex = state.statuses.findIndex(status => status.status === statusName)
            return state.statuses[statusIndex];
        }
    },
    getIsAddNewStudentState( state ) {
        return state.isAddNewStudentState;
    },
    getReferralStatuses ( state ) {
        return state.referralStatuses;
    },
    //filters
    getStudentProfileStatusFilter() {
        return state.profileStatusFilter;
    },
    getStudentStatusFilter() {
        return state.statusFilter;
    },
    getStudentAgentFilter() {
        return state.agentFilter;
    },
    getStudentSchoolFilter() {
        return state.schoolFilter;
    },
    getStudentCampusFilter() {
        return state.campusFilter;
    },
    getStudentSchoolContactsFilter() {
        return state.schoolContactFilter;
    },
    getStartDateFilter() {
        return state.startDateRangeFilter;
    },
    getPaidStatusFilter() {
        return state.paidStatusFilter;
    },
    getIsFiltered() {
        if( state.profileStatusFilter === null  &&
            state.statusFilter === null &&
            state.agentFilter === null &&
            state.schoolFilter === null &&
            state.searchFilter === null &&
            state.schoolContactFilter === null &&
            state.startDateRangeFilter === null) {
                return false
            } else {
                return true
            }
    },
    getActiveFiltersParams() {
        return (currentUser, params = '') => {
            params = `${params !== '' ? params + '&' : '?'}type=student&appendSchool=true`;

            if (state.profileStatusFilter) {
                params = `${params}&profileStatus=${state.profileStatusFilter}`
            }
            if (state.statusFilter) {
                params = `${params}&statusId=${state.statusFilter}`
            }
            if (state.agentFilter) {
                params = `${params}&agentId=${state.agentFilter}`
            }
            if (state.schoolFilter) {
                params = `${params}&schoolId=${state.schoolFilter}`
            }
            if (state.campusFilter) {
                params = `${params}&campusId=${state.campusFilter}`
            }
            if (state.schoolContactFilter) {
                params = `${params}&schoolContactId=${state.schoolContactFilter}`
            }
            if (state.startDateRangeFilter) {
                params = `${params}&startDateRange=${state.startDateRangeFilter}`
            }
            if (state.searchFilter) {
                params = `${params}&search=${state.searchFilter}`
            }
            if (state.paidStatusFilter) {
                params = `${params}&paidStatus=${state.paidStatusFilter}`
            }
            if (currentUser.type === 'schoolContact') {
                const campusIds = currentUser.school_contact.campuses.map(scc => scc['id'])
                params = `${params}&campusIds=${campusIds}`
            }

            return params
        }
    }
};

// Actions
const actions = {
    async getStudents({ commit, rootState, getters }) {
        let params = `?paginate=${state.studentsPerPage}`

        if (state.currentPage) {
            params = `${params}&page=${state.currentPage}`
        }

        params = getters.getActiveFiltersParams(rootState['User'].user, params)

        commit('setIsLoadingStudents', true);
        const pagination = await StudentService.getStudents(params);
        commit('setStudents', pagination);
        commit('setIsLoadingStudents', false);
        if(!getters.getIsFiltered) {
            commit('setTotalNoFilter', state.students.total);
        }
    },
    async getStudentStatuses({ commit }, data) {
        commit('setIsLoadingStudentStatus', true);
        const statuses = await StudentService.getStudentStatuses(data);
        commit('setStudentStatuses', statuses);
        commit('setIsLoadingStudentStatus', false);
    },
    async getReferralStatuses({ commit }, studentId) {
        commit('setIsLoadingReferralStatuses', true);
        const referralStatuses = await StudentService.getReferralStatuses(studentId);
        commit('setReferralStatuses', referralStatuses);
        commit('setIsLoadingReferralStatuses', false);
    }
};

// Mutations
const mutations = {
    setStudents(state, data) {
        state.students = data;
    },

    setTotalNoFilter(state, data) {
        state.totalNoFilter = data;
    },

    setIsLoadingStudents(state, data) {
        state.isLoadingStudents = data;
    },

    setStudentStatuses(state, data) {
        state.statuses = data;
    },

    setIsLoadingStudentStatus(state, data) {
        state.isLoadingStudentStatus = data;
    },

    setIsAddNewStudentState(state, data) {
        state.isAddNewStudentState = data;
    },

    //referral statuses
    setIsLoadingReferralStatuses(state, data) {
        state.isLoadingReferralStatuses = data;
    },
    setReferralStatuses(state, data) {
        state.referralStatuses = data;
    },

    //filters
    setProfileStatusFilter(state, data) {
        state.profileStatusFilter = data
    },
    setStatusFilter(state, data) {
        state.statusFilter = data
    },
    setAgentFilter(state, data) {
        state.agentFilter = data
    },
    setSchoolFilter(state, data) {
        state.schoolFilter = data
    },
    setStudentCampusFilter(state, data) {
        state.campusFilter = data
    },
    setSchoolContactFilter(state, data) {
        state.schoolContactFilter = data
    },
    setStartDateRangeFilter(state, data) {
        state.startDateRangeFilter = data
    },
    setStudentSearchFilter(state, data) {
        state.searchFilter = data
    },
    setStudentCurrentPage(state, data) {
        state.currentPage = data
    },
    setStudentsPerPage(state, data) {
        state.studentsPerPage = data
    },
    setPaidStatusFilter(state, data) {
        state.paidStatusFilter = data
    },
    updateStudent(state, data) {
        if (state.students.length === 0) return

        const student = state.students.data.find(s => s.id === data.studentId)
        if (!student) return

        student[data.key] = data.value
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
