<template>
   <div>
       <v-dialog v-model="dialog" scrollable :max-width="$vuetify.breakpoint.mdAndUp ? '1111' : '70%'" v-if="action">
           <template v-slot:activator="{ on }">
               <v-btn color="primary" large height="40" v-on="on" v-if="action === 'add'" @click="updatePropertyState('add')">
                   <v-icon left>mdi-plus</v-icon>
                   Add New
               </v-btn>
               <v-avatar tile size="10" v-on="on" v-if="action === 'show'" @click="updatePropertyState('show')">
                   <v-img :src="require(`@/assets/icons/nav.svg`)"></v-img>
               </v-avatar>
               <a v-on="on" v-if="action === 'show-btn' && $vuetify.breakpoint.mdAndUp " class="property-name-field" @click="updatePropertyState('show')">
                   {{actionText}}
               </a>
               <a v-else class="property-name-field" @click="openViewProperty(existingProperty)">
                   {{actionText}}
               </a>
           </template>

           <Property :property="property"
                     :dialog="dialog"
                     v-on:onHideDialog="onHideDialog"
                     v-on:updateProperty="onUpdateProperty($event)"
           />
       </v-dialog>
   </div>
</template>

<script>
    import Property from "../../views/Property";

    export default {
        name: 'PropertyDialog',
        props: ['action', 'existingProperty', 'actionText'],
        components: {Property},
        data() {
            return {
                dialog: false,
                property: {}
            }
        },
        methods: {
            openViewProperty(property) {
			    window.open(`/#/view-property/${property.id}`,'_blank')
		    },
            updatePropertyState(state) {              
                if (state === 'add') {
                    this.$store.commit('setIsAddNewPropertyState', true)
                } else {
                    this.$store.commit('setIsAddNewPropertyState', false)
                }             
            },
            initProperty() {
                this.property = {
                    id: '',
                    business_id: this.$store.getters.getBusinessId,
                    name: '',
                    description: '',
                    community: '',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state_id: '',
                    country: '',
                    zip_code: '',
                    longitude: '',
                    google_place_id: '',
                    latitude: '',
                    phone_number: '',
                    referral_email: '',
                    invoice_email: '',
                    website: '',
                    commission: '',
                    flat_rate: '',
                    mo_marketing_fee: '',
                    is_active: this.$store.getters.getIsActive,
                    is_sponsored: 0,
                    amenities: []
                }
            },
            onUpdateProperty(data) {
                Object.keys(data).forEach((key) =>  {
                    this.property[key] = [];
                    this.property[key] = data[key];
                })
            },
            onHideDialog() {
                if (this.action ==='add') {
                    this.initProperty();
                }

                this.dialog = false;
            }
        },
        beforeMount() {
            if (this.action === 'add') {
                this.initProperty();
                return;
            }

            this.property = this.existingProperty;
        }
    }
</script>

<style scoped>
    .property-name-field {
        color: #2D76E3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }

    .content {
        padding-left: 250px
    }
@media only screen and (max-width: 600px) {
    .v-dialog__content {
        align-items: flex-start;
        justify-content: flex-start;
    }
}
</style>
