<template>
    <div class="content">
        <v-row>
            <v-col cols="12" md="1">
                <h2>Amenities</h2>
            </v-col>
            <v-col class="pl-md-8">
                <AmenityDialog action="add"/>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <AmenityDataTable />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AmenityDialog from "../components/Amenities/AmenityDialog";
    import AmenityDataTable from "../components/Amenities/AmenityDataTable";

    export default {
        name: 'Amenities',
        components : {
            AmenityDataTable,
            AmenityDialog
        }
    }
</script>

<style scoped>
    .content {
        padding-left: 30px;
        padding-right: 30px;
    }
</style>
