
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import ApiService from "./services/api.service";
import { TokenService } from './services/storage.service'
import Vuelidate from 'vuelidate'
import * as GmapVue from 'gmap-vue'
import VuePapaParse from 'vue-papa-parse'
import Echo from "laravel-echo";
import IdleVue from "idle-vue";

const eventsHub = new Vue();


Vue.use(VuePapaParse)
Vue.use(Vuelidate);
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBWR6PPa4xAgyBeVaiJMCh9kEsJJ3aTwUQ',
    libraries: 'places',
  },
});
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, //900000 for 15 mins
  startAtIdle: false
});

window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    forceTLS: process.env.VUE_APP_WEBSOCKETS_FORCE_TLS,
    encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPTED,
    disableStats: true,
});

Vue.config.productionTip = false;

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_API_BASE_URL)

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setAuthorizationHeader()
}

ApiService.setXAuthorizationHeader();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
