<template>
    <div>
        <v-card :loading="isLoadingPropertyUnits">
            <v-row>
                <v-col cols="12" md="4" class="side-menu" v-if="propertyUnits.length">
                    <v-list dense nav class="py-4 side-menu">
                        <v-list-item-group>

                            <h3 class="pl-md-2 pb-md-4">Units</h3>
                            <v-list-item
                                    v-for="(unit, index) in propertyUnits"
                                    :key="index"
                                    link
                                    @click="currentPropertyUnitIndex = index"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ unit.number_of_bedrooms }}/{{ unit.number_of_baths }} - {{ unit.name }}
                                    </v-list-item-title>
                                </v-list-item-content>

                                <p class="mt-4">{{propertyUnits[index].availabilities.length}}</p>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="12" md="8">
                    <v-card class="px-md-2" flat>
                        <v-row v-if="propertyUnits.length">
                            <v-col cols="12">
                                <v-row class="justify-space-between">
                                    <h3 class="mb-6">
                                        Availability
                                        <span style="color: grey; font-weight: normal">{{propertyUnits[currentPropertyUnitIndex].name}}</span>
                                    </h3>
                                    <v-btn icon class="mr-4" @click="hideDialog">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-row>
                                <v-row class="justify-start">
                                    <v-col class="py-0">
                                        <h5>Price</h5>
                                        <v-text-field outlined dense
                                                      v-model="propertyUnits[currentPropertyUnitIndex].price"
                                                      :error-messages="priceErrors"
                                                      @change="updatePropertyUnit('price', $event)"
                                                      @blur="$v.propertyUnits[currentPropertyUnitIndex].price.$touch()"
                                                      @input="$v.propertyUnits[currentPropertyUnitIndex].price.$touch()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="py-0">
                                        <h5>Available After</h5>
                                        <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="computedDateFormatted"
                                                        outlined
                                                        append-icon="mdi-calendar"
                                                        dense
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date" no-title @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0">
                                        <h5># of Units</h5>
                                        <v-row class="justify-space-between">
                                            <v-col class="py-0">
                                                <v-text-field outlined dense v-model="unitTotal"></v-text-field>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-btn color="primary" @click="addAvailability">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row class="justify-start" v-if="propertyUnits[currentPropertyUnitIndex].availabilities.length">
                                    <v-col cols="6">
                                        <h3> Date </h3>
                                    </v-col>
                                    <v-col cols="6">
                                        <h3> Total </h3>
                                    </v-col>
                                    <hr />
                                </v-row>

                                <v-row>
                                    <v-col cols="12" class="py-0 mb-2" v-for="(row, index) in propertyUnits[currentPropertyUnitIndex].availabilities" :key="index">
                                        <v-card height="45">
                                            <v-card-text>
                                                <v-row class="justify-space-between">
                                                    <p>{{row.available_date}}</p>
                                                    <p>{{row.number_of_units}}</p>
                                                    <v-icon class="mb-4" @click="deleteUnitAvailability(row.id)">mdi-delete</v-icon>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import {PropertyService} from "../../services/property.service";
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: 'PropertyUnitAvailabilities',
        props: ['property'],
        watch: {
            date () {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        validations() {
            const vm = this;
            const validations = {
                propertyUnits: {}
            };

            Object.keys(vm.propertyUnits).forEach(key => {
                validations.propertyUnits[key] = [];
                validations.propertyUnits[key] = {
                    name: { required },
                    square_feet: { required },
                    price: { required }
                }
            });

            return validations
        },
        data () {
            return {
                date: new Date().toISOString().substr(0, 10),
                propertyUnits: [],
                currentPropertyUnitIndex: 0,
                unitTotal: 1,
                selectedAmenities: [],

                isSavingNewUnit: false,
                showSaveButton: false,
                isLoadingPropertyUnits: false,

                headers: [
                    {
                        text: 'Date',
                        align: 'start',
                        value: 'available_date'
                    },
                    { text: 'Total', value: 'number_of_units' },
                    { text: '', value: 'delete' },
                ],
            }
        },
        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            nameErrors () {
                const errors = []
                if (!this.$v.propertyUnits[this.currentPropertyUnitIndex].name.$dirty) return errors
                !this.$v.propertyUnits[this.currentPropertyUnitIndex].name.required && errors.push('Property unit name is required')
                return errors
            },
            squareFeetErrors () {
                const errors = []
                if (!this.$v.propertyUnits[this.currentPropertyUnitIndex].square_feet.$dirty) return errors
                !this.$v.propertyUnits[this.currentPropertyUnitIndex].square_feet.required && errors.push('Property unit square feet value is required')
                return errors
            },
            priceErrors () {
                const errors = []
                if (!this.$v.propertyUnits[this.currentPropertyUnitIndex].price.$dirty) return errors
                !this.$v.propertyUnits[this.currentPropertyUnitIndex].price.required && errors.push('Property unit price is required')
                return errors
            }
        },
        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            addAvailability: async function () {
                this.propertyUnits[this.currentPropertyUnitIndex].availabilities.push({
                    available_date: this.date,
                    number_of_units: this.unitTotal,
                    price: this.propertyUnits[this.currentPropertyUnitIndex].price
                });

                //add the new availability
                this.isLoadingPropertyUnits = true;
                await PropertyService.addPropertyUnitAvailability(
                    this.propertyUnits[this.currentPropertyUnitIndex].id,
                    {
                        availability: {
                            available_date: this.date,
                            number_of_units: this.unitTotal,
                            price: this.propertyUnits[this.currentPropertyUnitIndex].price
                        }
                    }
                );
                this.isLoadingPropertyUnits = false;
                // reset number of units count
                this.unitTotal = 1;

                // reload properties
                this.$emit('getProperties')
            },
            getPropertyUnits: async function () {
                this.isLoadingPropertyUnits = true;
                await PropertyService.getPropertyUnits({property_id: this.property.id}).then(propertyUnits => {
                    this.propertyUnits = propertyUnits;
                    this.isLoadingPropertyUnits = false;
                });
            },
            handleDeleteIconMouseOver(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash-black.svg`);
            },
            handleDeleteIconMouseLeave(refName) {
                this.$refs[refName].src = require(`@/assets/icons/trash.svg`);
            },
            deleteUnitAvailability: async function(propertyUnitAvailabilityId) {
                this.isLoadingPropertyUnits = true;
                await this.$store.dispatch('deletePropertyUnitAvailability', propertyUnitAvailabilityId);
                this.isLoadingPropertyUnits = false;

                this.getPropertyUnits();
                // reload properties
                this.$emit('getProperties')
            },
            updatePropertyUnit(field, value) {
                if (this.$store.getters.getIsAddNewPropertyState
                    || (this.$v.propertyUnits[this.currentPropertyUnitIndex][field] !== undefined && this.$v.propertyUnits[this.currentPropertyUnitIndex][field].$invalid)
                ) {
                    return
                }

                PropertyService.updatePropertyUnit(this.propertyUnits[this.currentPropertyUnitIndex].id, {[field]: value});
            },
            hideDialog() {
                this.$emit('onHideDialog');
            }
        },
        beforeMount() {
            this.getPropertyUnits();
        }
    }
</script>

<style scoped>

    .side-menu {
        background: #F4F5F6;
    }
    .v-item--active {
        background: #FFFFFF;
    }
</style>
