import InvitationService from "@/services/invitation.service";

// State object
const state = {
    pagination: null,
    isLoadingPagination: false,

    paginationCurrentPage: null,
    paginationItemsPerPage: 10,

    statusFilter: null
};

// Getter functions
const getters = {
    pagination( state ) {
        return state.pagination;
    },
    paginationItemsPerPage( state ) {
        return state.paginationItemsPerPage
    },
    invitations( state ) {
        if (!state.pagination) return []
        return state.pagination.data;
    },
    isLoadingPagination(state) {
        return state.isLoadingPagination
    },
};

// Actions
const actions = {
    async getPagination({ commit }) {
        let params = `?paginate=${state.paginationItemsPerPage}`;

        if (state.statusFilter || state.statusFilter === 0 ) {
            params = `${params}&status=${state.statusFilter}`;
        }
        if (state.typeFilter) {
            params = `${params}&type=${state.typeFilter}`;
        }

        commit('setIsLoadingPagination', true);
        const pagination = await InvitationService.getInvitations(params);
        commit('setPagination', pagination);
        commit('setIsLoadingPagination', false);
    }
};

// Mutations
const mutations = {
    setPagination(state, data) {
        state.pagination = data;
    },

    setIsLoadingPagination(state, data) {
        state.isLoadingPagination = data;
    },

    setState(state, data) {
        state[data.key] = data.value
    },

    updateInvitations(state, invite) {
        let index = state.pagination.data.findIndex(i => i.id === invite.id)

        if (index === undefined) return

        Object.assign(state.pagination.data[index], invite)
    },

    deleteInvitation(state, inviteId) {
        let index = state.pagination.data.findIndex(i => i.id === inviteId)

        if (index === undefined) return

        state.pagination.data.splice(index, 1)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
