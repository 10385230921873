<template>
<div>
  <Campus :campus="campus"
          @updateCampus="onUpdateCampus($event)"
          @hideDialog="$emit('hideDialog')"
  >
    <template #gmaps>
      <GoogleMaps :markers="markers" height="350"/>
    </template>
  </Campus>
  <v-dialog v-model="showGoogleAutoCompleteDialog" max-width="900">
    <v-card>
      <v-card-title class="py-6">Verify New School Address</v-card-title>
      <v-card-text>
        <GMAutoComplete :address="newCampusAddress"
                        :is-loading="isUpdatingCampusAddress"
                        p-holder="Enter campus address"
                        source="school"
                        title="Verify Campus Location"
                        :show-remove-button="false"
                        :always-show-add-button="true"
                        @updateCoordinates="updateGMapsCoordinates($event)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
    import GMAutoComplete from "../GMAutoComplete";
    import Campus from "@/components/Campuses/Campus";
    import GoogleMaps from "@/components/GoogleMaps.vue";
    import CampusService from "@/services/campus.service";

    export default {
        name: 'ExistingCampus',
        props: {
          isSchoolSaved: {
            type: Boolean,
            default: false
          },
          campus: {
            type: Object,
            required: true
          },
          markers: {
            type: Array,
            required: true
          }
        },
        components: {
          GoogleMaps,
            GMAutoComplete,
            Campus
        },
        data () {
            return {
                newCampusAddress: '',
                showGoogleAutoCompleteDialog: false,
                isUpdatingCampusAddress: false,
            }
        },
        methods: {
            async onUpdateCampus({field, value}) {
              if (field === 'address') {
                this.newCampusAddress = value
                this.showGoogleAutoCompleteDialog = true
                return
              }

              this.updateCampus({[field]: value})
            },
            async updateCampus(data) {
              const campus = await CampusService.updateCampus(this.campus.id, data);
              this.$store.commit('setSchoolCampus', campus)
              this.showGoogleAutoCompleteDialog = false;
            },
            async updateGMapsCoordinates(gmaps) {
              const address = {
                address: gmaps.address,
                google_place_id: gmaps.google_place_id,
                longitude: gmaps.longitude,
                latitude: gmaps.latitude
              }

              await this.updateCampus(address)
              this.$emit('campusCoordinatesUpdated')
            }
        }
    }
</script>