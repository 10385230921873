<template>
    <div class="content-login">

        <v-card tile color="#212B38" class="d-flex flex-row align-center justify-space-between">

            <v-card tile class="image-login">
                <v-img
                        height="100%"
                        src="../../src/assets/admin-forgot-password.png"
                ></v-img>
            </v-card>

            <v-card color="transparent" elevation="0" tile
                    class="d-flex flex-column align-center justify-center form-login">

                <div class="form-login-container text-center">

                    <img src="../../src/assets/logo.svg" alt="logo"/>

                    <p class="my-8 font-weight-bold white--text sign-in-text">
                        {{title}}
                    </p>

                    <p class="my-8 white--text">{{message}}</p>

                    <div v-if="status === ''">
                        <v-text-field
                                v-model="email"
                                placeholder="Email Address"
                                background-color="white"
                                outlined
                                dense
                                :error-messages="emailErrors"
                                @input="$v.email.$touch()"
                                @blur="$v.email.$touch()"
                                :disabled="isCallingApi"
                        ></v-text-field>

                        <v-btn dense block x-large depressed
                               color="primary"
                               class="text-capitalize font-weight-bold mb-6"
                               @click="processForgotPassword"
                               :loading="isCallingApi"
                        >Send
                        </v-btn>
                    </div>

                    <div v-if="status === 'verify'">
                        <v-text-field
                                v-model="verificationCode"
                                placeholder="Verification Code"
                                background-color="white"
                                outlined
                                dense
                                :error-messages="verificationCodeErrors"
                                @input="$v.verificationCode.$touch()"
                                @blur="$v.verificationCode.$touch()"
                                :disabled="isCallingApi"
                        ></v-text-field>

                        <v-btn dense block x-large depressed
                               color="primary"
                               class="text-capitalize font-weight-bold mb-6"
                               @click="processVerificationCode"
                               :loading="isCallingApi"
                        >Send
                        </v-btn>
                    </div>

                    <div v-if="status === 'update'">
                        <v-text-field
                                v-model="newPassword"
                                type="password"
                                placeholder="New Password"
                                background-color="white"
                                outlined
                                dense
                                :error-messages="newPasswordErrors"
                                @input="$v.newPassword.$touch()"
                                @blur="$v.newPassword.$touch()"
                                :disabled="isCallingApi"
                        ></v-text-field>

                        <v-text-field
                                v-model="confirmNewPassword"
                                type="password"
                                placeholder="Confirm New Password"
                                background-color="white"
                                outlined
                                dense
                                :error-messages="confirmNewPasswordErrors"
                                @input="$v.confirmNewPassword.$touch()"
                                @blur="$v.confirmNewPassword.$touch()"
                                :disabled="isCallingApi"
                        ></v-text-field>

                        <v-btn dense block x-large depressed
                               color="primary"
                               class="text-capitalize font-weight-bold mb-6"
                               @click="updatePassword"
                               :loading="isCallingApi"
                        >Send
                        </v-btn>
                    </div>

                    <p class="white--text">
                        <a class="primary--text" @click="$router.push('/login')">Back to Sign In</a>
                    </p>

                </div>

            </v-card>

        </v-card>

    </div>

</template>

<script>
    import {email, required, sameAs} from 'vuelidate/lib/validators'
    import UserService from "../services/user.service";

    export default {
        name: 'ForgotPassword',
        validations: {
            email: {email, required},
            verificationCode: {required},
            newPassword: { required },
            confirmNewPassword: {required, sameAsPassword: sameAs('newPassword')},
        },
        beforeCreate: function () {
            document.body.className = 'login-page';
        },
        data() {
            return {
                email: '',
                verificationCode: '',
                newPassword: '',
                confirmNewPassword: '',
                status: '',
                isCallingApi: false,
                title: '',
                message: ''
            }
        },
        computed: {
            emailErrors() {
                const errors = []
                if (!this.$v.email.$dirty) return errors;
                !this.$v.email.email && errors.push('A valid email is required');
                !this.$v.email.required && errors.push('Email is required');
                return errors
            },
            verificationCodeErrors () {
                const errors = []
                if (!this.$v.verificationCode.$dirty) return errors
                !this.$v.verificationCode.required && errors.push('Verification code is required')
                return errors
            },
            newPasswordErrors () {
                const errors = [];
                if (!this.$v.newPassword.$dirty) return errors;
                !this.$v.newPassword.required && errors.push('Password is required');

                return errors;
            },
            confirmNewPasswordErrors () {
                const errors = [];
                if (!this.$v.confirmNewPassword.$dirty) return errors;
                !this.$v.confirmNewPassword.required && errors.push('Password confirmation is required');
                !this.$v.confirmNewPassword.sameAsPassword && errors.push('Password confirmation must match password value')

                return errors;
            }
        },
        methods: {
            processForgotPassword: async function () {
                this.$v.email.$touch();
                if (this.$v.email.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Email is required. Please enter your email in the highlighted field',
                        color: 'red'
                    });

                    return
                }

                let hasErrors = false;
                this.isCallingApi = true;
                await UserService.sendVerificationCode({email: this.email}).catch(() => {
                    hasErrors = true;
                });

                this.isCallingApi = false;
                if (hasErrors) {
                    return
                }


                this.setVerificationCodeText();
            },
            processVerificationCode: async function() {
                this.$v.verificationCode.$touch();
                if (this.$v.verificationCode.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'Please enter the verification code which was sent to your email',
                        color: 'red'
                    });

                    return
                }

                let hasErrors = false;
                this.isCallingApi = true;
                await UserService.confirmVerificationCode({
                    email: this.email,
                    verification_code: this.verificationCode
                }).catch(() => {
                    hasErrors = true;
                });

                this.isCallingApi = false;
                if (hasErrors) {
                    return
                }

                this.setPasswordUpdateText();
            },
            updatePassword: async function() {
                this.$v.newPassword.$touch();
                this.$v.confirmNewPassword.$touch();

                // check if form is invalid
                if (this.$v.newPassword.$invalid || this.$v.confirmNewPassword.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'A valid password is required and confirmation password must match the new password',
                        color: 'red'
                    });

                    return
                }

                let hasErrors = false;
                this.isCallingApi = true;
                await UserService.updatePassword(this.email, this.newPassword).catch(() => {
                    hasErrors = true;
                });

                this.isCallingApi = false;
                if (hasErrors) {
                    return
                }

                this.$router.push('/login')
            },
            setPasswordResetText() {
                this.status = '';
                this.title = 'Password Reset';
                this.message = 'Please enter the email address associated with your account and we\'ll email you a password reset link.'
            },
            setVerificationCodeText() {
                this.status = 'verify';
                this.title = 'Code Verification';
                this.message = 'Please enter the verification code that was sent to your email.'
            },
            setPasswordUpdateText() {
                this.status = 'update'
                this.title = 'Update Password';
                this.message = 'Please enter your new password.'
            }
        },
        beforeMount() {
            this.setPasswordResetText();
        }
    }

</script>

<style>

    .content-login .v-card {
        height: 100vh;
        font-size: 14px;
    }

    .content-login .v-card .v-card {
        width: 50%;
    }

    @media only screen and (max-width: 850px) {

        .content-login .v-card .v-card {
            width: 100%;
        }

        .content-login .image-login {
            display: none;
        }

    }

    .content-login .v-card.form-login {
        height: auto;
    }

    .content-login .sign-in-text {
        font-size: 20px;
    }

    .content-login .form-login-container {
        width: 350px;
    }

    @media only screen and (max-width: 380px) {

        .content-login .form-login-container {
            width: 90%;
        }

    }

    .content-login .form-login-container img {
        width: 70%;
    }

</style>