<template>
    <v-card width="1111" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title>
            <v-row class="px-4 py-6">
                <v-col cols="12" class="d-flex justify-space-between">
                  <div class="d-flex">
                    <h2 class="px-2">
                      {{isAddNewStudentState && student.name === '' ? 'Add New Student' : student.name}}
                    </h2>
                    <StudentStatus :status-id="student.status_id"/>
                    <div class="d-flex">
                      <StudentStatusTracker :student="student"></StudentStatusTracker>
                    </div>
                  </div>

                  <div>
                    <v-switch
                        v-model="student.has_paid_application_fees"
                        :label="`${student.has_paid_application_fees ? 'Paid' : 'Unpaid'}`"
                        color="success"
                        hide-details
                        inset
                        class="py-0 my-0"
                        @change="onUpdateExistingStudent({has_paid_application_fees: $event})"
                    ></v-switch>
                  </div>


                  <v-btn icon @click="$emit('hideDialog')" v-if="isDialog" class="py-0 my-0">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
            </v-row>

            <v-tabs mobile-break-point="1" v-model="headerTab">
                <v-tab>Student</v-tab>
                <v-tab :disabled="isAddNewStudentState">Activity</v-tab>
                <v-tab :disabled="isAddNewStudentState" v-if="canView">Properties</v-tab>
                <v-tab :disabled="isAddNewStudentState" v-if="canView">Communications</v-tab>
                <v-col class="text-right" v-if="!isAddNewStudentState && Object.keys(student).length > 0">
                    <v-card-subtitle class="grey--text font-weight-light">Last log in: {{student.last_login_date | loginDate}}</v-card-subtitle>
                </v-col>
            </v-tabs>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-tabs-items v-model="headerTab">
                <v-tab-item>
                    <StudentPersonalInfo :student="student"
                                         :validation="v"
                                         :isStudentSaved="isStudentSaved"
                                         v-on:updateStudent="$emit('updateStudent', $event)"
                                         v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                    />

                    <v-divider></v-divider>
                    <StudentRentalProfile v-if="!isAddNewStudentState"
                                        :student="student"
                                        :validation="v"
                                        v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                    />

                    <v-divider></v-divider>
                    <StudentRoommateProfile :student="student"
                                            :validation="v"
                                            :isSaved="isStudentSaved"
                                            v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                                            v-if="!isAddNewStudentState"
                    />

                    <v-divider v-if="!$store.getters.getIsAddNewStudentState"></v-divider>
                    <StudentEdufund :student="student" v-if="!$store.getters.getIsAddNewStudentState"/>
                </v-tab-item>

                <v-tab-item>
                    <StudentActivities :student="student"
                                       :load-notes="loadNotes"
                                       :load-events="loadEvents"
                                       v-on:onActivityNotesReceived="onActivityNotesReceived"
                                       v-on:onActivityEventsReceived="onActivityEventsReceived"
                                       @allEventsCompleted="$emit('allEventsCompleted')"
                    />
                </v-tab-item>
                <v-tab-item>
                    <StudentProperties :student="student"/>
                </v-tab-item>
                <v-tab-item>
                    <StudentCommunications :student="student"></StudentCommunications>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end pa-0 v-card-action-container" v-if="canView">
            <v-card elevation="10" height="80" width="100%" class="save-button-card px-7" v-if="isAddNewStudentState">
                <v-card-text class="text-right">
                    <v-btn large text color="primary" @click="$emit('hideDialog')">Cancel</v-btn>
                    <v-btn large color="primary"
                           @click="save"
                           :loading="isCallingApi"
                           :disabled="v.student.$anyError"
                    >Save and Continue
                    </v-btn>
                </v-card-text>
            </v-card>

            <v-card elevation="10" :height="showMoreFooter ? 300 : 90" width="100%" class="save-button-card px-7"
                    v-else>
                <v-textarea outlined placeholder="Add Note or Event" rows="2"
                            v-if="!showMoreFooter"
                            @click="showMoreFooter = true"
                            readonly
                            class="pt-4"
                ></v-textarea>

                <div v-if="showMoreFooter">
                    <v-card-title>
                        <v-tabs v-model="footerTab">
                            <v-tab>Add Note</v-tab>
                            <v-tab>Add Event</v-tab>

                            <v-row class="justify-end">
                                <div class="event-tab-component" v-if="footerTab === 0">
                                    <v-btn outlined @click="showNoteList=true">
                                        Select a Note
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </div>
                                <div class="event-tab-component" v-if="footerTab === 1">
                                    <v-menu
                                            v-model="startDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="eventDate"
                                                    outlined
                                                    append-icon="mdi-calendar"
                                                    dense
                                                    readonly
                                                    v-on="on"
                                                    class="mr-2"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="eventDate" no-title
                                                       @input="startDateMenu = false"></v-date-picker>
                                    </v-menu>
                                </div>

                                <div class="event-tab-component" v-if="footerTab === 1">
                                    <v-btn outlined @click="showEventList=true">
                                        Select an Event
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </v-tabs>

                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                        <v-tabs-items v-model="footerTab">
                            <v-tab-item transition="false" reverse-transition="false">
                                <v-textarea outlined v-model="noteContent"></v-textarea>
                                <div class="footer-buttons">
                                    <v-btn text @click="showMoreFooter = !showMoreFooter">Cancel</v-btn>
                                    <v-btn color="primary" @click="submitNote">Submit</v-btn>
                                </div>
                            </v-tab-item>

                            <v-tab-item transition="false" reverse-transition="false">
                                <v-textarea outlined v-model="eventContent"></v-textarea>
                                <div class="footer-buttons">
                                    <v-btn text @click="showMoreFooter = !showMoreFooter">Cancel</v-btn>
                                    <v-btn color="primary" @click="submitEvent">Submit</v-btn>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </div>
            </v-card>
        </v-card-actions>

        <v-dialog v-model="showNoteList" max-width="600">
            <NoteList :defaultMessageCategories="defaultMessageCategories" v-on:noteSelected="onNoteSelected($event)"/>
        </v-dialog>

        <v-dialog v-model="showEventList" max-width="600">
            <EventList :defaultMessageCategories="defaultMessageCategories" v-on:eventSelected="onEventSelected($event)"/>
        </v-dialog>
    </v-card>
    <v-card v-else>
        <v-card-title>
            <v-row class="justify-space-between">
                <v-col cols="12" class="d-flex justify-end">
                    <v-btn icon @click="$emit('hideDialog')" v-if="isDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <h2 class="px-2 text-center">
                            {{isAddNewStudentState && student.name === '' ? 'Add New Student' : student.name}}
                        </h2>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <StudentStatus :status-id="student.status_id"/>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <StudentStatusTracker :student="student"></StudentStatusTracker>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div>
                        <v-switch
                            v-model="student.has_paid_application_fees"
                            :label="`${student.has_paid_application_fees ? 'Paid' : 'Unpaid'}`"
                            color="success"
                            hide-details
                            inset
                            class="py-0 my-0"
                            @change="onUpdateExistingStudent({has_paid_application_fees: $event})"
                        ></v-switch>
                      </div>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-card-subtitle class="grey--text font-weight-light">Last log in: {{student.last_login_date | loginDate}}</v-card-subtitle>
                    </v-col>
                </v-row>
            </v-row>
        </v-card-title>

        <v-tabs v-model="headerTab" center-active mobile-break-point="1">
            <v-tab>Student</v-tab>
            <v-tab :disabled="isAddNewStudentState">Activity</v-tab>
            <v-tab :disabled="isAddNewStudentState" v-if="canView">Properties</v-tab>
            <v-tab :disabled="isAddNewStudentState" v-if="canView">Communications</v-tab>
        </v-tabs>

        <v-divider></v-divider>

            <v-card-text>
            <v-tabs-items v-model="headerTab">
                <v-tab-item>
                    <StudentPersonalInfo :student="student"
                                         :validation="v"
                                         :isStudentSaved="isStudentSaved"
                                         v-on:updateStudent="$emit('updateStudent', $event)"
                                         v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                    />

                    <v-divider></v-divider>
                    <StudentRentalProfile :student="student"
                                          :validation="v"
                                          v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                    />

                    <v-divider></v-divider>
                    <StudentRoommateProfile :student="student"
                                            :validation="v"
                                            :isSaved="isStudentSaved"
                                            v-on:updateExistingStudent="onUpdateExistingStudent($event)"
                    />

                    <v-divider v-if="!$store.getters.getIsAddNewStudentState"></v-divider>
                    <StudentEdufund :student="student" v-if="!$store.getters.getIsAddNewStudentState"/>
                </v-tab-item>

                <v-tab-item>
                    <StudentActivities :student="student"
                                       :load-notes="loadNotes"
                                       :load-events="loadEvents"
                                       v-on:onActivityNotesReceived="onActivityNotesReceived"
                                       v-on:onActivityEventsReceived="onActivityEventsReceived"
                                       @allEventsCompleted="$emit('allEventsCompleted')"
                    />
                </v-tab-item>
                <v-tab-item>
                    <StudentProperties :student="student"/>
                </v-tab-item>
                <v-tab-item>
                    <StudentCommunications :student="student"></StudentCommunications>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <v-card-actions class="justify-end pa-0 v-card-action-container" v-if="canView">
            <v-card elevation="10" height="80" width="100%" class="save-button-card px-7" v-if="isAddNewStudentState">
                <v-card-text class="text-right">
                    <v-btn large text color="primary" @click="$emit('hideDialog')">Cancel</v-btn>
                    <v-btn large color="primary"
                           @click="save"
                           :loading="isCallingApi"
                           :disabled="v.student.$anyError"
                    >Save and Continue
                    </v-btn>
                </v-card-text>
            </v-card>

            <v-card elevation="10" :height="showMoreFooter ? 350 : 90" width="100%" class="save-button-card px-7"
                    v-else>
                <v-textarea outlined placeholder="Add Note or Event" rows="2"
                            v-if="!showMoreFooter"
                            @click="showMoreFooter = true"
                            readonly
                            class="pt-4"
                ></v-textarea>

                <div v-if="showMoreFooter">
                    <v-card-title>
                        <v-row class="mx-auto">
                                <div class="event-tab-component" v-if="footerTab === 0">
                                    <v-btn outlined @click="showNoteList=true">
                                        Select a Note
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </div>
                                <div class="event-tab-component" v-if="footerTab === 1">
                                    <v-menu
                                            v-model="startDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="eventDate"
                                                    outlined
                                                    append-icon="mdi-calendar"
                                                    dense
                                                    readonly
                                                    v-on="on"
                                                    class="mr-2"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="eventDate" no-title
                                                       @input="startDateMenu = false"></v-date-picker>
                                    </v-menu>
                                </div>
                            </v-row>
                        <v-tabs center-active mobile-break-point="1" v-model="footerTab">
                            <v-tab>Add Note</v-tab>
                            <v-tab>Add Event</v-tab>
                        </v-tabs>

                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text>
                        <v-tabs-items v-model="footerTab">
                            <v-tab-item transition="false" reverse-transition="false">
                                <v-textarea outlined v-model="noteContent"></v-textarea>
                                <div class="footer-buttons">
                                    <v-btn text @click="showMoreFooter = !showMoreFooter">Cancel</v-btn>
                                    <v-btn color="primary" @click="submitNote">Submit</v-btn>
                                </div>
                            </v-tab-item>

                            <v-tab-item transition="false" reverse-transition="false">
                                <v-textarea outlined v-model="eventContent"></v-textarea>
                                <div class="footer-buttons">
                                    <v-btn text @click="showMoreFooter = !showMoreFooter">Cancel</v-btn>
                                    <v-btn color="primary" @click="submitEvent">Submit</v-btn>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </div>
            </v-card>
        </v-card-actions>

        <v-dialog v-model="showNoteList" max-width="600">
            <NoteList :defaultMessageCategories="defaultMessageCategories" v-on:noteSelected="onNoteSelected($event)"/>
        </v-dialog>

        <v-dialog v-model="showEventList" max-width="600">
            <EventList :defaultMessageCategories="defaultMessageCategories" v-on:eventSelected="onEventSelected($event)"/>
        </v-dialog>
    </v-card>
</template>

<script>
    import StudentPersonalInfo from "../components/Students/StudentPersonalInfo";
    import StudentStatusTracker from "../components/Students/StudentStatusTracker";
    import StudentRentalProfile from "../components/Students/StudentRentalProfile";
    import StudentRoommateProfile from "../components/Students/StudentRoommateProfile";
    import StudentEdufund from "../components/Students/StudentEdufund";
    import StudentStatus from "../components/StudentStatus";
    import StudentProperties from "./Student/StudentProperties";
    import StudentActivities from "./Student/StudentActivities";
    import StudentService from "../services/student.service";
    import moment from 'moment'
    import DefaultMessageService from "../services/defaultmessages.service";
    import NoteList from "../components/Students/Activity/NoteList";
    import EventList from "../components/Students/Activity/EventList";
    import StudentCommunications from "./Student/StudentCommunications";
    import {displayMessage} from "@/utils/utils";
    import store from "@/store";

    export default {
        name: 'Student',
        props: ['student', 'isDialog', 'v'],
        components: {
            StudentCommunications,
            StudentStatusTracker,
            EventList,
            NoteList,
            StudentActivities,
            StudentPersonalInfo,
            StudentRentalProfile,
            StudentRoommateProfile,
            StudentEdufund,
            StudentStatus,
            StudentProperties
        },
        data() {
            return {
                headerTab: null,
                footerTab: null,
                isCallingApi: false,
                isStudentSaved: false,
                showMoreFooter: false,
                eventDate: new Date().toISOString().substr(0, 10),
                noteContent: '',
                eventContent: '',

                loadNotes: false,
                loadEvents: false,
                defaultMessages: [],
                defaultMessageCategories: [],
                showNoteList: false,
                showEventList: false,
            }
        },
        computed: {
            isAddNewStudentState() {
                return this.$store.getters.getIsAddNewStudentState;
            },
            events() {
                return this.defaultMessages.filter(event => {
                    return event.type.toLowerCase() === 'event'
                })
            },
            notes() {
                return this.defaultMessages.filter(event => {
                    return event.type.toLowerCase() === 'note'
                })
            },
            user() {
              return this.$store.getters.getUser;
            },
            canView() {
              return this.user.type !== 'schoolContact'
            }
        },
        methods: {
            save: async function () {
                this.v.student.$touch();
                if (this.v.student.$invalid) {
                    this.$store.commit('setSnackbar', {
                        show: true,
                        message: 'A mandatory field is missing. Please look through the form and fix the field highlighted in red',
                        color: 'red'
                    });
                    return
                }

                //update student
                this.student.is_roommate_profile_completed = 1;

                this.isCallingApi = true;
                let hasErrors = false;
                const response = await StudentService.addNewStudent({student: this.student}).catch(() => {
                    hasErrors = true;
                });

                if (hasErrors) {
                    this.isCallingApi = false;
                    return;
                }


                this.$emit('updateStudent', {id: response.id})

                this.isCallingApi = false;

                //refresh students table
                await this.$store.dispatch('getStudents');

                // set saved to true
                // this value is watched by roommate profile
                this.isStudentSaved = true;

                // finally set state as not adding new student
                this.$store.commit('setIsAddNewStudentState', false);
            },

            onUpdateExistingStudent: async function (data) {
                //check if it's creating new student
                if (this.isAddNewStudentState && !this.isStudentSaved) {
                    return
                }

                if (!this.$store.getters.userHasPermission('students', 'update')) {
                    displayMessage('error', 'Sorry you do not have permission to update students');
                    return
                }

                await StudentService.updateStudent(this.student.id, data);
            },
            submitNote: async function () {
                await StudentService.addActivityNote(this.student.id, {
                    agent_id: this.user.agent.id,
                    user_id: this.student.id,
                    note: this.noteContent,
                });

                this.loadNotes = true;
                this.noteContent = '';
            },
            submitEvent: async function () {
                await StudentService.addActivityEvent(this.student.id, {
                    agent_id: this.user.agent.id,
                    user_id: this.student.id,
                    event: this.eventContent,
                    event_date: this.eventDate
                });

                this.loadEvents = true;
                this.eventContent = '';
            },
            onActivityNotesReceived() {
                this.loadNotes = false;
            },
            onActivityEventsReceived() {
                this.loadEvents = false;
            },
            updateNoteContent(noteId) {
                this.noteContent = this.notes.filter((note => {
                    return Number(note.id) === Number(noteId)
                })).map((note) => {
                    return note.content
                }).toString();
            },
            updateEventContent(eventId) {
                this.eventContent = this.events.filter((event => {
                    return Number(event.id) === Number(eventId)
                })).map((event) => {
                    return event.content;
                }).toString();
            },
            getDefaultMessages: async function () {
                this.defaultMessages = await DefaultMessageService.getDefaultMessages()
            },
            onNoteSelected(note) {
                this.noteContent  = note;
                this.showNoteList = false;
            },
            onEventSelected(event) {
                this.eventContent  = event;
                this.showEventList = false;
            }
        },
        filters: {
            loginDate(value) {
                return moment(value).format('l hh:mm A')
            }
        },
        beforeMount: async function() {
						if (Object.keys(this.user).length === 0) {
							await store.dispatch('getUserByTokenUnsigned', '?appendAgent&appendAgentRole')
						}
            this.getDefaultMessages();
            this.defaultMessageCategories = await DefaultMessageService.getDefaultMessageCategories('?appendDefaultMessages')
        }
    }
</script>

<style scoped>
    .footer-mobile {
        position: relative;
        top: 0;
        left: 0;
    }

    .status-incomplete {
      opacity: 0.2;
    }

    .v-card-action-container {
        position: relative;
    }

    .footer-buttons {
        position: absolute;
        bottom: 45px;
        right: 15px;
    }

    .event-tab-component {
        width: 250px;
    }

    .v-card__text, .v-card__title {
        word-break: normal; /* maybe !important  */
    }
</style>
