<template>
    <div class="content page-style-proto">
        <v-row>
            <v-col cols="12" md="4">
                <NewLeads></NewLeads>
            </v-col>
            <v-col cols="12" md="8">
                <DashboardContent></DashboardContent>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import NewLeads from "../components/Dashboard/NewLeads";
import DashboardContent from "../components/Dashboard/DashboardContent";
export default {
  name: 'Dashboard',
    components: {DashboardContent, NewLeads},
}
</script>
