<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <h2 class="py-4">Events</h2>
                <v-skeleton-loader type="card" v-if="isLoadingEvents"/>
                <EventCard v-for="(event, i) of upcomingEvents"
                           :event="event" :key="i"
                           :show-checkbox="true"
                           v-on:onEventCompleted="onEventCompleted"
                           v-else
                ></EventCard>
                <v-card-text class="pl-0" v-if="!isLoadingEvents && upcomingEvents.length === 0">No upcoming events for this student</v-card-text>

                <h2 class="mt-9 py-4">Past Events</h2>
                <v-skeleton-loader type="card" v-if="isLoadingEvents"/>
                <EventCard v-for="(event, i) of pastEvents" :event="event" :key="i" :show-checkbox="false" v-else></EventCard>
                <v-card-text class="pl-0" v-if="!isLoadingEvents && pastEvents.length === 0">No past events for this student</v-card-text>
            </v-col>
            <v-col cols="12" md="6">
                <h2 class="py-4">Notes</h2>
                <v-skeleton-loader type="card" v-if="isLoadingNotes"/>
                <NoteCard v-for="note of notes"
                          :note="note"
                          :key="note.id"
                          v-on:onNoteDeleted="onNoteDeleted()"
                          v-else
                ></NoteCard>
                <v-card-text class="pl-0" v-if="!isLoadingNotes && notes.length === 0">No notes for this student</v-card-text>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import EventCard from "../../components/Students/Activity/EventCard";
    import NoteCard from "../../components/Students/Activity/NoteCard";
    import StudentService from "../../services/student.service";

    export default {
        name: "StudentActivities",
        props: ['student', 'loadNotes', 'loadEvents'],
        watch: {
            student() {
              this.getStudentActivityNotes()
              this.getStudentActivityEvents()
            },
            loadNotes(value) {
                if (value === true) {
                    this.getStudentActivityNotes();
                    this.$emit('onActivityNotesReceived')
                }
            },
            loadEvents(value) {
                if (value === true) {
                    this.getStudentActivityEvents();
                    this.$emit('onActivityEventsReceived')
                }
            }
        },
        data() {
            return {
                notes: [],
                events: [],
                isLoadingEvents: false,
                isLoadingNotes: false
            }
        },
        components: {
            NoteCard,
            EventCard,
        },
        computed: {
            upcomingEvents() {
              return this.events.filter(e => { return e.is_complete === 0})
            },
            pastEvents() {
              return this.events.filter(e => { return e.is_complete === 1})
            }
        },
        methods: {
            onNoteDeleted: async function() {
                this.getStudentActivityNotes();
            },
            async onEventCompleted() {
              await this.getStudentActivityEvents();

              if (this.upcomingEvents.length === 0) {
	              this.$emit('allEventsCompleted')
              }
            },
            getStudentActivityNotes: async function() {
                this.isLoadingNotes = true;
                this.notes = await StudentService.getStudentActivityNotes(this.student.id);
                this.isLoadingNotes = false;
            },
            getStudentActivityEvents: async function() {
                this.isLoadingEvents = true;
                this.events = await StudentService.getStudentActivityEvents(this.student.id);
                this.isLoadingEvents = false;
            }
        },
        beforeMount: async function() {
            this.getStudentActivityNotes();
            this.getStudentActivityEvents();
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
</style>