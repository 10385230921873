import CampusService from '@/services/campus.service'
import {alphabeticalSort} from "@/utils/utils";

// State object
const state = {
    campuses: []
};

// Getter functions
const getters = {
    campuses( state ) {
        return state.campuses.sort(alphabeticalSort());
    },
    getCampusById() {
        return campusId => {
            return state.campuses.find(c => c.id === campusId)
        }
    },
};

// Actions
const actions = {
    async getCampuses({ commit }, data) {
        const campuses = await CampusService.getCampuses(data);
        commit('setCampuses', campuses);
    },

    setSearchSchool({ commit }, school) {
        commit('setSearchSchool', school)
    }
};

// Mutations
const mutations = {
    setCampuses(state, data) {
        state.campuses = data;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
