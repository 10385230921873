import { CampaignService } from '@/services/campaign.service'

// State object
const state = {
    students: [],
    campaigns: [],
    campaign: null,

    isLoadingCampaigns: false,
    getIsCampaignFiltered: false,

    studentFilter: null,
    activeStatusFilter: 'active',
    endedStatusFilter: null,
    campaignTypeFilter: null,
    dateFromFilter: null,
    dateToFilter: null,
    searchFilter: null,

    sortByKey: 'created_at',
    sortByDirection: 'desc',

    totalNoFilter: 0,
    campaignsCurrentPage: null,
    campaignsPerPage: 10
};

// Getter functions
const getters = {
    getCampaigns() {
        return state.campaigns.data;
    },
    getCampaignsPerPage( state ) {
        return state.campaignsPerPage;
    },
    getIsloadingCampaigns() {
        return state.isLoadingCampaigns;
    },
    getCampaignsTotal() {
        return state.campaigns.total;
    },
    getStudentFilter() {
        return state.studentFilter;
    },
    getActiveStatusFilter() {
        return state.activeStatusFilter;
    },
    getCampaignsCurrentPage( state ) {
        return state.campaigns.current_page;
    },
    getEndedStatusFilter() {
        return state.endedStatusFilter;
    },
    getCampaignTypeFilter() {
        return state.campaignTypeFilter;
    },
    getDateFromFilter() {
        return state.dateFromFilter;
    },
    getDateToFilter() {
        return state.dateToFilter;
    },
    getIsCampaignFiltered() {
        if( state.studentFilter === null  &&
            state.activeStatusFilter === null &&
            state.endedStatusFilter === null &&
            state.dateFromFilter === null &&
            state.dateToFilter === null ) {
                return false
            } else {
                return true
            }
    },
    getCampaign() {
        return state.campaign;
    }
};

// Actions
const actions = {
    async getCampaigns({ commit }) {
        let params = `?paginate=${state.campaignsPerPage}`;
        if (state.studentFilter) {
            params = `${params}&studentId=${state.studentFilter}`
        }
        if (state.activeStatusFilter && state.activeStatusFilter === 'active') {
            params = `${params}&active`;
        }
        if (state.activeStatusFilter && state.activeStatusFilter === 'processed') {
            params = `${params}&processed`;
        }
        if (state.endedStatusFilter && state.endedStatusFilter == 'ended') {
            params = `${params}&ended`
        }
        if (state.endedStatusFilter && state.endedStatusFilter == 'not ended') {
            params = `${params}&not-ended`
        }
        if (state.campaignTypeFilter) {
            params = `${params}&type=${state.campaignTypeFilter}`
        }
        if (state.dateFromFilter) {
            params = `${params}&date_from=${state.dateFromFilter}`
        }
        if (state.dateToFilter) {
            params = `${params}&date_to=${state.dateToFilter}`
        }
        if (state.searchFilter) {
            params = `${params}&search=${state.searchFilter}`
        }
        if (state.campaignsCurrentPage) {
            params = `${params}&page=${state.campaignsCurrentPage}`
        }
        if (state.sortByKey) {
            params = `${params}&sortByKey=${state.sortByKey}&sortByDirection=${state.sortByDirection}`;
        }
        commit('setIsLoadingCampaigns', true);
        const pagination = await CampaignService.getCampaigns(params);
        commit('setCampaigns', pagination);
        commit('setIsLoadingCampaigns', false);
        if(!getters.getIsCampaignFiltered()) {
            commit('setTotalNoFilter', state.campaigns.total);
        }
    },
    setCampaign({ commit }, data) {
        commit('setCampaign', data);
    }
};

// Mutations
const mutations = {
    setCampaigns(state, data) {
        state.campaigns = data;
    },

    setCampaignsCurrentPage(state, data) {
        state.campaignsCurrentPage = data;
    },

    setCampaignsPerPage(state, data) {
        state.campaignsPerPage = data;
    },

    setStudentFilter(state, data) {
        state.studentFilter = data;
    },

    setActiveStatusFilter(state, data) {
        state.activeStatusFilter = data;
    },

    setEndedStatusFilter(state, data) {
        state.endedStatusFilter = data;
    },

    setDateFromFilter(state, data) {
        state.dateFromFilter = data;
    },

    setDateToFilter(state, data) {
        state.dateToFilter = data;
    },

    setTotalNoFilter(state, data) {
        state.totalNoFilter = data;
    },

    setIsLoadingCampaigns(state, data) {
        state.isLoadingCampaigns = data;
    },

    setCampaignStatuses(state, data) {
        state.statuses = data;
    },

    setCampaignTypeFilter(state, data) {
        state.campaignTypeFilter = data;
    },

    setIsLoadingStudentStatus(state, data) {
        state.isLoadingStudentStatus = data;
    },

    setCampaignsSearchFilter(state, data) {
        state.searchFilter = data
    },

    setStartDateFilter(state, data) {
        state.dateFromFilter = data;
    },

    setCampaign(state, data) {
        state.campaign = data;
    },

    setSortByKey(state, data) {
        state.sortByKey = data;
    },

    setSortByDirection(state, data) {
        state.sortByDirection = data;
    }

}
export default {
    state,
    getters,
    actions,
    mutations
}
