<template>
    <div class="image-container">
        <v-row>
            <v-col cols="6" class="pl-4 pr-0 py-0">
                <v-hover v-slot:default="{ hover }" open-delay="200" close-delay="200">
                    <v-card flat class="image-card d-flex" :elevation="hover ? 16 : 2">
                        <v-img
                                :src="amenity.icon_url"
                                :lazy-src="amenity.icon_url"
                                aspect-ratio="1"
                                class="grey lighten-2 image"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="6" class="py-0">
                <v-btn color="primary" @click="addFileByClick" @drop.prevent="addFileByDrag" dense height="45" width="45">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <input type="file" ref="file" style="display: none" @change="previewImage">
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'AmenityIcon',
        props: ['amenity'],
        data () {
            return {
                file: []
            }
        },
        methods: {
            addFileByDrag(e) {
                e.preventDefault();

                let droppedFiles = e.dataTransfer.files;
                if(!droppedFiles) return;

                // foreach dropped file read image
                ([...droppedFiles]).forEach(f => {
                    //this.files.push(f);
                    this.readImageFile(f)
                });
            },
            addFileByClick() {
                this.$refs.file.click();
            },
            removeFile(file) {
                this.files = this.files.filter(f => {
                    return f != file;
                });
            },
            previewImage: function(event) {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    this.readImageFile(input.files[0])
                }
            },
            readImageFile(file) {
                //add to files array
                this.file = file

                // create a new FileReader to read this image and convert to base64 format
                let reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    this.amenity.icon_url = e.target.result
                    // Read image as base64 and set to imageData
                    this.$emit('onAmenityIconModified', {
                        currentPropertyUnitIndex: this.currentPropertyUnitIndex,
                        image: file,
                        image_url:e.target.result
                    })
                }
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(file);
            },
        },
        computed: {
            uploadDisabled() {
                return this.files.length === 0;
            }
        },
        filters: {
            kb(value) {
                return Math.floor(value/1024);
            }
        },
    }
</script>

<style scoped>
    .image-container {
        background: #FFFFFF;
        border-radius: 4px;
    }
    .image {
        height: 45px;
        width: 45px;
        border-radius: 4px;
        background: #C4C4C4;
    }
</style>
