<template>
    <v-row>
        <v-col cols="12">
            <h5>Select Campuses</h5>
            <v-combobox
                    v-model="selectedCampuses"
                    :items="campuses"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                    outlined
                    dense
                    @change="updateSelectedCampusesAndSetChanged"
                    @blur="updatePropertyCampuses"
                    :loading="isLoadingPropertyCampuses"
            >
                <template v-slot:selection="data">
                    <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                    >
                        <v-avatar
                                class="accent white--text"
                                left
                                v-text="data.item.name.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item.name }}
                    </v-chip>
                </template>
            </v-combobox>
        </v-col>
    </v-row>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'PropertyCampuses',
        props: ['property'],
        data () {
            return {
                selectedCampuses: [],
                isSelectedCampusesChanged: false,
                isLoadingPropertyCampuses: false
            }
        },
        computed: {
          ...mapGetters('Campuses', {
            campuses: 'campuses'
          }),
        },
        methods: {
            updatePropertyCampuses: async function() {
                if (this.isSelectedCampusesChanged) {
                    this.isLoadingPropertyCampuses = true;
                    await this.$store.dispatch('updatePropertyCampuses', {
                        propertyId: this.property.id,
                        campuses: this.selectedCampuses
                    })

                    this.isLoadingPropertyCampuses = false;
                    this.isSelectedCampusesChanged = false;
                }
            },
          updateSelectedCampusesAndSetChanged() {
                const lastIndex = this.selectedCampuses.length -1;
                this.selectedCampuses[lastIndex].property_id = this.property.id;

                this.campuses.forEach(campus => {
                    if (campus.name.indexOf(this.selectedCampuses[lastIndex].name) !== -1) {
                        this.selectedCampuses[lastIndex].campus_id = campus.id;
                    }
                });

                this.isSelectedCampusesChanged = true;
            },
        },

        async beforeMount() {
            if (this.campuses.length === 0) {
              this.getCampuses()
            }

            this.isLoadingPropertyCampuses = true;
            this.selectedCampuses = await this.$store.dispatch('getPropertyCampuses', this.property.id);
            this.isLoadingPropertyCampuses = false;
        }
    }
</script>
