<template>
    <GmapMap
            :center="markers[0].position"
            :zoom="7"
            :style="`width: 100%; height: ${height}px`"
    >
        <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            <StudentPropertyMatchCard v-if="currentMarkerType === 'propertyUnit'"
                                      :student="student"
                                      :property-unit="currentPropertyUnit"
                                      type="matches"
                                      :distance-to-school="currentDistanceToSchool"
                                      v-on:getProperties="handleGetProperties($event)"
            />
            <p v-if="currentMarkerType === 'school'">
                <v-card flat>
                    <v-card-title class="py-0">{{this.currentSchool.name}}</v-card-title>
                    <v-card-text class="py-0">{{this.currentSchool.address}}</v-card-text>
                </v-card>
            </p>
            <p v-if="currentMarkerType === 'schoolProperty'">
                <v-card flat>
                    <v-card-title class="py-0">{{this.currentPropertyUnit.name}}</v-card-title>
                    <v-card-text class="py-0">{{this.currentPropertyUnit.address_line_1}}</v-card-text>
                     <v-card-text class="py-0">{{this.currentPropertyUnit.phone_number}}</v-card-text>
                </v-card>
            </p>
        </GmapInfoWindow>

        <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="{url: m.icon ? require(`@/assets/icons/${m.icon}`) : ''}"
                @click="toggleInfoWindow(m)"
        />
    </GmapMap>
</template>

<script>
    import StudentPropertyMatchCard from "./Students/StudentPropertyMatchCard";

    export default {
        name: "GoogleMaps",
        props: {
            markers: {
              type: Array,
              required: true
            },
            height: {
                default() {
                    return 520
                }
            },
            student: {},
            selectedMarker: {}
        },
        components: {
            StudentPropertyMatchCard
        },
        data() {
            return {
                infoWindowPos: null,
                infoWinOpen: false,
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                currentPropertyUnit: {},
                currentSchool: {},
                currentMarkerType: '',
                currentDistanceToSchool: null
            }
        },
        watch: {
            selectedMarker(marker) {
              this.toggleInfoWindow(marker)
            },
            markers() {
              this.infoWinOpen = false
            }
        },
        methods: {
            toggleInfoWindow: function (marker) {
                this.infoWindowPos = marker.position;
                this.currentMarkerType = marker.type;

                if (marker.type === 'school') {
                    this.currentSchool = marker.school;
                }
                if (marker.type === 'propertyUnit' || marker.type === 'schoolProperty') {
                    this.currentPropertyUnit = marker.propertyUnit;
                    this.currentDistanceToSchool = marker.distanceToSchool
                }

                this.infoWinOpen = true;
            },
          handleGetProperties() {
              this.infoWinOpen = false;
            this.$emit('getProperties')
          }
        }
    }
</script>
