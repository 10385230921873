import ApiService from './api.service'

const DefaultMessageService = {
    getDefaultMessages: async function(params = '') {
        const response = await ApiService.get(`default-messages${params}`);
        return response.data.data
    },
    getDefaultMessageCategories: async function(params = '') {
        const response = await ApiService.get(`default-message-categories${params}`);
        return response.data.data
    },
    updateDefaultMessage: async function(defaultMessageId, data) {
        return await ApiService.post(`default-message/${defaultMessageId}`, {data: data});
    },
    addDefaultMessage: async function(data) {
        const response = await ApiService.post(`default-message`, data);
        return response.data.data;
    },
    deleteDefaultMessage: async function(defaultMessageId) {
        const response = await ApiService.delete(`default-message/${defaultMessageId}`);
        return response.data.data;
    }

}

export default DefaultMessageService

export { DefaultMessageService }
