<template>
    <div class="d-flex justify-center py-9">
		<Property v-if="property.id" :property="property" :isDialog="dialog" />
	</div>
</template>
<script>
import Property from "../../views/Property.vue";
import PropertyService from "@/services/property.service";
export default {
    name: 'PropertyPreview',
    components: {Property},
    props: ["propertyId"],
    data() {
        return {
            dialog: false,
            property: {}
        }
    },
    methods: {
        async getProperty() {
            this.property = await PropertyService.getProperty(this.propertyId);
        }
    },
    async mounted() {
        this.getProperty();
    },
}
</script>
<style>
    
</style>