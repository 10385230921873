<template>
  <v-card>
    <slot name="card-title" :handleHideDialog="handleHideDialog"></slot>

    <v-card-text class="py-5">
      <h2 class="my-4">Basic Info</h2>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <h5>Name</h5>
              <v-text-field outlined dense
                            :value="campus.name"
                            :error-messages="nameErrors"
                            @blur="$v.campus.name.$touch()"
                            @change="updateCampus('name', $event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h5>Campus Website <i><small>(Optional)</small></i></h5>
              <v-text-field outlined dense
                            :value="campus.website"
                            @change="formatWebsite($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="right-column">
          <v-row>
            <v-col cols="12">
              <h5>Address</h5>
              <v-text-field outlined dense
                            :value="campus.address"
                            :error-messages="addressErrors"
                            @change="updateCampus('address', $event)"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-8">
          <!--google maps slot-->
          <slot name="gmaps"></slot>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <slot name="action-btn" :resetCampusValidation="resetCampusValidation" :isValidForm="isValidForm"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators"

    export default {
        name: 'Campus',
        props: {
          campus: {
            type: Object,
            required: true
          },
        },
        validations : {
          campus: {
            name: { required },
            address: { required }
          }
        },
        watch: {
            properties() {
                this.refreshMarkers();
            }
        },
        computed: {
            nameErrors () {
                const errors = []
                if (!this.$v.campus.name.$dirty) return errors
                !this.$v.campus.name.required && errors.push('Campus name is required');
                return errors
            },
            addressErrors() {
                const errors = []
                if (!this.$v.campus.address.$dirty) return errors
                !this.$v.campus.address.required && errors.push('Campus address is required');
                return errors
            },
        },
        methods: {
            updateCampus: async function(field, value) {
                this.$emit('updateCampus', {field, value})
            },
            formatWebsite(website) {
                if (website.indexOf('http') === -1) {
                    website = `https://${website}`
                }

                this.updateCampus('website', website);
            },
            handleHideDialog() {
                this.resetCampusValidation()
                this.$emit('hideDialog')
            },
            resetCampusValidation() {
              this.$v.$reset();
            },
            isValidForm() {
              return this.$v.$invalid === false
            }
        },
    }
</script>
