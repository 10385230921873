import { PropertyService } from '@/services/property.service'

// State object
const state = {
    properties: [],
    schools: [],
    fetching: false,
    isLoadingProperties:false,
    isLoadingPropertyAmenities: false,
    isAddNewPropertyState: false,
    filterBedsValue: 0,
    filterBathsValue: 0,
    filterPriceValue: 1250,
    filterSortValue: 'asc',

    recordsPerPage: 10,
    currentPage: null,

    statusFilter: null,
    stateFilter: null,
    availableFilter: null,
    schoolFilter: null,
    campusFilter: null,
    searchFilter: null,

    totalPropertiesNoFilter: 0,
};

// Getter functions
const getters = {
    getIsLoadingProperties( state ) {
        return state.isLoadingProperties;
    },
    getProperties( state ) {
        return state.properties.data;
    },
    getIsAddNewPropertyState(state ) {
        return state.isAddNewPropertyState;
    },
    getPropertiesPerPage() {
        return state.recordsPerPage;
    },
    getPropertiesCurrentPage() {
        return state.properties.current_page
    },
    getPropertiesTotal( state ) {
        return state.properties.total;
    },
    getPropertiesTotalNoFilter( state ) {
        return state.totalPropertiesNoFilter;
    },
    getPropertiesIsFiltered() {
        if( state.stateFilter === null  &&
            state.statusFilter === null &&
            state.availableFilter === null &&
            state.schoolFilter === null &&
            state.searchFilter === null) {
                return false
            } else {
                return true
            }
    }
};

// Actions
const actions = {
    async getProperties({ commit, state }) {
        let params = `?paginate=${state.recordsPerPage}&sort=desc&appendPropertyUnits=true&appendState=true`;

        if (state.statusFilter) {
            params = `${params}&status=${state.statusFilter}`;
        }
        if (state.stateFilter) {
            params = `${params}&state=${state.stateFilter}`;
        }
        if (state.availableFilter) {
            params = `${params}&available=${state.availableFilter}`;
        }
        if (state.schoolFilter) {
            params = `${params}&school=${state.schoolFilter}`;
        }
        if (state.campusFilter) {
            params = `${params}&campus=${state.campusFilter}`;
        }
        if (state.searchFilter) {
            params = `${params}&search=${state.searchFilter}`;
        }
        if (state.currentPage) {
            params = `${params}&page=${state.currentPage}`
        }

        commit('setIsLoadingProperties', true);
        const properties = await PropertyService.getProperties(params);
        commit('setProperties', properties);
        commit('setIsLoadingProperties', false);

        if(!getters.getPropertiesIsFiltered()) {
            commit('setTotalPropertiesNoFilter', state.properties.total);
        }
    },
    async updateProperty({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.updateProperty(data);
        commit('setFetching', false);

        return response
    },
    async deletePropertyUnit({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.deletePropertyUnit(data);
        commit('setFetching', false);

        return response
    },
    async deletePropertyUnitAvailability({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.deletePropertyUnitAvailability(data);
        commit('setFetching', false);

        return response
    },
    async addNewPropertyRentSpecial({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.addNewPropertyRentSpecial(data);
        commit('setFetching', false);

        return response
    },
    async deletePropertyRentSpecial({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.deletePropertyRentSpecial(data);
        commit('setFetching', false);

        return response.data
    },
    async getPropertyCampuses({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.getPropertyCampuses(data);
        commit('setFetching', false);

        return response.data
    },
    async updatePropertyCampuses({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.updatePropertyCampuses(
            data.propertyId, {campuses: data.campuses}
        );
        commit('setFetching', false);

        return response.data
    },
    async getContactTypes({ commit }) {
        commit('setFetching', true);
        const response =  await PropertyService.getContactTypes();
        commit('setFetching', false);

        return response.data
    },
    async addPropertyContact({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.addPropertyContact(data);
        commit('setFetching', false);

        return response.data
    },
    async getPropertyContacts({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.getPropertyContacts(data);
        commit('setFetching', false);

        return response.data
    },
    async deletePropertyContact({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.deletePropertyContact(data);
        commit('setFetching', false);

        return response.data
    },
    async addPropertyDocument({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.addPropertyDocument(data);
        commit('setFetching', false);

        return response.data
    },
    async getPropertyDocuments({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.getPropertyDocuments(data);
        commit('setFetching', false);

        return response.data
    },
    async deletePropertyDocument({ commit }, data) {
        commit('setFetching', true);
        const response =  await PropertyService.deletePropertyDocument(data);
        commit('setFetching', false);

        return response.data
    },
};

// Mutations
const mutations = {
    setProperties(state, data) {
        state.properties = data;
    },
    setIsLoadingProperties(state, data) {
        state.isLoadingProperties = data;
    },
    setFetching(state, data) {
        state.fetching = data;
    },
    setIsAddNewPropertyState(state, data) {
        state.isAddNewPropertyState = data;
    },
    setPropertyStatusFilter(state, data) {
        state.statusFilter = data
    },
    setStateFilter(state, data) {
        state.stateFilter = data
    },
    setTotalPropertiesNoFilter(state, data) {
        state.totalPropertiesNoFilter = data;
    },
    setAvailableFilter(state, data) {
        state.availableFilter = data
    },
    setSchoolFilter(state, data) {
        state.schoolFilter = data
    },
    setCampusFilter(state, data) {
        state.campusFilter = data
    },
    setPropertySearchFilter(state, data) {
        state.searchFilter = data
    },
    setPropertyCurrentPage(state, data) {
        state.currentPage = data
    },
    setPropertiesPerPage(state, data) {
        state.recordsPerPage = data
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
