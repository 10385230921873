<template>
    <div class="px-8">
	    <v-row>
		    <v-col>
			    <v-row class="justify-start">
				    <v-col class="justify-start d-flex">
					    <h2 class="pr-6">Invitations</h2>
					    <v-btn depressed color="primary" class="text-capitalize font-weight-bold" @click="invitePeople">
						    <v-icon left size="18">mdi-plus</v-icon>
						    Invite People
					    </v-btn>
				    </v-col>
			    </v-row>
		    </v-col>
	    </v-row>

        <v-row>
            <v-col cols="12" md="2">
                <p>Invite Type</p>
                <v-select dense
                          outlined
                          v-model="type"
                          :items="inviteTypes"
                          @change="setFilterState('typeFilter', $event)"
                />
            </v-col>
            <v-col cols="12" md="2">
                <p>Status</p>
              <v-select dense
                        outlined
                        v-model="status"
                        :items="statuses"
                        @change="setFilterState('statusFilter', $event)"
              />
            </v-col>
            <v-col cols="12" md="2" class="pt-10">
              <v-btn text color="primary" @click="resetFilters">
                Clear Filters
              </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="invitations"
            :items-per-page="paginationItemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="isLoadingPagination || isCallingApi"
        >
          <template v-slot:item.status="props">
	          <v-chip small color="green" text-color="white" v-if="props.item.status === 'registered'">Registered</v-chip>
	          <v-chip small color="primary" text-color="white" v-else>Invited</v-chip>
          </template>
          <template v-slot:item.invitee="props">
            <a @click="showCurrentAgent(props.item.inviteable)" v-if="props.item.inviteable && props.item.inviteable.user && props.item.inviteable.user.name">{{props.item.inviteable.user.name}}</a>
            <span v-else>Available after registration</span>
          </template>
          <template v-slot:item.type="props">
	          {{formatType(props.item.type)}}
          </template>
	        <template v-slot:item.inviting_agent="props">
		        <a @click="showCurrentAgent(props.item.inviting_agent)" v-if="props.item.inviting_agent && props.item.inviting_agent.user && props.item.inviting_agent.user.name">{{props.item.inviting_agent.user.name }}</a>
		        <span v-else>Unknown Inviting Agent</span>
	        </template>
	        <template v-slot:item.last_email_sent="props">
		        {{formatUTCDate(props.item.last_email_sent)}}
	        </template>
          <template v-slot:item.created_at="props">
            {{formatDate(props.item.created_at)}}
          </template>
          <template v-slot:item.resend="props">
            <v-hover v-slot:default="{hover}">
	            <v-btn icon :color="hover ? 'success': ''"
	                   :elevation="hover ? 12 : 0"
	                   @click="resendInvite(props.item)"
	                   :disabled="props.item.status === 'registered'"
	            >
		            <v-icon>mdi-email-send</v-icon>
	            </v-btn>
            </v-hover>
          </template>
          <template v-slot:item.delete="props">
            <v-hover v-slot:default="{hover}">
	            <v-btn icon :color="hover ? 'red': ''"
	                   :elevation="hover ? 12 : 0"
	                   :disabled="props.item.status === 'registered'"
	                   @click="deleteInvite(props.item)"
	            >
		            <v-icon>mdi-delete</v-icon>
	            </v-btn>
            </v-hover>
          </template>
        </v-data-table>

				<Pagination state="Invitations" />

	    <v-dialog width="1000" v-model="showCurrentAgentDialog">
		    <Agent :agent="currentAgent" v-on:hideDialog="showCurrentAgentDialog=false" is-dialog="true" source="agent"/>
	    </v-dialog>

	    <v-dialog v-model="showInvitationDialog" max-width="500">
		    <InvitationDialog v-on:hideDialog="showInvitationDialog= false" />
	    </v-dialog>

    </div>
</template>

<script>
    import Pagination from "@/components/Pagination";
    import moment from "moment"
    import {mapGetters, mapActions, mapMutations} from 'vuex'
    import Agent from "@/components/Agents/Agent";
    import {displayMessage} from "@/utils/utils";
    import InvitationDialog from "@/components/Agents/InvitationDialog";
    import InvitationService from "@/services/invitation.service";

    export default {
        name: 'Invitations',
        components: {InvitationDialog, Agent, Pagination},
        data () {
            return {
                headers: [
                    {
                        text: 'Email',
                        align: 'start',
                        value: 'email'
                    },
                  { text: 'Status', value: 'status' },
                  { text: 'Invitee', value: 'invitee' },
                  { text: 'Type', value: 'type' },
                  { text: 'Invited By', value: 'inviting_agent' },
	                { text: 'Last Email Sent', value: 'last_email_sent' },
	                { text: 'Date Created', value: 'created_at' },
	                { text: '', value: 'resend' },
	                { text: '', value: 'delete' },
                ],
                statuses: ['Registered', 'Invited'],
	              inviteTypes: ['Agent', 'PropertyAgent'],
                currentAgent: null,
	              showCurrentAgentDialog: false,
	              showInvitationDialog: false,
	              isCallingApi: false,

                type: null,
                status: null
            }
        },
	      watch: {
        	user() {
        		this.getPagination()
	        }
	      },
        computed: {
          ...mapGetters('Invitations', {
	          invitations: 'invitations',
            isLoadingPagination: 'isLoadingPagination',
            paginationItemsPerPage: 'paginationItemsPerPage'
          }),
          user() {
            return this.$store.getters.getUser;
          }
        },
        methods: {
          ...mapMutations('Invitations', {
            setState: 'setState',
	          updateInvitations: 'updateInvitations',
	          deleteInvitation: 'deleteInvitation'
          }),
          ...mapActions('Invitations', ['getPagination']),
          formatDate(date) {
            return moment(date).format('l')
          },
	        formatUTCDate(date) {
          	return moment(date).local().format('l hh:mm A')
	        },
	        formatType(type) {
          	switch (type) {
          		case 'propertyagent':
          			return 'Property Agent'
	            default:
	              return type.charAt(0).toUpperCase() + type.slice(1)
	          }
	        },
          setFilterState(key, value) {
            this.setState({key, value})
            this.getPagination()
          },
          resetFilters() {
            this.type = null
            this.status = null

            this.setState({key: 'statusFilter', value: null})
            this.setState({key: 'typeFilter', value: null})

            this.getPagination()
          },
	        showCurrentAgent(agent) {
            this.currentAgent = agent
            this.showCurrentAgentDialog = true
          },
	        invitePeople() {
		        if (!this.user.agent.is_admin_agent) {
			        displayMessage('error', 'You do not have permission to invite an agent');
			        return
		        }

		        this.showInvitationDialog = true;
	        },
	        async resendInvite(invite) {
          	let error = false
          	this.isCallingApi = true

          	const updatedInvite = await InvitationService.sendInvite(invite.id).catch(() => {
          		error = true
	          })

		        this.isCallingApi = false
		        if (error) return

		        //update the store
		        this.updateInvitations(updatedInvite)
	        },
	        async deleteInvite(invite) {
		        let error = false
		        this.isCallingApi = true

          	await InvitationService.deleteInvite(invite.id).catch(() => {
		          error = true
	          })

		        this.isCallingApi = false
		        if (error) return

		        //update the store
		        this.deleteInvitation(invite.id)
	        }
        },
        beforeMount() {
             if (Object.keys(this.user).length === 0) {
               return
             }

	        this.getPagination()
        }
    }
</script>

<style scoped>
  .chip {
    width: 80px;
    display: flex;
    justify-content: center;
  }

  a {
    font-size: 14px
  }
</style>
