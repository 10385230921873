<template>
    <v-card width="1111">
        <v-card-title>
            <v-row class="justify-space-between px-4 py-8">
                <slot name="title"></slot>
                <v-btn icon @click="$emit('hideDialog')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-tabs mobile-break-point="1" v-model="headerTab">
                <v-tab>School</v-tab>
                <v-tab v-if="showContactTab">Contact</v-tab>
            </v-tabs>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
            <v-tabs-items v-model="headerTab">
                <v-tab-item>
                  <h2 class="my-4">Basic Info</h2>
                  <v-row>
                    <v-col>
                      <h5>Name</h5>
                      <v-text-field outlined dense
                                    v-model="school.name"
                                    :error-messages="nameErrors"
                                    @input="$v.school.name.$touch()"
                                    @blur="$v.school.name.$touch()"
                                    @change="updateSchool('name', $event)"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <h5>Website</h5>
                      <v-text-field outlined dense
                                    v-model="school.website"
                                    @change="updateSchool('website', $event)"
                                    @blur="formatWebsite"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <h2 class="my-4">
                    Campuses
                    <v-btn color="primary" class="ml-3" @click="showNewCampusDialog = true">Add Campus</v-btn>
                  </h2>
                    <slot name="campuses"></slot>
                </v-tab-item>

                <v-tab-item v-if="showContactTab">
                    <v-divider></v-divider>
                    <h3 class="mt-8 mb-3">Contacts</h3>
                    <SchoolContacts :school="school"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <slot name="card-actions" :$v="$v"></slot>

      <v-dialog v-if="showNewCampusDialog" v-model="showNewCampusDialog" width="1111" scrollable>
        <NewCampus
            :school="school"
            @hideDialog="showNewCampusDialog=false"
            @addCampus="$emit('addCampus', $event)"
        ></NewCampus>
      </v-dialog>
    </v-card>
</template>

<script>
    import SchoolContacts from "./SchoolContacts.vue"
    import NewCampus from "@/components/Campuses/NewCampus"
    import {required} from "vuelidate/lib/validators"

    export default {
        name: 'School',
        props: {
          school: {
            type: Object,
            required: true
          },
          showContactTab: {
            type: Boolean,
            default: true
          },
        },
        validations : {
          school: {
            name: { required }
          }
        },
        components: {
          NewCampus,
          SchoolContacts,
        },
        data() {
            return {
                headerTab: null,
                showNewCampusDialog: false
            }
        },
        computed: {
            nameErrors () {
              const errors = []
              if (!this.$v.school.name.$dirty) return errors
              !this.$v.school.name.required && errors.push('School name is required');
              return errors
            }
        },
        methods: {
            formatWebsite() {
              if (
                  this.school.website.indexOf('http') !== -1
                  || this.school.website === ''
              ) {
                return
              }

              this.$emit('updateSchool', {website: `https://${this.school.website}`});
            },
            updateSchool: async function(fieldName, newValue) {
              this.$emit('updateSchool', {[fieldName] : newValue})
            }
        }
    }
</script>
